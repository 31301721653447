<template>
  <PageTitle title="Dashboard" :routes="routes" />
  <div class="dashboard">
    <DashboardComponent v-if="!loadingDashboard" />
    <template v-if="loadingDashboard">
      <div class="py-3">
        <div
          class="w-full flex items-center justify-center h-[calc(100vh_-_120px)]"
        >
          <ProgressLoader label="Preparing Dashboard" />
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import axios, { AxiosResponse } from "axios";
import DashboardComponent from "@/components/Dashboard/DashboardComponent.vue";
import PageTitle from "@/components/Layout/Partials/PageTitle.vue";
import { setTeacherDashboard } from "@/store/dashboard/dashboard-dispatch";
import ProgressLoader from "@/components/utilities/ProgressLoader.vue";
import { TRoute } from "@/components/Layout/Partials/PageTitle.vue";

@Component({
  components: {
    DashboardComponent,
    PageTitle,
    ProgressLoader,
  },
})
export default class DashboardView extends Vue {
  public user!: object | null;

  routes: TRoute[] = [
    {
      title: "Dashboard",
      routeName: "Dashboard",
      current: true,
    },
  ];
  loadingDashboard = true;
  /**
   * getUser
   * get authenticated user
   */
  public async getUser() {
    try {
      const response: AxiosResponse = await axios.get(`teachers/me`);
      if (response.data.ok) {
        console.log("response.data.data");
        console.log(response.data.data);
        this.user = response.data.data;
        // this.$store.dispatch("setUser", response.data.data);
        this.$store.dispatch("setUser", response.data.data);
      } else {
        console.error(response.data.message);
        this.$router.push("/login");
      }
    } catch (error) {
      this.$router.push("/login");
      if (error instanceof ReferenceError) {
        console.error(error.message);
      } else {
        // throw error;
      }
    }
  }

  async loadAssignments() {
    await this.$store.dispatch(setTeacherDashboard);
  }

  /**
   * mounted
   */
  async mounted() {
    localStorage.setItem("onboarding", "hide");
    await this.getUser();
    this.loadingDashboard = false;
    await this.loadAssignments();
  }
}
</script>

<style scoped lang="scss">
body {
  margin: 0;
  overflow: hidden !important;
}
</style>
