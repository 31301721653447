<template>
  <ModalUtility title="Edit Student Details" v-model="modalOpen">
    <template #content>
      <div v-if="showResult" class="w-full py-7 px-4">
        <div v-if="successStudents.length > 0">
          <AppTypographyText
            label="The following student have beed updated successfully:"
            variant="bd"
            type="body"
            class="mb-2"
          />
          <AppTypographyText
            v-for="(student, i) in successStudents"
            :key="i"
            :label="`• ${student.profile.firstName} ${student.profile.lastName}`"
            type="body"
            class="mb-7"
          />
        </div>
        <div v-if="failedStudents.length > 0">
          <AppTypographyText
            label="Failed to update the following student details:"
            variant="bd"
            type="subtitle"
            class="mb-2"
          />
          <AppTypographyText
            v-for="(student, i) in failedStudents"
            :key="i"
            :label="`• ${student.profile.firstName} ${student.profile.lastName}`"
            type="body"
            class="mb-7"
          />
        </div>
        <div class="w-full flex justify-end items-center">
          <AppButton type="submit" @click="handleCloseModal">
            <template #icon_left>
              <span v-html="icon.checkBlack"></span>
            </template>
            Finish
          </AppButton>
        </div>
      </div>
      <div v-else class="w-full py-7 px-4 text-center">
        <AppTypographyText
          label="Some of your student accounts don't have an email. Add them below to continue."
          variant="bd"
          type="title"
          class="mb-7"
        />
        <div
          v-if="studentDataClone && studentDataClone.length > 0"
          class="flex flex-col gap-2 mb-5"
        >
          <StudentItem
            v-for="(item, index) in studentDataClone"
            :key="index"
            :index="index"
            :student="item"
            @onStudentUpdate="handleStudentUpdate"
            @onRemoveStudent="handleRemoveStudent"
          />
        </div>

        <div class="w-full flex justify-end items-center">
          <AppButton
            type="submit"
            :loading="isLoading"
            :disabled="isLoading"
            @click="updateStudentAccounts"
          >
            <template #icon_left>
              <span v-html="icon.checkBlack"></span>
            </template>
            Update Student Accounts
          </AppButton>
        </div>
      </div>
    </template>
  </ModalUtility>
</template>

<script lang="ts">
import { Component, Prop, Vue, Model, Watch } from "vue-facing-decorator";
import { useToast } from "primevue/usetoast";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { icons as AppIcons } from "@/utils/icons";
import ModalUtility from "@/components/utilities/ModalUtility.vue";
import FormTextInput from "@/components/Layout/Forms/FormTextInput.vue";
import StudentService from "@/services/StudentService";
import { AxiosResponse } from "axios";
import FormField from "@/components/Layout/Forms/FormField.vue";
import emitter from "@/config/emitter";
import AppTypographyText from "./../../Layout/Typhography/AppTypographyText.vue";
import StudentItem from "./StudentItem.vue";
import { AssignmentSubmissionSummaryStudent } from "@/store/assignment/assignmentTypes";

@Component({
  components: {
    AppButton,
    ModalUtility,
    FormTextInput,
    FormField,
    AppTypographyText,
    StudentItem,
  },
})
export default class AddEmailForStudentModal extends Vue {
  private studentService: StudentService = new StudentService();
  eventBus = emitter;

  @Model({
    default: false,
    required: false,
  })
  modalOpen!: boolean;

  @Prop({
    type: Array,
    required: true,
  })
  studentData!: AssignmentSubmissionSummaryStudent[];

  @Prop({
    type: String,
    required: true,
  })
  classUuid!: string;

  studentDataClone!: AssignmentSubmissionSummaryStudent[];

  icon = AppIcons;
  toast = useToast();

  isLoading = false;

  firstName = "";
  lastName = "";
  email = "";

  showResult = false;
  successStudents: AssignmentSubmissionSummaryStudent[] = [];
  failedStudents: AssignmentSubmissionSummaryStudent[] = [];

  studentDataPayload!: AssignmentSubmissionSummaryStudent[];

  @Watch("modalOpen")
  modalOpenWatcher(value: boolean) {
    if (!value) {
      this.showResult = false;
    }
  }

  handleRemoveStudent(index: number) {
    const studentData = structuredClone(this.studentDataClone);
    studentData.splice(index, 1);
    this.studentDataClone = studentData;
  }

  mounted() {
    this.studentDataClone = structuredClone(this.studentData);
  }

  handleStudentUpdate(data: {
    index: number;
    student: AssignmentSubmissionSummaryStudent;
  }) {
    const studentData = structuredClone(this.studentDataClone);
    studentData[data.index] = data.student;
    this.studentDataPayload = studentData;
  }

  validateForm() {
    const studentDataPayload = structuredClone(this.studentDataPayload);
    const incompleteDetails = studentDataPayload.filter(
      (student: AssignmentSubmissionSummaryStudent) =>
        !student.profile.email ||
        !student.profile.firstName ||
        !student.profile.lastName
    );

    if (incompleteDetails && incompleteDetails.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  handleCloseModal() {
    this.modalOpen = false;
  }

  async updateStudentAccounts() {
    try {
      const isValid = this.validateForm();
      if (isValid) {
        this.isLoading = true;
        const successStudents: AssignmentSubmissionSummaryStudent[] = [];
        const failedStudents: AssignmentSubmissionSummaryStudent[] = [];
        for (let i = 0; i < this.studentDataPayload.length; i++) {
          const item = this.studentDataPayload[i];
          const profile = item.profile;
          const payload = {
            firstName: profile.firstName,
            lastName: profile.lastName,
            email: profile.email,
          };
          const response: AxiosResponse = await this.studentService.putStudent(
            payload,
            item.uuid,
            this.classUuid
          );
          if (response.data.ok) {
            successStudents.push(item);
          } else {
            failedStudents.push(item);
          }
        }
        this.successStudents = successStudents;
        this.failedStudents = failedStudents;
        this.showResult = true;

        this.eventBus.emit("REFRESH_SELECTED_CLASS");
      } else {
        this.showToast("error", `Please fill up all fields`);
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  showToast(
    severity: "success" | "info" | "warn" | "error" | undefined,
    message: string
  ) {
    this.toast.add({
      severity: severity,
      detail: message,
      life: 5000,
    });
  }
}
</script>
