<template>
  <div class="flex justify-between items-center mb-[31px]">
    <h6 class="text-flohh-h6 font-flohh-font-bold">
      {{ isEdit ? "Edit Assignment" : "Create New Assignment" }}
    </h6>
  </div>
  <hr class="mb-[26px] w-full" />
  <FormField
    label="Name"
    placeholder="i.e. 'Romeo and Juliet Analytical Essay'"
    :error="error?.assignment"
    v-model="title"
    type="text"
    :required="true"
    toolTip="Use this field to give your assignment a name that you and your students can use to easily identify it."
    :background="false"
    class="mb-[22px]"
  />
  <div class="mb-[48px]">
    <AppTypographyText
      variant="bd"
      type="title"
      label="Instructions"
      class="mb-[5px]"
    />
    <AppTypographyText
      variant="rg"
      type="caption"
      label="Choose one of the options below to upload your assignment instructions."
      class="mb-[16px]"
    />
    <div v-if="isEdit && fetchingInstructions" class="mx-auto">
      <ProgressLoader
        label="Fetching Instructions"
        labelVariant="md"
        labelType="subtitle"
      />
    </div>
    <div v-else>
      <div class="px-2 flex gap-10 mb-5">
        <div class="flex gap-2 items-center">
          <FormRadioButton
            v-model="selectedInstruction"
            name="text"
            inputId="text"
            :value="0"
            :disabled="selectedInstruction != 0 && currentFile.length > 0"
          />
          <label
            for="text"
            class="font-flohh-font-medium text-flohh-text-body cursor-pointer"
            :class="[
              {
                'cursor-default text-flohh-neutral-70':
                  selectedInstruction != 0 && currentFile.length > 0,
              },
            ]"
            >Text Entry</label
          >
        </div>
        <div class="flex gap-2 items-center">
          <FormRadioButton
            v-model="selectedInstruction"
            name="file-upload"
            inputId="file-upload"
            :value="1"
            :disabled="
              selectedInstruction != 1 &&
              (currentFile.length > 0 || textInstructionContent.length > 0)
            "
          />
          <label
            for="file-upload"
            class="font-flohh-font-medium text-flohh-text-body cursor-pointer"
            :class="[
              {
                'cursor-default text-flohh-neutral-70':
                  selectedInstruction != 1 &&
                  (currentFile.length > 0 || textInstructionContent.length > 0),
              },
            ]"
            >File Upload</label
          >
        </div>
        <div class="flex gap-2 items-center">
          <FormRadioButton
            v-model="selectedInstruction"
            name="google-drive"
            inputId="google-drive"
            :value="2"
            :disabled="
              selectedInstruction != 2 &&
              (currentFile.length > 0 || textInstructionContent.length > 0)
            "
          />
          <label
            for="google-drive"
            class="font-flohh-font-medium text-flohh-text-body cursor-pointer"
            :class="[
              {
                'cursor-default text-flohh-neutral-70':
                  selectedInstruction != 2 &&
                  (currentFile.length > 0 || textInstructionContent.length > 0),
              },
            ]"
            >Google Drive</label
          >
        </div>
      </div>
      <TextInstructionComponent
        v-if="selectedInstruction === 0"
        v-model="textInstructionContent"
        @onInstructionUpdate="handleInstructions"
      />
      <FileUploadInstructionComponent
        v-else-if="selectedInstruction === 1"
        v-model="currentFile"
        :progress="progress"
        @onInstructionUpdate="handleInstructions"
      />
      <GoogleDriveInstructionComponent
        v-else
        v-model="currentFile"
        :progress="progress"
        @onInstructionUpdate="handleInstructions"
      />
    </div>
  </div>

  <hr class="mb-[25px]" />
  <div class="flex justify-between">
    <AppButton
      @click="handeSaveAsUnassigned"
      text
      blackLabel
      v-tooltip.top="{
        value: 'Assignment will not be visible to students',
        showDelay: 500,
      }"
      :disabled="isSaving"
      :loading="loadingSaveUnassigned"
    >
      Save as Draft
    </AppButton>
    <AppButton
      textColor="text-[#000000]"
      type="submit"
      iconLeft="checkBlack"
      label="Assign"
      @click="handleClickSave(true)"
      :disabled="isSaving"
      :loading="loadingSaveAssign"
    >
      {{ isEdit ? "Save" : "Assign" }}
    </AppButton>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Emit, Watch } from "vue-facing-decorator";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { icons } from "@/utils/icons";
import { ErrorProps } from "../type";
import FormField from "@/components/Layout/Forms/FormField.vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import TextInstructionComponent from "./TextInstructionComponent.vue";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import FileUploadInstructionComponent from "./FileUploadInstructionComponent.vue";
import GoogleDriveInstructionComponent from "./GoogleDriveInstructionComponent.vue";
import { TitleAndInstructions } from "../type";
import ErrorSpan from "@/components/utilities/ErrorSpan.vue";
import emitter from "@/config/emitter";
import FormRadioButton from "@/components/Layout/Forms/FormRadioButton.vue";
import { AssignmentData } from "@/store/assignment/assignmentTypes";
import MediaService from "@/services/MediaService";
import { dataURLtoFile } from "@/utils/helper";
import ProgressLoader from "@/components/utilities/ProgressLoader.vue";

@Component({
  components: {
    AppButton,
    FormField,
    TabView,
    TabPanel,
    TextInstructionComponent,
    AppTypographyText,
    ErrorSpan,
    FormRadioButton,
    FileUploadInstructionComponent,
    GoogleDriveInstructionComponent,
    ProgressLoader,
  },
})
export default class MainPanelComponent extends Vue {
  private mediaService: MediaService = new MediaService();
  icons = icons;
  eventBus = emitter;

  @Prop({
    type: Number,
    default: 0,
  })
  progress!: number;

  @Prop({
    type: Object,
    required: true,
  })
  error!: ErrorProps;

  @Prop({
    type: Boolean,
    default: false,
  })
  isSaving!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  isEdit!: boolean;

  isUnassignModalOpen = false;
  selectedInstruction = 0;
  textInstructionContent = "";

  fetchingInstructions = true;

  title = "";
  currentFile: File[] = [];

  details: TitleAndInstructions = {
    title: "",
    fileInstruction: null,
    textInstruction: "",
  };

  loadingSaveUnassigned = false;
  loadingSaveAssign = false;
  saveButtonClicked!: "assigned" | "unassigned";

  @Watch("isSaving")
  isSavingWatcher(value: boolean) {
    if (!value) {
      this.loadingSaveUnassigned = false;
      this.loadingSaveAssign = false;
    } else {
      if (this.saveButtonClicked === "unassigned") {
        this.loadingSaveUnassigned = true;
      } else {
        this.loadingSaveAssign = true;
      }
    }
  }

  @Watch("title")
  titleWatcher(value: string) {
    this.details.title = value;
    this.$emit("onDetailsUpdate", this.details);
  }

  async mounted() {
    this.eventBus.on("CLEAR_FIELDS", () => {
      this.title = "";
      this.handleInstructions("");
    });

    if (this.isEdit) {
      this.fetchingInstructions = true;
      // Fill up state model to update the parent component
      const selectedAssignment: AssignmentData = structuredClone(
        this.$store.state.assignment.selectedAssignment
      );
      this.title = selectedAssignment.title;

      if (selectedAssignment.assignmentType === "wysiwyg") {
        this.selectedInstruction = 0;

        if (selectedAssignment.component.content) {
          this.textInstructionContent = selectedAssignment.component.content;
          this.handleInstructions(selectedAssignment.component.content);
        }
      } else {
        const fileData: File[] = await this.handleFetchMedia(
          selectedAssignment.component.media
        );
        this.selectedInstruction = 1;

        this.handleInstructions(fileData);
      }

      this.fetchingInstructions = false;
    }
  }

  async handleFetchMedia(uuid: string) {
    try {
      const response = await this.mediaService.getMedia(uuid);
      const files: File[] = [];
      if (response.data.ok) {
        const responseData = response.data.data;
        const file = dataURLtoFile(
          `data:${responseData.mimeType};base64,${responseData.data}`,
          `${responseData.name}`
        );

        files.push(file);
        this.currentFile = Array.from(files);
        return files;
      } else {
        throw new Error(response.data);
      }
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  @Watch("selectedInstruction")
  activeWatcher(value: number) {
    this.$emit("onTabChange", value);
  }

  tabs = [
    {
      heading: "Text Entry",
      component: TextInstructionComponent,
    },
    {
      heading: "File Upload",
      component: FileUploadInstructionComponent,
    },
    {
      heading: "Google Drive",
      component: GoogleDriveInstructionComponent,
    },
  ];

  handleInstructions(value: string | File[]) {
    this.details.fileInstruction =
      typeof value === "object" && value.length > 0 ? value : null;
    this.details.textInstruction = typeof value === "string" ? value : "";
    this.$emit("onDetailsUpdate", this.details);
  }

  @Emit("onClickUnassign")
  handeSaveAsUnassigned() {
    this.saveButtonClicked = "unassigned";
    return;
  }

  handleClickSave(value: boolean) {
    this.saveButtonClicked = "assigned";
    if (this.isEdit) {
      // For updating assignment
      this.$emit("onClickSave");
    } else {
      // For creating assignment
      this.$emit("onClickAssign", value);
    }
    return value;
  }
}
</script>

<style scoped lang="scss"></style>
