<template>
  <div class="flex relative flex-col gap-5">
    <ClassesTabHeaderComponent
      v-model="selectedAssignmentFilter"
      :assignments="assignmentList"
      :selectedAssignment="selectedAssignment"
      :loadingAssignments="loadingAssignments"
      tabType="learning-goal"
      @onSelectAssignment="handleSelectAssignment"
      :loadingSummary="tableLoading"
    />
    <div class="h-full w-full rounded-lg p-5 bordered lg-section">
      <div
        v-if="tableLoading"
        class="flex flex-col gap-2 justify-center items-center bg-flohh-neutral-95 rounded-[8px] h-[500px] w-full"
      >
        <ProgressLoader
          label="Loading learning goals"
          labelVariant="md"
          labelType="subtitle"
        />
      </div>

      <div
        v-if="!tableLoading && assignments.length === 0"
        class="flex justify-center flex-col items-center bg-flohh-neutral-95 rounded-[8px] h-[500px] w-full space-y-4 mt-5"
      >
        <AppTypographyText
          variant="bd"
          type="title"
          label="Welcome to your new class!"
        />
        <AppTypographyText
          variant="md"
          type="subtitle"
          label="Get started by creating an assignment or jump straight to uploading some submissions to mark."
        />
        <div class="flex justify-center gap-10 items-center">
          <AppButton type="submit" @click="handleAddAssignment">
            <span v-html="icon.plusBlack" class="mr-2"></span>
            Assignment
          </AppButton>
          <AppButton type="submit" @click="toggleBulkUpload">
            <template #icon_left>
              <span v-html="icon.uploadBlack"></span>
              Submissions
            </template>
          </AppButton>
        </div>
      </div>
      <div v-else>
        <ClassesTableComponent
          v-if="selectedAssignmentFilter === 'all' && !tableLoading"
          :classUuid="classUuid"
          :data="learningGoals"
          :assignment="selectedAssignment"
          :assignments="assignments"
          tabType="all-learning-goal"
          :hideHeader="hideHeader"
          :isFull="true"
          :sortDefault="sortDefault"
          @onSort="handleSort"
          @onSearch="handleSearch"
        >
          <Column
            :colspan="2"
            frozen
            :style="{ padding: '0px', height: '3.5em', zIndex: 1 }"
          >
            <template #body="props">
              <div class="class-custom-cell">
                <CustomTooltip :tooltip="props.data.email"
                  ><template #body>{{
                    `${props.data.firstName} ${props.data.lastName}`
                  }}</template></CustomTooltip
                >
              </div>
            </template>
          </Column>
          <div v-for="(assignment, index) in assignments" :key="index">
            <Column
              :style="{ borderLeft: index !== 0 ? '5px solid #d9d9d9' : '' }"
            >
              <template #body="{ data }">
                <Ellipsis
                  width="300px"
                  :content="
                    getColumnDataAll(data.assignments[index], 'learningGoal')
                  "
                />
              </template>
            </Column>
            <Column>
              <template #body="{ data }">
                <Ellipsis
                  width="300px"
                  :content="
                    getColumnDataAll(data.assignments[index], 'reflection')
                  "
                />
              </template>
            </Column>
          </div>
        </ClassesTableComponent>
        <ClassesTableComponent
          v-if="selectedAssignmentFilter !== 'all' && !tableLoading"
          :classUuid="classUuid"
          :data="specificAssignmentData"
          :assignment="selectedAssignment"
          tabType="learning-goal"
          :hideHeader="hideHeader"
          :isFull="true"
          :sortDefault="sortDefault"
          @onSort="handleSort"
          @onSearch="handleSearch"
        >
          <div
            class="flex justify-between items-center h-[3em] pl-4 lg-actions"
          >
            <div class="select-all">
              <Checkbox
                v-model="selectedAllStudent"
                :binary="true"
                :disabled="!hasLearningGoalSubmitted"
                @click="handleSelectAllStudent(specificAssignmentData)"
              ></Checkbox>
              <AppDropdownButton
                size="sm"
                @clickMenu="handleClickSortStatus"
                :menus="statuses"
                type="default"
                width="w-40"
                text
                dropdownIcon="arrowDropDownBlack"
                :toRight="true"
              />
            </div>
            <div
              v-if="selectedStudents.length > 0"
              class="flex items-center justify-center"
            >
              <AppButton
                class="lg-actions-btn"
                @click="() => handleActionOnSelected('accepted')"
              >
                <template #icon_left>
                  <span v-html="icon.checkBlack"></span>
                  Approve
                </template>
              </AppButton>
              <AppButton
                class="lg-actions-btn"
                @click="() => handleActionOnSelected('rejected')"
              >
                <template #icon_left>
                  <span v-html="icon.closeBlack"></span>
                </template>
                Reject
              </AppButton>
            </div>
          </div>

          <Column headerStyle="width: 3rem">
            <template #body="{ data }">
              <Checkbox
                v-if="data.status === 'learning goal submitted'"
                :modelValue="isRowChecked(data)"
                :binary="true"
                :inputId="data.id"
                :value="data.id"
                @click="handleStudentSelect(data)"
              ></Checkbox>
            </template>
          </Column>
          <Column header="Student Name" style="width: 15%">
            <template #body="props">
              <CustomTooltip :tooltip="props.data.email"
                ><template #body>{{
                  `${props.data.firstName} ${props.data.lastName}`
                }}</template></CustomTooltip
              >
            </template>
          </Column>
          <Column header="Summary Comment" :style="{ maxWidth: '350px' }">
            <template #body="{ data }">
              <Ellipsis width="100%" :content="data.summaryContent"
            /></template>
          </Column>
          <Column header="Learning Goal" :style="{ maxWidth: '350px' }">
            <template #body="{ data }">
              <Ellipsis width="100%" :content="data.learningGoal"
            /></template>
          </Column>
          <Column :style="{ textAlign: 'center', lineHeight: 1.1 }">
            <template #header
              ><div class="mx-auto">Marking Status</div></template
            >
            <template #body="{ data }">
              <!-- unassigned -->
              <div
                v-if="data.status == 'assigned' && !selectedAssignment.visible"
                class="bg-flohh-neutral-85 px-4 py-2 rounded-lg w-max mx-auto"
              >
                <span class="text-flohh-text-small font-flohh-font-medium">
                  Unassigned
                </span>
              </div>

              <!-- assigned -->
              <div
                v-if="data.status == 'assigned' && selectedAssignment.visible"
                class="bg-flohh-secondary-dark-blue px-4 py-2 rounded-lg w-max mx-auto"
              >
                <span class="text-flohh-text-small font-flohh-font-medium">
                  Assigned
                </span>
              </div>

              <!-- unmarked -->
              <div
                v-else-if="data.status == 'unmarked'"
                class="bg-flohh-secondary-purple px-4 py-2 rounded-lg w-max mx-auto"
              >
                <span class="text-flohh-text-small font-flohh-font-medium">
                  Submitted
                </span>
              </div>

              <!-- marked -->
              <div
                v-if="data.status == 'marked'"
                class="bg-flohh-secondary-pink px-4 py-2 rounded-lg w-max mx-auto"
              >
                <span class="text-flohh-text-small font-flohh-font-medium">
                  Marked
                </span>
              </div>

              <!-- making in progress -->
              <div
                v-if="data.status == 'marking in progress'"
                class="bg-flohh-secondary-yellow px-4 py-2 rounded-lg w-max mx-auto"
              >
                <span class="text-flohh-text-small font-flohh-font-medium">
                  Marking In Progress
                </span>
              </div>

              <!-- requested -->
              <div
                v-if="data.status == 'learning goal requested'"
                class="bg-flohh-secondary-blue px-4 py-2 rounded-lg w-max mx-auto"
              >
                <span class="text-flohh-text-small font-flohh-font-medium">
                  Learning Goal Requested</span
                >
              </div>

              <!-- student submitted the learning goal -->
              <div
                v-if="data.status == 'learning goal submitted'"
                class="bg-flohh-secondary-orange px-4 py-2 rounded-lg w-max mx-auto"
              >
                <span class="text-flohh-text-small font-flohh-font-medium">
                  Learning Goal Submitted
                </span>
              </div>

              <!-- completed -->
              <div
                v-if="data.status == 'completed'"
                class="bg-flohh-secondary-green px-4 py-2 rounded-lg w-max mx-auto"
              >
                <span class="text-flohh-text-small font-flohh-font-medium">
                  Completed
                </span>
              </div>
            </template>
          </Column>
          <template #empty> No Students found. </template>
        </ClassesTableComponent>
      </div>
    </div>

    <UploadSubmissionModal
      v-model="isBulkUploadOpen"
      :defaultClass="classUuid"
      :checkStatus="false"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-facing-decorator";
import Column from "primevue/column";
import ClassesTableComponent from "./ClassesTableComponent.vue";
import { LearningGoal, IMenuRef } from "./types";
import { EVENT_ICONS, LEARNING_GOALS } from "./data";
import Menu from "primevue/menu";
import AppButton from "../Layout/Buttons/AppButton.vue";
import { icons as AppIcons } from "@/utils/icons";
import Checkbox from "primevue/checkbox";
import FormTextInput from "../Layout/Forms/FormTextInput.vue";
import FormInputDropdown from "../Layout/Forms/FormInputDropdown.vue";
import ClassesTabHeaderComponent from "./ClassesTabHeaderComponent.vue";
import {
  AssignmentList,
  AssignmentData,
  AssigmentSubmissionSummaryData,
  LearningGoalSpecificAssignmentData,
  AssignmentSubmissionSummaryStudents,
  LearningGoalAllAssignmentData,
  SubmissionLearningGoals,
  AssignmentSubmissionLearningGoals,
  AllLearningGoals,
  LearningGoalAssignment,
  LearningGoalStudents,
} from "@/store/assignment/assignmentTypes";
import { setAssignmentSubmissionSummary } from "@/store/assignment/assignment-dispatch";
import AppTypographyText from "../Layout/Typhography/AppTypographyText.vue";
import LearningGoalService from "@/services/LearningGoalService";
import { AxiosResponse } from "axios";
import { useToast } from "primevue/usetoast";
import ColumnGroup from "primevue/columngroup";
import Row from "primevue/row";
import ModalUtility from "@/components/utilities/ModalUtility.vue";
import CreateAssignment from "../Assignment/CreateAssignment/CreateAssignment.vue";
import emitter from "@/config/emitter";
import AppDropdownButton from "@/components/Layout/Buttons/AppDropdownButton.vue";
import { sortLearningGoalsTable } from "@/utils/sort";
import Ellipsis from "@/components/utilities/Ellipsis.vue";
import CustomTooltip from "../utilities/CustomTooltip.vue";
import ProgressLoader from "@/components/utilities/ProgressLoader.vue";
import UploadSubmissionModal from "@/components/utilities/UploadSubmissionModal.vue";

@Component({
  components: {
    ClassesTableComponent,
    Column,
    Menu,
    AppButton,
    Checkbox,
    FormTextInput,
    FormInputDropdown,
    ClassesTabHeaderComponent,
    AppTypographyText,
    Row,
    ColumnGroup,
    ModalUtility,
    CreateAssignment,
    AppDropdownButton,
    Ellipsis,
    CustomTooltip,
    ProgressLoader,
    UploadSubmissionModal,
  },
})
export default class ClassesLearningGoalsComponent extends Vue {
  icon = AppIcons;
  private learningGoalService = new LearningGoalService();
  toast = useToast();
  eventBus = emitter;

  @Prop({
    type: [String, Number],
    required: true,
  })
  classUuid!: string | number;

  @Prop({
    default: [],
    required: true,
  })
  assignments!: AssignmentData[];

  @Prop({
    default: {},
    required: true,
  })
  selectedAssignmentData!: AssignmentData;

  @Prop({
    default: {},
    required: true,
  })
  assignmentSummaryData!: AssigmentSubmissionSummaryData;

  @Prop({
    type: Boolean,
    required: true,
  })
  loadingAssignments!: boolean;

  @Prop({
    type: Boolean,
    required: true,
  })
  loadingSummary!: boolean;

  destroyed = false;
  sidebarTitle = "All Assignments";
  data: LearningGoal[] = LEARNING_GOALS;
  eventIcons = EVENT_ICONS;
  assignmentList: AssignmentList[] = [];
  selectedAssignment!: AssignmentData;
  tableLoading = true;
  assignmentSummary!: AssigmentSubmissionSummaryData;
  specificAssignmentData!: LearningGoalSpecificAssignmentData[];
  specificAssignmentDataClone!: LearningGoalSpecificAssignmentData[];
  allAssignmentData!: LearningGoalAllAssignmentData;
  learningGoals!: AllLearningGoals[];
  learningGoalsClone!: AllLearningGoals[];
  isBulkUploadOpen = false;
  sortDefault = {
    sortBy: "last_alphabetical",
    sortData: [
      { label: "Last Name: Alphabetical", value: "last_alphabetical" },
      { label: "First Name: Alphabetical", value: "first_alphabetical" },
      // { label: "Marking Status", value: "marking_status" },
    ],
  };
  statuses = [
    {
      name: "All",
    },
    {
      name: "Unassigned",
    },
    {
      name: "Assigned",
    },
    {
      name: "Submitted",
    },
    {
      name: "Marking In Progress",
    },
    {
      name: "Marked",
    },
    {
      name: "Learning Goal Submitted",
    },
    {
      name: "Learning Goal Requested",
    },
    {
      name: "Completed",
    },
  ];
  hasLearningGoalSubmitted = false;

  hideHeader = true;
  // loadingAssignments = true;
  selectedAssignmentFilter = "all";
  selectedStudents: LearningGoalSpecificAssignmentData[] = [];
  selectedStudent = false;
  selectedAllStudent = false;

  @Watch("loadingSummary")
  async loadingSummaryWatcher(newValue: boolean, oldValue: boolean) {
    if (oldValue && !newValue) {
      await this.handleFetchLearningGoalsData();
      // this.selectedAssignmentFilter = "all";
    }
  }

  @Watch("selectedAssignmentData")
  selectedAssignmentWatcher(value: AssignmentData) {
    this.selectedAssignment = value;
  }

  @Watch("assignments")
  assignmentsWatcher(value: AssignmentData[]) {
    if (value.length > 0) {
      this.assignmentList = [];
      value.forEach((assignment: AssignmentData) => {
        const item = {
          uuid: assignment.uuid,
          title: assignment.title,
        };
        this.assignmentList.push(item);
      });
      this.assignmentList.unshift({
        uuid: "all",
        title: "All Assignments",
      });
    } else {
      this.assignmentList = [];
    }
  }

  @Watch("assignmentSummaryData")
  assignmentSummaryDataWatcher(value: AssigmentSubmissionSummaryData) {
    this.assignmentSummary = value;
    this.handleSpecificAssignmentData(value);
  }

  beforeUnmount() {
    this.destroyed = true;
  }

  created() {
    this.eventBus.on("SELECT_ASSIGNMENT", (data: any) => {
      this.selectedAssignmentFilter = data;
    });
  }

  handleStudentSelect(row: LearningGoalSpecificAssignmentData) {
    const selectedStudentClone = this.selectedStudents;
    const existing = selectedStudentClone.filter((student) => {
      return student.id === row.id;
    });
    if (existing.length > 0) {
      const filteredArray = this.selectedStudents.filter((item) => {
        return item.id !== row.id;
      });
      this.selectedStudents = filteredArray;
    } else {
      this.selectedStudents.push(row);
    }
    if (this.selectedStudents.length > 0) {
      this.selectedAllStudent = true;
    } else {
      this.selectedAllStudent = false;
    }
  }

  isRowChecked(row: LearningGoalSpecificAssignmentData) {
    const selectedStudentClone = this.selectedStudents;
    const selected = selectedStudentClone.find(
      (student) => student.id === row.id
    );
    if (selected) {
      return true;
    } else {
      return false;
    }
  }

  handleSelectAllStudent(allRow: LearningGoalSpecificAssignmentData[]) {
    const selectedAll = !this.selectedAllStudent;
    if (selectedAll) {
      const learningGoalSubmitted = allRow.filter(
        (item: LearningGoalSpecificAssignmentData) =>
          item.status === "learning goal submitted"
      );
      this.selectedStudents = learningGoalSubmitted;
      this.selectedStudent = true;
    } else {
      this.selectedStudents = [];
      this.selectedStudent = false;
    }
  }

  async handleFetchAssignmentSubmissionSummary(
    selectedAssignment: AssignmentData
  ) {
    this.tableLoading = true;

    if (selectedAssignment.uuid === "all") {
      this.tableLoading = false;
      return;
    }

    await this.$store.dispatch(
      setAssignmentSubmissionSummary,
      selectedAssignment.uuid
    );
    const assignmentSubmissionSummary = structuredClone(
      this.$store.state.assignment.assignmentSubmissionSummary
    );
    this.handleSpecificAssignmentData(assignmentSubmissionSummary);
    this.assignmentSummary = assignmentSubmissionSummary;
    this.selectedStudents = [];
    this.tableLoading = false;
  }

  async handleFetchLearningGoalsData() {
    this.tableLoading = true;
    const response: AxiosResponse =
      await this.learningGoalService.getLearningGoals(this.classUuid);

    if (response.data.ok) {
      this.allAssignmentData = response.data.data;
      const allLearningGoal: any = [];
      const responseData = response.data.data;

      responseData.class.students.forEach((student: LearningGoalStudents) => {
        let assignments: any = [];

        responseData.assignmentSubmissionLearningGoals.forEach(
          (assignment: AssignmentSubmissionLearningGoals) => {
            const submission = assignment.submissionLearningGoals.find(
              (item: SubmissionLearningGoals) =>
                item.studentUuid === student.studentUuid
            );
            const entry = {
              assignmentTitle: assignment.assignmentTitle,
              assignmentUuid: assignment.assignmentUuid,
              ...submission,
            };
            assignments.push(entry);
          }
        );

        // let studentEmail = "";
        // let studentFirstName = "";
        // let studentLastName = "";
        // if (this.assignmentSummaryData && this.assignmentSummaryData.students) {
        //   const currentSudent = this.assignmentSummaryData.students.find(
        //     (item) => item.student.uuid === student
        //   );
        //   if (currentSudent) {
        //     studentEmail = currentSudent.student.profile.email;
        //     studentFirstName = currentSudent.student.profile.firstName;
        //     studentLastName = currentSudent.student.profile.lastName;
        //   }
        // }
        const obj = {
          firstName: student.firstName,
          lastName: student.lastName,
          email: student.email,
          assignments: assignments,
        };

        allLearningGoal.push(obj);
      });
      this.learningGoals = allLearningGoal;
      this.learningGoalsClone = allLearningGoal;
    } else {
      console.error(response);
    }
    this.handleFetchAssignmentFromQuery();
    this.tableLoading = false;
  }

  async handleFetchAssignmentFromQuery() {
    const assignmentQuery = this.$route.query.assignment;
    const assignmentUuidQuery = assignmentQuery
      ? assignmentQuery.toString()
      : "";
    const tabQuery = this.$route.query.tab;
    const tab = tabQuery ? tabQuery.toString() : "";
    if (
      !assignmentUuidQuery &&
      tab === "learning-goals" &&
      this.assignments.length > 0
    ) {
      this.selectedAssignmentFilter = "all";
      return;
    }
    if (
      assignmentUuidQuery !== "all" &&
      tab === "learning-goals" &&
      this.assignments.length > 0
    ) {
      await this.handleSelectAssignment(this.selectedAssignmentData);
    }
  }

  constructData(item: AssignmentSubmissionSummaryStudents) {
    return structuredClone({
      id: item.student.uuid,
      submissionUuid: item.submission ? item.submission.uuid : "",
      lastName: item.student.profile.lastName,
      firstName: item.student.profile.firstName,
      email: item.student.email,
      summaryContent:
        item.submission && item.submission.finalTeacherComments
          ? item.submission.finalTeacherComments
          : "",
      learningGoal:
        item.submission && item.submission.studentLearningGoal
          ? item.submission.studentLearningGoal.content
          : "",
      status: item.submission ? item.submission.status : "assigned",
    });
  }

  handleSpecificAssignmentData(summary: AssigmentSubmissionSummaryData) {
    let specificAssignmentData: LearningGoalSpecificAssignmentData[] = [];
    this.hasLearningGoalSubmitted = false;
    summary.students.forEach((item) => {
      const formData = this.constructData(item);
      specificAssignmentData.push(formData);
      if (item.submission) {
        if (item.submission.status === "learning goal submitted") {
          this.hasLearningGoalSubmitted = true;
        }
      }
    });
    this.specificAssignmentData = specificAssignmentData;
    this.specificAssignmentDataClone = specificAssignmentData;
  }

  async handleSelectAssignment(assignment: AssignmentData) {
    this.tableLoading = true;
    if (assignment.uuid === "all" && !this.destroyed) {
      this.hideHeader = true;
      this.tableLoading = false;

      this.$router.push({
        name: "Classes",
        params: { id: this.classUuid },
        query: { tab: "learning-goals", assignment: "all" },
      });
      return;
    }
    const selectedAssignment = this.assignments.filter(
      (item) => item.uuid === assignment.uuid
    );
    this.selectedAssignment = selectedAssignment[0];
    await this.handleFetchAssignmentSubmissionSummary(this.selectedAssignment);

    this.handleAssignmentQuery(assignment);
    this.selectedAssignmentFilter = assignment.uuid;

    this.tableLoading = false;
    this.hideHeader = false;
  }

  handleAssignmentQuery(assignment: AssignmentData) {
    if (!this.destroyed) {
      this.$router.push({
        name: "Classes",
        params: { id: this.classUuid },
        query: { tab: "learning-goals", assignment: assignment.uuid },
      });
    }
  }

  toggleMenu(event: Event, index: number) {
    const ref = this.$refs["menu-" + index] as IMenuRef | undefined;
    if (ref) {
      ref.toggle(event);
    }
  }

  getColumnDataAll(data: LearningGoalAssignment, field: string) {
    if (data) {
      if (field === "learningGoal") {
        const cellData = JSON.parse(JSON.stringify(data))[field];
        return cellData;
      } else {
        const submissionRemarks = data.submissionRemarks;
        if (submissionRemarks) {
          return submissionRemarks.hasMetLearningGoal
            ? "I have met this goal"
            : "I have not met this goal";
        }
      }
    } else {
      return "";
    }
  }

  handleClickSortStatus(submissionStatus: string) {
    const status = submissionStatus.toLowerCase();
    const summary: AssigmentSubmissionSummaryData = this.assignmentSummary;
    const isAssignmentVisible = this.selectedAssignment.visible;
    let specificAssignmentData: LearningGoalSpecificAssignmentData[] = [];
    this.hasLearningGoalSubmitted = false;
    summary.students.forEach((item) => {
      const formData = this.constructData(item);
      if (item.submission) {
        if (item.submission.status === "unmarked" && status === "submitted") {
          specificAssignmentData.push(formData);
        } else {
          if (item.submission.status === status) {
            specificAssignmentData.push(formData);
          }
        }
      } else {
        if (status === "assigned" && isAssignmentVisible) {
          specificAssignmentData.push(formData);
        }
      }
      if (
        status === "all" ||
        (status === "unassigned" && !isAssignmentVisible)
      ) {
        specificAssignmentData.push(formData);
      }
      // Check if there's learning goal submitted
      if (
        item.submission &&
        (status === "all" || status === "learning goal submitted")
      ) {
        if (item.submission.status === "learning goal submitted") {
          this.hasLearningGoalSubmitted = true;
        }
      }
    });
    this.specificAssignmentData = specificAssignmentData;
  }

  handleAddAssignment() {
    this.$router.push({
      name: "CreateAssignment",
      query: { class: this.classUuid },
    });
  }

  handleSort(sort: string) {
    if (this.selectedAssignmentFilter === "all") {
      const sortedData = sortLearningGoalsTable(sort, this.learningGoals);
      this.learningGoals = sortedData;
    } else {
      const sortedData = sortLearningGoalsTable(
        sort,
        this.specificAssignmentData
      );
      this.specificAssignmentData = sortedData;
    }
  }

  handleSearch(searchText: string) {
    const filter = this.selectedAssignmentFilter;
    if (filter === "all") {
      if (this.learningGoalsClone) {
        const searchData = structuredClone(this.learningGoalsClone);
        if (searchText) {
          this.learningGoals = structuredClone(
            searchData.filter((student: AllLearningGoals) => {
              return (
                student.firstName
                  .toLowerCase()
                  .includes(searchText.toLowerCase()) ||
                student.lastName
                  .toLowerCase()
                  .includes(searchText.toLowerCase())
              );
            })
          );
        } else {
          this.learningGoals = structuredClone(this.learningGoalsClone);
        }
      }
    } else {
      if (this.specificAssignmentDataClone) {
        const searchData = structuredClone(this.specificAssignmentDataClone);
        if (searchText) {
          this.specificAssignmentData = structuredClone(
            searchData.filter((student: LearningGoalSpecificAssignmentData) => {
              return (
                student.firstName
                  .toLowerCase()
                  .includes(searchText.toLowerCase()) ||
                student.lastName
                  .toLowerCase()
                  .includes(searchText.toLowerCase()) ||
                student.learningGoal
                  .toLowerCase()
                  .includes(searchText.toLowerCase()) ||
                student.summaryContent
                  .toLowerCase()
                  .includes(searchText.toLowerCase())
              );
            })
          );
        } else {
          this.specificAssignmentData = structuredClone(
            this.specificAssignmentDataClone
          );
        }
      }
    }
  }

  toggleBulkUpload() {
    this.eventBus.emit("EVENT_TRIGGER", "CP001");
    this.isBulkUploadOpen = !this.isBulkUploadOpen;
  }

  handleActionOnSelected(type: "accepted" | "rejected") {
    this.eventBus.emit("EVENT_TRIGGER", "CP018");
    let failed = 0;
    let success = 0;
    this.selectedStudents.forEach(async (item) => {
      const payload = {
        submissionUuid: item.submissionUuid,
        status: type,
      };
      const response: AxiosResponse =
        await this.learningGoalService.putLearningGoal(payload);
      if (response.data.ok) {
        success += 1;
      } else {
        failed += 1;
      }
    });
    this.showToast(`Learning goals status updated`, "success");
    this.eventBus.emit("REFRESH_ASSIGNMENT");
    this.selectedStudents = [];
  }

  showToast(
    message: string,
    severity: "success" | "info" | "warn" | "error" | undefined
  ) {
    this.toast.add({
      severity: severity,
      detail: message,
      life: 3000,
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.lg-actions {
  background-color: #92b2c3;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.lg-actions-btn {
  background-color: #92b2c3;
}
.lg-actions-btn:hover {
  background-color: #92b2c3;
  font-weight: 900;
}
.select-all {
  display: flex;
  align-items: center;
}
.class-custom-cell {
  padding: 1em;
}
</style>
