<template>
  <ModalUtility
    v-model="isModalOpen"
    title="Select Your Upload Method"
    width="73vw"
  >
    <template #content>
      <div class="py-5 px-4 text-center">
        <h6 class="text-flohh-h6 font-flohh-font-bold mb-[49px]">
          How would you like to upload your student submissions?
        </h6>
        <div class="flex gap-3">
          <div
            class="flex-1 rounded-lg bg-flohh-tertiary-light-purple p-7 flex flex-col justify-between"
          >
            <div class="flex flex-col text-left">
              <AppTypographyText
                label="Scanned Paper Upload & Splitting"
                variant="bd"
                type="title"
                class="mb-[23px] text-flohh-neutral-35"
              />
              <AppTypographyText
                label="Start here if you need to upload and split scanned paper submissions from a single PDF. Our system will guide you through downloading cover sheets and splitting the file into individual submissions."
                type="body"
                class="mb-[9px] text-flohh-neutral-35"
              />
            </div>
            <AppButton
              class="!bg-flohh-tertiary-purple hover:brightness-90 w-full"
              @click="handleRedirectBulkUpload('paper')"
              >Upload</AppButton
            >
          </div>
          <div
            class="flex-1 rounded-lg bg-flohh-tertiary-light-yellow p-7 flex flex-col justify-between"
          >
            <div class="flex flex-col text-left">
              <AppTypographyText
                label="Digital Files Bulk Upload"
                variant="bd"
                type="title"
                class="mb-[23px] text-flohh-neutral-35"
              />
              <AppTypographyText
                label="Choose this if you have individual student submissions saved as separate files. You can upload up to 25 files at once."
                type="body"
                class="mb-[9px] text-flohh-neutral-35"
              />
            </div>
            <AppButton
              class="!bg-flohh-tertiary-yellow hover:brightness-90 w-full"
              @click="handleRedirectBulkUpload('digital')"
              >Upload</AppButton
            >
          </div>
        </div>
      </div>
    </template>
    <template #footer
      ><hr class="mb-5" />
      <AppButton text @click="closeModal">Go Back</AppButton>
    </template>
  </ModalUtility>
</template>

<script lang="ts">
import { Vue, Component, Model, Prop } from "vue-facing-decorator";
import ModalUtility from "./ModalUtility.vue";
import AppCardButton from "../Layout/Buttons/AppCardButton.vue";
import AppButton from "../Layout/Buttons/AppButton.vue";
import BulkUploadService from "@/services/BulkUploadService";
import { AxiosResponse } from "axios";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";

@Component({
  components: { ModalUtility, AppCardButton, AppButton, AppTypographyText },
})
export default class UploadSubmissionModal extends Vue {
  private bulkUploadService = new BulkUploadService();

  @Model({
    type: Boolean,
    required: true,
  })
  isModalOpen!: boolean;

  @Prop({
    type: String,
    required: false,
    default: "",
  })
  defaultAssignment!: string;

  @Prop({
    type: String,
    required: false,
    default: "",
  })
  defaultClass!: string;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  checkStatus!: boolean;

  async handleRedirectBulkUpload(type: "paper" | "digital") {
    const destinationRoute = {
      name: "BulkUpload",
      params: { type: type },
    };
    let hasPendingSubmission = false;
    if (this.checkStatus) {
      hasPendingSubmission = await this.handleCheckStatus(
        this.defaultAssignment
      );
    }

    if (hasPendingSubmission && type === "paper") {
      this.$router.push({
        name: "BulkUploadSplit",
        params: {
          type: "split",
          id: this.defaultAssignment,
        },
      });
    } else {
      if (this.defaultClass && this.defaultAssignment) {
        this.$router.push({
          ...destinationRoute,
          query: {
            class: this.defaultClass,
            assignment: this.defaultAssignment,
          },
        });
      } else if (this.defaultClass) {
        this.$router.push({
          ...destinationRoute,
          query: { class: this.defaultClass },
        });
      } else if (this.defaultAssignment) {
        this.$router.push({
          ...destinationRoute,
          query: { assignment: this.defaultAssignment },
        });
      } else {
        this.$router.push(destinationRoute);
      }
    }
  }

  async handleCheckStatus(uuid: string) {
    try {
      const response: AxiosResponse =
        await this.bulkUploadService.getBulkUploadStatus(uuid);
      if (response.data.ok) {
        const responseData = response.data.data;
        if (!responseData) {
          return false;
        } else {
          if (
            responseData.status === "completed" ||
            responseData.status === "pending" ||
            responseData.status === "processing"
          ) {
            return true;
          }
          return false;
        }
      }
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  closeModal() {
    this.isModalOpen = false;
  }
}
</script>
