<template>
  <div class="flex justify-end items-center gap-3">
    <div class="max-w-[300px]">
      <FormTextInput
        type="text"
        name="search"
        v-model="text"
        placeholder="Search..."
        :iconLeft="'searchBlack'"
        :background="false"
      />
    </div>

    <div
      class="border border-solid border-neutral-gray-85 rounded-lg flex overflow-hidden"
    >
      <div
        :class="`w-auto p-3 cursor-pointer ${
          classView === 'grid' ? 'bg-flohh-primary-light-pink' : ''
        }`"
        @click="handleChangeView('grid')"
      >
        <span v-html="icons.gridIcon"></span>
      </div>
      <div
        :class="`w-auto p-3 cursor-pointer ${
          classView === 'table' ? 'bg-flohh-primary-light-pink' : ''
        }`"
        @click="handleChangeView('table')"
      >
        <span v-html="icons.tableIcon"></span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Model, Watch } from "vue-facing-decorator";
import FormTextInput from "@/components/Layout/Forms/FormTextInput.vue";
import { icons as AppIcons } from "@/utils/icons";

@Component({
  components: { FormTextInput },
})
export default class ClassesListUtility extends Vue {
  icons = AppIcons;
  @Model({
    type: String,
    required: true,
  })
  searchText!: string;

  @Prop({
    type: String,
    required: true,
  })
  classView!: string;

  text = "";

  @Emit("onViewChange")
  handleChangeView(view: string) {
    return view;
  }

  @Watch("text")
  watchSearchText(value: string) {
    this.searchText = value.toLowerCase();
  }

  mounted() {
    //
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.progress-bar {
  background-color: #f2f2f2;
}
</style>
