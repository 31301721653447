import axios from "axios";
import { Submission, UpdateSubmission } from "@/models/Submission";
import {
  AssignmentDetails,
  InstantAssignmentDetails,
} from "@/models/Assignment";
import { TELEMETRY_HEAD } from "@/utils/telemetry/data";

const ASSIGNMENT_BASE_URL = `/assignments`;
const ASSIGNMENT_SUBMISSION_BASE_URL = `/submissions`;
const ASSIGNMENT_BY_CLASS_UUID = (classUuid: string) =>
  `${ASSIGNMENT_BASE_URL}/class/${classUuid}`;
const GET_INDIVIDUAL_ASSIGNMENT = (assignmentUuid: string) =>
  `${ASSIGNMENT_BASE_URL}/${assignmentUuid}`;
const GET_ASSIGNMENT_SUBMISSION = (assignmentUuid: string) =>
  `${ASSIGNMENT_BASE_URL}/${assignmentUuid}/submissions`;
const GET_ASSIGNEMNT_SUBMISSION_SUMMARY = (assignmentUuid: string) =>
  `${ASSIGNMENT_BASE_URL}/${assignmentUuid}/submissions/summary`;
const UPDATE_ASSIGNMENT = (assignmentUuid: string) =>
  `${ASSIGNMENT_BASE_URL}/${assignmentUuid}`;
const DELETE_ASSIGNMENT = (assignmentUuid: string) =>
  `${ASSIGNMENT_BASE_URL}/${assignmentUuid}`;
const RETURN_ALL_FEEDBACK_URL = (assignmentUuid: string) =>
  `${ASSIGNMENT_BASE_URL}/${assignmentUuid}/submissions/return-feedback`;
const RETURN_FEEDBACK_URL = (assignmentUuid: string, submissionUuid: string) =>
  `${ASSIGNMENT_BASE_URL}/${assignmentUuid}/submissions/${submissionUuid}/return-feedback`;

export default class AssignmentService {
  getAssignments() {
    return axios.get(ASSIGNMENT_BASE_URL);
  }

  getAssignment(id: string) {
    return axios.get(`${ASSIGNMENT_BASE_URL}/${id}`);
  }

  getAssignmentByClass(classUuid: string) {
    return axios.get(ASSIGNMENT_BY_CLASS_UUID(classUuid));
  }

  getAssignmentSubmissionSummary(assignmentUUid: string) {
    return axios.get(GET_ASSIGNEMNT_SUBMISSION_SUMMARY(assignmentUUid));
  }

  updateAssignment<T>(assignmentUuid: string, payload: T) {
    return axios.put(UPDATE_ASSIGNMENT(assignmentUuid), payload, {
      headers: TELEMETRY_HEAD.updateAssignment,
    });
  }

  deleteAssignment(assignmentUuid: string) {
    return axios.delete(DELETE_ASSIGNMENT(assignmentUuid), {
      headers: TELEMETRY_HEAD.deleteAssignment,
    });
  }

  getAssignmentSubmission(assignmentUuid: string) {
    return axios.get(GET_ASSIGNMENT_SUBMISSION(assignmentUuid));
  }

  getIndividualAssignment(assignmentUuid: string) {
    return axios.get(GET_INDIVIDUAL_ASSIGNMENT(assignmentUuid));
  }

  postAssignment(payload: AssignmentDetails) {
    return axios.post(ASSIGNMENT_BASE_URL, payload, {
      headers: TELEMETRY_HEAD.postAssignment,
    });
  }

  deleteAssignments(id: number) {
    return axios.delete(`${ASSIGNMENT_BASE_URL}/${id}`);
  }

  getSubmission(id: string) {
    return axios.get(`${ASSIGNMENT_SUBMISSION_BASE_URL}/${id}`);
  }

  postSubmission(payload: Submission) {
    return axios.post(ASSIGNMENT_SUBMISSION_BASE_URL, payload, {
      headers: TELEMETRY_HEAD.postAssignment,
    });
  }

  updateSubmission(payload: UpdateSubmission, id: string) {
    return axios.put(`${ASSIGNMENT_SUBMISSION_BASE_URL}/${id}`, payload, {
      headers: TELEMETRY_HEAD.updateSubmission,
    });
  }

  deleteSubmission(id: number | string) {
    return axios.delete(`${ASSIGNMENT_SUBMISSION_BASE_URL}/${id}`, {
      headers: TELEMETRY_HEAD.deleteSubmission,
    });
  }

  getAnnotationBySubmissionId(id: string) {
    return axios.get(`annotations/${id}`);
  }

  postFlattenAssignment(id: string) {
    // API not being used
    return axios.post(`${ASSIGNMENT_BASE_URL}/${id}/submissions/flatten`);
  }

  postReturnAllFeedback(assignmentUuid: string) {
    return axios.post(RETURN_ALL_FEEDBACK_URL(assignmentUuid), {
      headers: TELEMETRY_HEAD.postReturnAllFeedback,
    });
  }

  postReturnFeedback(assignmentUuid: string, submissionUuid: string) {
    return axios.post(
      RETURN_FEEDBACK_URL(assignmentUuid, submissionUuid),
      {},
      {
        headers: TELEMETRY_HEAD.postReturnFeedback,
      }
    );
  }

  postFlattenAssignmentBySubmissionId(id: string, submissionId: string) {
    return axios.post(
      `${ASSIGNMENT_BASE_URL}/${id}/submissions/${submissionId}/flatten`,
      {},
      {
        headers: TELEMETRY_HEAD.postFlattenAssignmentBySubmissionId,
      }
    );
  }

  instantCreateAssignment(payload: InstantAssignmentDetails) {
    return axios.post("/assignments/instant-create", payload, {
      headers: TELEMETRY_HEAD.postAssignment,
    });
  }

  // remove assignment route when BE has finish refactoring POST and GET
  generateAssignmentShareCode(payload: any) {
    return axios.post("/share-codes", payload);
  }

  getAssignmentShareCode(code: string) {
    return axios.get(`/share-codes/${code}`);
  }
}
