import { ActionContext } from "vuex";
import { RootState } from "@/store";
import * as mutationType from "../mutation-types";
import { DashboardState } from "../dashboardTypes";
import DashboardService from "@/services/DashboardService";

export const SetStudentDashboardAction = async (
  context: ActionContext<DashboardState, RootState>
) => {
  try {
    //
    const dashboard = new DashboardService();
    const response = await dashboard.getStudentDashboard();
    if (response.data.ok) {
      context.commit(mutationType.SET_STUDENT_DASHBOARD, response.data.data);
    }
  } catch (error) {
    console.error(error);
  }
};
