<template>
  <div
    class="flex flex-col py-[26px] px-[30px] bg-[#EAE5FF] rounded-lg justify-between"
  >
    <h6 class="text-[18px] leading-[1.5] text-[#333] mb-[23px] font-bold">
      Try the marking screen
    </h6>
    <p class="text-[#595959] text-[14px] leading-[1.5] mb-[30px] font-medium">
      Want to try out the marking screen before diving right in? Try Flohh’s
      demo assignment to get a feel for the marking screen without having to
      upload your own work or students.
    </p>
    <AppButton
      class="!bg-[#CEC3FF] hover:opacity-80"
      @click="markDemoAssignment"
      >Try the marking screen</AppButton
    >
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import AppButton from "../../Layout/Buttons/AppButton.vue";
import { icons as AppIcons, icons } from "@/utils/icons";
import { StorageDataKey } from "@/enums/enums";
import AppTypographyText from "../../Layout/Typhography/AppTypographyText.vue";
import DashboardService from "@/services/DashboardService";
import AssignmentService from "@/services/AssignmentService";
@Component({
  components: {
    AppButton,
    AppTypographyText,
  },
})
export default class TaskMarkingCard extends Vue {
  //
  private dashboardService = new DashboardService();
  private assignmentService = new AssignmentService();

  @Prop({
    type: Function,
  })
  hideMarkingCard!: () => void;

  demoUuid = "";

  mounted() {
    //
    this.getAssignmentData();
  }

  async getAssignmentData() {
    try {
      const res = await this.dashboardService.getTeacherDashboard();

      if (res.data.ok) {
        const data = res.data.data;
        const demo = data.find(
          (o: any) =>
            o.title === "Demo Assignment" &&
            o.class.code === "Demo Class" &&
            o.class.subject === "Demo Subject"
        );

        const assignmentRes =
          await this.assignmentService.getAssignmentSubmission(demo.uuid);

        if (assignmentRes.data.ok) {
          const demoData = assignmentRes.data.data.find(
            (o: any) =>
              o.assignment.title === "Demo Assignment" &&
              o.assignment.class.code === "Demo Class" &&
              o.assignment.class.subject === "Demo Subject"
          );

          this.demoUuid = demoData.uuid;
          if (demoData.status !== "unmarked") {
            localStorage.setItem("MarkDemoAssignment", "true");
            if (this.hideMarkingCard) {
              this.hideMarkingCard();
            }
          }
        }

        console.log(assignmentRes);
      }
    } catch (e) {
      //
    }
  }

  markDemoAssignment() {
    localStorage.setItem("MarkDemoAssignment", "true");
    this.$router.push({
      name: "Marking",
      params: { id: this.demoUuid },
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
