<template>
  <div class="p-[30px] bg-white rounded-lg mb-5">
    <div class="w-full overflow-hidden flex flex-col gap-7">
      <AssignmentListComponent
        title="Assignments in Progress"
        :assignments="assignments"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Ref, Prop } from "vue-facing-decorator";
import { icons as AppIcons } from "@/utils/icons";
import emitter from "@/config/emitter";
import AssignmentCardComponent from "@/components/Assignments/utilities/AssignmentCardComponent.vue";
import { AssignmentsTabsData } from "@/components/Assignments/type";
import { AssignmentDashboard } from "@/store/dashboard/dashboardTypes";
import { formatDate, formatStringToNumberMinutes } from "@/utils/formatter";
import ProgressLoader from "@/components/utilities/ProgressLoader.vue";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { sortAssignmentsPageData } from "@/utils/sort";
import AssignmentListComponent from "@/components/Assignments/utilities/AssignmentListComponent/AssignmentListComponent.vue";

@Component({
  components: {
    AssignmentCardComponent,
    ProgressLoader,
    AppButton,
    AssignmentListComponent,
  },
})
export default class DashboardAssignmentComponent extends Vue {
  icons = AppIcons;

  @Prop({
    type: Array,
    required: true,
  })
  assignments!: AssignmentDashboard[];

  @Ref flexDiv!: HTMLElement;

  eventBus = emitter;
  assignmentsList: AssignmentsTabsData[] = [];
  maxWidth!: number;

  prepareAssignmentData() {
    const assignments = structuredClone(this.assignments);
    const assignmentsList: AssignmentsTabsData[] = [];
    assignments.forEach((assignment: AssignmentDashboard) => {
      const lgSubmitted = assignment.submissionCounts.learningGoalsToReview;
      const lgRequested = assignment.submissionCounts.learningGoalsRequested;
      const done = assignment.submissionCounts.completedSubmissions;
      const marked = assignment.submissionCounts.markedSubmissions;
      const total = assignment.submissionCounts.totalSubmissions;

      const markedNumerator = done + marked + lgRequested + lgSubmitted;
      const markedPerc =
        total !== 0 && markedNumerator !== 0
          ? (markedNumerator / total) * 100
          : 0;

      const goalDenominator = lgSubmitted + done;
      const goalPerc =
        done !== 0 && goalDenominator !== 0
          ? (done / goalDenominator) * 100
          : 0;

      if (!assignment.visible || (done === total && done !== 0 && total !== 0))
        return;

      const elapsedTime = assignment.elapsedTime
        ? assignment.elapsedTime
        : "0 second";

      const formData = {
        uuid: assignment.uuid,
        title: assignment.title,
        class: assignment.class.code,
        classUuid: assignment.class.uuid,
        subject: assignment.class.subject,
        dueDate: `${formatDate(
          assignment.dueDate,
          "DD MMMM YYYY"
        )} at ${formatDate(assignment.dueDate, "h:mm A")}`,
        dueDateValue: assignment.dueDate,
        elapsedTime: `Elapsed time: ${elapsedTime}`,
        elapsedTimeValue: formatStringToNumberMinutes(elapsedTime),
        marked: `${markedNumerator} of ${total} Marked`,
        reviewed: `${done} of ${goalDenominator} Learning Goals Reviewed`,
        submissionProgressBar: Number(this.fixDecimal(markedPerc)),
        learningGoalsProgressBar: Number(this.fixDecimal(goalPerc)),
      };
      assignmentsList.push(formData);
    });
    const sortedData = sortAssignmentsPageData("due_earliest", assignmentsList);
    this.assignmentsList = sortedData;
  }

  fixDecimal(value: number, fixTo = 1) {
    return value.toFixed(fixTo);
  }

  handleClickScroll(move = 500) {
    const scrollContainer = this.flexDiv;
    scrollContainer.scrollBy({
      left: move,
      behavior: "smooth",
    });
  }

  async mounted() {
    this.prepareAssignmentData();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.assignments-wrapper {
  display: flex;
  flex-direction: row;
  gap: 1em;
  // transition: transform 0.5s ease-in-out;
  padding-bottom: 1em;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 10px !important;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #f9b2ce; /* Color of the scrollbar thumb */
    border-radius: 8px; /* Rounded corners for the thumb */
  }
  &::-webkit-scrollbar-track {
    background: #d9d9d9; /* Color of the scrollbar track */
    border-radius: 8px;
  }
}
.disabled {
  opacity: 0.5;
}
</style>
