import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, Teleport as _Teleport, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex items-center justify-end" }
const _hoisted_2 = { class: "flex items-center justify-start" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "block items-center text-[14px] font-medium text-[#0D0D0D] ml-[12px] truncate w-[119px]" }
const _hoisted_5 = { class: "flex items-center" }
const _hoisted_6 = { class: "flex items-center" }
const _hoisted_7 = {
  key: 0,
  class: "bg-flohh-secondary-pink w-[10px] h-[10px] rounded-lg mr-[10px]"
}
const _hoisted_8 = {
  key: 1,
  class: "bg-flohh-secondary-yellow w-[10px] h-[10px] rounded-lg mr-[10px]"
}
const _hoisted_9 = {
  key: 2,
  class: "bg-flohh-secondary-blue w-[10px] h-[10px] rounded-lg mr-[10px]"
}
const _hoisted_10 = {
  key: 3,
  class: "bg-flohh-secondary-orange w-[10px] h-[10px] rounded-lg mr-[10px]"
}
const _hoisted_11 = {
  key: 4,
  class: "bg-flohh-secondary-green w-[10px] h-[10px] rounded-lg mr-[10px]"
}
const _hoisted_12 = { class: "marking" }
const _hoisted_13 = {
  key: 1,
  class: "w-[calc(100%-288px)] h-[calc(100vh-80px)] absolute z-[100] overflow-hidden flex align-center items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_MarkingNavComponent = _resolveComponent("MarkingNavComponent")!
  const _component_PSPDFKitContainer = _resolveComponent("PSPDFKitContainer")!
  const _component_LoadingState = _resolveComponent("LoadingState")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageTitle, {
      title: "Marking",
      routes: _ctx.routes
    }, null, 8, ["routes"]),
    (_openBlock(), _createBlock(_Teleport, { to: "#top_navigation_buttons_teleport" }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Dropdown, {
          modelValue: _ctx.selectedStudent,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedStudent) = $event)),
          options: _ctx.studentLists,
          optionLabel: "name",
          placeholder: "-- --",
          filter: "",
          filterIcon: 'pi pi-search',
          class: "w-[227px] student-dropdown h-[48px] mr-[22px] rounded-[8px] !border-none",
          loading: _ctx.loading,
          onChange: _cache[1] || (_cache[1] = 
          (item) => {
            _ctx.handleChangeSubmission(item.value);
          }
        )
        }, {
          value: _withCtx((slotProps) => [
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("span", {
                innerHTML: _ctx.icons.person
              }, null, 8, _hoisted_3),
              _createElementVNode("span", _hoisted_4, _toDisplayString(slotProps.value ? slotProps.value.name : slotProps.placeholder), 1)
            ])), [
              [
                _directive_tooltip,
                {
              value: slotProps.value
                ? slotProps.value.name
                : slotProps.placeholder,
              showDelay: 500,
            },
                void 0,
                { bottom: true }
              ]
            ])
          ]),
          option: _withCtx((slotProps) => [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                (slotProps.option.status == 'marked')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7))
                  : _createCommentVNode("", true),
                (slotProps.option.status == 'marking in progress')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8))
                  : _createCommentVNode("", true),
                (slotProps.option.status == 'learning goal requested')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9))
                  : _createCommentVNode("", true),
                (slotProps.option.status == 'learning goal submitted')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10))
                  : _createCommentVNode("", true),
                (slotProps.option.status == 'completed')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11))
                  : _createCommentVNode("", true),
                _createTextVNode(" " + _toDisplayString(slotProps.option.name), 1)
              ])
            ])
          ]),
          _: 1
        }, 8, ["modelValue", "options", "loading"]),
        (_ctx.isTimerLoaded)
          ? (_openBlock(), _createBlock(_component_MarkingNavComponent, {
              key: 0,
              markingTime: _ctx.timer,
              startTime: _ctx.startTime
            }, null, 8, ["markingTime", "startTime"]))
          : _createCommentVNode("", true),
        (!_ctx.isTimerLoaded)
          ? (_openBlock(), _createBlock(_component_MarkingNavComponent, {
              key: 1,
              markingTime: 0,
              startTime: 0
            }))
          : _createCommentVNode("", true)
      ])
    ])),
    _createElementVNode("div", _hoisted_12, [
      (_ctx.pdfBase64 && !_ctx.loading)
        ? (_openBlock(), _createBlock(_component_PSPDFKitContainer, {
            key: 0,
            ref: "PSPDFKitContainerRef",
            pdfBase64: _ctx.pdfBase64,
            annotationData: _ctx.annotationData,
            revisionId: _ctx.revisionId,
            assignmentId: _ctx.assignmentId,
            assignmentName: _ctx.assignmentName,
            isFlattened: _ctx.isFlattened,
            submissionId: _ctx.id,
            studentId: _ctx.studentId,
            classId: _ctx.classId,
            onLoaded: _ctx.handleLoaded,
            onRefresh: _ctx.handleRefreshMarkingView
          }, null, 8, ["pdfBase64", "annotationData", "revisionId", "assignmentId", "assignmentName", "isFlattened", "submissionId", "studentId", "classId", "onLoaded", "onRefresh"]))
        : _createCommentVNode("", true),
      (!_ctx.pdfBase64 && _ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _createVNode(_component_LoadingState, {
              state: "Fetching data. Please wait.",
              backdrop: "95",
              textCls: "!text-flohh-neutral-10"
            })
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}