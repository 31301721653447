<template>
  <div
    v-if="firstName && lastName"
    class="avatar"
    :style="{
      width: width,
      backgroundColor: getBgColor(),
    }"
  >
    <AppTypographyText
      :label="generateInitials(firstName, lastName)"
      :type="variant"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-facing-decorator";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";

interface Sizes {
  size: string;
  variant: string;
  width: string;
}
@Component({ components: { AppTypographyText } })
export default class PersonAvatar extends Vue {
  @Prop({
    type: String,
    required: true,
    default: "",
  })
  firstName!: string;

  @Prop({
    type: String,
    required: true,
    default: "",
  })
  lastName!: string;

  @Prop({
    type: String,
    required: false,
    default: "md",
    validator: (value) => {
      return ["xs", "s", "md", "lg", "xl"].includes(value);
    },
  })
  size!: string;

  width = "30px";
  variant = "md";

  bgColors = [
    "#FFF2B9",
    "#FBC2DA",
    "#FFDBB8",
    "#FFBEB8",
    "#BFD5CD",
    "#C8F1FF",
    "#B2AD96",
    "#CEC3FF",
    "#FF8A8A",
    "#FFA576",
    "#EDD6A1",
  ];

  sizes: Sizes[] = [
    {
      size: "xs",
      variant: "small",
      width: "21px",
    },
    {
      size: "s",
      variant: "caption",
      width: "26px",
    },
    {
      size: "md",
      variant: "body",
      width: "31px",
    },
    {
      size: "lg",
      variant: "subtitle",
      width: "36px",
    },
    {
      size: "xl",
      variant: "title",
      width: "41px",
    },
  ];

  mounted() {
    const selected = this.sizes.find((item: Sizes) => item.size === this.size);
    if (selected) {
      this.variant = selected.variant;
      this.width = selected.width;
    }
  }

  getBgColor() {
    const randomNum = Math.random() * (10 - 1) + 1;
    const index = Number(randomNum.toFixed(0));
    return this.bgColors[index];
  }

  generateInitials(firstName: string, lastName: string) {
    return firstName[0] + lastName[0];
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.avatar {
  border-radius: 50px;
  overflow: hidden;
  min-width: 21px;
  min-height: 21px;
  display: grid;
  place-items: center;
  aspect-ratio: 1;
}
</style>
