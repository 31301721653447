//main.ts
//app container

import { createApp } from "vue";
import "./config/axios";
import "./config/froala";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/primevue-theme/primary_pink_theme.css";
import "./app.css";
import PrimeVue from "primevue/config";
import "primeicons/primeicons.css";
import ToastService from "primevue/toastservice";
import "./modules";
import ConfirmationService from "primevue/confirmationservice";
import Tooltip from "primevue/tooltip";
import { VueRecaptchaPlugin } from "vue-recaptcha";
import { createHead } from "@unhead/vue";

const head = createHead();
const app = createApp(App);
app.directive("tooltip", Tooltip);
app.use(head);
app.use(VueRecaptchaPlugin, {
  v2SiteKey: process.env.VUE_APP_CAPTCHA_KEY_V2,
  v3SiteKey: process.env.VUE_APP_CAPTCHA_KEY_V3,
});
app.use(store);
app.use(router);
app.use(PrimeVue);
app.use(ToastService);
app.use(ConfirmationService);
app.mount("#app");
