<template>
  <div>
    <UploadSubmissionsComponent
      v-if="filesSucceeded.length === 0 && filesFailed.length === 0"
      :uploadingFiles="uploadingFiles"
      :uploadProgress="uploadProgress"
      @onSubmit="handleOnSubmit"
    />
    <SubmissionResultComponent
      v-else
      :filesSucceeded="filesSucceeded"
      :filesFailed="filesFailed"
      :digitalFirstSubmissionUuid="digitalFirstSubmissionUuid"
      @onFinish="handleFinishUpload"
      @onSubmit="handleOnSubmit"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import UploadSubmissionsComponent from "./DigitalSubmission/UploadSubmissionsComponent.vue";
import SubmissionResultComponent from "./DigitalSubmission/SubmissionResultComponent.vue";
import { UploadProgress } from "@/components/utilities/utilitiesTypes";

@Component({
  components: { UploadSubmissionsComponent, SubmissionResultComponent },
})
export default class DigitalSubmission extends Vue {
  @Prop({
    type: Boolean,
    default: false,
    required: false,
  })
  uploadingFiles!: boolean;

  @Prop({
    type: Array,
    required: false,
  })
  filesSucceeded: File[] = [];

  @Prop({
    type: Array,
    required: false,
  })
  filesFailed: File[] = [];

  @Prop({
    type: Object,
    required: false,
  })
  uploadProgress!: UploadProgress;

  @Prop({
    type: String,
    default: "",
  })
  digitalFirstSubmissionUuid!: string;

  handleOnSubmit(files: File[] | string) {
    this.$emit("onSubmit", files);
  }

  handleFinishUpload() {
    this.$emit("onFinish");
  }
}
</script>

<style scoped lang="scss"></style>
