import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "px-2 flex gap-10 mb-5" }
const _hoisted_2 = { class: "flex gap-2 items-center" }
const _hoisted_3 = { class: "flex gap-2 items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormRadioButton = _resolveComponent("FormRadioButton")!
  const _component_DefaultFileUploader = _resolveComponent("DefaultFileUploader")!
  const _component_GoogleDriveFileUploader = _resolveComponent("GoogleDriveFileUploader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_FormRadioButton, {
          modelValue: _ctx.selectedUploadMethod,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedUploadMethod) = $event)),
          name: "fileUpload",
          inputId: "fileUpload",
          value: 0
        }, null, 8, ["modelValue"]),
        _createElementVNode("label", {
          for: "fileUpload",
          class: _normalizeClass(["font-flohh-font-medium text-flohh-text-body cursor-pointer", _ctx.selectedUploadMethod === 0 ? 'text-flohh-primary-pink' : ''])
        }, "File Upload", 2)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_FormRadioButton, {
          modelValue: _ctx.selectedUploadMethod,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedUploadMethod) = $event)),
          name: "googleDriveUpload",
          inputId: "googleDriveUpload",
          value: 1
        }, null, 8, ["modelValue"]),
        _createElementVNode("label", {
          for: "googleDriveUpload",
          class: _normalizeClass(["font-flohh-font-medium text-flohh-text-body cursor-pointer", _ctx.selectedUploadMethod === 1 ? 'text-flohh-primary-pink' : ''])
        }, "Google Drive", 2)
      ])
    ]),
    _createElementVNode("div", null, [
      (_ctx.selectedUploadMethod === 0)
        ? (_openBlock(), _createBlock(_component_DefaultFileUploader, {
            key: 0,
            selectedFiles: _ctx.selectedFiles,
            "onUpdate:selectedFiles": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedFiles) = $event)),
            fileSizeLimit: 32,
            acceptedFiles: ".pdf,.docx,.doc",
            supportedFileTypes: "Supported file types: PDF & Microsoft Word",
            progress: _ctx.uploadProgress,
            onOnRemoveFile: _ctx.handleRemoveFile
          }, null, 8, ["selectedFiles", "progress", "onOnRemoveFile"]))
        : (_ctx.selectedUploadMethod === 1)
          ? (_openBlock(), _createBlock(_component_GoogleDriveFileUploader, {
              key: 1,
              selectedFiles: _ctx.selectedFiles,
              "onUpdate:selectedFiles": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedFiles) = $event)),
              fileSizeLimit: 32,
              gDriveAcceptedFiles: "application/pdf,application/vnd.google-apps.document",
              supportedFileTypes: "Supported file types: PDF & Google Doc",
              progress: _ctx.uploadProgress,
              onOnRemoveFile: _ctx.handleRemoveFile
            }, null, 8, ["selectedFiles", "progress", "onOnRemoveFile"]))
          : _createCommentVNode("", true)
    ])
  ], 64))
}