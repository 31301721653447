import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_CreateClassPopup = _resolveComponent("CreateClassPopup")!
  const _component_AddStudentsForClassPopup = _resolveComponent("AddStudentsForClassPopup")!
  const _component_ManualAddStudentPopup = _resolveComponent("ManualAddStudentPopup")!
  const _component_CreateClassUploadPopup = _resolveComponent("CreateClassUploadPopup")!
  const _component_CreateClassLinkPopup = _resolveComponent("CreateClassLinkPopup")!
  const _component_CreateClassGooglePopup = _resolveComponent("CreateClassGooglePopup")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _renderSlot(_ctx.$slots, "default", { handleClickClass: _ctx.handleClickClass }, () => [
      _withDirectives((_openBlock(), _createBlock(_component_AppButton, {
        type: _ctx.type,
        onClick: _ctx.handleClickClass
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", {
            innerHTML: _ctx.icons[_ctx.icon]
          }, null, 8, _hoisted_1),
          (!_ctx.isIcon)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode("Class")
              ], 64))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["type", "onClick"])), [
        [
          _directive_tooltip,
          {
        value: 'Create a new class',
        showDelay: 500,
      },
          void 0,
          { top: true }
        ]
      ])
    ]),
    _createVNode(_component_CreateClassPopup, {
      modelValue: _ctx.openClassDialog,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.openClassDialog) = $event)),
      onOnCreateClass: _ctx.handleOnCreateClass,
      isEdit: _ctx.isEdit
    }, null, 8, ["modelValue", "onOnCreateClass", "isEdit"]),
    _createVNode(_component_AddStudentsForClassPopup, {
      modelValue: _ctx.openClassAddStudentDialog,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.openClassAddStudentDialog) = $event)),
      onOnSkipOrGoBack: _ctx.handleSkipOrGoBack,
      onOnClickSave: _ctx.handleClickOnSave
    }, null, 8, ["modelValue", "onOnSkipOrGoBack", "onOnClickSave"]),
    _createVNode(_component_ManualAddStudentPopup, {
      modelValue: _ctx.openAddStudentManuallyDialog,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.openAddStudentManuallyDialog) = $event)),
      onOnSkipOrGoBack: _ctx.handleSkipOrGobackAddManually,
      onOnSaveAddStudentManually: _ctx.handleOnSaveStudent
    }, null, 8, ["modelValue", "onOnSkipOrGoBack", "onOnSaveAddStudentManually"]),
    _createVNode(_component_CreateClassUploadPopup, {
      modelValue: _ctx.openUploadDialog,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.openUploadDialog) = $event)),
      onOnSkipOrGoBack: _ctx.handleSkipOrGoBackUpload,
      onOnSaveFileUploadStudent: _ctx.handleOnSaveFileUploadStudent
    }, null, 8, ["modelValue", "onOnSkipOrGoBack", "onOnSaveFileUploadStudent"]),
    _createVNode(_component_CreateClassLinkPopup, {
      modelValue: _ctx.openLinkDialog,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.openLinkDialog) = $event)),
      onOnCloseSkipOrGoBack: _ctx.handleCloseSkipOrGoBackLink
    }, null, 8, ["modelValue", "onOnCloseSkipOrGoBack"]),
    (_ctx.openGoogleDialog)
      ? (_openBlock(), _createBlock(_component_CreateClassGooglePopup, {
          key: 0,
          modelValue: _ctx.openGoogleDialog,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.openGoogleDialog) = $event)),
          onOnCloseSkipOrGoBack: _ctx.handleCloseSkipOrGoBackGoogle,
          onOnSaveGoogleImport: _ctx.handleOnSaveGoogleImport
        }, null, 8, ["modelValue", "onOnCloseSkipOrGoBack", "onOnSaveGoogleImport"]))
      : _createCommentVNode("", true)
  ], 64))
}