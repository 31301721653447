import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "px-[28px] py-[30px]" }
const _hoisted_2 = { class: "flex pt-5 border-t border-solid border-flohh-neutral-85" }
const _hoisted_3 = { class: "flex-1 flex justify-start items-center" }
const _hoisted_4 = { class: "flex-1 flex justify-end items-center gap-x-4" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppTypographyText = _resolveComponent("AppTypographyText")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_ModalUtility = _resolveComponent("ModalUtility")!

  return (_openBlock(), _createBlock(_component_ModalUtility, {
    modelValue: _ctx.visible,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event)),
    width: "20vw",
    title: "Unsaved Changes",
    onOnClose: _ctx.handleCloseUnsaveModal
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_AppTypographyText, {
          variant: "rg",
          type: "body",
          label: "You have unsaved changes on this page. Do you want to discard or save your changes?"
        })
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_AppButton, {
            onClick: _ctx.handleLeave,
            text: "",
            blackLabel: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Discard ")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_AppButton, {
            type: "submit",
            onClick: _ctx.handlePrimaryButtonAction
          }, {
            icon_left: _withCtx(() => [
              _createElementVNode("span", {
                innerHTML: _ctx.icons.checkBlack
              }, null, 8, _hoisted_5)
            ]),
            default: _withCtx(() => [
              _createTextVNode(" Save Changes ")
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "onOnClose"]))
}