import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1033556e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify-between w-full" }
const _hoisted_2 = { class: "flex items-center justify-end actions-cell actions-body" }
const _hoisted_3 = { class: "h-[4px] w-full bg-white rounded-[4px] overflow-hidden relative progress-bar" }
const _hoisted_4 = ["progress"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppTypographyText = _resolveComponent("AppTypographyText")!
  const _component_AppDropdownButton = _resolveComponent("AppDropdownButton")!
  const _component_PeopleAvatars = _resolveComponent("PeopleAvatars")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: "border border-solid border-neutral-gray-85 rounded-lg p-5 flex flex-col gap-4 cursor-pointer",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleRedirectToClassPage && _ctx.handleRedirectToClassPage(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_AppTypographyText, {
        variant: "bd",
        label: _ctx.data.code
      }, null, 8, ["label"]),
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_AppDropdownButton, {
          size: "sm",
          onClickMenu: _ctx.handleSelectMenu,
          menus: _ctx.actions,
          type: "default",
          text: "",
          width: "w-40",
          dropdownIcon: "ellepsisVerticalBlack"
        }, null, 8, ["onClickMenu", "menus"])
      ])), [
        [
          _directive_tooltip,
          {
          value: 'More Options',
          showDelay: 500,
        },
          void 0,
          { top: true }
        ]
      ])
    ]),
    _createVNode(_component_AppTypographyText, {
      type: "caption",
      label: `${_ctx.data.subject} - ${_ctx.data.yearLevel}`
    }, null, 8, ["label"]),
    (_ctx.studentNames.length > 0)
      ? (_openBlock(), _createBlock(_component_PeopleAvatars, {
          key: 0,
          data: _ctx.studentNames
        }, null, 8, ["data"]))
      : _createCommentVNode("", true),
    (_ctx.data.description)
      ? (_openBlock(), _createBlock(_component_AppTypographyText, {
          key: 1,
          type: "caption",
          label: _ctx.data.description
        }, null, 8, ["label"]))
      : _createCommentVNode("", true),
    _createVNode(_component_AppTypographyText, {
      type: "small",
      label: `
          Marked
          ${_ctx.totalMarkedAssignments}/${_ctx.totalSubmissionsDone}
          submissions`,
      class: "mb-[9px]"
    }, null, 8, ["label"]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: _normalizeClass(['absolute bg-flohh-secondary-green h-full animate-width']),
        ref: "completedProgressBar",
        progress: _ctx.submissionProgressBar
      }, null, 8, _hoisted_4)
    ])
  ]))
}