import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-912df1d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "assignments" }
const _hoisted_2 = {
  key: 1,
  class: "bg-white rounded-l-lg m-3 p-8 w-full min-h-[80vh] flex items-center justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_AssignmentFormComponent = _resolveComponent("AssignmentFormComponent")!
  const _component_ProgressLoader = _resolveComponent("ProgressLoader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageTitle, {
      title: "Edit Assignment",
      routes: _ctx.routes
    }, null, 8, ["routes"]),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.assignmentUuid)
        ? (_openBlock(), _createBlock(_component_AssignmentFormComponent, {
            key: 0,
            isEdit: true,
            assignmentUuid: _ctx.assignmentUuid
          }, null, 8, ["assignmentUuid"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_ProgressLoader, { size: "lg" })
          ]))
    ])
  ], 64))
}