<template>
  <div class="mb-5">
    <h6 class="text-flohh-h6 font-flohh-font-bold">Submissions Uploaded</h6>
    <div class="py-6">
      <hr />
    </div>
    <div class="mb-7">
      <AppTypographyText
        :label="`Successful Uploads: ${filesSucceeded.length}`"
        variant="md"
        type="body"
        class="mb-1"
      />
      <AppTypographyText
        :label="`Failed Uploads: ${filesFailed.length}`"
        variant="md"
        type="body"
        class="mb-1"
      />
    </div>
    <div class="submission-result-tabs">
      <TabView v-model:activeIndex="activeTab">
        <TabPanel :header="item.heading" v-for="(item, key) in tabs" :key="key">
          <div class="h-full w-full">
            <component
              :is="item.component"
              :files="item.type === 'success' ? filesSucceeded : filesFailed"
              :type="item.type"
            />
          </div>
        </TabPanel>
      </TabView>
    </div>
    <hr class="my-7" />

    <div class="flex justify-between">
      <AppButton text @click="handleClickFinish">Finish</AppButton>
      <div>
        <AppButton
          v-if="activeTab === 0"
          :disabled="!digitalFirstSubmissionUuid"
          :loading="!digitalFirstSubmissionUuid"
          type="submit"
          @click="handleRedirectToMarkingScreen"
        >
          <template #icon_left>
            <span v-html="icon.checkBlack"></span>
          </template>
          Start Marking
        </AppButton>
        <AppButton
          v-if="activeTab === 1 && filesFailed.length > 0"
          type="submit"
          @click="handleRetryFailedSubmissions"
        >
          <template #icon_left>
            <span v-html="icon.checkBlack"></span>
          </template>
          Retry
        </AppButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-facing-decorator";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import UploadResultListComponent from "./UploadResultListComponent.vue";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { icons } from "@/utils/icons";

@Component({
  components: {
    AppTypographyText,
    TabView,
    TabPanel,
    UploadResultListComponent,
    AppButton,
  },
})
export default class SubmissionResultComponent extends Vue {
  icon = icons;
  @Prop({
    type: Array,
    required: false,
  })
  filesSucceeded: File[] = [];

  @Prop({
    type: Array,
    required: false,
  })
  filesFailed: File[] = [];

  @Prop({
    type: String,
    default: "",
  })
  digitalFirstSubmissionUuid!: string;

  activeTab = 0;

  tabs = [
    {
      heading: "Successful Uploads",
      component: UploadResultListComponent,
      type: "success",
    },
    {
      heading: "Failed Uploads",
      component: UploadResultListComponent,
      type: "failed",
    },
  ];

  handleRedirectToMarkingScreen() {
    this.$router.push({
      name: "Marking",
      params: { id: this.digitalFirstSubmissionUuid },
    });
  }

  handleRetryFailedSubmissions() {
    this.$emit("onSubmit", this.filesFailed);
  }

  handleClickFinish() {
    this.$emit("onFinish");
  }
}
</script>

<style lang="scss">
$color: #f9b2ce;
.submission-result-tabs {
  .p-tabview {
    .p-tabview-nav {
      background: transparent;
      // exact width 530
      border-bottom-width: 4px;
      li {
        &:hover {
          .p-tabview-nav-link {
            border-color: $color !important;
            border-bottom-width: 4px;
          }
        }
        &.p-highlight {
          .p-tabview-nav-link {
            border-color: $color;
            color: $color;
            border-bottom-width: 4px;
          }
        }
        .p-tabview-nav-link {
          background: transparent !important;
          box-shadow: none !important;
          border-bottom-width: 4px;
        }
      }
    }
    .p-tabview-panels {
      background-color: transparent;
      padding: 25px 0 0 0 !important;
    }
  }
  .bordered {
    border: 1px solid #d9d9d9;
  }
}
</style>
