<script lang="ts">
import { Vue, Component, Model, Emit, Prop } from "vue-facing-decorator";
import ModalUtility from "../utilities/ModalUtility.vue";
import ClassInformation from "./ClassInformation.vue";
import FormField from "../Layout/Forms/FormField.vue";
import { Icon, icons } from "@/utils/icons";
import AppButton from "../Layout/Buttons/AppButton.vue";
import AddStudentManually from "./Manually/AddStudentManually.vue";
import { ClassInfo, Student } from "@/store/createClass/types";
import { toTitleCase, isEmailValid } from "@/utils/helper";
import ClassService from "@/services/ClassService";
import emitter from "@/config/emitter";
type InvalidObject = {
  index: number;
  [key: string]: string | number;
};

type Error = {
  index?: number;
  firstName?: string;
  lastName?: string;
  studentId?: string;
  email?: string;
};

@Component({
  components: {
    ModalUtility,
    ClassInformation,
    FormField,
    AppButton,
    AddStudentManually,
  },
})
export default class ManualAddStudentPopup extends Vue {
  @Model({
    type: Boolean,
    default: false,
  })
  openDialog!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  isEdit!: boolean;

  private classService = new ClassService();

  icon: Icon = icons;
  isValid = true;
  invalidObjects: InvalidObject[] = [];
  errors: Error[] = [];
  loadingStudents = false;
  errorStudents = false;
  eventBus = emitter;

  @Emit("onSkipOrGoBack")
  handleClickSkipOrGoBack(index = 0) {
    return index;
  }

  @Emit("onSaveAddStudentManually")
  handleOnSaveEmit() {
    const classInfo: ClassInfo =
      this.$store.getters["createClassPopup/classInformation"];
    if (classInfo && classInfo.uuid && !this.isEdit) {
      if (this.$route.query && this.$route.query.assignment) {
        this.$router.push({
          name: "Classes",
          params: { id: classInfo.uuid },
          query: { assignment: this.$route.query.assignment },
        });
        return;
      }
      this.$router.push({ name: "Classes", params: { id: classInfo.uuid } });
    }
    if (this.isEdit) {
      this.eventBus.emit("LOAD_CLASSES");
    }
    return;
  }

  validateData() {
    this.isValid = true;
    let students: Student[] = structuredClone(
      this.$store.state.createClassPopup.addManually
    );

    this.invalidObjects = [];
    students.forEach((object: Student, index: number) => {
      for (const key in object) {
        let message = "";
        if (
          object[key as keyof Student] === null ||
          object[key as keyof Student] === undefined ||
          object[key as keyof Student] === ""
        ) {
          message = `The ${toTitleCase(key)} field is required.`;
        }
        if (
          key === "email" &&
          object[key as keyof Student] &&
          !isEmailValid(object[key as keyof Student])
        ) {
          message = `The ${toTitleCase(key)} field is invalid.`;
        }

        if (
          key === "email" &&
          students.filter((student) => student.email === object.email).length >=
            2
        ) {
          message = `The ${toTitleCase(key)} is duplicate`;
        }

        if (message) {
          const invalidObj = {
            index,
            [key]: message,
          } as InvalidObject;
          this.invalidObjects.push(invalidObj);
        }
      }
    });

    if (this.invalidObjects.length > 0) {
      this.isValid = false;
      const result: InvalidObject[] = [];

      this.invalidObjects.forEach((item) => {
        const existingItem = result.find((entry) => entry.index === item.index);

        if (existingItem) {
          for (const key in item) {
            if (key !== "index") {
              existingItem[key] = item[key];
            }
          }
        } else {
          result.push({ ...item });
        }
      });
      this.errors = result;
    } else {
      this.isValid = true;
    }
  }

  handleCloseCreateClass() {
    this.$store.dispatch("createClassPopup/resetClass");
  }

  handleClickSave() {
    this.eventBus.emit("EVENT_TRIGGER", "CM006");
    this.validateData();
    if (this.isValid) {
      this.onPostStudent();
    }
  }

  async onPostStudent() {
    try {
      this.loadingStudents = true;
      const classInfo: ClassInfo =
        this.$store.getters["createClassPopup/classInformation"];
      const students = structuredClone(
        this.$store.state.createClassPopup.addManually
      );
      const payload = {
        students,
      };
      await this.classService.postManualAddStudent(
        classInfo.uuid as string,
        payload
      );
      this.eventBus.emit("REFRESH_SELECTED_CLASS");
      this.handleOnSaveEmit();
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingStudents = false;
    }
  }

  unmounted() {
    this.errors = [];
  }
}
</script>
<template>
  <ModalUtility
    v-model="openDialog"
    width="60vw"
    title="Add your students"
    @onClose="handleCloseCreateClass"
  >
    <template #content>
      <div class="px-4">
        <div class="py-2 mt-5">
          <FormField label="Class Information">
            <ClassInformation />
          </FormField>
        </div>
        <div class="py-4">
          <AddStudentManually :errors="errors" />
        </div>
      </div>
    </template>
    <template #footer>
      <div
        class="flex flex-row pt-5 border-t border-solid border-flohh-neutral-85"
      >
        <div class="flex-1 flex justify-start items-center">
          <AppButton text @click="handleClickSkipOrGoBack()">
            Go Back
          </AppButton>
        </div>
        <div class="flex-1 flex justify-end items-center gap-x-4">
          <AppButton text @click="handleClickSkipOrGoBack(1)" v-if="!isEdit">
            Skip this step
          </AppButton>
          <AppButton
            type="submit"
            :loading="loadingStudents"
            label="Save"
            @click="handleClickSave"
          >
            <template #icon_left>
              <span v-html="icon.checkBlack"></span>
            </template>
          </AppButton>
        </div>
      </div>
    </template>
  </ModalUtility>
</template>
