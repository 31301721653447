import { ActionContext } from "vuex";
import { RootState } from "@/store";
import * as mutationType from "../mutation-types";
import { AssignmentState, WarningModal } from "../assignmentTypes";

export const SetWarningModalAction = async (
  context: ActionContext<AssignmentState, RootState>,
  modal: WarningModal
) => {
  context.commit(mutationType.SET_WARNING_MODAL, modal);
};
