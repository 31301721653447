<script lang="ts">
import Toast from "primevue/toast";
import {
  Vue,
  Component,
  Model,
  Prop,
  Ref,
  Emit,
  Watch,
} from "vue-facing-decorator";
import { useToast } from "primevue/usetoast";
import ProgressBar from "primevue/progressbar";
import CloudStorageService from "@/services/CloudStorageService";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { icons } from "@/utils/icons";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import { UploadProgress } from "@/components/utilities/utilitiesTypes";

@Component({ components: { Toast, ProgressBar, AppButton, AppTypographyText } })
export default class DefaultFileUploader extends Vue {
  private cloudStorageService = new CloudStorageService();
  icon = icons;

  @Prop({
    type: Boolean,
    default: false,
  })
  multiple!: boolean;

  @Prop({
    type: String,
    default: ".pdf,.docx,.doc,.jpg,.jpeg,.png",
  })
  acceptedFiles!: string;

  @Prop({
    type: String,
    default: "Drag and drop, or click to browse your computer",
  })
  placeholder!: string;

  @Prop({
    type: String,
    default: "Support file types: PDF, JPG, Microsoft Word & Google Doc",
  })
  supportedFileTypes!: string;

  @Prop({
    type: Boolean,
    default: true,
  })
  showFileList!: boolean;

  @Prop({
    type: String,
    default: "Release to drop files here.",
  })
  draggingPlaceholder!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: Object,
    required: false,
    default: {
      fileName: "",
      progress: 0,
    },
  })
  uploadProgress!: UploadProgress;

  @Prop({
    type: Boolean,
    default: false,
  })
  startUploading!: boolean;

  @Model({
    type: Array,
    required: true,
    name: "selectedFiles",
  })
  selectedFilesModel!: File[];

  @Prop({
    type: Number,
    required: false,
    default: 0,
  })
  fileSizeLimit!: number; //mb

  @Prop({
    type: String,
    required: false,
    default: "",
  })
  fileSizeLimitPlaceholder!: string;

  @Prop({
    type: Number,
    required: false,
    default: 25, // For multiple file upload
  })
  maxNumberOfFiles!: number;

  @Ref
  fileInput!: HTMLInputElement;

  toast = useToast();
  isDragging = false;
  refreshKey = 0;
  fileUploading = false;

  filesCompleted: string[] = [];

  @Watch("uploadProgress")
  uploadProgressWatcher(value: UploadProgress) {
    if (value.progress === 100) {
      this.filesCompleted.push(value.fileName);
    }
  }

  onChange(): void {
    if (this.disabled) {
      return;
    }

    if (this.fileInput && this.fileInput.files) {
      let maxFileCountExeeded = false;
      let files: File[] = [];
      const limit = this.fileSizeLimit * 1000000;

      if (this.multiple) {
        const selectedFilesClone = structuredClone(this.selectedFilesModel);
        files = selectedFilesClone;
      }

      let fileCount = files.length;

      Array.from(this.fileInput.files).forEach(async (file) => {
        if (this.fileSizeLimit && file.size > limit) {
          this.toast.add({
            severity: "error",
            summary: "File max size limit exceeded",
            detail: `${file.name} exceeded the file size limit`,
            life: 6000,
          });
        } else if (this.multiple && fileCount + 1 > this.maxNumberOfFiles) {
          maxFileCountExeeded = true;
        } else {
          files.push(file);
          fileCount++;
        }
      });

      if (maxFileCountExeeded) {
        this.toast.add({
          severity: "error",
          summary: "Max file count limit exceeded",
          detail: `You can only upload maximum of ${this.maxNumberOfFiles} files per batch`,
          life: 6000,
        });
      }
      this.selectedFilesModel = Array.from(files);
    }
  }

  handleClickBrowse() {
    this.fileInput.value = "";
    this.fileInput.click();
  }

  dragover(e: DragEvent): void {
    e.preventDefault();
    this.isDragging = true;
  }

  dragleave() {
    this.isDragging = false;
  }

  drop(e: DragEvent): void {
    e.preventDefault();
    if (this.disabled) {
      return;
    }
    if (this.fileInput && e.dataTransfer?.files) {
      if (e.dataTransfer.files.length > 1 && !this.multiple) {
        this.toast.add({
          severity: "error",
          summary: "File upload",
          detail: "Maximum number of upload is 1",
          life: 6000,
        });
      } else if (
        e.dataTransfer.files.length > this.maxNumberOfFiles &&
        this.multiple
      ) {
        this.toast.add({
          severity: "error",
          summary: "Max file count limit exceeded",
          detail: `You can only upload maximum of ${this.maxNumberOfFiles} files per batch`,
          life: 6000,
        });
      } else {
        this.selectedFilesModel = Array.from(e.dataTransfer.files);
      }
    }
    this.isDragging = false;
  }

  @Emit("onRemoveFile")
  handleClickRemoveFile(index: number) {
    this.selectedFilesModel.splice(index, 1);
    this.fileInput.value = "";
    return index;
  }
}
</script>
<template>
  <div
    class="flex flex-col rounded-[8px] w-full justify-center cursor-pointer items-center h-full bg-flohh-neutral-95 border-dashed border-2 border-flohh-neutral-85 pt-[2.1em] pb-[1.7em] px-5"
    @dragover="dragover"
    @dragleave="dragleave"
    @drop="drop"
    @click="handleClickBrowse"
  >
    <img src="@/assets/upload_file_icon.svg" />
    <div v-if="isDragging" class="text-center">
      <p
        class="text-flohh-text-body font-flohh-font-medium text-flohh-neutral-20 mt-2"
      >
        {{ draggingPlaceholder }}
      </p>
    </div>
    <div v-else class="text-center">
      <p
        class="text-flohh-text-body font-flohh-font-medium text-flohh-neutral-20 mt-2"
      >
        <strong>{{ placeholder }}</strong>
      </p>
      <p
        class="text-flohh-text-caption font-flohh-font-medium text-flohh-neutral-20 mt-2"
      >
        {{ supportedFileTypes }}
      </p>
      <p
        v-if="fileSizeLimitPlaceholder"
        class="text-flohh-text-caption font-flohh-font-medium text-flohh-neutral-20"
      >
        {{ fileSizeLimitPlaceholder }}
      </p>
    </div>
  </div>
  <div
    class="w-full flex-col gap-2 max-h-[220px] overflow-y-auto mt-5"
    v-if="showFileList && selectedFilesModel && selectedFilesModel.length"
  >
    <div
      v-for="(file, index) in selectedFilesModel"
      :key="index"
      class="flex w-full rounded-[8px] justify-center items-center bg-flohh-neutral-95 px-[1.6em] py-4 mt-4"
    >
      <div class="w-[95%]">
        <div class="flex justify-between">
          <AppTypographyText variant="md" type="caption" :label="file.name" />
          <AppTypographyText
            v-if="
              uploadProgress.fileName === file.name.split('.')[0] ||
              filesCompleted.includes(file.name.split('.')[0])
            "
            variant="md"
            type="caption"
            :label="`${
              filesCompleted.includes(file.name.split('.')[0])
                ? 100
                : uploadProgress.progress
            }%`"
          />
        </div>
        <div
          v-if="
            uploadProgress.fileName === file.name.split('.')[0] ||
            filesCompleted.includes(file.name.split('.')[0])
          "
          class="w-full bg-flohh-neutral-85 rounded-full dark:bg-flohh-neutral-85 mt-2"
        >
          <div
            class="bg-flohh-secondary-green text-xs font-medium text-white text-center p-0.5 leading-none rounded-full"
            :style="{
              width: `${
                filesCompleted.includes(file.name.split('.')[0])
                  ? 100
                  : uploadProgress.progress
              }%`,
            }"
          ></div>
        </div>
      </div>
      <div class="w-[5%] flex justify-end items-center cursor-pointer">
        <span
          v-html="icon.closeBlack"
          @click="handleClickRemoveFile(index)"
        ></span>
      </div>
    </div>
  </div>
  <input
    type="file"
    name="file"
    id="fileInput"
    class="hidden"
    @change="onChange"
    ref="fileInput"
    :multiple="multiple"
    :key="refreshKey"
    :disabled="disabled"
    :accept="acceptedFiles"
  />
  <div
    class="absolute bg-gray-500 bg-opacity-40 w-full h-full animate-pulse top-0"
    v-if="disabled"
  ></div>
</template>

<style lang="scss">
.picker-dialog {
  &-bg {
    z-index: 5000 !important;
  }
  z-index: 5001 !important;
}
</style>
