import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "px-6 py-4"
}
const _hoisted_2 = { class: "text-center mb-[24px]" }
const _hoisted_3 = {
  key: 0,
  class: "mb-5"
}
const _hoisted_4 = {
  key: 1,
  class: "mb-5"
}
const _hoisted_5 = {
  key: 1,
  class: "px-6 py-4"
}
const _hoisted_6 = { class: "mb-[24px] text-center" }
const _hoisted_7 = { class: "flex gap-2" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "flex flex-row pt-5 border-t border-solid border-flohh-neutral-85" }
const _hoisted_10 = {
  key: 0,
  class: "flex-1 flex justify-start items-center"
}
const _hoisted_11 = { class: "flex-1 flex justify-end items-center gap-x-4" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppTypographyText = _resolveComponent("AppTypographyText")!
  const _component_SelectClass = _resolveComponent("SelectClass")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_SelectAssignment = _resolveComponent("SelectAssignment")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_ModalUtility = _resolveComponent("ModalUtility")!

  return (_openBlock(), _createBlock(_component_ModalUtility, {
    modelValue: _ctx.modalOpen,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.modalOpen) = $event)),
    width: "55vw",
    title: _ctx.modalTitle,
    onOnClose: _ctx.handleModalClose,
    draggable: true,
    containerStyle: "min-w-[600px]"
  }, {
    content: _withCtx(() => [
      (!_ctx.inviteLink)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_AppTypographyText, {
                label: "Let's get your submission link set up. Select your class and assignment below. ",
                variant: "bd",
                type: "subtitle"
              }),
              _createVNode(_component_AppTypographyText, {
                class: "underline",
                label: "Note: Generating this link will immediately make the assignment visible to students for submissions.",
                type: "body"
              })
            ]),
            _createVNode(_component_AppTypographyText, {
              label: "Class name",
              variant: "md",
              type: "body",
              class: "mb-2"
            }),
            _createVNode(_component_FormField, {
              class: "mb-3",
              error: _ctx.error.selectedClass,
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SelectClass, {
                  modelValue: _ctx.selectedClass,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedClass) = $event)),
                  classLabel: _ctx.classLabel,
                  placeholder: "Select your class",
                  defaultValue: _ctx.selectedClassDefault,
                  invalid: _ctx.error && _ctx.error.selectedClass ? true : false,
                  onOnClassSelect: _ctx.handleSelectedClass
                }, null, 8, ["modelValue", "classLabel", "defaultValue", "invalid", "onOnClassSelect"])
              ]),
              _: 1
            }, 8, ["error"]),
            (_ctx.selectedClass === 'create')
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_AppTypographyText, {
                    label: "What should we call your class?",
                    variant: "md",
                    type: "body",
                    class: "mb-2"
                  }),
                  _createVNode(_component_FormField, {
                    required: "",
                    error: _ctx.error.className,
                    background: false,
                    placeholder: "Year 9 English",
                    modelValue: _ctx.className,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.className) = $event))
                  }, null, 8, ["error", "modelValue"])
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_AppTypographyText, {
              label: "Assignment name",
              variant: "md",
              type: "body",
              class: "mb-2"
            }),
            _createVNode(_component_FormField, {
              class: "mb-3",
              error: _ctx.error.selectedAssignment,
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SelectAssignment, {
                  modelValue: _ctx.selectedAssignment,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedAssignment) = $event)),
                  assignmentLabel: _ctx.assignmentLabel,
                  classUuid: _ctx.selectedClass,
                  placeholder: "Select your assignment",
                  defaultValue: _ctx.selectedAssignmentDefault,
                  invalid: _ctx.error && _ctx.error.selectedAssignment ? true : false,
                  disabled: _ctx.selectedClass === 'create' ? true : false,
                  publishedOnly: true
                }, null, 8, ["modelValue", "assignmentLabel", "classUuid", "defaultValue", "invalid", "disabled"])
              ]),
              _: 1
            }, 8, ["error"]),
            (_ctx.selectedAssignment === 'create')
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_AppTypographyText, {
                    label: "What should we call your assignment?",
                    variant: "md",
                    type: "body",
                    class: "mb-2"
                  }),
                  _createVNode(_component_FormField, {
                    required: "",
                    error: _ctx.error.assignmentName,
                    background: false,
                    placeholder: "Romeo and Juliet Essay",
                    modelValue: _ctx.assignmentName,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.assignmentName) = $event))
                  }, null, 8, ["error", "modelValue"])
                ]))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_AppTypographyText, {
                label: "Awesome! Your submission link has been created and the assignment is now visible to students. Copy the link below and share it with your class.",
                variant: "bd",
                type: "subtitle"
              })
            ]),
            _createVNode(_component_AppTypographyText, {
              variant: "md",
              type: "body",
              class: "mb-2"
            }),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_FormField, {
                class: "mb-3 w-[70%]",
                modelValue: _ctx.inviteLink,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.inviteLink) = $event)),
                type: "text",
                readonly: ""
              }, null, 8, ["modelValue"]),
              _createVNode(_component_AppButton, {
                class: "w-[30%]",
                type: "primary",
                onClick: _ctx.handleCopyLink
              }, {
                icon_left: _withCtx(() => [
                  _createElementVNode("span", {
                    innerHTML: _ctx.icon.copyContent
                  }, null, 8, _hoisted_8)
                ]),
                default: _withCtx(() => [
                  _createTextVNode(" Copy Link ")
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ]))
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_9, [
        (_ctx.inviteLink)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createVNode(_component_AppButton, {
                text: "",
                onClick: _ctx.handleGoBack
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Go Back ")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_11, [
          (!_ctx.inviteLink)
            ? (_openBlock(), _createBlock(_component_AppButton, {
                key: 0,
                type: "submit",
                onClick: _ctx.handleGenerateLink,
                disabled: _ctx.generatingLink,
                loading: _ctx.generatingLink
              }, {
                icon_left: _withCtx(() => [
                  _createElementVNode("span", {
                    innerHTML: _ctx.icon.iconLink
                  }, null, 8, _hoisted_12)
                ]),
                default: _withCtx(() => [
                  _createTextVNode(" Generate Submission Link (Assignment Visible to Students) ")
                ]),
                _: 1
              }, 8, ["onClick", "disabled", "loading"]))
            : (_openBlock(), _createBlock(_component_AppButton, {
                key: 1,
                type: "submit",
                onClick: _ctx.handleRedirectToClassPage
              }, {
                icon_left: _withCtx(() => [
                  _createElementVNode("span", {
                    innerHTML: _ctx.icon.checkBlack
                  }, null, 8, _hoisted_13)
                ]),
                default: _withCtx(() => [
                  _createTextVNode(" Take me to my class page ")
                ]),
                _: 1
              }, 8, ["onClick"]))
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "title", "onOnClose"]))
}