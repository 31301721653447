<template>
  <div class="p-[30px] bg-white rounded-lg">
    <div v-if="fetchingMetrics" class="flex justify-center items-center">
      <ProgressLoader label="Loading" labelVariant="md" labelType="body" />
    </div>
    <div v-else>
      <div
        v-if="teacherMetricsData.submissionsMarked < 10"
        class="flex justify-center items-center py-10"
      >
        <AppTypographyText
          label="No enough data yet. Mark 10 submissions to see your stats"
          variant="md"
          type="caption"
        />
      </div>
      <div v-else class="flex flex-col gap-7">
        <h6 class="text-flohh-h6 font-flohh-font-bold !text-[#333]">
          Your Flohh Journey
        </h6>
        <div class="flex justify-center gap-5">
          <div
            class="grow flex items-center gap-3 bg-flohh-tertiary-pink rounded-lg py-5 px-7"
          >
            <span v-html="icons.dashboardPerson"></span>
            <div class="flex flex-col">
              <h5 class="text-flohh-h5 font-flohh-font-bold">
                {{ teacherMetricsData.submissionsMarked }}
              </h5>
              <AppTypographyText
                label="Submissions Marked"
                variant="bd"
                type="caption"
              />
            </div>
          </div>
          <div
            class="grow flex items-center gap-3 bg-flohh-tertiary-pink rounded-lg py-5 px-7"
          >
            <span v-html="icons.dashboardStar"></span>
            <div class="flex flex-col">
              <h5 class="text-flohh-h5 font-flohh-font-bold">
                {{
                  (teacherMetricsData.averageMarkingTime.value / 60).toFixed(2)
                }}
              </h5>
              <AppTypographyText
                label="Minutes per Submission"
                variant="bd"
                type="caption"
              />
            </div>
          </div>
          <div
            class="grow flex items-center gap-3 bg-flohh-tertiary-pink rounded-lg py-5 px-7"
          >
            <span v-html="icons.dashboardPlay"></span>
            <div class="flex flex-col">
              <h5 class="text-flohh-h5 font-flohh-font-bold">
                {{
                  (teacherMetricsData.totalMarkingTime.value / 3600).toFixed(2)
                }}
              </h5>
              <AppTypographyText
                label="Hours Spent Marking"
                variant="bd"
                type="caption"
              />
            </div>
          </div>
          <div
            class="grow flex items-center gap-3 bg-flohh-tertiary-pink rounded-lg py-5 px-7"
          >
            <span v-html="icons.dashboardAnnotation"></span>
            <div class="flex flex-col">
              <h5 class="text-flohh-h5 font-flohh-font-bold">
                {{ teacherMetricsData.totalAnnotations }}
              </h5>
              <AppTypographyText
                label="Annotations Made"
                variant="bd"
                type="caption"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import { icons as AppIcons, icons } from "@/utils/icons";
import AppTypographyText from "../Layout/Typhography/AppTypographyText.vue";
import DashboardService from "@/services/DashboardService";
import { AxiosResponse } from "axios";
import { TTeacherMetrics } from "./types";
import ProgressLoader from "../utilities/ProgressLoader.vue";

@Component({
  components: {
    AppTypographyText,
    ProgressLoader,
  },
})
export default class DashboardJourneyComponent extends Vue {
  private dasboardService: DashboardService = new DashboardService();
  icons = AppIcons;
  fetchingMetrics = true;

  teacherMetricsData!: TTeacherMetrics;

  async created() {
    try {
      const teacherData = localStorage.getItem("teacher");
      const teacher = teacherData ? JSON.parse(teacherData) : "";
      if (teacher) {
        const response: AxiosResponse =
          await this.dasboardService.getTeacherMetrics(teacher.uuid);
        if (response.data.ok) {
          this.teacherMetricsData = response.data.data;
        }
      } else {
        throw new Error("Teacher UUID is empty");
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.fetchingMetrics = false;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.colored-container {
  background-image: url("../../assets/rainbow-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  // min-height: 315px;
  width: 100%;
  border-radius: 8px;
}

.assignments-wrapper {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1em;
}
</style>
