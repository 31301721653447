<template>
  <div v-if="submission.pages.length > 0" class="flex flex-row">
    <div
      class="bg-flohh-neutral-95 px-12 py-10 flex flex-row justify-start items-start rounded-lg"
    >
      <div class="flex flex-col">
        <div class="w-64 flex gap-2">
          <Dropdown
            :options="filterStudents(submission.studentsList)"
            optionLabel="name"
            v-model="selectedStudentUuid"
            placeholder="Assign to..."
            option-value="uuid"
            :pt="{ item: { class: '!p-0' } }"
          >
            <template v-slot:option="slotProps">
              <div
                @click="
                  handleOptionClick(
                    slotProps.option.uuid,
                    slotProps.option.name
                  )
                "
                class="py-[12px] px-[20px]"
              >
                {{ slotProps.option.name }}
              </div>
            </template>
          </Dropdown>
          <AppButton
            @click="handleUnassign"
            v-tooltip.top="{ value: 'Unassign', autoHide: false }"
          >
            <span v-html="icon.closeBlack"></span>
          </AppButton>
        </div>
        <div class="w-full flex flex-row">
          <SingleSubmission
            v-for="(page, i) in submission.pages"
            :key="i"
            :document="page"
            :pageIndex="i"
            :submissionsCount="length"
            :submissionIndex="index"
            :length="submission.pages.length"
            @onCut="handleCut"
            @onRemove="handleRemove"
          />
        </div>
      </div>
    </div>
    <div
      class="flex justify-center items-start flex-col px-[8px] gap-y-[8px] h-full pt-12"
      v-if="index + 1 !== length"
    >
      <button
        class="rounded-full w-[32px] h-[32px] bg-flohh-secondary-green flex justify-center items-center"
        @click="handleMerge"
        v-tooltip.top="{
          value: 'Join Page',
          showDelay: 500,
        }"
      >
        <span v-html="icon.compressIcon"></span>
      </button>
      <button
        class="rounded-full cursor-not-allowed w-[32px] h-[32px] bg-flohh-neutral-85 flex justify-center items-center"
        disabled
      >
        <span v-html="icon.scissorSmallIconBlack"></span>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch, Ref } from "vue-facing-decorator";
import { icons } from "@/utils/icons";
import { CustomSubmission, StudentList } from "../type";
import SingleSubmission from "./SingleSubmission.vue";
import FormInputDropdown from "@/components/Layout/Forms/FormInputDropdown.vue";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import Dropdown, { DropdownFilterEvent } from "primevue/dropdown";
import emitter from "@/config/emitter";

@Component({
  components: { SingleSubmission, AppButton, Dropdown, FormInputDropdown },
})
export default class SubmissionsGroup extends Vue {
  icon = icons;
  eventBus = emitter;

  @Prop({
    type: Object,
    required: true,
  })
  submission!: CustomSubmission;

  @Prop({
    type: Number,
    required: false,
  })
  index!: number;

  @Prop({
    type: Number,
    required: false,
  })
  length!: number;

  selectedStudentName = "";
  selectedStudentUuid = "";

  mounted() {
    this.updateSelectedStudent();
  }

  @Watch("length")
  lengthWatcher() {
    this.updateSelectedStudent();
  }

  updateSelectedStudent() {
    this.selectedStudentUuid = this.submission.studentUuid;
    this.selectedStudentName = this.submission.studentName;
  }

  handleOptionClick(uuid: string, name: string) {
    if (
      this.selectedStudentUuid !== uuid &&
      this.selectedStudentName !== name
    ) {
      this.eventBus.emit("EVENT_TRIGGER", "BU007");
      const returnData = this.composeReturnData(uuid, name);
      this.selectedStudentUuid = uuid;
      this.selectedStudentName = name;
      this.$emit("onStudentChange", returnData);
    }
  }

  composeReturnData(newUuid: string, newName: string) {
    const returnData = {
      index: this.index,
      newStudent: {
        uuid: newUuid,
        name: newName,
      },
      oldStudent: {
        uuid: this.selectedStudentUuid,
        name: this.selectedStudentName,
      },
    };
    return returnData;
  }

  handleUnassign() {
    this.eventBus.emit("EVENT_TRIGGER", "BU008");
    const returnData = this.composeReturnData("", "");
    this.selectedStudentUuid = "";
    this.selectedStudentName = "";
    this.$emit("onStudentChange", returnData);
  }

  handleMerge() {
    this.eventBus.emit("EVENT_TRIGGER", "BU006");
    const returnData = {
      firstIndex: this.index,
      secondIndex: this.index + 1,
    };
    this.$emit("onSubmissionsMerge", returnData);
  }

  handleCut(data: { submissionIndex: number; pageIndex: number }) {
    this.$emit("onSubmissionsCut", data);
  }

  filterStudents(students: StudentList[]) {
    const filtered = students.filter((student: StudentList) => student.uuid);
    return filtered;
  }

  handleRemove(data: {
    submissionIndex: number;
    pageIndex: number;
    pagesCount: number;
  }) {
    this.$emit("onRemovePage", data);
  }
}
</script>

<style scoped lang="scss"></style>
