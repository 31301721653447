import * as actionName from "./action-names";
const namespace = "assignment";

export const setAssignmentByClassDispatch = `${namespace}/${actionName.SET_ASSIGNMENT_BY_CLASS}`;
export const setAssignment = `${namespace}/${actionName.SET_ASSIGNMENT}`;
export const setAssignmentSubmissionSummary = `${namespace}/${actionName.SET_ASSIGNMENT_SUBMISSION_SUMMARY}`;
export const setSelectedStudentSummary = `${namespace}/${actionName.SET_SELECTED_STUDENT_SUMMARY}`;
export const deleteAssignment = `${namespace}/${actionName.DELETE_ASSIGNMENT}`;
export const setSelectedAssignment = `${namespace}/${actionName.SET_SELECTED_ASSIGNMENT}`;
export const setWarningModal = `${namespace}/${actionName.SET_WARNING_MODAL}`;
