import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_CreateClassPopup = _resolveComponent("CreateClassPopup")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _renderSlot(_ctx.$slots, "default", { handleClickClass: _ctx.handleClickClass }, () => [
      _withDirectives((_openBlock(), _createBlock(_component_AppButton, {
        type: _ctx.type,
        onClick: _ctx.handleClickClass
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", {
            innerHTML: _ctx.icons[_ctx.icon]
          }, null, 8, _hoisted_1),
          (!_ctx.isIcon)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode("Class")
              ], 64))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["type", "onClick"])), [
        [
          _directive_tooltip,
          {
        value: 'Create a new class',
        showDelay: 500,
      },
          void 0,
          { top: true }
        ]
      ])
    ]),
    _createVNode(_component_CreateClassPopup, {
      modelValue: _ctx.openClassDialog,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.openClassDialog) = $event)),
      onOnCreateClass: _ctx.handleOnCreateClass,
      isEdit: _ctx.isEdit
    }, null, 8, ["modelValue", "onOnCreateClass", "isEdit"])
  ], 64))
}