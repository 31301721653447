<template>
  <div class="p-10">
    <div v-for="(item, index) in data" :key="index" class="p-5">
      <ChangeLogComponent
        :title="item.title"
        :date="item.date"
        :added="item.added"
        :fixed="item.fixed"
        :changed="item.changed"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import ChangeLogComponent from "@/components/ChangeLog/ChangeLogComponent.vue";

@Component({
  components: {
    ChangeLogComponent,
  },
})
export default class ChangeLogView extends Vue {
  data = [
    {
      title: "v1.24.5",
      date: "01 - 03 - 2023",
      added: ["Add bulk upload to class page", "Add learning goal tab"],
      changed: ["Improve class page UI"],
      fixed: ["Fix bugs on class page", "Fix CSV upload issues"],
    },
    {
      title: "v1.20.0",
      date: "15 - 07 - 2023",
      added: ["Add bulk upload to class page", "Add learning goal tab"],
      changed: ["Improve class page UI"],
      fixed: ["Fix bugs on class page", "Fix CSV upload issues"],
    },
    {
      title: "v1.18.8",
      date: "01 - 12 - 2023",
      added: ["Add bulk upload to class page", "Add learning goal tab"],
      changed: ["Improve class page UI"],
      fixed: ["Fix bugs on class page", "Fix CSV upload issues"],
    },
  ];
}
</script>

<style>
body {
  margin: 0;
}
</style>
