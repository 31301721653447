<script lang="ts">
import SelectClass from "../../../utilities/SelectClass.vue";
import SelectTime from "@/components/utilities/SelectTime.vue";

import { Vue, Emit, Component, Model, Prop } from "vue-facing-decorator";
import Button from "primevue/button";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { icons } from "@/utils/icons";
import FormField from "@/components/Layout/Forms/FormField.vue";
import CreateClassButton from "@/components/CreateClass/CreateClassButton.vue";
import emitter from "@/config/emitter";
import FormDateTimePicker from "@/components/Layout/Forms/FormDateTimePicker.vue";

interface ErrorProps {
  className?: string;
  name?: string;
  dueDate?: string | Date;
  dueTime?: string;
  dueTimestamp: string;
  timeSubmission?: number;
}

@Component({
  components: {
    SelectClass,
    SelectTime,
    Button,
    AppButton,
    FormField,
    CreateClassButton,
    FormDateTimePicker,
  },
})
export default class AssignmentDetailsForm extends Vue {
  @Prop({
    type: Object,
    required: false,
  })
  error?: ErrorProps;

  @Model({
    type: String,
    required: true,
    name: "selectedClass",
  })
  selectedClassModel!: string;

  @Model({
    type: String,
    required: true,
    name: "name",
  })
  nameModel!: string;

  @Model({
    type: [String, Date],
    required: true,
    name: "dueDate",
  })
  dueDateModel!: string | Date;

  @Model({
    type: String,
    required: true,
    name: "dueTime",
  })
  selectedTimeModel!: string;

  @Model({
    type: String,
    required: true,
    name: "dueTimestamp",
  })
  dueTimestampModel!: string;

  @Model({
    type: String,
    required: true,
    name: "timezone",
  })
  timezoneNameModel!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  loadingUpdateAssignment!: boolean;

  @Model({
    type: String,
    required: true,
    name: "timeSubmission",
  })
  timeSubmissionModel!: string;

  eventBus = emitter;

  icon = icons;
  teleport = false;
  loadingDetails = false;
  refreshKey = 0;

  handleDueDate(data: { dateTime: string; timezoneName: string }) {
    this.dueTimestampModel = data.dateTime;
    this.timezoneNameModel = data.timezoneName;
  }

  handleOnSaveCreateClass() {
    this.refreshKey++;
    return;
  }

  @Emit("onClickSaveAssignmentDetails")
  handleClickSaveAssignmentDetails() {
    this.eventBus.emit("EVENT_TRIGGER", "AM001");
    return;
  }
}
</script>
<template>
  <div class="w-full flex flex-col justify-start items-start gap-y-2">
    <div class="py-2 w-full flex flex-col justify-start items-start">
      <FormField
        label="Select your class"
        :error="error?.className"
        :required="true"
      >
        <div class="flex flex-row gap-x-[8px] justify-center items-center">
          <SelectClass
            v-model="selectedClassModel"
            :defaultValue="selectedClassModel"
            :key="refreshKey"
            :invalid="error && error.className ? true : false"
          />
          <CreateClassButton
            type="secondary"
            :is-icon="true"
            @onSaveCreateClass="handleOnSaveCreateClass"
          />
        </div>
      </FormField>
    </div>
    <div class="col-span-1 w-full flex justify-start flex-col items-start">
      <FormField
        label="Give your assignment a name"
        placeholder="Type something"
        :error="error?.name"
        v-model="nameModel"
        type="text"
        :required="true"
        toolTip="Use this field to give your assignment a name that you and your students can use to easily identify it."
        :background="false"
      />
    </div>
    <div
      class="col-span-1 w-full flex justify-start gap-x-2 flex-col sm:flex-row items-start"
    >
      <div class="flex-1 flex flex-col justify-start items-start">
        <FormField
          label="Duration"
          placeholder="In Minutes"
          :error="error?.timeSubmission"
          v-model="timeSubmissionModel"
          type="text"
          :required="true"
          :isNumber="true"
          toolTip="What is 'duration'? This is the average time it takes you to mark a single student submission. For example it may typically take you 20 minutes to mark a student submission.
          
          How does Flohh use your average marking time? Flohh uses the average marking time that you set to give you statistics on your overall time saved."
          :background="false"
        />
      </div>
      <div class="flex-1 flex flex-col justify-start items-start ml-4">
        <FormField
          label="Set the Due Date"
          :error="error?.dueTimestamp"
          :required="true"
          toolTip="The due date of your assignment will be visible to both you and your students."
          class="mb-[15px]"
        >
          <FormDateTimePicker
            v-if="dueTimestampModel"
            v-model="dueTimestampModel"
            :selectedTimezone="timezoneNameModel"
            @onSelect="handleDueDate"
            :isEdit="true"
          />
        </FormField>
      </div>
    </div>
    <div
      class="w-full py-4 border-t border-flohh-neutral-85 border-solid mt-5 flex justify-end flex-col items-end"
    >
      <AppButton
        type="submit"
        :loading="loadingDetails || loadingUpdateAssignment"
        :disabled="loadingDetails || loadingUpdateAssignment"
        iconLeft="checkBlack"
        label="Save"
        @click="handleClickSaveAssignmentDetails"
      >
      </AppButton>
    </div>
  </div>
</template>
