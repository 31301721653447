<template>
  <div
    class="login-container flex h-screen items-center justify-center w-full max-w-[600px] px-5"
  >
    <div
      class="rounded-md flex flex-col justify-center items-center pb-0 px-6 md:px-10 pt-5 bg-white w-full"
    >
      <div
        class="h idden flex flex-col w-full items-center justify-center p-4 relative z-[1]"
      >
        <img
          src="@/assets/flohh-logo.svg"
          class="object-contain object-[14px] max-w-[210px] w-full mx-auto my-0"
        />
      </div>

      <label
        class="ml-2 text-flohh-text-body font-flohh-font-medium color-flohh-neutral-20 mt-1"
        for="isAgree"
        v-if="!isSuperAdmin"
        >Are you a student?

        <router-link
          to="student/login"
          class="text-flohh-primary-pink underline"
        >
          Sign in here
        </router-link>
      </label>
      <Divider />
      <div class="pt-2 pb-6 text-center">
        <p class="text-flohh-text-title color-flohh-neutral-20 font-bold">
          {{ isSuperAdmin ? "Super Admin Login" : "Log In" }}
        </p>
        <label
          class="ml-2 text-flohh-text-body font-flohh-font-medium color-flohh-neutral-20"
          for="isAgree"
          v-if="!isSuperAdmin"
          >Need an account?

          <router-link to="/register" class="text-flohh-primary-pink underline">
            Sign up here
          </router-link>
        </label>
      </div>
      <form
        @submit.prevent="onSubmit"
        autocomplete="off"
        class="w-full md:px-8"
      >
        <div
          class="col-span-1 w-full flex justify-start flex-col items-start mb-4"
        >
          <p
            class="font-flohh-font-medium text-flohh-text-body color-flohh-neutral-20 mb-1"
          >
            Email
          </p>
          <FormTextInput
            type="email"
            v-model="form.email"
            name="email"
            placeholder="Enter Email"
          />
        </div>
        <div
          class="col-span-1 w-full flex justify-start flex-col items-start mb-4"
        >
          <p
            class="font-flohh-font-medium text-flohh-text-body color-flohh-neutral-20 mb-1"
          >
            Password
          </p>
          <FormTextInput
            type="password"
            v-model="form.password"
            name="password"
            placeholder="Enter Password"
            iconRight="eyeActive"
          />
        </div>
        <div class="text-center my-6">
          <label
            class="ml-2 text-flohh-text-body font-flohh-font-medium color-flohh-neutral-20 text-center pb-5"
            for="isAgree"
            >Forgot your password?

            <span
              @click="handleClickReset"
              class="text-flohh-primary-pink underline cursor-pointer"
            >
              Reset password
            </span>
          </label>
        </div>

        <LoginActionComponent
          :handleView="handleView"
          :isSuperAdmin="isSuperAdmin"
          :isLoading="isLoading"
        />
      </form>
    </div>
  </div>

  <ActivationModalComponent
    v-model="visible"
    :onClose="closeModal"
    :verificationError="verificationError"
    :inputs="inputs"
    modalTitle="Teacher Activation"
    :handlePaste="handlePaste"
    :handleResend="handleResend"
    :handleVerify="handleVerify"
    :isVerifying="isVerifying"
  />
</template>

<script lang="ts">
import { Component, Prop, Ref, Vue, Watch } from "vue-facing-decorator";
import LoginFormComponent from "@/components/Authentication/Login/LoginFormComponent.vue";
import LoginActionComponent from "@/components/Authentication/Login/LoginActionComponent.vue";
import { LoginInfo } from "./types";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { icons } from "@/utils/icons";
import FormTextInput from "@/components/Layout/Forms/FormTextInput.vue";
import ErrorSpan from "@/components/utilities/ErrorSpan.vue";
import Divider from "primevue/divider";

import TextInputComponent from "@/components/Input/TextInput/TextInputComponent.vue";
import axios, { AxiosResponse } from "axios";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
import { setAuthDispatch } from "@/store/auth/auth-dispatch";
import InputTextComponent from "@/components/utilities/InputTextComponent.vue";

import ModalUtility from "@/components/utilities/ModalUtility.vue";
import ActivationModalComponent from "../Registration/ActivationModalComponent.vue";

interface ClipboardEvent extends Event {
  clipboardData: DataTransfer;
}

@Component({
  components: {
    LoginFormComponent,
    LoginActionComponent,
    TextInputComponent,
    AppButton,
    FormTextInput,
    ErrorSpan,
    Divider,
    ModalUtility,
    InputTextComponent,
    ActivationModalComponent,
  },
})
export default class LoginComponent extends Vue {
  toast = useToast();

  @Prop({
    type: Function,
    required: true,
  })
  private updateView!: () => void;

  @Prop({
    type: Boolean,
    required: false,
  })
  isSuperAdmin = false;

  visible = false;

  form: LoginInfo = {
    email: "",
    password: "",
  };
  icon = icons;

  loginResponse!: any;
  isLoading = false;
  isVerifying = false;

  // @Ref() readonly inputFields!: HTMLInputElement[];
  // inputFields = ref();

  inputs: { value: string }[] = [
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
  ];

  verificationError = {
    show: false,
    message: "",
  };

  @Watch("visible")
  visibleWatcher(newValue: any) {
    if (newValue === false) {
      this.isLoading = false;
    }
  }

  handleClickReset() {
    localStorage.setItem("redirectPath", "teacher");
    this.$router.push({
      path: "/forgot-password",
    });
  }

  handlePaste(event: Event) {
    const codes = this.splitAndDisplay(event);
    if (codes) {
      codes.forEach((item, index) => {
        this.inputs[index].value = item;
      });
    }
  }

  splitAndDisplay(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const clipboardData = (event as ClipboardEvent).clipboardData;
    const pastedText = clipboardData.getData("text");

    const characters = pastedText.split("");

    event.preventDefault();
    return characters;
  }

  async handleVerify() {
    try {
      this.isVerifying = true;

      const code = this.inputs.map((item) => item.value).join("");
      ///authentication/otp/verify
      const allValuesNotEmpty = this.inputs.every((item) =>
        Boolean(item.value)
      );
      if (allValuesNotEmpty) {
        const payload = {
          code: code,
          type: "teacher_activation",
          token: this.loginResponse.data.data.accessToken,
        };
        const response: any = await axios
          .post("/authentication/otp/verify", payload)
          .catch((error) => {
            console.error(error);
            this.isVerifying = false;
            // this.showError(error.message);
            this.verificationError.show = true;
            this.verificationError.message = error.response.data.details.info;
          });
        this.isVerifying = false;
        if (response && response.data.ok) {
          this.visible = false;
          localStorage.setItem("auth", JSON.stringify(response.data.data));
          const authorization = `Bearer ${response.data.data.accessToken}`;
          axios.defaults.headers.common["Authorization"] = authorization;
          localStorage.setItem(
            "authenticatedAs",
            response.data.data.authenticatedAs
          );
          if (this.isSuperAdmin) {
            this.handleRedirect("/superadmin/teacher");
          } else {
            this.handleRedirect("/dashboard");
          }
        }
        // const response = this.registrationResponse;
      } else {
        this.verificationError.show = true;
        this.verificationError.message = "Invalid code";
        this.isVerifying = false;
      }
    } catch (error: any) {
      this.isVerifying = false;
      console.error(error);
      //
    } finally {
      this.isVerifying = false;
    }
  }

  handleRedirect(link: string) {
    window.location.href = link;
  }

  async handleResend() {
    const payload = {
      type: "teacher_activation",
      token: this.loginResponse.data.data.accessToken,
    };

    const response: any = await axios
      .post("/authentication/otp/resend", payload)
      .catch((error) => {
        console.error(error);
        if (error.response.status === 422) {
          this.showError(error.message);
        }
      });
    if (response.data.ok) {
      this.toast.add({
        severity: "success",
        detail: "Resent code in your email",
        life: 3000,
      });
    }
  }

  /**
   * onSubmit
   */
  public async onSubmit() {
    this.isLoading = true;
    const { email, password } = this.form;
    const payload = {
      email,
      password,
      userType: this.isSuperAdmin ? "super admin" : "teacher",
      authenticatingAs: this.isSuperAdmin ? "super admin" : "teacher",
    };

    try {
      const response: AxiosResponse = await axios.post(
        "authentication/login",
        payload
      );
      if (response.data.ok) {
        if (response.data.data.type === "activation") {
          this.verificationError.show = false;
          this.verificationError.message = "";
          this.visible = true;
          this.loginResponse = response;
          this.inputs = [
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
          ];
        } else {
          localStorage.setItem("auth", JSON.stringify(response.data.data));
          this.$store.dispatch(setAuthDispatch, response.data.data);
          const authorization = `Bearer ${response.data.data.accessToken}`;
          axios.defaults.headers.common["Authorization"] = authorization;
          localStorage.setItem("onboarding", "hide");
          localStorage.setItem(
            "authenticatedAs",
            response.data.data.authenticatedAs
          );
          if (this.isSuperAdmin) {
            this.handleRedirect("/superadmin/teacher");
          } else {
            const shareCode = sessionStorage.getItem("shareCode");
            const sharePage = sessionStorage.getItem("sharePage");
            if (shareCode && sharePage) {
              this.handleRedirect(`${sharePage}?share-code=${shareCode}`);
            } else {
              this.handleRedirect("/dashboard");
            }
          }
        }
      } else {
        console.error("Error", response.data.message);
        this.showError(response.data.message);
      }
    } catch (error) {
      if (error instanceof ReferenceError) {
        this.showError(error.message);
        console.error(error.message);
      }
      this.isLoading = false;
    } finally {
      this.isLoading = false;
    }
  }

  public handleView() {
    // You can perform some logic here and then call the parent function
    this.updateView();
  }

  private showError(message: string) {
    this.toast.add({
      severity: "error",
      detail: message,
      life: 3000,
    });

    this.isLoading = false;
  }

  closeModal() {
    this.visible = false;
    this.isLoading = false;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$browser-context: 16; // Default
@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}
.login-wrap {
  background-color: #f4d5de;
  border-radius: 4px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 454px;
  margin-bottom: 80px;
  p {
    font-size: 22px;
    font-weight: 700;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  form {
    width: 100%;
    max-width: 313px;
  }
}

.text-input {
  margin-bottom: 15px;
}
</style>
