import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bg-flohh-primary-pink rounded-md"
}
const _hoisted_2 = { class: "flex py-[12px] px-[20px] justify-between items-center gap-2" }
const _hoisted_3 = { class: "px-[28px] py-[30px] h-[364px] overflow-auto" }
const _hoisted_4 = {
  key: 0,
  class: "flex flex-col"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["for"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "flex pt-5 border-t border-solid border-flohh-neutral-85" }
const _hoisted_9 = { class: "flex-1 flex justify-start items-center" }
const _hoisted_10 = { class: "flex-1 flex justify-end items-center gap-x-4" }
const _hoisted_11 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Ellipsis = _resolveComponent("Ellipsis")!
  const _component_AppDropdownButton = _resolveComponent("AppDropdownButton")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FormRadioButton = _resolveComponent("FormRadioButton")!
  const _component_ProgressLoader = _resolveComponent("ProgressLoader")!
  const _component_ModalUtility = _resolveComponent("ModalUtility")!
  const _component_DetachDialogComponent = _resolveComponent("DetachDialogComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_FormField, { label: "Comment Bank" }, {
      default: _withCtx(() => [
        (_ctx.selectedCommentBankName)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_Ellipsis, { content: _ctx.selectedCommentBankName }, null, 8, ["content"]),
                _createVNode(_component_AppDropdownButton, {
                  size: "xs",
                  onClickMenu: _ctx.handleSelectMenu,
                  menus: _ctx.statuses,
                  width: "w-30",
                  text: "",
                  dropdownIcon: "moreOptionBlack",
                  loading: _ctx.detaching,
                  disabled: _ctx.detaching,
                  class: "!px-0 !w-[15px]"
                }, null, 8, ["onClickMenu", "menus", "loading", "disabled"])
              ])
            ]))
          : (_openBlock(), _createBlock(_component_AppButton, {
              key: 1,
              type: "transparent",
              label: "Comment Bank",
              onClick: _ctx.handleClickCreate,
              iconLeft: "plusPink",
              textColor: "text-flohh-primary-pink",
              borderColor: "border-flohh-primary-pink",
              size: "md"
            }, null, 8, ["onClick"]))
      ]),
      _: 1
    }),
    _createVNode(_component_ModalUtility, {
      modelValue: _ctx.visible,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.visible) = $event)),
      width: "614px",
      title: "Select a Comment Bank",
      onOnClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.visible = false)),
      draggable: false
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          (!_ctx.listLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(["flex gap-2 items-center w-full px-4 py-4 hover:bg-flohh-neutral-95 cursor-pointer", [
              {
                'bg-flohh-neutral-95': item.uuid === _ctx.selectedCommentBank,
              },
            ]]),
                    key: index,
                    onClick: ($event: any) => (_ctx.handleCommentBankClick(item.uuid))
                  }, [
                    _createVNode(_component_FormRadioButton, {
                      modelValue: _ctx.selectedCommentBank,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCommentBank) = $event)),
                      name: index.toString(),
                      inputId: index.toString(),
                      value: item.uuid
                    }, null, 8, ["modelValue", "name", "inputId", "value"]),
                    _createElementVNode("label", {
                      for: item.name,
                      class: "font-flohh-font-medium text-flohh-text-body cursor-pointer"
                    }, _toDisplayString(item.name), 9, _hoisted_6)
                  ], 10, _hoisted_5))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_component_ProgressLoader)
              ]))
        ])
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_AppButton, {
              onClick: _ctx.handleManageCommentBank,
              text: "",
              blackLabel: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Manage Comment Banks ")
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_AppButton, {
              type: "submit",
              onClick: _ctx.handleClickSelect,
              disabled: !_ctx.selectedCommentBank,
              class: _normalizeClass(!_ctx.selectedCommentBank ? 'opacity-[0.5]' : '')
            }, {
              icon_left: _withCtx(() => [
                _createElementVNode("span", {
                  innerHTML: _ctx.icons.checkBlack
                }, null, 8, _hoisted_11)
              ]),
              default: _withCtx(() => [
                _createTextVNode(" Select ")
              ]),
              _: 1
            }, 8, ["onClick", "disabled", "class"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_DetachDialogComponent, {
      modelValue: _ctx.detachModalOpen,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.detachModalOpen) = $event)),
      title: "Detach Comment Bank",
      body: `Are you sure you want to detach '${_ctx.selectedCommentBankName}' to this assignment?`,
      onOnConfirmDetach: _ctx.handleDetachCommentBank
    }, null, 8, ["modelValue", "body", "onOnConfirmDetach"])
  ], 64))
}