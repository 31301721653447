import axios, { AxiosResponse } from "axios";

export const version = {
  releaseDate: "17/09/2024",
  commit: "f7d638e6975c268fd1dbfe85bef3d2880955a821",
  environment: process.env.VUE_APP_NODE_ENV,
  number: "2402.0.3",
};

export const getAPIVersion = async () => {
  try {
    const appURL = process.env.VUE_APP_API_URL;
    const splitURL = appURL ? appURL.split("/api/v1").join("") : "";
    const response = await fetch(`${splitURL}/version`);

    if (response) {
      return await response.json();
    }
  } catch (err) {
    console.error(err);
  }
};
