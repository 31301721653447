<template>
  <div
    class="auth-container flex h-screen items-center justify-center w-full max-w-[600px] px-5"
  >
    <div
      class="bg-flohh-primary-light-pi nk rounded-md flex flex-col justify-center items-center pb-0 px-6 md:px-10 pt-5 bg-white w-full"
    >
      <!-- <div
      class="bg-flohh -primary-light-pink rounded flex flex-col justify-center items-center w-[454px] p-8"
    > -->
      <!-- <p class="text-flohh-text-title color-flohh-neutral-20 font-bold mb-5">
        {{ title || "Sign In" }}
      </p> -->
      <div
        class="h idden flex flex-col w-full items-center justify-center p-4 relative z-[1]"
      >
        <img
          src="@/assets/flohh-logo.svg"
          class="object-contain object-[14px] max-w-[210px] w-full mx-auto my-0"
        />
      </div>

      <label
        class="ml-2 text-flohh-text-body font-flohh-font-medium color-flohh-neutral-20 mt-1"
        for="isAgree"
        v-if="view !== 'register'"
        >Are you a student?

        <router-link
          to="student/login"
          class="text-flohh-primary-pink underline"
        >
          Sign in here
        </router-link>
      </label>
      <Divider />
      <div class="pt-2 pb-6 text-center">
        <template v-if="view === 'register'">
          <p class="text-[24px] color-flohh-neutral-20 font-bold">
            Create A New Teacher Account
          </p>
          <label
            class="ml-2 text-flohh-text-body font-flohh-font-medium color-flohh-neutral-20"
            for="isAgree"
            >Already have an account?
            <router-link to="/login" class="text-flohh-primary-pink underline"
              >Sign in here</router-link
            >
          </label>
        </template>
        <template v-if="view !== 'register'">
          <p class="text-[24px] color-flohh-neutral-20 font-bold">
            Teacher Sign In
          </p>
          <label
            class="ml-2 text-flohh-text-body font-flohh-font-medium color-flohh-neutral-20"
            for="isAgree"
            >Need an account?
            <router-link
              to="/register"
              class="text-flohh-primary-pink underline"
              >Sign up here</router-link
            >
          </label>
        </template>
      </div>
      <div class="w-full md:px-14 flex items-center justify-center flex-col">
        <AppButton
          @click="handleGoogleAuth"
          type="transparent"
          class="flex w-full !gap-4 text-flohh-text-body font-flohh-font-medium leading-[1.2] text-center !justify-start text-[#333] h-10 mb-4"
        >
          <i><img src="@/assets/icon-google.svg" /></i>
          Continue with Google
        </AppButton>
        <AppButton
          @click="handleMicrosoftAuth"
          type="transparent"
          class="flex w-full !gap-4 text-flohh-text-body font-flohh-font-medium leading-[1.2] text-center !justify-start text-[#333] h-10"
        >
          <i><img src="@/assets/icon-microsoft.svg" /></i>
          Continue with Microsoft
        </AppButton>

        <Divider align="center" type="solid">
          <span>or</span>
        </Divider>
        <AppButton
          type="transparent"
          @click="handleView"
          class="flex w-full !gap-4 text-flohh-text-body font-flohh-font-medium leading-[1.2] text-center !justify-start text-[#333] h-10 mb-4"
        >
          <i><img src="@/assets/icon-email.svg" /></i>
          Continue with Email
        </AppButton>
      </div>
      <!-- <div class="text-center" v-if="view === 'register'">
        <router-link
          to="/login"
          class="text-[15px] font-bold leading-none color-flohh-neutral-20 no-underline"
          >Go back to login</router-link
        >
      </div> -->
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import AppButton from "../Layout/Buttons/AppButton.vue";
import Divider from "primevue/divider";
import { environment } from "@/environments/environment";

@Component({
  components: {
    AppButton,
    Divider,
  },
})
export default class AuthSelectionComponent extends Vue {
  //
  @Prop({
    type: String,
    required: true,
  })
  title!: string;

  @Prop({
    type: String,
    required: true,
  })
  view!: string;

  @Prop({
    type: Function,
    required: true,
  })
  private updateView!: () => void;

  BASE_URL = environment.apiEndpoint;
  REDIRECT_BASE_URL = "";
  googleCallbackUrl: string | null = "";
  microsoftCallbackUrl: string | null = "";

  // TODO: Change to more dynamic approach
  handleGoogleAuth() {
    if (window.location.pathname.includes("superadmin")) {
      //
    } else {
      window.location.href = `${this.REDIRECT_BASE_URL}/google?authenticatingAs=teacher&successRedirectUrl=${this.googleCallbackUrl}&failedRedirectUrl=${this.googleCallbackUrl}`;
    }
  }

  handleMicrosoftAuth() {
    if (window.location.pathname.includes("superadmin")) {
      //
    } else {
      window.location.href = `${this.REDIRECT_BASE_URL}/microsoft?authenticatingAs=teacher&successRedirectUrl=${this.microsoftCallbackUrl}&failedRedirectUrl=${this.microsoftCallbackUrl}`;
    }
  }

  mounted() {
    this.REDIRECT_BASE_URL = `${this.BASE_URL}/authentication/social`;
    const CALLBACK_BASE_URL = `${window.location.protocol}//${window.location.host}/authenticate`;
    this.googleCallbackUrl = `${CALLBACK_BASE_URL}/google?authenticatedAs=teacher`;
    this.microsoftCallbackUrl = `${CALLBACK_BASE_URL}/microsoft?authenticatedAs=teacher`;
  }

  public handleView() {
    // You can perform some logic here and then call the parent function
    if (this.view === "register") {
      // this.$router.push("/register/form");
      this.$router.push("/register/details");
    } else {
      this.updateView();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$browser-context: 16; // Default
@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}
.auth-wrap {
  background-color: #f4d5de;
  border-radius: 4px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 454px;
  p {
    font-size: 22px;
    font-weight: 700;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  .btn-wrap {
    width: 100%;
    max-width: 313px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    button {
      display: flex;
      width: 250px;
      padding: 8px 16px;
      align-items: center;
      gap: 16px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.2;
      text-align: center;
      align-items: center;
      justify-content: flex-start;
      color: #333;
      height: 40px;
      background-color: #fff;
      border-radius: 4px;
      margin-bottom: 16px;
    }
  }
  .btn-link-wrap {
    text-align: center;
    a {
      font-size: 15px;
      font-weight: 700;
      line-height: 1;
      color: #666;
      text-decoration: none;
    }
  }
}
</style>
