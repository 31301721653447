<template>
  <PageTitle title="Classes" :routes="routes" />
  <div class="classes">
    <ClassesListComponent
      v-if="classes.length > 0 && !loadingClasses"
      :loadingClasses="loadingClasses"
      :classes="classes"
    />

    <div
      v-if="loadingClasses"
      class="bg-white rounded-lg m-3 p-10 relative h-[50vh]"
    >
      <div class="w-full flex flex-col h-full">
        <div
          class="w-full h-24 flex flex-row bg-flohh-neutral-85 animate-pulse rounded-[8px]"
        ></div>
        <div class="w-full flex flex-row gap-x-4 mt-5 h-full">
          <div
            class="flex-1 h-full bg-flohh-neutral-85 animate-pulse rounded-[8px]"
          ></div>
        </div>
      </div>
    </div>
    <div
      class="parent-div mt-[30rem] fixed h-[370px] bottom-[-165px] w-full bg-[url('@/assets/bg-dashboard-pattern.png')] bg-repeat-x bg-left-top bg-[auto_60%]"
    ></div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import ClassesListComponent from "@/components/Classes/ClassList/ClassesListComponent.vue";
import { ClassData } from "@/store/class/classTypes";
import { getTeacherUuid } from "@/config/auth";
import { setClassess } from "@/store/class/class-dispatch";
import emitter from "@/config/emitter";
import PageTitle, { TRoute } from "@/components/Layout/Partials/PageTitle.vue";

@Component({
  components: {
    ClassesListComponent,
    PageTitle,
  },
})
export default class ClassesListView extends Vue {
  classes: ClassData[] = [];
  loadingClasses = true;
  filteredClasses: ClassData[] = [];
  eventBus = emitter;

  routes: TRoute[] = [
    {
      title: "Classes",
      routeTo: "ClassesList",
      current: true,
    },
  ];

  async handleFetchTeacherClassess(code = "") {
    this.loadingClasses = true;
    const params: { teacher: string; code?: string } = {
      teacher: getTeacherUuid(),
    };
    if (code) {
      params.code = code;
    } else {
      delete params.code;
    }
    await this.$store.dispatch(setClassess, params);
    this.classes = structuredClone(this.$store.state.teacherClass.classes);
    this.filteredClasses = structuredClone(
      this.$store.state.teacherClass.classes
    );
    // this.handleSortClassesAlphabetically();
    this.loadingClasses = false;
  }

  mounted() {
    this.eventBus.on("LOAD_CLASSES", () => {
      this.handleFetchTeacherClassess();
    });
  }

  created() {
    this.handleFetchTeacherClassess();
    //
  }
}
</script>

<style scoped lang="scss">
body {
  margin: 0;
  overflow: hidden !important;
}
.classes {
  margin-left: 0;
  height: 100%;
  position: relative;
  background-color: #f1f3f3;
}
</style>
