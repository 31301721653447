<script lang="ts">
import { Icon, icons } from "@/utils/icons";
import { Vue, Component, Prop } from "vue-facing-decorator";

@Component({ components: {} })
export default class AppCardButton extends Vue {
  @Prop({
    type: String,
    default: null,
  })
  label!: string;

  @Prop({
    type: String,
    default: "blue",
    validator: (value) => {
      return ["blue", "yellow", "purple", "green"].includes(value);
    },
  })
  color!: string;

  @Prop({
    type: String,
    default: "title",
    validator: (value) => {
      return ["title", "body", "subtitle", "caption", "small"].includes(value);
    },
  })
  type!: string;

  @Prop({
    type: String,
    default: "md",
    validator: (value) => {
      return ["bd", "md", "rg"].includes(value);
    },
  })
  variant!: string;

  icon: Icon = icons;
}
</script>
<template>
  <button
    v-bind="$attrs"
    :class="[
      {
        'font-flohh-font-bold': variant === 'bd',
        'font-flohh-font-medium': variant === 'md',
        'font-flohh-font-regular': variant === 'rg',
        'bg-flohh-tertiary-light-blue': color === 'blue',
        'border-flohh-tertiary-blue': color === 'blue',
        'bg-flohh-tertiary-light-yellow': color === 'yellow',
        'border-flohh-tertiary-yellow': color === 'yellow',
        'bg-flohh-tertiary-light-purple': color === 'purple',
        'border-flohh-tertiary-purple': color === 'purple',
        'bg-flohh-tertiary-light-green': color === 'green',
        'border-flohh-tertiary-green': color === 'green',
        'text-flohh-text-title': type === 'title',
        'text-flohh-text-body': type === 'body',
        'text-flohh-text-subtitle': type === 'subtitle',
        'text-flohh-text-caption': type === 'caption',
        'text-flohh-text-small': type === 'small',
      },
      'border-solid rounded-lg cursor-pointer border-[10px] py-[2.4em] px-[1.9em]',
    ]"
  >
    {{ label }}
  </button>
</template>
<style scoped></style>
