<template>
  <div class="flex flex-col">
    <TabUtilityComponent
      :sortDefault="sortDefault"
      @onSort="handleSort"
      @onSearch="handleSearch"
      class="mb-[29px]"
    />
    <div
      v-if="assignmentsList && assignmentsList.length > 0"
      class="assignments-wrapper"
    >
      <AssignmentCardComponent
        v-for="(item, i) in assignmentsList"
        :key="i"
        :data="item"
        :tab="tab"
        :activeIndex="activeIndex"
        :maxWidth="maxWidth"
        @onDelete="handleDeleteAssignment"
      />
    </div>
    <div v-else class="assignments-wrapper-empty">
      <AppTypographyText
        variant="rg"
        type="body"
        label="No Draft Assignments"
      />
    </div>
    <PaginationUtility
      v-if="assignmentsListClone && assignmentsListClone.length > 0"
      :data="assignmentsListClone"
      :searchText="searchText"
      :config="paginatorConfig"
      @onPageChange="handlePageChange"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import AssignmentCardComponent from "./utilities/AssignmentCardComponent.vue";
import TabUtilityComponent from "./utilities/TabUtilityComponent.vue";
import { AssignmentDashboard } from "@/store/dashboard/dashboardTypes";
import { AssignmentsTabsData } from "./type";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import PaginationUtility from "@/components/utilities/PaginationUtility.vue";
import { sortAssignmentsPageData } from "@/utils/sort";
import { formatDate } from "@/utils/formatter";
import { converToTwelveHoursFormat } from "@/utils/helper";

@Component({
  components: {
    AssignmentCardComponent,
    TabUtilityComponent,
    AppTypographyText,
    PaginationUtility,
  },
})
export default class AssignmentsUnassignedComponent extends Vue {
  //   icons = icons;

  @Prop({
    type: String,
    required: true,
  })
  tab!: string;

  @Prop({
    type: [],
    required: true,
  })
  assignments!: AssignmentDashboard[];

  @Prop({
    type: Number,
    required: true,
  })
  activeIndex!: number;

  @Prop({
    type: Number,
    required: false,
  })
  maxWidth!: number;

  assignmentsList!: AssignmentsTabsData[];
  assignmentsListClone!: AssignmentsTabsData[];

  paginatorConfig = {};

  sortDefault = {
    sortBy: "title_alphabetical",
    sortData: [
      { label: "Title: Alphabetical", value: "title_alphabetical" },
      { label: "Due Date: Earliest", value: "due_earliest" },
    ],
  };
  searchText = "";

  handlePageChange(data: AssignmentsTabsData[]) {
    this.assignmentsList = data;
  }

  handleDeleteAssignment(uuid: string) {
    const assignments = structuredClone(this.assignmentsListClone);
    const filteredAssignments = assignments.filter(
      (assignment: AssignmentsTabsData) => assignment.uuid !== uuid
    );
    this.assignmentsList = filteredAssignments;
    this.assignmentsListClone = filteredAssignments;
  }

  mounted() {
    const assignments = this.assignments;
    const assignmentsList: AssignmentsTabsData[] = [];
    assignments.forEach((assignment: AssignmentDashboard) => {
      if (assignment.visible === false) {
        const formData = {
          uuid: assignment.uuid,
          title: assignment.title,
          class: assignment.class.code,
          classUuid: assignment.class.uuid,
          subject: assignment.class.subject,
          dueDate: `${formatDate(
            assignment.dueDate,
            "DD MMMM YYYY"
          )} at ${converToTwelveHoursFormat(assignment.dueDate.split("T")[1])}`,
          dueDateValue: assignment.dueDate,
        };
        assignmentsList.push(formData);
      }
    });
    const sortedData = sortAssignmentsPageData(
      "title_alphabetical",
      assignmentsList
    );
    this.assignmentsList = sortedData;
    this.assignmentsListClone = sortedData;
  }

  handleSort(sort: string) {
    const assignmentsList = structuredClone(this.assignmentsList);
    const sortedData = sortAssignmentsPageData(sort, assignmentsList);
    this.assignmentsList = sortedData;
  }

  handleSearch(searchText: string) {
    this.searchText = searchText;
  }
}
</script>

<style scoped lang="scss">
.assignments-wrapper {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1em;

  &-empty {
    grid-template-columns: 1fr !important;
    place-items: center;
    padding: 3em 0em;
    text-align: center;
  }
}
</style>
