<template>
  <div class="py-4 first:ml-0 last:mr-0 flex-1 flex flex-row">
    <div class="max-w-[256px] w-72 flex flex-row justify-center">
      <div class="max-h-96 w-auto relative">
        <img class="h-full" :src="handleImage(document.thumbnail)" />
        <div
          class="absolute bottom-2 right-2 flex justify-between items-center gap-2"
        >
          <button
            @click="toggleImageViewer"
            class="cursor-pointer bg-white rounded-full shadow-md p-[8px] flex justify-center items-center"
            v-tooltip.top="{
              value: 'Page Preview',
              showDelay: 500,
            }"
          >
            <span v-html="icon.fullScreenBlackIcon" class="" />
          </button>
          <div v-if="submissionsCount === 1 && length === 1"></div>
          <button
            v-else
            @click="removeFileModalOpen = true"
            class="cursor-pointer bg-white rounded-full shadow-md p-[8px] flex justify-center items-center"
            v-tooltip.top="{
              value: 'Remove Page',
              showDelay: 500,
            }"
          >
            <span v-html="icon.trashBlack" class="" />
          </button>
        </div>
      </div>
    </div>
    <div
      class="flex justify-center items-start flex-col px-[8px] gap-y-[8px]"
      v-if="pageIndex + 1 !== length"
    >
      <button
        disabled
        class="rounded-full w-[32px] h-[32px] bg-flohh-neutral-85 flex justify-center items-center cursor-not-allowed"
      >
        <span v-html="icon.compressIcon"></span>
      </button>
      <button
        class="rounded-full w-[32px] h-[32px] bg-flohh-secondary-red flex justify-center items-center"
        @click="handleCut"
        v-tooltip.top="{
          value: 'Split Page',
          showDelay: 500,
        }"
      >
        <span v-html="icon.scissorSmallIconBlack"></span>
      </button>
    </div>
  </div>

  <ImageViewer
    v-if="isViewerOpen"
    v-model="isViewerOpen"
    :mediaUuid="document.media"
  />

  <Dialog
    :visible="removeFileModalOpen"
    modal
    :closable="false"
    :close-on-escape="true"
    :style="{ width: '410px' }"
    :pt="{
      header: { class: '!py-4 !pl-6' },
      content: { class: '!p-0' },
      footer: { class: '!p-0' },
    }"
  >
    <template #header>
      <div class="">
        <h5
          class="text-default text-neutral-gray-500 flex font-bold items-center justify-start"
        >
          <span v-html="icon.trashBlack" class="mr-2"></span>Remove Page
        </h5>
      </div>
    </template>
    <div class="border-t border-solid border-neutral-gray-500 py-4 px-6">
      <p class="text-sm text-neutral-gray-500">
        Are you sure you want to remove this page?
      </p>
    </div>
    <template #footer>
      <div class="py-2 px-6 flex items-center justify-end">
        <Button
          label="No"
          @click="removeFileModalOpen = false"
          class="border border-solid border-neutral-gray-70 bg-transparent text-sm text-neutral-gray-70 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5"
        />
        <Button
          label="Yes"
          @click="handleRemovePage"
          class="border border-solid border-neutral-gray-500 bg-neutral-gray-85 text-sm text-neutral-gray-500 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5 !mr-0"
          :disabled="removeFileLoading"
          :loading="removeFileLoading"
          :class="[removeFileLoading ? 'cursor-progress' : '']"
        />
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-facing-decorator";
import { icons } from "@/utils/icons";
import { SubmissionPage } from "../type";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import ImageViewer from "@/components/utilities/ImageViewer.vue";
import emitter from "@/config/emitter";

@Component({
  components: { Dialog, Button, ImageViewer },
})
export default class SingleSubmission extends Vue {
  icon = icons;
  eventBus = emitter;

  @Prop({
    type: Object,
    required: true,
  })
  document!: SubmissionPage;

  @Prop({
    type: Number,
    required: false,
  })
  pageIndex!: number;

  @Prop({
    type: Number,
    required: false,
  })
  submissionIndex!: number;

  @Prop({
    type: Number,
    required: false,
  })
  length!: number;

  @Prop({
    type: Number,
    required: false,
  })
  submissionsCount!: number;

  isViewerOpen = false;
  removeFileModalOpen = false;
  removeFileLoading = false;

  handleCut() {
    this.eventBus.emit("EVENT_TRIGGER", "BU005");
    const returnData = {
      submissionIndex: this.submissionIndex,
      pageIndex: this.pageIndex + 1,
    };
    this.$emit("onCut", returnData);
  }

  toggleImageViewer() {
    this.isViewerOpen = !this.isViewerOpen;
  }

  handleRemovePage() {
    this.eventBus.emit("EVENT_TRIGGER", "BU004");
    const returnData = {
      submissionIndex: this.submissionIndex,
      pageIndex: this.pageIndex,
      pagesCount: this.length,
    };
    this.$emit("onRemove", returnData);
    this.removeFileModalOpen = false;
  }

  handleImage(base64File: string) {
    return `data:image/png;base64,${base64File}`;
  }
}
</script>

<style scoped lang="scss"></style>
