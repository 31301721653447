<template>
  <div class="criteria-sheet">
    <CriteriaSheetComponent :relative="true" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import CriteriaSheetComponent from "@/components/PSPDFKit/CriteriaSheetComponent.vue";
@Component({
  components: { CriteriaSheetComponent },
})
export default class CriteriaSheetView extends Vue {
  isCriteriaSheetOpen = true;
}
</script>

<style>
body {
  margin: 0;
}
</style>
