import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-row"
}
const _hoisted_2 = { class: "bg-flohh-neutral-95 px-12 py-10 flex flex-row justify-start items-start rounded-lg" }
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = { class: "w-64 flex gap-2" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "w-full flex flex-row" }
const _hoisted_8 = {
  key: 0,
  class: "flex justify-center items-start flex-col px-[8px] gap-y-[8px] h-full pt-12"
}
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = {
  class: "rounded-full cursor-not-allowed w-[32px] h-[32px] bg-flohh-neutral-85 flex justify-center items-center",
  disabled: ""
}
const _hoisted_11 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_SingleSubmission = _resolveComponent("SingleSubmission")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_ctx.submission.pages.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_Dropdown, {
                options: _ctx.filterStudents(_ctx.submission.studentsList),
                optionLabel: "name",
                modelValue: _ctx.selectedStudentUuid,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedStudentUuid) = $event)),
                placeholder: "Assign to...",
                "option-value": "uuid",
                pt: { item: { class: '!p-0' } }
              }, {
                option: _withCtx((slotProps) => [
                  _createElementVNode("div", {
                    onClick: ($event: any) => (
                  _ctx.handleOptionClick(
                    slotProps.option.uuid,
                    slotProps.option.name
                  )
                ),
                    class: "py-[12px] px-[20px]"
                  }, _toDisplayString(slotProps.option.name), 9, _hoisted_5)
                ]),
                _: 1
              }, 8, ["options", "modelValue"]),
              _withDirectives((_openBlock(), _createBlock(_component_AppButton, { onClick: _ctx.handleUnassign }, {
                default: _withCtx(() => [
                  _createElementVNode("span", {
                    innerHTML: _ctx.icon.closeBlack
                  }, null, 8, _hoisted_6)
                ]),
                _: 1
              }, 8, ["onClick"])), [
                [
                  _directive_tooltip,
                  { value: 'Unassign', autoHide: false },
                  void 0,
                  { top: true }
                ]
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.submission.pages, (page, i) => {
                return (_openBlock(), _createBlock(_component_SingleSubmission, {
                  key: i,
                  document: page,
                  pageIndex: i,
                  submissionsCount: _ctx.length,
                  submissionIndex: _ctx.index,
                  length: _ctx.submission.pages.length,
                  onOnCut: _ctx.handleCut,
                  onOnRemove: _ctx.handleRemove
                }, null, 8, ["document", "pageIndex", "submissionsCount", "submissionIndex", "length", "onOnCut", "onOnRemove"]))
              }), 128))
            ])
          ])
        ]),
        (_ctx.index + 1 !== _ctx.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _withDirectives((_openBlock(), _createElementBlock("button", {
                class: "rounded-full w-[32px] h-[32px] bg-flohh-secondary-green flex justify-center items-center",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleMerge && _ctx.handleMerge(...args)))
              }, [
                _createElementVNode("span", {
                  innerHTML: _ctx.icon.compressIcon
                }, null, 8, _hoisted_9)
              ])), [
                [
                  _directive_tooltip,
                  {
          value: 'Join Page',
          showDelay: 500,
        },
                  void 0,
                  { top: true }
                ]
              ]),
              _createElementVNode("button", _hoisted_10, [
                _createElementVNode("span", {
                  innerHTML: _ctx.icon.scissorSmallIconBlack
                }, null, 8, _hoisted_11)
              ])
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}