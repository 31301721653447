import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Paginator = _resolveComponent("Paginator")!

  return (_openBlock(), _createBlock(_component_Paginator, {
    first: _ctx.first,
    rows: _ctx.rows,
    totalRecords: _ctx.pageCount,
    onPage: _ctx.handlePageChange
  }, null, 8, ["first", "rows", "totalRecords", "onPage"]))
}