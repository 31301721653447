<script lang="ts">
import { Vue, Model, Component, Emit, Ref, Prop } from "vue-facing-decorator";
import SelectCountry from "@/components/utilities/SelectCountry.vue";
import SelectState from "@/components/utilities/SelectState.vue";
import UpdatePasswords from "../Credentials/UpdatePasswords.vue";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import { EditMyAccount } from "../types";

@Component({
  components: {
    SelectCountry,
    SelectState,
    Button,
    UpdatePasswords,
    InputText,
  },
})
export default class EditMyAccountForm extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  userProfile!: EditMyAccount;

  @Model({
    type: String,
    default: "",
    name: "firstName",
  })
  firstNameModel!: string;

  @Model({
    type: String,
    default: "",
    name: "lastName",
  })
  lastNameModel!: string;

  @Model({
    type: String,
    default: "",
    name: "email",
  })
  emailModel!: string;

  @Model({
    type: String,
    default: "",
    name: "phoneNumber",
  })
  phoneNumberModel!: string;

  @Model({
    type: String,
    default: "",
    name: "name",
  })
  nameModel!: string;

  @Model({
    type: String,
    default: "",
    name: "state",
  })
  stateModel!: string;

  @Model({
    type: String,
    default: "",
    name: "country",
  })
  countryModel!: string;

  @Model({
    type: String,
    default: "",
    name: "currentPassword",
  })
  currentPasswordModel!: string;

  @Model({
    type: String,
    default: "",
    name: "newPassword",
  })
  newPasswordModel!: string;

  @Model({
    type: String,
    default: "",
    name: "confirmPassword",
  })
  confirmPasswordModel!: string;

  @Model({
    type: [Array, String],
    default: () => [],
    name: "profile",
  })
  profileImage!: File[];

  @Model({
    type: String,
    default: "",
    name: "imageUrl",
  })
  imageUrlModel!: string;

  @Emit("onSaveProfile")
  handleSaveProfile() {
    return;
  }

  @Emit("onSaveSchool")
  handleSaveSchool() {
    return;
  }

  @Emit("onSaveCredentials")
  handleSaveCredential() {
    return;
  }

  @Emit("onChangeProfilePicture")
  handleChangeProfilePicture() {
    return;
  }

  @Ref
  profileUploader!: HTMLInputElement;

  isEditProfile = false;
  isEditSchool = false;
  isEditCredential = false;

  handleClickEditProfile(type: string): void {
    if (type.toLowerCase() === "save") {
      this.handleSaveProfile();
    }
    this.isEditProfile = !this.isEditProfile;
  }

  handleClickEditSchool(type: string) {
    if (type.toLowerCase() === "save") {
      this.handleSaveSchool();
    }
    this.isEditSchool = !this.isEditSchool;
  }

  handleClickEditCredential(type: string): void {
    if (type.toLowerCase() === "save") {
      this.handleSaveCredential();
    }
    this.isEditCredential = !this.isEditCredential;
  }

  onChange() {
    if (this.profileUploader && this.profileUploader.files) {
      this.profileImage = Array.from(this.profileUploader.files);
      const file = this.profileUploader.files[0];

      if (file) {
        // Use FileReader to read the image file and set it as the imageURL
        const reader = new FileReader();
        reader.onload = () => {
          this.imageUrlModel = reader.result as string;
        };
        reader.readAsDataURL(file);
      }
    }
  }

  handleClickUploadProfile() {
    this.profileUploader.click();
  }
}
</script>
<template>
  <!-- Avatar -->
  <!-- <div class="w-full">
    <div class="w-44 h-44 relative">
      <div
        class="bg-gray-light rounded-full w-full h-full"
        v-if="!imageUrlModel"
      />
      <img
        class="rounded-full w-full h-full object-cover"
        :src="imageUrlModel"
        v-else
        alt="Profile picture"
      />
      <div class="absolute bottom-0 right-0">
        <i
          class="pi pi-pencil cursor-pointer"
          @click="handleClickUploadProfile"
          style="color: rgb(107 114 128)"
        ></i>
        <input
          type="file"
          name="file"
          id="profileUploader"
          class="hidden"
          @change="onChange"
          ref="profileUploader"
          accept="image/*"
        />
      </div>
    </div>
  </div> -->
  <!-- End of avatar -->
  <!-- profile information -->
  <div class="max-h-[260px] w-full">
    <div class="w-full flex justify-start mt-5">
      <h4 class="font-semibold">Personal Details</h4>
    </div>
    <div class="px-6 pt-4 pb-6 flex flex-col">
      <div
        class="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xxl:grid-cols-2 gap-4"
      >
        <div class="col-span-1 flex justify-start flex-col items-start">
          <p class="font-semibold text-sm mb-1">First name</p>
          <InputText
            type="text"
            size="small"
            class="w-full"
            name="firstName"
            v-model="firstNameModel"
          />
        </div>
        <div class="col-span-1 flex justify-start flex-col items-start">
          <p class="font-semibold text-sm mb-1">Last name</p>
          <InputText
            type="text"
            size="small"
            class="w-full"
            name="lastName"
            v-model="lastNameModel"
          />
        </div>
        <div class="col-span-1 flex justify-start flex-col items-start">
          <p class="font-semibold text-sm mb-1">Email</p>
          <InputText
            type="email"
            size="small"
            class="w-full cursor-not-allowed"
            name="email"
            :value="emailModel"
            :disabled="true"
          />
        </div>
        <!-- <div class="col-span-1 flex justify-start flex-col items-start">
          <p class="font-semibold text-sm mb-1">Your phone number</p>
          <InputText
            type="text"
            size="small"
            class="w-full"
            name="phone_number"
            v-model="phoneNumberModel"
          />
        </div> -->
      </div>
    </div>
    <div class="w-full flex justify-start">
      <h4 class="font-semibold">School Details</h4>
    </div>
    <div class="px-6 pt-4 pb-6 flex flex-col">
      <div
        class="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xxl:grid-cols-3 gap-4"
      >
        <div class="col-span-1 flex justify-start flex-col items-start">
          <p class="font-semibold text-sm mb-1">School</p>
          <InputText
            type="text"
            size="small"
            class="w-full"
            name="school_name"
            v-model="nameModel"
          />
        </div>
        <div class="col-span-1 flex justify-start flex-col items-start">
          <p class="font-semibold text-sm mb-1">Country</p>
          <SelectCountry v-model="countryModel" />
        </div>
        <div class="col-span-1 flex justify-start flex-col items-start">
          <p class="font-semibold text-sm mb-1">State/province</p>
          <SelectState v-model="stateModel" :country="countryModel" />
        </div>
      </div>
    </div>
    <div class="w-full flex justify-start">
      <h4 class="font-semibold">Login credentials</h4>
    </div>
    <div class="mt-5">
      <UpdatePasswords :userProfile="userProfile" />
    </div>
  </div>
  <!-- end of profile information -->
</template>
