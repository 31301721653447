<script lang="ts">
import FormField from "@/components/Layout/Forms/FormField.vue";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import { Student } from "@/store/createClass/types";
import { Vue, Component, Prop, Emit, Watch } from "vue-facing-decorator";
import emitter from "@/config/emitter";

type Error = {
  index: number;
  firstName: string;
  lastName: string;
  studentId: string;
  email: string;
};

@Component({
  components: { FormField, AppTypographyText },
})
export default class AddStudent extends Vue {
  eventBus = emitter;
  @Prop({
    type: Number,
    required: true,
  })
  index!: number;

  @Prop({
    type: Object,
  })
  student!: Student;

  @Prop({
    type: Object,
  })
  error!: Error;
  propertyEmail = "email";

  @Prop({
    type: Number,
    required: true,
  })
  studentCount!: number;

  studentModel: Student = {
    firstName: "",
    email: "",
    lastName: "",
    studentId: "N/A",
  };

  errorModel = {
    index: 0,
    firstName: "",
    lastName: "",
    studentId: "",
    email: "",
  };

  @Emit("onRemoveStudent")
  handleOnRemoveStudent() {
    return this.index;
  }

  @Emit("onUpdateListOfStudents")
  handleOnUpdateListOfStudentsEmit() {
    return;
  }

  handleClickRemoveStudent() {
    this.handleOnRemoveStudent();
  }

  mounted() {
    this.studentModel = structuredClone(this.student);
    this.errorModel = this.error
      ? structuredClone(this.error)
      : this.errorModel;
  }

  updated() {
    this.$store.dispatch("createClassPopup/updateStudent", {
      index: this.index,
      student: structuredClone(this.studentModel),
    });
  }

  @Watch("student")
  studentWatcher(value: Student) {
    this.studentModel = value;
  }

  @Watch("error")
  errorWatcher(value: Error) {
    this.errorModel = value;
  }

  @Watch("studentModel.email")
  studentEmailWatcher(value: string) {
    if (value) {
      this.errorModel.email = "";
    } else {
      this.errorModel.email = "Email is required";
    }
  }

  @Watch("studentModel.firstName")
  studentFirstNameWatcher(value: string) {
    if (value) {
      this.errorModel.firstName = "";
    } else {
      this.errorModel.firstName = "First Name is required";
    }
  }

  @Watch("studentModel.lastName")
  studentLastNameWatcher(value: string) {
    if (value) {
      this.errorModel.lastName = "";
    } else {
      this.errorModel.lastName = "Last Name is required";
    }
  }
}
</script>
<template>
  <div class="flex flex-row gap-x-4 mb-2">
    <div class="flex-1">
      <AppTypographyText
        v-if="index === 0"
        label="First Name"
        variant="md"
        type="body"
        class="mb-2"
      />
      <FormField
        type="text"
        v-model="studentModel.firstName"
        :error="errorModel && errorModel.firstName ? errorModel.firstName : ''"
        :placeholder="index === 0 ? 'Jessica' : ''"
        required
      ></FormField>
    </div>
    <div class="flex-1">
      <AppTypographyText
        v-if="index === 0"
        label="Last Name"
        variant="md"
        type="body"
        class="mb-2"
      />
      <FormField
        type="text"
        v-model="studentModel.lastName"
        :error="errorModel && errorModel.lastName ? errorModel.lastName : ''"
        :placeholder="index === 0 ? 'Jones' : ''"
        required
      ></FormField>
    </div>
    <div class="flex-1">
      <AppTypographyText
        v-if="index === 0"
        label="Email"
        variant="md"
        type="body"
        class="mb-2"
      />
      <FormField
        type="text"
        v-model="studentModel.email"
        :error="errorModel && errorModel.email ? errorModel.email : ''"
        :placeholder="index === 0 ? 'Jessica@school.com' : ''"
        required
      ></FormField>
    </div>
    <div class="px-1 flex justify-center items-center">
      <i
        v-if="studentCount === 1"
        :class="index === 0 ? 'translate-y-2/4' : ''"
        class="pi pi-times-circle text-lg rounded-full cursor-not-allowed"
      ></i>
      <i
        v-else
        @click="handleClickRemoveStudent"
        :class="index === 0 ? 'translate-y-2/4' : ''"
        class="pi pi-times-circle text-lg rounded-full cursor-pointer"
      ></i>
    </div>
  </div>
</template>
