import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "text-default text-neutral-gray-500 flex font-bold items-center justify-start" }
const _hoisted_3 = { class: "border-t border-solid border-neutral-gray-500 py-4 px-6" }
const _hoisted_4 = { class: "text-sm text-neutral-gray-500" }
const _hoisted_5 = { class: "py-2 px-6 flex items-center justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.visible,
    closable: false,
    draggable: false,
    modal: "",
    style: { width: '515px' },
    pt: {
      header: { class: '!py-4 !pl-6' },
      content: { class: '!p-0' },
      footer: { class: '!p-0' },
    }
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.title), 1)
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_Button, {
          label: "Cancel",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.visible = false)),
          class: "border border-solid border-neutral-gray-70 bg-transparent text-sm text-neutral-gray-70 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5"
        }),
        _createVNode(_component_Button, {
          label: "Detach",
          onClick: _ctx.handleDetach,
          class: "border border-solid border-neutral-gray-500 bg-neutral-gray-85 text-sm text-neutral-gray-500 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5 !mr-0"
        }, null, 8, ["onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.body), 1)
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}