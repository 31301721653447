import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f09f624"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "absolute left-[24px] top-[50%] translate-y-[-50%] w-4"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "unit" }
const _hoisted_5 = { class: "invisible text-flohh-text-body" }
const _hoisted_6 = {
  key: 0,
  class: "units-value text-flohh-neutral-70 text-flohh-text-body font-poppins"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createElementBlock("div", _mergeProps({ class: "w-full relative" }, _ctx.$attrs), [
    _createVNode(_component_InputText, _mergeProps({
      type: _ctx.type === 'password' ? _ctx.toggleType : _ctx.type,
      size: _ctx.size,
      modelValue: _ctx.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      readonly: _ctx.readonly,
      disabled: _ctx.disabled,
      placeholder: _ctx.placeholder
    }, _ctx.$attrs, {
      class: [
        'w-full h-[47px] text-flohh-text-body font-flohh-font-medium px-[24px] py-[24px]',
        {
          'p-invalid': _ctx.invalid,
          'bg-flohh-neutral-95': _ctx.background,
        },
        _ctx.iconRight && _ctx.iconLeft ? 'with-icon-left-right' : '',
        _ctx.iconRight && !_ctx.iconLeft ? 'with-icon-right' : '',
        !_ctx.iconRight && _ctx.iconLeft ? 'with-icon-left' : '',
        !_ctx.iconRight && !_ctx.iconLeft ? 'form-text-padding' : '',
        _ctx.inputClass,
      ]
    }), null, 16, ["type", "size", "modelValue", "readonly", "disabled", "placeholder", "class"]),
    (_ctx.iconLeft)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("span", {
            innerHTML: _ctx.icon[_ctx.iconLeft]
          }, null, 8, _hoisted_2)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleToggePass && _ctx.handleToggePass(...args))),
      class: "absolute right-[24px] top-[12px] w-4 cursor-pointer opacity-80"
    }, [
      _createElementVNode("span", {
        innerHTML: _ctx.icon[_ctx.eyeIcon ? _ctx.eyeIcon : _ctx.iconRight]
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.value), 1),
      (_ctx.value)
        ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.unit), 1))
        : _createCommentVNode("", true)
    ])
  ], 16))
}