import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FroalaEditorComponent = _resolveComponent("FroalaEditorComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FroalaEditorComponent, {
      config: _ctx.fraolaConfig,
      modelValue: _ctx.typeAssignmentModel,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.typeAssignmentModel) = $event)),
      disabled: _ctx.disabledTextEditor,
      onOnEditorEmpty: _ctx.handleDisableUpload
    }, null, 8, ["config", "modelValue", "disabled", "onOnEditorEmpty"])
  ]))
}