import { StudentProfile } from "@/models/Student";
import { Teacher } from "@/models/Teacher";

export interface AuthProfile {
  accessToken: string;
  accessTokenExpiresOn: string;
  refreshToken: string;
  refeshTokenExpiresOn: string;
  type: string;
  data: AuthUser;
}

export interface AuthUser {
  email: string;
  roles?: string[];
  status: string;
  uuid: string;
  firstName?: string;
  lastName?: string;
  user?: string;
}

export interface GetAuthUser {
  authUser: AuthProfile;
}

export interface ProfileDetails {
  profile: AuthUser;
  profileUuid: string;
  schoolAddress: Address;
  schoolName: string;
  user: string;
  uuid: string;
  workEmail?: string;
  email?: string;
}
interface Address {
  address1?: string;
  address2?: string;
  country: string;
  postCode?: number;
  state: string;
  suburb?: string;
}
export function getAuthUser(): GetAuthUser {
  let authUser: AuthProfile = {
    accessToken: "",
    accessTokenExpiresOn: "",
    data: {
      email: "",
      roles: [],
      status: "",
      uuid: "",
    },
    refreshToken: "",
    refeshTokenExpiresOn: "",
    type: "",
  };
  const getAuthProfileLocalStorage = localStorage.getItem("auth");
  if (getAuthProfileLocalStorage) {
    const authProfile: AuthProfile = JSON.parse(getAuthProfileLocalStorage);
    authUser = authProfile;
  }

  return {
    authUser,
  };
}

export function getProfileDetails(role: string) {
  let profile!: ProfileDetails;
  const getProfileLocalStorage = localStorage.getItem(role);
  if (getProfileLocalStorage) {
    profile = JSON.parse(getProfileLocalStorage);

    if (!profile.schoolAddress) {
      profile = {
        ...profile,
        schoolAddress: {
          address1: "",
          address2: "",
          country: "",
          postCode: undefined,
          state: "",
          suburb: "",
        },
      };
    }
  }
  return profile;
}

export function getTeacherUuid(): string {
  let uuid = "";
  const getTeacherProfileLocalStorage = localStorage.getItem("teacher");
  if (getTeacherProfileLocalStorage) {
    const teacher: Teacher = JSON.parse(getTeacherProfileLocalStorage);
    uuid = teacher.uuid;
  }
  return uuid;
}

export function getAuthAccessToken(): string {
  const { authUser } = getAuthUser();
  return `Bearer ${authUser?.accessToken}`;
}

export function getAuthUserUuid(): string {
  const { authUser } = getAuthUser();
  return authUser?.data.uuid as string;
}
