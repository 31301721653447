<template>
  <ModalUtility v-model="isUpdatesModalOpen" title="Updates">
    <template #content>
      <div v-if="updatesItems" class="bg-white p-[1.5em]">
        <TeacherUpdates v-if="role === 'teacher'" :updates="updatesItems" />
        <StudentUpdates
          v-else-if="role === 'student'"
          :updates="updatesItems"
        />
      </div>
      <div v-else class="p-10">No updates are available</div></template
    ></ModalUtility
  >
</template>

<script lang="ts">
import { Component, Prop, Vue, Model, Watch, Emit } from "vue-facing-decorator";
import ModalUtility from "@/components/utilities/ModalUtility.vue";
import { PendingActions } from "@/store/dashboard/dashboardTypes";
import TeacherUpdates from "./TeacherUpdates.vue";
import StudentUpdates from "./StudentUpdates.vue";

@Component({
  components: { TeacherUpdates, StudentUpdates, ModalUtility },
})
export default class UpdatesModal extends Vue {
  @Model({
    type: Boolean,
    default: false,
    required: true,
  })
  isUpdatesModalOpen!: boolean;

  @Prop({
    type: Array,
    required: false,
  })
  updates!: PendingActions[];

  updatesItems!: PendingActions[];
  role = "";

  @Emit("onUpdatesModalClose")
  closeModal() {
    return true;
  }

  @Watch("isUpdatesModalOpen")
  isUpdatesModalOpenWatcher(value: boolean) {
    if (value) {
      const updatesFromLocal = localStorage.getItem("userSession");
      if (updatesFromLocal) {
        this.updatesItems = JSON.parse(updatesFromLocal);
      }
      const role = localStorage.getItem("authenticatedAs");
      this.role = role ? role : "";
    } else {
      this.closeModal();
    }
  }

  mounted() {
    this.updatesItems = this.updates;
    const role = localStorage.getItem("authenticatedAs");
    this.role = role ? role : "";
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
