import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col justify-start items-start px-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AssignmentDetailsForm = _resolveComponent("AssignmentDetailsForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AssignmentDetailsForm, {
      selectedClass: _ctx.assignmentDetailsModel.class,
      "onUpdate:selectedClass": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.assignmentDetailsModel.class) = $event)),
      name: _ctx.assignmentDetailsModel.name,
      "onUpdate:name": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.assignmentDetailsModel.name) = $event)),
      dueDate: _ctx.assignmentDetailsModel.dueDate,
      "onUpdate:dueDate": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.assignmentDetailsModel.dueDate) = $event)),
      dueTime: _ctx.assignmentDetailsModel.dueTime,
      "onUpdate:dueTime": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.assignmentDetailsModel.dueTime) = $event)),
      dueTimestamp: _ctx.assignmentDetailsModel.dueTimestamp,
      "onUpdate:dueTimestamp": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.assignmentDetailsModel.dueTimestamp) = $event)),
      timezone: _ctx.assignmentDetailsModel.timezoneName,
      "onUpdate:timezone": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.assignmentDetailsModel.timezoneName) = $event)),
      timeSubmission: _ctx.assignmentDetailsModel.timeSubmission,
      "onUpdate:timeSubmission": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.assignmentDetailsModel.timeSubmission) = $event)),
      onOnClickSaveAssignmentDetails: _ctx.handleValidateAssignmentDetails,
      loadingUpdateAssignment: _ctx.loadingUpdateAssignment,
      error: _ctx.error
    }, null, 8, ["selectedClass", "name", "dueDate", "dueTime", "dueTimestamp", "timezone", "timeSubmission", "onOnClickSaveAssignmentDetails", "loadingUpdateAssignment", "error"])
  ]))
}