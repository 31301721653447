<script lang="ts">
import { Vue, Component, Watch } from "vue-facing-decorator";
import {
  Navigation,
  navigation as navigationList,
  superAdminNavigation,
} from "@/utils/navigations";
import { icons as AppIcons } from "@/utils/icons";
import { RouteRecord } from "vue-router";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import PopUpSidePanel from "./Partials/PopUpSidePanel.vue";
import FullSidePanel from "./Partials/FullSidePanel.vue";
import IconSidePanel from "./Partials/IconSidePanel.vue";
import TopNavigation from "./Partials/TopNavigation.vue";
import TeacherService from "@/services/TeacherService";
import StudentService from "@/services/StudentService";
import {
  getAuthMe,
  setTeacherDispatch,
  setStudentDispatch,
} from "@/store/auth/auth-dispatch";
import emitter from "@/config/emitter";
import { getTeacherUuid } from "@/config/auth";
import { setClassess } from "@/store/class/class-dispatch";
import { ProfileDetails } from "@/config/auth";

// interface Navigation {
//   name: string;
//   route: string;
//   icon: string;
//   current: boolean;
//   children?: SubNavigation[];
//   subNavigation?: boolean;
//   custom?: boolean;
//   isHidden?: boolean;
// }

@Component({
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    PopUpSidePanel,
    FullSidePanel,
    IconSidePanel,
    TopNavigation,
  },
})
export default class AppLayout extends Vue {
  private teacherService: TeacherService = new TeacherService();
  private studentService: StudentService = new StudentService();
  sidebarOpen = false;
  navigation: Navigation[] = [];
  hideNavigation = false;
  icons = AppIcons;
  isSuperAdmin = false;
  eventBus = emitter;
  profile!: ProfileDetails;
  userNavigation = [
    { name: "Your profile", href: "#" },
    { name: "Sign out", href: "#" },
  ];

  async onGetUserProfile(role: string) {
    const service =
      role === "student"
        ? this.studentService.getStudent()
        : this.teacherService.getTeacher();

    try {
      const response = await service;
      if (response.data.ok) {
        const profile = response.data.data;
        this.profile = profile;

        if (role === "student") {
          localStorage.setItem(
            "student",
            profile.schoolAddress
              ? JSON.stringify(profile)
              : JSON.stringify({
                  ...profile,
                  schoolAddress: { country: "", state: "" },
                  schoolName: "",
                })
          );
        } else {
          localStorage.setItem("teacher", JSON.stringify(profile));
        }

        this.$store.dispatch(
          role === "student" ? setStudentDispatch : setTeacherDispatch,
          profile
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadClasses() {
    try {
      const params = {
        teacher: getTeacherUuid(),
      };
      await this.$store.dispatch(setClassess, params);
    } catch (error) {
      console.error(error);
    }
  }

  getAuthProfile() {
    this.$store.dispatch(getAuthMe);
  }

  // handleFilteredRoutesForStudent() {
  //   this.navigation = this.navigation
  //     .filter((nav: Navigation) => {
  //       return nav.name === "Class" && nav.custom ? false : true;
  //     })
  //     .map((nav: Navigation) => {
  //       if (nav.name === "Dashboard") {
  //         nav.route = "StudentDashboard";
  //       }

  //       return nav;
  //     });
  // }

  mounted() {
    const auth = localStorage.getItem("auth");
    const authenticatedAs = localStorage.getItem("authenticatedAs");

    if (auth) {
      const authData = JSON.parse(auth).data;
      this.isSuperAdmin = authData.roles.includes("super admin");

      this.getAuthProfile();
      this.navigation = this.isSuperAdmin
        ? superAdminNavigation
        : navigationList;

      if (authenticatedAs) {
        const userType = authenticatedAs;

        switch (userType) {
          case "teacher":
            this.onGetUserProfile("teacher");
            this.loadClasses();
            break;
          case "student":
            this.onGetUserProfile("student");
            // this.handleFilteredRoutesForStudent();

            this.eventBus.on("UPDATE_MY_ACCOUNT", () => {
              this.onGetUserProfile("student");
            });
            break;
          default:
            break;
        }
      }
    }

    this.eventBus.on("LOAD_CLASSES", () => {
      this.loadClasses();
    });
  }

  @Watch("$route", { immediate: true, deep: true })
  routeWatcher(value: RouteRecord) {
    if (!value) return;

    this.navigation.map((nav) => {
      if (nav.route === value.name) {
        nav.current = true;
      } else {
        nav.current = false;
      }
    });
  }
}
</script>
<template>
  <div>
    <PopUpSidePanel v-model="sidebarOpen" :navigation="navigation" />
    <FullSidePanel v-if="!hideNavigation" :navigation="navigation" />
    <IconSidePanel v-else :navigation="navigation" />
    <div :class="hideNavigation ? 'lg:pl-32' : 'lg:pl-72'">
      <TopNavigation
        v-model:sidebarOpen="sidebarOpen"
        v-model:hideNavigation="hideNavigation"
        :isSuperAdmin="isSuperAdmin"
        :profile="profile"
      />

      <main class="">
        <div class="">
          <div id="custom-overlay-menu"></div>
          <!-- Your content -->
          <slot />
        </div>
      </main>
    </div>
  </div>
</template>
<style lang="scss" scoped></style>
