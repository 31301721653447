<template>
  <div
    class="relative w-full min-h-[440px] bg-white overflow-hidden"
    :style="{
      backgroundColor: 'rgba(255, 255, 255, 1)',
      color: 'rgba(102, 102, 102, 1)',
    }"
  >
    <div class="z-[1] btn-close-wrap absolute top-[10px] right-[10px]">
      <button class="p-2 text-white rounded-full" @click="handleClickClose">
        <img src="@/assets/close.svg" class="w-4" />
      </button>
    </div>
    <template v-if="stepCount === 1">
      <Step1Component
        :handleFormStep="handleFormStep"
        v-model:subjectClassName="subjectClassName"
        v-model:subject="subject"
        v-model:yearLevel="yearLevel"
      />
    </template>

    <template v-else-if="stepCount === 2">
      <Step2Component
        :subject="subject"
        :subjectClassName="subjectClassName"
        :handleAddStudentType="handleAddStudentType"
        :addStudentType="addStudentType"
        :handleFormStep="handleFormStep"
      />
    </template>

    <template v-else-if="stepCount === 3">
      <Step3Component
        v-model:students="students"
        :subject="subject"
        :subjectClassName="subjectClassName"
        :addStudentType="addStudentType"
        :handleFormStep="handleFormStep"
        :handleStudents="handleStudents"
      />
    </template>

    <template v-else-if="stepCount === 4">
      <Step4Component
        :students="students"
        :subject="subject"
        :subjectClassName="subjectClassName"
        :handleFormStep="handleFormStep"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";

import HeaderComponent from "./HeaderComponent.vue";
import FooterComponent from "./FooterComponent.vue";
import Step1Component from "./Step1Component.vue";
import Step2Component from "./Step2Component.vue";
import Step3Component from "./Step3Component.vue";
import Step4Component from "./Step4Component.vue";
import { TButtonAction, TStudent } from "./types";

@Component({
  components: {
    HeaderComponent,
    FooterComponent,
    Step1Component,
    Step2Component,
    Step3Component,
    Step4Component,
  },
})
export default class CreateClassComponent extends Vue {
  @Prop({
    type: Function,
  })
  handleClickClose!: () => void;

  stepCount = 1;

  subject = "";
  subjectClassName = "";
  yearLevel = "";

  addStudentType = "";
  students: TStudent[] = [
    { firstName: "", lastName: "", studentID: "", email: "" },
  ];

  handleFormStep(action: TButtonAction) {
    const actionHandlers = {
      next: () => {
        if (this.stepCount + 1 === 5) {
          this.handleClickClose();
        } else {
          this.stepCount += 1;
        }
      },
      previous: () => {
        this.stepCount -= 1;
      },
      close: () => {
        this.handleClickClose();
      },
    };

    const handler = actionHandlers[action];

    if (handler) {
      handler();
    } else {
      // Handler null
    }
  }

  handleAddStudentType(type: string) {
    this.addStudentType = type;
  }

  handleStudents(studentsList: TStudent[]) {
    this.students = studentsList;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
