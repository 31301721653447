<template>
  <div
    class="border border-solid rounded-lg border-flohh-neutral-85 p-[1em] cursor-pointer"
    ref="cardContainer"
    @click.prevent="handleRedirectToAssignment"
  >
    <div class="flex justify-between mb-3">
      <div class="flex flex-col">
        <AppTypographyText variant="md" type="caption" :label="data.subject" />
        <AppTypographyText variant="bd" type="subtitle">
          <Ellipsis :width="`${maxWidth}px`" :content="data.title"
        /></AppTypographyText>
        <AppTypographyText variant="bd" type="small" :label="data.class" />
      </div>
      <div class="flex justify-end">
        <AppDropdownButton
          size="xxs"
          @clickMenu="handleMenuSelection"
          :menus="menus"
          type="default"
          width="w-35"
          text
          dropdownIcon="moreOptionBlack"
        />
      </div>
    </div>

    <div class="flex flex-col mb-[10px]">
      <AppTypographyText
        v-if="tab !== 'completed'"
        class="date-time"
        color="color-flohh-neutral-70"
        type="small"
        :label="`Due on ${data.dueDate}`"
      />
      <AppTypographyText
        class="date-time"
        color="color-flohh-neutral-70"
        type="small"
        :label="data.elapsedTime"
      />
    </div>

    <div v-if="tab === 'active'" class="flex flex-col mb-[10px]">
      <div class="flex justify-between">
        <div class="flex justify-center items-center gap-1">
          <div class="bullet bg-flohh-primary-pink"></div>
          <AppTypographyText variant="md" type="small" :label="data.marked" />
        </div>
        <AppTypographyText
          variant="md"
          type="small"
          :label="`${data.submissionProgressBar}%`"
        />
      </div>
      <div class="flex justify-between">
        <div class="flex justify-center items-center gap-1">
          <div class="bullet bg-flohh-secondary-purple"></div>
          <AppTypographyText variant="md" type="small" :label="data.reviewed" />
        </div>
        <AppTypographyText
          variant="md"
          type="small"
          :label="`${data.learningGoalsProgressBar}%`"
        />
      </div>
    </div>

    <div v-else-if="tab === 'completed'" class="flex flex-col mb-[10px]">
      <div class="flex justify-between">
        <AppTypographyText variant="md" type="small" :label="data.completed" />
        <AppTypographyText variant="md" type="small" label="100%" />
      </div>
    </div>

    <div
      v-if="
        tab === 'active' &&
        data.submissionProgressBar !== undefined &&
        data.learningGoalsProgressBar !== undefined
      "
      class="h-[4px] w-full rounded-[4px] overflow-hidden relative bg-flohh-neutral-85"
    >
      <div
        :style="{
          zIndex:
            data.submissionProgressBar > data.learningGoalsProgressBar ? 1 : 2,
        }"
        ref="markedProgressBar"
        :class="['absolute bg-flohh-primary-pink h-full animate-width']"
        :progress="`${data.submissionProgressBar}%`"
      ></div>
      <div
        :style="{
          zIndex:
            data.learningGoalsProgressBar > data.submissionProgressBar ? 1 : 2,
        }"
        ref="learningGoalProgressBar"
        :class="['absolute bg-flohh-secondary-purple h-full animate-width']"
        :progress="`${data.learningGoalsProgressBar}%`"
      ></div>
    </div>
    <div
      v-else-if="tab === 'completed'"
      class="h-[4px] w-full rounded-[4px] overflow-hidden relative bg-flohh-secondary-green"
      ref="completedProgressBar"
    ></div>
  </div>

  <Dialog
    :visible="deleteModalOpen"
    modal
    :closable="false"
    :close-on-escape="true"
    :style="{ width: '410px' }"
    :pt="{
      header: { class: '!py-4 !pl-6' },
      content: { class: '!p-0' },
      footer: { class: '!p-0' },
    }"
  >
    <template #header>
      <div class="">
        <h5
          class="text-default text-neutral-gray-500 flex font-bold items-center justify-start"
        >
          <i v-html="icons.trashBlack" class="mr-2"></i>Delete Assignment
        </h5>
      </div>
    </template>
    <div class="border-t border-solid border-neutral-gray-500 py-4 px-6">
      <p class="text-sm text-neutral-gray-500">
        You are about to delete this assignment
      </p>
      <br />
      <p class="text-sm text-neutral-gray-500 font-bold">
        {{ data.title }}
      </p>
    </div>
    <template #footer>
      <div class="py-2 px-6 flex items-center justify-end">
        <Button
          label="Cancel"
          @click.prevent="deleteModalOpen = false"
          class="border border-solid border-neutral-gray-70 bg-transparent text-sm text-neutral-gray-70 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5"
        />
        <Button
          label="Delete"
          @click.prevent="handleConfirmDelete"
          class="border border-solid border-neutral-gray-500 bg-neutral-gray-85 text-sm text-neutral-gray-500 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5 !mr-0"
          :disabled="deletingAssignment"
          :loading="deletingAssignment"
          :class="[deletingAssignment ? 'cursor-progress' : '']"
        />
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue, Ref } from "vue-facing-decorator";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import AppDropdownButton from "@/components/Layout/Buttons/AppDropdownButton.vue";
import { AssignmentsTabsData } from "../type";
import Ellipsis from "@/components/utilities/Ellipsis.vue";
import { useToast } from "primevue/usetoast";
import ModalUtility from "@/components/utilities/ModalUtility.vue";
import AssignmentService from "@/services/AssignmentService";
import { icons } from "@/utils/icons";
import { AxiosResponse } from "axios";
import { deleteAssignment } from "@/store/assignment/assignment-dispatch";
import emitter from "@/config/emitter";
import Dialog from "primevue/dialog";
import Button from "primevue/button";

@Component({
  components: {
    AppTypographyText,
    AppDropdownButton,
    Ellipsis,
    ModalUtility,
    Dialog,
    Button,
  },
})
export default class AssignmentCardComponent extends Vue {
  assignmentService = new AssignmentService();
  toast = useToast();
  icons = icons;
  eventBus = emitter;

  @Prop({
    type: Object,
    required: true,
  })
  data!: AssignmentsTabsData;

  @Prop({
    type: String,
    required: true,
  })
  tab!: string;

  @Prop({
    type: Number,
    required: true,
  })
  activeIndex!: number;

  @Prop({
    type: Number,
    required: false,
  })
  maxWidth!: number;

  @Prop({
    type: Boolean,
    required: false,
  })
  isDraft!: boolean;

  @Ref() cardContainer!: HTMLElement;
  @Ref() markedProgressBar!: HTMLElement;
  @Ref() completedProgressBar!: HTMLElement;

  @Ref() learningGoalProgressBar!: HTMLElement;

  host = `${window.location.protocol}//${window.location.host}`;
  assignmentOpen = false;
  deleteModalOpen = false;
  deletingAssignment = false;

  //   icons = icons;
  menus = [
    {
      icon: "assignmentIcon",
      name: "Open",
    },
    {
      icon: "pencilBlack",
      name: "Edit",
    },
    {
      icon: "iconLink",
      name: "Copy Link",
    },
    {
      name: "Delete",
      icon: "trashSmallRedIcon",
      class: "!text-[#be1d17]",
    },
  ];

  animateActiveTabProgressBars() {
    setTimeout(() => {
      if (this.markedProgressBar) {
        const progress = this.markedProgressBar.getAttribute(
          "progress"
        ) as string;

        this.markedProgressBar.style.width = progress;
      }
      if (this.learningGoalProgressBar) {
        const progress = this.learningGoalProgressBar.getAttribute(
          "progress"
        ) as string;

        this.learningGoalProgressBar.style.width = progress;
      }
    }, 1000);
  }

  mounted() {
    if (this.isDraft) {
      this.menus = [
        {
          icon: "assignmentIcon",
          name: "Open",
        },
        {
          icon: "pencilBlack",
          name: "Edit",
        },
      ];
    }
    this.animateActiveTabProgressBars();
  }

  handleMenuSelection(menuName: string) {
    if (menuName === "Open") {
      this.handleRedirectToAssignment();
    } else if (menuName === "Edit") {
      this.handleEditAssignment();
    } else if (menuName === "Copy Link") {
      this.handleCopyLink();
    } else if (menuName === "Delete") {
      this.deleteModalOpen = true;
    }
  }

  handleRedirectToAssignment() {
    this.$router.push({
      name: "Classes",
      params: { id: this.data.classUuid },
      query: { assignment: this.data.uuid },
    });
  }

  async handleEditAssignment() {
    this.$router.push({
      name: "EditAssignment",
      params: { id: this.data.uuid },
    });
  }

  async handleCopyLink() {
    try {
      if (navigator.clipboard) {
        const payload = {
          type: "assignment",
          uuid: this.data.uuid,
        };
        let code = "";
        const res = await this.assignmentService.generateAssignmentShareCode(
          payload
        );

        if (res.data.ok) {
          code = res.data.data.code;
          const link = `${window.location.protocol}//${window.location.host}/student/shared-assignment?share-code=${code}`;

          navigator.clipboard
            .writeText(link)
            .then(() => {
              this.showToast(
                "The assignment shared link is copied to your clipboard.",
                "success"
              );
            })
            .catch((err) => {
              console.error("Unable to copy text: ", err);
            });
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  async handleConfirmDelete() {
    try {
      this.deletingAssignment = true;
      await this.$store.dispatch(deleteAssignment, this.data.uuid);
      this.showToast("Assignment deleted successfully", "success");
      this.$emit("onDelete", this.data.uuid);
    } catch (error) {
      console.error(error);
    } finally {
      this.deletingAssignment = false;
      this.deleteModalOpen = false;
    }
  }

  showToast(
    message: string,
    severity: "success" | "info" | "warn" | "error" | undefined
  ) {
    this.toast.add({
      severity: severity,
      detail: message,
      life: 3000,
    });
  }
}
</script>

<style scoped lang="scss">
.date-time {
  color: #b3b3b3;
}
.bullet {
  width: 3px;
  height: 3px;
  border-radius: 50px;
}
.animate-width {
  width: 0%;
  transition: width 2s cubic-bezier(0.695, 0.035, 0.05, 0.95);
}
</style>
