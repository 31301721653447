import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createSlots as _createSlots, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex gap-x-2 my-[8px] justify-start items-center"
}
const _hoisted_2 = {
  key: 2,
  class: "cursor-pointer"
}
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppTypographyText = _resolveComponent("AppTypographyText")!
  const _component_FormTextInput = _resolveComponent("FormTextInput")!
  const _component_FormTextareaInput = _resolveComponent("FormTextareaInput")!
  const _component_FormDatePicker = _resolveComponent("FormDatePicker")!
  const _component_FormInputDropdown = _resolveComponent("FormInputDropdown")!
  const _component_FormErrorSpan = _resolveComponent("FormErrorSpan")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _mergeProps({ class: "w-full" }, _ctx.$attrs), [
    (_ctx.label || _ctx.subLabel)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.label)
            ? (_openBlock(), _createBlock(_component_AppTypographyText, {
                key: 0,
                variant: "bd",
                type: "title",
                label: _ctx.label,
                class: "my-[8px]"
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "label")
                ]),
                _: 3
              }, 8, ["label"]))
            : _createCommentVNode("", true),
          (_ctx.subLabel)
            ? (_openBlock(), _createBlock(_component_AppTypographyText, {
                key: 1,
                class: "text-flohh-neutral-70",
                variant: "rg",
                type: "caption"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.subLabel), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.toolTip)
            ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("span", {
                  innerHTML: _ctx.icons.tooltipQuestionMark
                }, null, 8, _hoisted_3)
              ])), [
                [
                  _directive_tooltip,
                  {
          value: _ctx.toolTip,
          showDelay: 500,
          pt: {
            text: 'font-normal text-sm w-[150%] left-[-40px] relative',
          },
        },
                  void 0,
                  { top: true }
                ]
              ])
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.caption)
      ? (_openBlock(), _createBlock(_component_AppTypographyText, {
          key: 1,
          class: "my-[8px]",
          variant: "rg",
          type: "small"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.caption), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.subCaption)
      ? (_openBlock(), _createBlock(_component_AppTypographyText, {
          key: 2,
          class: "my-[8px]",
          variant: "rg",
          type: "small"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.subCaption), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, () => [
      (_ctx.type === 'text')
        ? (_openBlock(), _createBlock(_component_FormTextInput, {
            key: 0,
            modelValue: _ctx.model,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
            placeholder: _ctx.placeholder,
            invalid: _ctx.error && _ctx.required ? true : false,
            name: _ctx.name,
            readonly: _ctx.readonly,
            isNumber: _ctx.isNumber,
            disabled: _ctx.disabled,
            iconLeft: _ctx.iconLeft,
            iconRight: _ctx.iconRight,
            background: _ctx.background,
            unit: _ctx.unit
          }, null, 8, ["modelValue", "placeholder", "invalid", "name", "readonly", "isNumber", "disabled", "iconLeft", "iconRight", "background", "unit"]))
        : _createCommentVNode("", true),
      (_ctx.type === 'textarea')
        ? (_openBlock(), _createBlock(_component_FormTextareaInput, {
            key: 1,
            modelValue: _ctx.model,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model) = $event)),
            placeholder: _ctx.placeholder,
            invalid: _ctx.error && _ctx.required ? true : false,
            name: _ctx.name,
            rows: _ctx.rows,
            cols: _ctx.cols,
            readonly: _ctx.readonly,
            autoResize: _ctx.autoResize,
            background: _ctx.background
          }, null, 8, ["modelValue", "placeholder", "invalid", "name", "rows", "cols", "readonly", "autoResize", "background"]))
        : _createCommentVNode("", true),
      (_ctx.type === 'date')
        ? (_openBlock(), _createBlock(_component_FormDatePicker, {
            key: 2,
            invalid: _ctx.error && _ctx.required ? true : false,
            placeholder: _ctx.placeholder,
            readonly: _ctx.readonly,
            modelValue: _ctx.model,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model) = $event)),
            disablePast: _ctx.disablePast,
            background: _ctx.background
          }, null, 8, ["invalid", "placeholder", "readonly", "modelValue", "disablePast", "background"]))
        : _createCommentVNode("", true),
      (_ctx.type === 'select')
        ? (_openBlock(), _createBlock(_component_FormInputDropdown, {
            key: 3,
            modelValue: _ctx.model,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model) = $event)),
            options: _ctx.options,
            optionLabel: _ctx.optionLabel,
            "option-value": _ctx.optionValue,
            invalid: _ctx.error && _ctx.required ? true : false,
            placeholder: _ctx.placeholder,
            filter: _ctx.filter,
            loading: _ctx.loading,
            disabled: _ctx.disabled,
            background: _ctx.background
          }, _createSlots({
            option: _withCtx((slotProps) => [
              _renderSlot(_ctx.$slots, "option", { slotProps: slotProps })
            ]),
            _: 2
          }, [
            (_ctx.$slots.value)
              ? {
                  name: "value",
                  fn: _withCtx((slotProps) => [
                    _renderSlot(_ctx.$slots, "value", {
                      value: slotProps.value
                    })
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1032, ["modelValue", "options", "optionLabel", "option-value", "invalid", "placeholder", "filter", "loading", "disabled", "background"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.error)
      ? (_openBlock(), _createBlock(_component_FormErrorSpan, { key: 3 }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.error), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 16))
}