import { ActionContext } from "vuex";
import { RootState } from "@/store";
import StudentService from "@/services/StudentService";
import { StudentState } from "../studentTypes";

export const DeleteStudentAction = async (
  context: ActionContext<StudentState, RootState>,
  studentUuid: string
) => {
  try {
    const classService = new StudentService();
    const response = await classService.deleteStudent(studentUuid);
    if (response.data.ok) {
      //
    }
  } catch (error) {
    console.error(error);
  }
};
