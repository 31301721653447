<template>
  <div class="bg-white rounded-lg m-3 p-8 relative">
    <div class="flex justify-between mb-7">
      <div>
        <h6 class="text-flohh-h6 font-flohh-font-bold">
          Split and Save Assignments
        </h6>
        <AppTypographyText
          :label="`Number of Submissions: ${submissionCount}`"
          variant="rg"
          type="body"
        />
      </div>
      <div>
        <AppButton
          type="submit"
          @click="confirmBulkUpload"
          :loading="isSaving"
          :disabled="isSaving"
        >
          <span v-html="icon.checkBlack" class="mr-2"></span>
          Save
        </AppButton>
      </div>
    </div>
    <hr class="mb-4" />
    <div class="mb-10">
      <AppTypographyText
        label="You are currently viewing the bulk upload splitting screen."
        variant="rg"
        type="caption"
        class="mb-3"
      />
      <AppTypographyText
        label="Here, you can assign scanned submissions to students using the drop-down list. If no options are available in the drop-down, it means either you haven't added students to this class yet or all available students have been assigned to submissions. In either case, you can leave submissions unassigned for now. They can be easily assigned later when you open them in the marking screen."
        variant="rg"
        type="caption"
        class="mb-3"
      />

      <AppTypographyText
        label="Once you are happy your submissions are split accurately, clicking save will save the individual files."
        variant="rg"
        type="caption"
      />
    </div>
    <div class="border border-solid border-flohh-neutral-85 rounded-lg p-5">
      <div v-if="loadingStudents" class="py-10">
        <ProgressLoader label="Loading Students" labelType="body" />
      </div>
      <div v-else class="relative">
        <div
          class="overflow-x-auto flex flex-row rounded-lg pb-4 scrollable"
          ref="assignmentsRef"
        >
          <SubmissionsGroup
            v-for="(submission, index) in customSubmissions"
            :key="index"
            :index="index"
            :submission="submission"
            :length="customSubmissions.length"
            @onStudentChange="handleStudentChange"
            @onSubmissionsCut="handleCut"
            @onSubmissionsMerge="handleMerge"
            @onRemovePage="handleRemove"
          />
        </div>
        <div class="flex justify-end items-end">
          <div class="flex flex-row justify-center items-center">
            <AppButton
              text
              @click="handleClickScroll(-100)"
              iconLeft="arrowLeftThinBlack"
              v-tooltip.top="{
                value: 'Scroll Left',
                showDelay: 500,
              }"
            />
            <AppButton
              text
              @click="handleClickScroll(100)"
              iconLeft="arrowRightThinBlack"
              v-tooltip.top="{
                value: 'Scroll Right',
                showDelay: 500,
              }"
            />
          </div>
        </div>
        <div
          v-if="executingAction"
          class="absolute w-full h-full top-0 cursor-wait z-10"
        ></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Ref, Prop } from "vue-facing-decorator";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { icons } from "@/utils/icons";
import SubmissionsGroup from "./SubmissionsGroup.vue";
import {
  AssigmentSubmissionSummaryData,
  AssignmentSubmissionSummaryStudents,
} from "@/store/assignment/assignmentTypes";
import { AxiosResponse } from "axios";
import AssignmentService from "@/services/AssignmentService";
import ProgressLoader from "@/components/utilities/ProgressLoader.vue";
import BulkUploadService from "@/services/BulkUploadService";
import emitter from "@/config/emitter";
import { useToast } from "primevue/usetoast";
import {
  CustomSubmission,
  Submission,
  StudentList,
  SubmissionPage,
} from "../type";
import { GroupedData } from "@/models/BulkUpload";

@Component({
  components: {
    AppTypographyText,
    AppButton,
    SubmissionsGroup,
    ProgressLoader,
  },
})
export default class BulkUploadSplitComponent extends Vue {
  private bulkUploadService = new BulkUploadService();
  private assignmentService = new AssignmentService();
  icon = icons;
  eventBus = emitter;
  toast = useToast();

  @Prop({
    type: String,
    required: true,
  })
  assignmentUuid!: string;

  @Prop({
    type: String,
    required: true,
  })
  classUuid!: string;

  @Prop({
    type: Array,
    required: true,
  })
  submissions!: Submission[];

  studentsList!: StudentList[];

  @Ref("assignmentsRef")
  assignmentsRef!: HTMLDivElement;

  submissionCount = 0;
  assignmentSummary!: AssigmentSubmissionSummaryData;
  loadingStudents = true;

  customSubmissions!: CustomSubmission[];
  executingAction = false;
  isSaving = false;

  async created() {
    await this.getStudents();
    this.prepareInitialSubmissions(this.submissions, this.studentsList);
    this.submissionCount = this.submissions.length;

    this.assignmentsRef.addEventListener(
      "wheel",
      (evt: Record<string, any>) => {
        evt.preventDefault();
        this.assignmentsRef.scrollLeft += evt.deltaY;
      }
    );
  }

  handlePreparePayload() {
    const payload: GroupedData[] = [];
    this.customSubmissions.forEach((submission: CustomSubmission) => {
      const mediaList: string[] = [];
      submission.pages.forEach((page: SubmissionPage) => {
        mediaList.push(page.media);
      });
      if (submission.studentUuid) {
        payload.push({
          mediaList,
          studentUuid: submission.studentUuid,
        });
      } else {
        payload.push({
          mediaList,
        });
      }
    });
    return payload;
  }

  async confirmBulkUpload() {
    this.eventBus.emit("EVENT_TRIGGER", "BU003");
    this.isSaving = true;
    try {
      const payload = this.handlePreparePayload();
      const response: AxiosResponse =
        await this.bulkUploadService.postBulkUpload(
          { groupedData: payload },
          this.assignmentUuid
        );

      if (response.data.ok) {
        this.$emit("onStatusUpdate", "processing");
        this.showMessage(
          "success",
          "Success!",
          "Your submissions are being split. Please refresh your class page if you don't see them immediately."
        );

        this.$router.push({
          name: "Classes",
          params: { id: this.classUuid },
          query: { assignment: this.assignmentUuid },
        });
      }
    } catch (err: any) {
      this.showMessage("error", err.title, err.body);
    } finally {
      this.isSaving = false;
    }
  }

  updateSubmissionCount() {
    this.submissionCount = this.customSubmissions.length;
  }

  setAssignedStudentsOnLoad(submissions: Submission[]) {
    const assigned: string[] = [];
    submissions.forEach((submission: Submission) => {
      assigned.push(submission.student.uuid);
    });
    return assigned;
  }

  prepareInitialSubmissions(
    submissions: Submission[],
    students: StudentList[]
  ) {
    const submissionsClone: CustomSubmission[] = [];
    const assignedStudents = this.setAssignedStudentsOnLoad(submissions);

    submissions.forEach((submission: Submission) => {
      const studentList: StudentList[] = [];
      students.forEach((student: StudentList) => {
        const isAssigned =
          assignedStudents.includes(student.uuid) &&
          submission.student.uuid !== student.uuid;
        if (!isAssigned) {
          studentList.push(student);
        }
      });
      const firstName = submission.student.profile.firstName;
      const lastName = submission.student.profile.lastName;
      let studentName =
        firstName === "Unknown" && lastName === ""
          ? ""
          : `${firstName} ${lastName}`;
      let studentUuid = "";

      const exists = this.studentsList.some(
        (student: StudentList) => student.name === studentName
      );
      if (submission.student.uuid) {
        studentUuid = submission.student.uuid;
      }
      if (!exists) {
        studentName = "";
        studentUuid = "";
      }

      const objData = {
        pages: submission.pdfDocuments,
        studentName,
        studentUuid: studentUuid,
        studentsList: studentList,
      };
      submissionsClone.push(objData);
    });
    this.customSubmissions = submissionsClone;
  }

  async getStudents() {
    try {
      this.loadingStudents = true;
      const response: AxiosResponse =
        await this.assignmentService.getAssignmentSubmissionSummary(
          this.assignmentUuid
        );
      if (response.data.ok) {
        const responseData = structuredClone(response.data.data);
        const studentsList: StudentList[] = [];
        responseData.students.forEach(
          (student: AssignmentSubmissionSummaryStudents) => {
            if (!student.submission) {
              const studentData = student.student;
              const data = {
                uuid: studentData.uuid,
                name: `${studentData.profile.firstName} ${studentData.profile.lastName}`,
              };
              studentsList.push(data);
            }
          }
        );
        this.studentsList = studentsList;
      } else {
        throw new Error();
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.loadingStudents = false;
    }
  }

  handleStudentChange(data: {
    index: number;
    newStudent: StudentList;
    oldStudent: StudentList;
  }) {
    this.executingAction = true;
    const customSubmissionsClone: CustomSubmission[] = [];
    this.customSubmissions.forEach(
      (submission: CustomSubmission, i: number) => {
        let studentsList: StudentList[] = submission.studentsList;
        if (data.index === i) {
          // Updates students list of the updated student submission
          const objData = {
            pages: submission.pages,
            studentName: data.newStudent.name,
            studentUuid: data.newStudent.uuid,
            studentsList: studentsList,
          };
          customSubmissionsClone.push(objData);
        } else {
          // Updates students list of other submissions

          if (data.oldStudent.uuid && data.oldStudent.name) {
            // Push the old student to other submissions since it is already a free student
            studentsList.push({
              uuid: data.oldStudent.uuid,
              name: data.oldStudent.name,
            });
          }

          if (data.newStudent.uuid && data.newStudent.name) {
            // Remove the selected student from other submissions
            //     since it is assigned to a specific submission
            studentsList = studentsList.filter(
              (student: StudentList) => student.uuid !== data.newStudent.uuid
            );
          }

          const objData = {
            pages: submission.pages,
            studentName: submission.studentName,
            studentUuid: submission.studentUuid,
            studentsList: studentsList,
          };
          customSubmissionsClone.push(objData);
        }
      }
    );
    this.customSubmissions = customSubmissionsClone;
    this.executingAction = false;
  }

  handleCut(data: { submissionIndex: number; pageIndex: number }) {
    this.executingAction = true;
    const { submissionIndex, pageIndex } = data;
    let customSubmissionsClone: CustomSubmission[] = this.customSubmissions;
    // let updatedCustomSubmissionsClone: CustomSubmission[] = [];
    const firstBatch = customSubmissionsClone[submissionIndex].pages;
    const secondBatch = firstBatch.splice(pageIndex);

    customSubmissionsClone[submissionIndex].pages = firstBatch;

    const studentsListClone =
      customSubmissionsClone[submissionIndex].studentsList;
    const newSubmissionStudentsList = studentsListClone.filter(
      (student: StudentList) =>
        customSubmissionsClone[submissionIndex].studentUuid !== student.uuid
    );

    const newSubmission: CustomSubmission = {
      pages: secondBatch,
      studentName: "",
      studentUuid: "",
      studentsList: newSubmissionStudentsList,
    };

    let insertIndex = submissionIndex + 1;
    customSubmissionsClone.splice(insertIndex, 0, newSubmission);
    this.customSubmissions = customSubmissionsClone;
    this.updateSubmissionCount();
    this.executingAction = false;
  }

  handleMerge(data: { firstIndex: number; secondIndex: number }) {
    this.executingAction = true;
    const { firstIndex, secondIndex } = data;
    let customSubmissionsClone: CustomSubmission[] = this.customSubmissions;
    const firstElement = customSubmissionsClone[firstIndex];
    const secondElement = customSubmissionsClone[secondIndex];

    const pagesClone: SubmissionPage[] = firstElement.pages;
    secondElement.pages.forEach((page: SubmissionPage) => {
      pagesClone.push(page);
    });
    if (firstElement.studentUuid && firstElement.studentName) {
      if (secondElement.studentUuid && secondElement.studentName) {
        let updatedCustomSubmissionsClone: CustomSubmission[] = [];
        customSubmissionsClone.forEach((submission: CustomSubmission) => {
          const studentsListClone: StudentList[] = submission.studentsList;
          studentsListClone.push({
            uuid: secondElement.studentUuid,
            name: secondElement.studentName,
          });
          const objData = {
            ...submission,
            studentsList: studentsListClone,
          };
          updatedCustomSubmissionsClone.push(objData);
        });
        customSubmissionsClone = updatedCustomSubmissionsClone;
      }
    } else {
      if (secondElement.studentUuid && secondElement.studentName) {
        customSubmissionsClone[firstIndex].studentUuid =
          customSubmissionsClone[secondIndex].studentUuid;
        customSubmissionsClone[firstIndex].studentName =
          customSubmissionsClone[secondIndex].studentName;

        const studentsListClone: StudentList[] =
          customSubmissionsClone[firstIndex].studentsList;
        studentsListClone.unshift({
          uuid: secondElement.studentUuid,
          name: secondElement.studentName,
        });
        customSubmissionsClone[firstIndex].studentsList = studentsListClone;
      }
    }
    customSubmissionsClone[firstIndex].pages = pagesClone;
    customSubmissionsClone.splice(secondIndex, 1);
    this.customSubmissions = customSubmissionsClone;
    this.updateSubmissionCount();
    this.executingAction = false;
  }

  handleRemove(data: {
    submissionIndex: number;
    pageIndex: number;
    pagesCount: number;
  }) {
    this.executingAction = true;
    const { submissionIndex, pageIndex, pagesCount } = data;
    let customSubmissionsClone: CustomSubmission[] = this.customSubmissions;
    const currentSubmission = customSubmissionsClone[submissionIndex];
    if (pagesCount === 1) {
      if (currentSubmission.studentUuid && currentSubmission.studentName) {
        let updatedCustomSubmissionsClone: CustomSubmission[] = [];
        customSubmissionsClone.forEach((submission: CustomSubmission) => {
          const studentsListClone: StudentList[] = submission.studentsList;
          studentsListClone.push({
            uuid: currentSubmission.studentUuid,
            name: currentSubmission.studentName,
          });
          const objData = {
            ...submission,
            studentsList: studentsListClone,
          };
          updatedCustomSubmissionsClone.push(objData);
        });
        updatedCustomSubmissionsClone.splice(submissionIndex, 1);
        customSubmissionsClone = updatedCustomSubmissionsClone;
      }
    } else {
      customSubmissionsClone[submissionIndex].pages.splice(pageIndex, 1);
    }
    this.customSubmissions = customSubmissionsClone;
    this.updateSubmissionCount();
    this.executingAction = false;
  }

  handleClickScroll(move = 200) {
    const scrollContainer = this.assignmentsRef;
    scrollContainer.scrollBy({
      left: move, // Adjust the scroll distance as needed
      behavior: "smooth", // For smooth scrolling
    });
  }

  showMessage(type: "error" | "success", title: string, message: string) {
    this.toast.add({
      severity: type,
      summary: title,
      detail: message,
      life: 5000,
    });
  }
}
</script>

<style lang="scss" scoped>
.scrollable::-webkit-scrollbar {
  height: 10px !important;
}
.scrollable::-webkit-scrollbar-thumb {
  background-color: #f9b2ce; /* Color of the scrollbar thumb */
  border-radius: 8px; /* Rounded corners for the thumb */
}

.scrollable::-webkit-scrollbar-track {
  background: #d9d9d9; /* Color of the scrollbar track */
  border-radius: 8px;
}
</style>
