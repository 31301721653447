import { environment } from "@/environments/environment";
import axios from "axios";
import { TELEMETRY_HEAD } from "@/utils/telemetry/data";
import { getRole } from "@/utils/helper";

import {
  CriteriaSheet,
  IAttachedResource,
  IGradeSchema,
  IGrades,
  IShareCode,
} from "@/models/CriteriaSheet";

const routeSuperAdmin = getRole() === "super admin" ? "/super-admin" : "";

const CRITERIA_SHEET_BASE_URL = `${routeSuperAdmin}/criteria-sheets`;
const GRADE_BASE_URL = `${routeSuperAdmin}/grades`;
const GRADE_SCHEMA_BASE_URL = `${routeSuperAdmin}/grade-schemas`;
const SHAREABLE_RESOURCES_URL = `/shareable-resource`;
const SHARE_CODE_URL = `${routeSuperAdmin}/share-codes`;

export default class CriteriaSheetService {
  getCriteriaSheets() {
    return axios.get(`${CRITERIA_SHEET_BASE_URL}`);
  }

  getCriteriaSheet(uuid: string) {
    return axios.get(`${CRITERIA_SHEET_BASE_URL}/${uuid}`);
  }

  getCriteriaSheetAssignment(uuid: string) {
    return axios.get(
      `${CRITERIA_SHEET_BASE_URL}?scope=assignment&spec=${uuid}`
    );
  }

  getGrade(subimissionUuid: string, studentUuid: string) {
    return axios.get(
      `${GRADE_BASE_URL}?submission=${subimissionUuid}&student=${studentUuid}`
    );
  }

  getAllGradeSchema() {
    return axios.get(`${GRADE_SCHEMA_BASE_URL}`);
  }

  getGradeSchema(uuid: string) {
    return axios.get(`${GRADE_SCHEMA_BASE_URL}/criteria-sheet/${uuid}`);
  }

  getStudentCriteriaGrade(uuid: string) {
    return axios.get(`student/submissions/${uuid}/grade`);
  }

  postCriteriaSheet(payload: any) {
    return axios.post(CRITERIA_SHEET_BASE_URL, payload, {
      headers: TELEMETRY_HEAD.postCriteriaSheet,
    });
  }

  postGrade(payload: IGrades) {
    return axios.post(GRADE_BASE_URL, payload, {
      headers: TELEMETRY_HEAD.postGrade,
    });
  }

  postGradeSchema(payload: IGradeSchema) {
    return axios.post(GRADE_SCHEMA_BASE_URL, payload, {
      headers: TELEMETRY_HEAD.postGradeSchema,
    });
  }

  updateCriteriaSheet(payload: any, uuid: string) {
    return axios.put(`${CRITERIA_SHEET_BASE_URL}/${uuid}`, payload, {
      headers: TELEMETRY_HEAD.updateCriteriaSheet,
    });
  }

  updateGrade(payload: any, uuid: string) {
    return axios.put(`${GRADE_BASE_URL}/${uuid}`, payload, {
      headers: TELEMETRY_HEAD.updateGrade,
    });
  }

  updateGradeSchema(payload: IGradeSchema, uuid: string | undefined) {
    return axios.put(`${GRADE_SCHEMA_BASE_URL}/${uuid}`, payload, {
      headers: TELEMETRY_HEAD.updateGradeSchema,
    });
  }

  deleteCriteriaSheet(id: string) {
    return axios.delete(`${CRITERIA_SHEET_BASE_URL}/${id}`, {
      headers: TELEMETRY_HEAD.deleteCriteriaSheet,
    });
  }

  deleteGradeSchema(uuid: string) {
    return axios.delete(`${GRADE_SCHEMA_BASE_URL}/${uuid}`, {
      headers: TELEMETRY_HEAD.deleteGradeSchema,
    });
  }

  attachedCriteriaSheetAssignment(payload: IAttachedResource) {
    return axios.post(`${SHAREABLE_RESOURCES_URL}/attach`, payload, {
      headers: TELEMETRY_HEAD.attachedCriteriaSheetAssignment,
    });
  }

  detachedCriteriaSheetAssignment(payload: IAttachedResource) {
    return axios.post(`${SHAREABLE_RESOURCES_URL}/detach`, payload, {
      headers: TELEMETRY_HEAD.detachedCriteriaSheetAssignment,
    });
  }

  getShareCode(code: string) {
    return axios.get(`share-codes/${code}`);
  }

  shareCode(payload: IShareCode) {
    return axios.post(`${SHARE_CODE_URL}`, payload);
  }

  shareCodeViaEmail(payload: IShareCode) {
    return axios.post(`${SHARE_CODE_URL}/send-email-invite`, payload);
  }
}
