import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b5166b0a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-white flex flex-col rounded-lg w-full border-x border-t border-solid border-neutral-gray-85" }
const _hoisted_2 = { class: "w-full flex flex-row px-4 pt-3" }
const _hoisted_3 = { class: "vue-draggable-handle flex justify-center items-center px-1" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["title"]
const _hoisted_6 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ViewAssignmentHeader = _resolveComponent("ViewAssignmentHeader")!
  const _component_ViewAssignmentComponent = _resolveComponent("ViewAssignmentComponent")!
  const _component_ModalUtility = _resolveComponent("ModalUtility")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: "flex-1 font-bold text-flohh-text-body cursor-pointer text-flohh-neutral-20 whitespace-nowrap w-full overflow-hidden truncate",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClickAssignment && _ctx.handleClickAssignment(...args)))
        }, _toDisplayString(_ctx.data.name), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", {
            innerHTML: _ctx.icons.dragIcon,
            class: "text-sm absolute -top-[5px] -right-[8px]"
          }, null, 8, _hoisted_4)
        ])
      ]),
      _createElementVNode("div", {
        class: "no-drag cursor-pointer",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleClickAssignment && _ctx.handleClickAssignment(...args)))
      }, [
        _createElementVNode("p", {
          class: "px-4 pb-4 pt-3 text-flohh-text-caption truncate",
          title: `${
          _ctx.data.subject === 'Demo Subject' ? 'Click Here' : _ctx.data.subject
        } - ${
          _ctx.data.subjectClass === 'Demo Class' ? 'To Mark' : _ctx.data.subjectClass
        }`
        }, _toDisplayString(_ctx.data.subject === "Demo Subject" ? "Click Here" : _ctx.data.subject) + " - " + _toDisplayString(_ctx.data.subjectClass === "Demo Class" ? "To Mark" : _ctx.data.subjectClass), 9, _hoisted_5),
        _createElementVNode("div", {
          class: _normalizeClass(`w-full px-4 py-2 flex justify-between rounded-b-lg text-flohh-text-small font-flohh-font-medium text-flohh-neutral-10`),
          style: _normalizeStyle({ backgroundColor: _ctx.tagData.color })
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.tagData.text), 1),
          (_ctx.data.marked !== undefined && _ctx.data.total !== undefined)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.data.numerator) + "/" + _toDisplayString(_ctx.data.denominator), 1))
            : _createCommentVNode("", true)
        ], 4)
      ])
    ])), [
      [
        _directive_tooltip,
        _ctx.data.name,
        void 0,
        { top: true }
      ]
    ]),
    _createVNode(_component_ModalUtility, {
      modelValue: _ctx.isViewAssignment,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isViewAssignment) = $event)),
      onOnClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.base64 = '')),
      width: '1200px',
      draggable: _ctx.isDraggable
    }, {
      header: _withCtx(({ handleClickClose }) => [
        _createVNode(_component_ViewAssignmentHeader, {
          handleClickClose: handleClickClose,
          infos: _ctx.infos,
          isStudentView: true
        }, null, 8, ["handleClickClose", "infos"])
      ]),
      content: _withCtx(() => [
        _createVNode(_component_ViewAssignmentComponent, {
          errorMsg: _ctx.errorMsg,
          pdfBase64: _ctx.base64,
          viewType: "student",
          comment: _ctx.infos.comment,
          assignmentStatus: _ctx.infos.assignmentStatus,
          assignment: _ctx.data,
          assignmentType: _ctx.infos.assignmentType,
          learningGoal: _ctx.infos.learningGoal,
          submissionId: _ctx.infos.submissionId,
          studentId: _ctx.infos.studentId,
          classUuid: _ctx.data.summary.class.uuid
        }, null, 8, ["errorMsg", "pdfBase64", "comment", "assignmentStatus", "assignment", "assignmentType", "learningGoal", "submissionId", "studentId", "classUuid"])
      ]),
      _: 1
    }, 8, ["modelValue", "draggable"])
  ], 64))
}