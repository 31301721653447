import { icons } from "./icons";
import { getRole } from "@/utils/helper";
export interface Navigation {
  name: string; //
  route: string; //
  path: string; //
  icon: string; //
  current: boolean; //
  children: SubNavigation[]; //
  isHidden?: boolean; //
  tooltip?: string; //
}

export interface SubNavigation {
  name: string; //
  route: string; //
  path: string; //
  icon: string; //
  current: boolean;
  parent: string; //
}

export const navigation: Navigation[] = [
  {
    name: "Dashboard",
    route: "StudentDashboard",
    path: "/student/dashboard",
    icon: icons.navDashboard,
    current: false,
    isHidden: getRole() === "student" ? false : true,
    tooltip: "Your Dashboard",
    children: [],
  },
  {
    name: "Dashboard",
    route: "Dashboard",
    path: "/dashboard",
    icon: icons.navDashboard,
    current: false,
    isHidden: getRole() === "teacher" ? false : true,
    tooltip: "Your Dashboard",
    children: [],
  },
  {
    name: "Assignments",
    route: "AssignmentsList",
    path: "/assignments/all",
    icon: icons.navAssignments,
    current: false,
    isHidden: getRole() === "teacher" ? false : true,
    tooltip: "Assignments",
    children: [],
  },
  {
    name: "Classes",
    route: "ClassesList",
    path: "/classes/all",
    icon: icons.navClasses,
    current: false,
    isHidden: getRole() === "teacher" ? false : true,
    tooltip: "Classes",
    children: [],
  },
  // TODO: Unhide. But for now as per ticket #FLO-699, hide it
  {
    name: "Resources",
    route: "",
    path: "",
    icon: icons.navResources,
    current: false,
    isHidden: getRole() === "teacher" ? false : true,
    tooltip: "Resources",
    children: [
      {
        name: "Comment Bank",
        route: "CommentBankView",
        path: "/comment-bank",
        icon: icons.navDashboard,
        current: false,
        parent: "Resources",
      },
      {
        name: "Criteria Sheet Bank",
        route: "CriteriaSheetBanksView",
        path: "/criteria-sheet-bank",
        current: false,
        icon: "",
        parent: "Resources",
      },
      {
        name: "Grade Schema Bank",
        route: "GradeSchemaBankView",
        path: "/grade-schema-bank",
        current: false,
        icon: "",
        parent: "Resources",
      },
      // {
      //   name: "Annotation Banks",
      //   route: "Annotation Banks View",
      //   icon: "",
      //   current: false,
      // },
    ],
  },
  // {
  //   name: "Criteria Sheet",
  //   route: "CriteriaSheet",
  //   icon: icons.starBlack,
  //   current: false,
  //   subNavigation: false,
  //   isHidden: true,
  // },
  // {
  //   name: "Bulk Upload",
  //   route: "BulkUpload",
  //   icon: icons.uploadBlack,
  //   current: false,
  //   subNavigation: false,
  //   isHidden: true,
  // },
  // {
  //   name: "Assignment",
  //   route: "Assignment",
  //   icon: icons.documentBlack,
  //   current: false,
  //   subNavigation: false,
  //   isHidden: true,
  // },
  // {
  //   name: "Rubrics Banks",
  //   route: "",
  //   icon: icons.analog,
  //   current: false,
  //   subNavigation: false,
  //   isHidden: true,
  // },
  // {
  //   name: "Team Hub",
  //   route: "",
  //   icon: icons.usersGroupBlack,
  //   current: false,
  //   subNavigation: false,
  //   isHidden: true,
  // },
  // {
  //   name: "Popup",
  //   route: "Popup",
  //   icon: icons.ellepsisDoubleVerticalBlack,
  //   current: false,
  //   subNavigation: false,
  //   isHidden: true,
  // },
];

export const superAdminNavigation: Navigation[] = [
  {
    name: "Teachers",
    route: "SuperAdminTeacher",
    icon: icons.navDashboard,
    current: false,
    children: [],
    path: "",
  },
  {
    name: "Students",
    route: "SuperAdminStudent",
    icon: icons.usersBlack,
    current: false,
    children: [],
    path: "",
  },
  {
    name: "Wonde",
    route: "SuperAdminWande",
    icon: icons.wandeIcon,
    current: false,
    children: [],
    path: "",
  },
  {
    name: "Resources",
    route: "",
    path: "",
    icon: icons.boxBlackIcon,
    current: false,
    tooltip: "Resources",
    children: [
      {
        name: "Comment Bank",
        route: "CommentBankView",
        path: "/comment-bank",
        icon: icons.dashbaordBlack,
        current: false,
        parent: "Resources",
      },
      {
        name: "Criteria Sheet Bank",
        route: "CriteriaSheetBanksView",
        path: "/criteria-sheet-bank",
        current: false,
        icon: "",
        parent: "Resources",
      },
      {
        name: "Grade Schema Bank",
        route: "GradeSchemaBankView",
        path: "/grade-schema-bank",
        current: false,
        icon: "",
        parent: "Resources",
      },
      // {
      //   name: "Annotation Banks",
      //   route: "Annotation Banks View",
      //   icon: "",
      //   current: false,
      // },
    ],
  },
  // {
  //   name: "Comment Bank",
  //   route: "Comment Bank View",
  //   icon: icons.squareGrid,
  //   current: false,
  //   children: [],
  //   path: "",
  // },
  // {
  //   name: "Criteria Sheet Bank",
  //   route: "Criteria Sheet Banks View",
  //   icon: icons.squareGrid,
  //   current: false,
  //   children: [],
  //   path: "",
  // },
  // {
  //   name: "Grade Schema Bank",
  //   route: "Grade Schema Bank View",
  //   icon: icons.squareGrid,
  //   current: false,
  //   children: [],
  //   path: "",
  // },
];
