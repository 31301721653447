import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col justify-start items-start gap-y-2" }
const _hoisted_2 = { class: "py-2 w-full flex flex-col justify-start items-start" }
const _hoisted_3 = { class: "flex flex-row gap-x-[8px] justify-center items-center" }
const _hoisted_4 = { class: "col-span-1 w-full flex justify-start flex-col items-start" }
const _hoisted_5 = { class: "col-span-1 w-full flex justify-start gap-x-2 flex-col sm:flex-row items-start" }
const _hoisted_6 = { class: "flex-1 flex flex-col justify-start items-start" }
const _hoisted_7 = { class: "flex-1 flex flex-col justify-start items-start ml-4" }
const _hoisted_8 = { class: "w-full py-4 border-t border-flohh-neutral-85 border-solid mt-5 flex justify-end flex-col items-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectClass = _resolveComponent("SelectClass")!
  const _component_CreateClassButton = _resolveComponent("CreateClassButton")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FormDateTimePicker = _resolveComponent("FormDateTimePicker")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FormField, {
        label: "Select your class",
        error: _ctx.error?.className,
        required: true
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(), _createBlock(_component_SelectClass, {
              modelValue: _ctx.selectedClassModel,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedClassModel) = $event)),
              defaultValue: _ctx.selectedClassModel,
              key: _ctx.refreshKey,
              invalid: _ctx.error && _ctx.error.className ? true : false
            }, null, 8, ["modelValue", "defaultValue", "invalid"])),
            _createVNode(_component_CreateClassButton, {
              type: "secondary",
              "is-icon": true,
              onOnSaveCreateClass: _ctx.handleOnSaveCreateClass
            }, null, 8, ["onOnSaveCreateClass"])
          ])
        ]),
        _: 1
      }, 8, ["error"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_FormField, {
        label: "Give your assignment a name",
        placeholder: "Type something",
        error: _ctx.error?.name,
        modelValue: _ctx.nameModel,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.nameModel) = $event)),
        type: "text",
        required: true,
        toolTip: "Use this field to give your assignment a name that you and your students can use to easily identify it.",
        background: false
      }, null, 8, ["error", "modelValue"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_FormField, {
          label: "Duration",
          placeholder: "In Minutes",
          error: _ctx.error?.timeSubmission,
          modelValue: _ctx.timeSubmissionModel,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.timeSubmissionModel) = $event)),
          type: "text",
          required: true,
          isNumber: true,
          toolTip: "What is 'duration'? This is the average time it takes you to mark a single student submission. For example it may typically take you 20 minutes to mark a student submission.\n          \n          How does Flohh use your average marking time? Flohh uses the average marking time that you set to give you statistics on your overall time saved.",
          background: false
        }, null, 8, ["error", "modelValue"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_FormField, {
          label: "Set the Due Date",
          error: _ctx.error?.dueTimestamp,
          required: true,
          toolTip: "The due date of your assignment will be visible to both you and your students.",
          class: "mb-[15px]"
        }, {
          default: _withCtx(() => [
            (_ctx.dueTimestampModel)
              ? (_openBlock(), _createBlock(_component_FormDateTimePicker, {
                  key: 0,
                  modelValue: _ctx.dueTimestampModel,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dueTimestampModel) = $event)),
                  selectedTimezone: _ctx.timezoneNameModel,
                  onOnSelect: _ctx.handleDueDate,
                  isEdit: true
                }, null, 8, ["modelValue", "selectedTimezone", "onOnSelect"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["error"])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_AppButton, {
        type: "submit",
        loading: _ctx.loadingDetails || _ctx.loadingUpdateAssignment,
        disabled: _ctx.loadingDetails || _ctx.loadingUpdateAssignment,
        iconLeft: "checkBlack",
        label: "Save",
        onClick: _ctx.handleClickSaveAssignmentDetails
      }, null, 8, ["loading", "disabled", "onClick"])
    ])
  ]))
}