<template>
  <GoogleDriveFileUploader
    v-model:selectedFiles="selectedFileModel"
    :multiple="false"
    acceptedFiles=".pdf,.docx,.doc"
    gDriveAcceptedFiles="application/pdf,application/vnd.google-apps.document"
    supportedFileTypes="Supported file types: PDF, Microsoft Word & Google Doc"
    :progress="progress"
    @onRemoveFile="handleRemoveFile"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch, Model } from "vue-facing-decorator";
import GoogleDriveFileUploader from "@/components/utilities/GoogleDriveFileUploader.vue";
import emitter from "@/config/emitter";

@Component({
  components: { GoogleDriveFileUploader },
})
export default class GoogleDriveInstructionComponent extends Vue {
  eventBus = emitter;
  @Prop({
    type: Number,
    default: 0,
  })
  progress!: number;

  @Model({
    type: File,
    required: false,
  })
  selectedFileModel!: File[];

  mounted() {
    this.eventBus.on("CLEAR_FIELDS", () => {
      this.selectedFileModel = [];
    });
  }

  @Watch("selectedFileModel")
  selectedFileModelWatcher(value: File[]) {
    this.$emit("onInstructionUpdate", value);
  }

  handleRemoveFile(index: number) {
    const fileArr = this.selectedFileModel;
    const newState = fileArr.filter((item: File, i: number) => i !== index);
    this.$emit("onInstructionUpdate", newState.length === 0 ? [] : newState);
  }
}
</script>

<style scoped lang="scss"></style>
