<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-facing-decorator";
import { Assignment } from "./assignmentType";
import ClassService from "@/services/ClassService";
import { Class } from "@/models/Class";
@Component({ components: {} })
export default class AssignmentInformation extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  assignment!: Assignment;

  private classService = new ClassService();

  loadingClass = false;
  classInformation: Class = {
    code: "",
    subject: "",
    yearLevel: "",
    uuid: "",
    teacher: "",
  };

  async onGetClassProfile() {
    try {
      this.loadingClass = true;
      const response = await this.classService.getClassById(
        this.assignment.assignmentDetails.class
      );
      this.classInformation = response.data.data as Class;
      this.$store.dispatch(
        "createClassPopup/setClassInfo",
        this.classInformation
      );
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingClass = false;
    }
  }

  mounted() {
    if (!this.classInformation.uuid) {
      this.onGetClassProfile();
    }
  }

  @Watch("assignment")
  assignmentWatcher(value: Assignment) {
    if (value.assignmentDetails.class) {
      this.onGetClassProfile();
    }
  }
}
</script>
<template>
  <div
    class="w-full rounded-[8px] px-3 flex flex-col border border-solid border-flohh-neutral-85"
    v-bind="$attrs"
    v-if="!loadingClass"
  >
    <div class="w-full py-4 flex justify-center items-center">
      <div class="flex flex-row w-full">
        <div
          class="flex justify-start items-start text-flohh-text-body font-flohh-font-medium"
        >
          Class Name
        </div>
        <div class="flex-1" />
        <div
          class="flex justify-end items-end font-flohh-font-bold text-flohh-text-body"
        >
          {{ classInformation.code }}
        </div>
      </div>
    </div>
    <div class="border-b border-solid border-flohh-neutral-85 w-full" />
    <div class="w-full py-4 flex justify-start items-start">
      <div class="flex flex-row w-full">
        <div
          class="flex justify-start items-start text-flohh-text-body font-flohh-font-medium"
        >
          Subject
        </div>
        <div class="flex-1" />
        <div
          class="flex justify-end items-end font-flohh-font-bold text-flohh-text-body"
        >
          {{ classInformation.subject }}
        </div>
      </div>
    </div>
    <div class="border-b border-solid border-flohh-neutral-85 w-full" />
    <div class="w-full py-4 flex justify-start items-start">
      <div class="flex flex-row w-full">
        <div
          class="flex justify-start items-start text-flohh-text-body font-flohh-font-medium"
        >
          Year Level
        </div>
        <div class="flex-1" />
        <div
          class="flex justify-end items-end font-flohh-font-bold text-flohh-text-body"
        >
          {{ classInformation.yearLevel }}
        </div>
      </div>
    </div>
    <div class="border-b border-solid border-flohh-neutral-85 w-full" />
    <div class="w-full py-4 flex justify-start items-start">
      <div class="flex flex-row w-full">
        <div
          class="flex justify-start items-start text-flohh-text-body font-flohh-font-medium"
        >
          Assignment Title
        </div>
        <div class="flex-1" />
        <div
          class="flex justify-end items-end font-flohh-font-bold text-flohh-text-body"
        >
          {{ assignment.assignmentDetails.name }}
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="w-full h-40 animate-pulse bg-flohh-neutral-95 flex justify-center items-center text-flohh-text-body font-flohh-font-medium"
  >
    Loading class information
  </div>
</template>
