<template>
  <ModalUtility
    v-model="openDialog"
    width="50vw"
    title="Invite Students"
    @onClose="handleCloseDialog"
  >
    <template #content>
      <div class="px-4">
        <div class="py-2 my-5 w-[60%]">
          <FormField
            label="Select the Flohh class you'd like to invite students to"
            :error="classError"
            :required="true"
          >
            <SelectClass
              v-model="selectedClassModel"
              :disabled="false"
              :key="refreshKey"
              :invalid="classError !== ''"
              :defaultValue="defaultClassUuid"
              @onClassSelect="handleClassSelect"
            />
          </FormField>
        </div>
        <div class="w-full">
          <AppTypographyText
            label="Select how you'd like to invite students"
            variant="bd"
            type="title"
            class="mb-5"
          />
          <div class="flex gap-3 mb-5">
            <div class="flex gap-2 items-center">
              <FormRadioButton
                v-model="selectedMethod"
                name="link"
                inputId="link"
                :value="0"
              />
              <label
                for="link"
                class="font-flohh-font-medium text-flohh-text-body cursor-pointer"
                :class="selectedMethod === 0 ? 'text-flohh-primary-pink' : ''"
                >Share a Link</label
              >
            </div>
            <div class="flex gap-2 items-center">
              <FormRadioButton
                v-model="selectedMethod"
                name="classroom"
                inputId="classroom"
                :value="1"
              />
              <label
                for="classroom"
                class="font-flohh-font-medium text-flohh-text-body cursor-pointer"
                :class="selectedMethod === 1 ? 'text-flohh-primary-pink' : ''"
                >Google Classroom</label
              >
            </div>
            <div class="flex gap-2 items-center">
              <FormRadioButton
                v-model="selectedMethod"
                name="upload"
                inputId="upload"
                :value="2"
              />
              <label
                for="upload"
                class="font-flohh-font-medium text-flohh-text-body cursor-pointer"
                :class="selectedMethod === 2 ? 'text-flohh-primary-pink' : ''"
                >Upload</label
              >
            </div>
            <FormRadioButton
              v-model="selectedMethod"
              name="manual"
              inputId="manual"
              :value="3"
            />
            <label
              for="manual"
              class="font-flohh-font-medium text-flohh-text-body cursor-pointer"
              :class="selectedMethod === 3 ? 'text-flohh-primary-pink' : ''"
              >Manual</label
            >
          </div>
          <InviteViaLink
            v-if="selectedMethod === 0"
            :selectedClass="selectedClassInfo"
            @onComplete="handleInvitationCompleted"
            @onClose="handleCloseDialog"
            @onClassError="handleClassError"
          />
          <InviteViaGoogleClassroom
            v-else-if="selectedMethod === 1"
            :selectedClass="selectedClassInfo"
            @onGoogleRedirectBack="handleGoogleCallBack"
            @onComplete="handleInvitationCompleted"
            @onClassError="handleClassError"
          />
          <InviteViaFileUpload
            v-else-if="selectedMethod === 2"
            :selectedClass="selectedClassInfo"
            @onComplete="handleInvitationCompleted"
            @onClose="handleCloseDialog"
            @onClassError="handleClassError"
          />
          <InviteManually
            v-else
            :selectedClass="selectedClassInfo"
            @onComplete="handleInvitationCompleted"
            @onClassError="handleClassError"
          />
        </div>
      </div>
    </template>
  </ModalUtility>
</template>
<script lang="ts">
import { Vue, Component, Model, Watch, Prop } from "vue-facing-decorator";
import ModalUtility from "@/components/utilities/ModalUtility.vue";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { Icon, icons } from "@/utils/icons";
import FormField from "@/components/Layout/Forms/FormField.vue";
import Toast from "primevue/toast";
import emitter from "@/config/emitter";
import SelectClass from "@/components/utilities/SelectClass.vue";
import InviteViaLink from "./InviteViaLink.vue";
import InviteViaFileUpload from "./InviteViaFileUpload.vue";
import InviteManually from "./InviteManually.vue";
import InviteViaGoogleClassroom from "./InviteViaGoogleClassroom.vue";
import { ClassInfo } from "@/store/createClass/types";
import FormRadioButton from "@/components/Layout/Forms/FormRadioButton.vue";
import AppTypographyText from "../Layout/Typhography/AppTypographyText.vue";

@Component({
  components: {
    ModalUtility,
    AppButton,
    FormField,
    Toast,
    SelectClass,
    FormRadioButton,
    InviteViaLink,
    InviteViaFileUpload,
    InviteManually,
    InviteViaGoogleClassroom,
    AppTypographyText,
  },
})
export default class InviteStudentPopup extends Vue {
  @Model({
    type: Boolean,
    default: false,
  })
  openDialog!: boolean;

  @Prop({
    type: String,
    required: false,
    default: "",
  })
  defaultClass!: string;

  eventBus = emitter;
  icon: Icon = icons;
  selectedClassModel = "";
  refreshKey = 0;
  selectedClassInfo!: ClassInfo;
  classError = "";

  defaultClassUuid = "";

  selectedMethod = 0;

  @Watch("defaultClass")
  openDialogWatcher(value: string) {
    if (value) {
      this.selectedClassModel = value;
      this.defaultClassUuid = this.defaultClass;
    }
  }

  handleCloseDialog() {
    this.openDialog = false;
  }

  handleClassError(isError: boolean) {
    this.classError = isError ? "Class is required" : "";
  }

  mounted() {
    const selectedClassUuid = localStorage.getItem(
      "classUuidFromClassroomMethod"
    );
    if (selectedClassUuid) {
      this.defaultClassUuid = selectedClassUuid;
      localStorage.removeItem("classUuidFromClassroomMethod");
    } else {
      this.defaultClassUuid = this.defaultClass;
    }

    if (this.$route.query.token) {
      this.openDialog = true;
      this.selectedMethod = 1;
    }
  }

  handleGoogleCallBack(classUuid: string) {
    this.selectedClassModel = classUuid;
  }

  @Watch("selectedClassModel")
  selecteClassModelWatcher(value: string) {
    if (value) {
      this.handleClassError(false);
    }
  }

  handleClassSelect(value: ClassInfo) {
    this.selectedClassInfo = value;
  }

  handleInvitationCompleted(classUuid: string) {
    this.openDialog = false;
    this.$router.push({
      name: "Classes",
      params: { id: classUuid },
      query: { tab: "assignments" },
    });
    this.eventBus.emit("REFRESH_SELECTED_CLASS");
  }
}
</script>
<style lang="scss" scoped></style>
