import { environment } from "@/environments/environment";
import axios from "axios";
import { Comment, CommentBank, IAttachResource } from "@/models/Comment";
import { TELEMETRY_HEAD } from "@/utils/telemetry/data";
import { getRole } from "@/utils/helper";
const routeSuperAdmin = getRole() === "super admin" ? "/super-admin" : "";
const COMMENT_BASE_URL = `${routeSuperAdmin}/comments`;
const COMMENT_BANK_BASE_URL = `${routeSuperAdmin}/comment-bank`;
const SHARE_CODE_URL = `${routeSuperAdmin}/share-codes`;
const SHAREABLE_RESOURCES_URL = "/shareable-resource";
export interface IShareCode {
  type: string;
  uuid: string;
  emails?: IEmails[];
}

export interface IEmails {
  emails: string;
}
export default class CommentService {
  getComment() {
    return axios.get(COMMENT_BASE_URL);
  }

  postComment(payload: Comment) {
    // API not being used
    return axios.post(COMMENT_BASE_URL, payload);
  }

  deleteComment(id: number) {
    return axios.delete(`${COMMENT_BASE_URL}/${id}`, {
      headers: TELEMETRY_HEAD.deleteComment,
    });
  }

  getCommentBank(payload: any) {
    if (payload.assignmentUuid) {
      return axios.get(
        `${COMMENT_BANK_BASE_URL}?scope=assignment&spec=${payload.assignmentUuid}`
      );
    } else {
      return axios.get(COMMENT_BANK_BASE_URL);
    }
  }

  getCommentBankByAssignment(id: string) {
    return axios.get(`${COMMENT_BANK_BASE_URL}?scope=assignment&spec=${id}`);
  }

  attachCommentBankAssignment(payload: IAttachResource) {
    return axios.post(`${SHAREABLE_RESOURCES_URL}/attach`, payload, {
      headers: TELEMETRY_HEAD.attachCommentBankAssignment,
    });
  }

  detachCommentBankAssignment(payload: IAttachResource) {
    return axios.post(`${SHAREABLE_RESOURCES_URL}/detach`, payload, {
      headers: TELEMETRY_HEAD.detachCommentBankAssignment,
    });
  }

  postCommentBank(payload: CommentBank) {
    return axios.post(COMMENT_BANK_BASE_URL, payload, {
      headers: TELEMETRY_HEAD.postCommentBank,
    });
  }

  deleteCommentBank(id: string) {
    return axios.delete(`${COMMENT_BANK_BASE_URL}/${id}`, {
      headers: TELEMETRY_HEAD.deleteCommentBank,
    });
  }

  updateCommentBank(payload: any, id: string) {
    return axios.put(`${COMMENT_BANK_BASE_URL}/${id}`, payload, {
      headers: TELEMETRY_HEAD.updateCommentBank,
    });
  }

  addNewCommentOnBank(payload: any, id: string) {
    return axios.post(`${COMMENT_BANK_BASE_URL}/${id}`, payload, {
      headers: TELEMETRY_HEAD.addNewCommentOnBank,
    });
  }

  getCommentBankByAssignmentUuid<T>(params: T) {
    return axios.get(`${COMMENT_BANK_BASE_URL}`, { params });
  }

  getShareCode(code: string) {
    return axios.get(`/share-codes/${code}`);
  }

  shareCode(payload: IShareCode) {
    return axios.post(`${SHARE_CODE_URL}`, payload);
  }

  shareCodeViaEmail(payload: IShareCode) {
    return axios.post(`${SHARE_CODE_URL}/send-email-invite`, payload);
  }
}
