<template>
  <div
    class="login-container flex h-screen items-center justify-center w-full max-w-[600px] px-5"
  >
    <div
      class="rounded-md flex flex-col justify-center items-center pb-6 px-6 md:px-10 pt-5 bg-white w-full"
    >
      <div
        class="h idden flex flex-col w-full items-center justify-center p-4 relative z-[1]"
      >
        <img
          src="@/assets/flohh-logo.svg"
          class="object-contain object-[14px] max-w-[210px] w-full mx-auto my-0"
        />
      </div>
      <Divider />
      <template v-if="!isLoading && !isMaintenace">
        <div class="pt-2 pb-6 text-center">
          <p class="text-flohh-text-title color-flohh-neutral-20 font-bold">
            Welcome to Flohh!
          </p>
          <p
            class="text-flohh-text-body color-flohh-neutral-20 font-medium mt-[8px]"
          >
            Please let us know what type of user you are.
          </p>

          <div
            class="justify-center items-center flex gap-2 flex-col mt-[40px]"
          >
            <router-link
              to="login"
              class="flex justify-center items-center w-full max-w-[400px] h-[48px] gap-2 rounded-[8px] bg-[#A3DBBE] border-[1px] border-solid border-[#D9D9D9]"
            >
              <span v-html="icon.person"></span>
              <span
                class="text-flohh-text-body color-flohh-neutral-20 font-medium"
                >I'm a teacher</span
              >
            </router-link>
            <router-link
              to="student/login"
              class="flex justify-center items-center w-full max-w-[400px] h-[48px] gap-2 rounded-[8px] bg-[#9CCEE2] border-[1px] border-solid border-[#D9D9D9]"
            >
              <span v-html="icon.personAdd"></span>
              <span
                class="text-flohh-text-body color-flohh-neutral-20 font-medium"
                >I'm a student</span
              >
            </router-link>
          </div>
        </div>
      </template>
      <template v-if="!isLoading && isMaintenace">
        <p class="text-[18px] font-bold mb-[0] mt-[10px] text-center">
          Flohh is Temporarily Unavailable
        </p>

        <img src="@/assets/loader.gif" class="w-[194px] mx-auto" />
        <div
          class="w-full max-w-[420px] px-[15px] mx-auto text-center pb-[20px]"
        >
          <p class="text-[10px] mb-[12px] leading-[1.5]">
            We’re currently performing scheduled maintenance to improve your
            experience on Flohh.
          </p>
          <p class="text-[10px] mb-[12px] leading-[1.5]">
            The platform will be back online shortly. Thank you for your
            patience and understanding. We’re working to make Flohh better for
            you!
          </p>
          <p class="text-[10px] leading-[1.5]">
            If you have any questions or need assistance, please contact our
            support team at
            <a href="mailto:support@flohh.com" class="underline"
              >support@flohh.com</a
            >.
          </p>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import Divider from "primevue/divider";
import { icons } from "@/utils/icons";
@Component({
  components: {
    Divider,
  },
})
export default class HomeView extends Vue {
  icon = icons;

  isLoading = true;
  isMaintenace = false;

  mounted() {
    this.isLoading = false;
    this.isMaintenace = process.env.VUE_APP_MAINTENANCE;
  }
}
</script>

<style>
body {
  margin: 0;
}
</style>
