<template>
  <PageTitle title="Super Admin" :routes="routes" />
  <SuperAdminStudentComponent />
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import axios, { AxiosResponse } from "axios";
import PageTitle from "@/components/Layout/Partials/PageTitle.vue";
import SuperAdminStudentComponent from "@/components/SuperAdmin/SuperAdminStudentComponent.vue";
@Component({
  components: {
    PageTitle,
    SuperAdminStudentComponent,
  },
})
export default class SuperAdminStudentView extends Vue {
  public user!: object | null;

  routes = [];

  /**
   * created
   */
  public async created() {
    //
  }
}
</script>

<style scoped lang="scss">
body {
  margin: 0;
  overflow: hidden !important;
}
</style>
