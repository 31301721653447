import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = ["type", "disabled"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", _mergeProps({
    type: _ctx.type === 'transparent' ? 'button' : 'submit',
    disabled: _ctx.disabled,
    class: [
      'flex justify-center items-center gap-x-[8px] rounded-[8px] font-flohh-font-medium',
      _ctx.outline ? 'border border-solid' : '',
      _ctx.borderColor ? _ctx.borderColor : '',
      _ctx.textColor ? _ctx.textColor : '',
      // size large
      _ctx.size === 'lg' ? 'px-[24px] py-[16px]' : '',
      {
        'cursor-not-allowed': _ctx.disabled,
        'cursor-progress': _ctx.loading,
      },
      {
        'w-full': _ctx.block,
      },
      // size === 'lg' && !$slots.icon_left && $slots.icon_right
      //   ? 'pl-[24px] pr-[16.79px] py-[16px]'
      //   : '',
      // size === 'lg' && $slots.icon_left && !$slots.icon_right
      //   ? 'pr-[24px] pl-[16.79px] py-[16px]'
      //   : '',
      // size === 'lg' && $slots.icon_left && $slots.icon_right
      //   ? 'pr-[16.79px] pl-[16.79px] py-[16px]'
      //   : '',
      // size medium
      _ctx.size === 'md' ? 'px-[20px] py-[12px]' : '',
      // size === 'md' && !$slots.icon_left && $slots.icon_right
      //   ? 'pl-[20px] pr-[12.79px] py-[12px]'
      //   : '',
      // size === 'md' && $slots.icon_left && !$slots.icon_right
      //   ? 'pr-[20px] pl-[12.79px] py-[12px]'
      //   : '',
      // size === 'md' && $slots.icon_left && $slots.icon_right
      //   ? 'pr-[12.79px] pl-[12.79px] py-[12px]'
      //   : '',
      // size small
      _ctx.size === 'sm' ? 'px-[16px] py-[8px]' : '',
      // size === 'sm' && !$slots.icon_left && $slots.icon_right
      //   ? 'pl-[16px] pr-[8.79px] py-[8px]'
      //   : '',
      // size === 'sm' && $slots.icon_left && !$slots.icon_right
      //   ? 'pr-[16px] pl-[8.79px] py-[8px]'
      //   : '',
      // size === 'sm' && $slots.icon_left && $slots.icon_right
      //   ? 'pr-[8.79px] pl-[8.79px] py-[8px]'
      //   : '',
      // size xsmall
      _ctx.size === 'xs' ? 'px-[14px] py-[6px]' : '',
      // size === 'xs' && !$slots.icon_left && $slots.icon_right
      //   ? 'pl-[14px] pr-[6.79px] py-[6px]'
      //   : '',
      // size === 'xs' && $slots.icon_left && !$slots.icon_right
      //   ? 'pr-[14px] pl-[6.79px] py-[6px]'
      //   : '',
      // size === 'xs' && $slots.icon_left && $slots.icon_right
      //   ? 'pr-[6.79px] pl-[6.79px] py-[6px]'
      //   : '',
      {
        // border outline
        'border-flohh-secondary-blue': _ctx.type === 'primary' && _ctx.outline,
        'border-flohh-primary-light-pink': _ctx.type === 'secondary' && _ctx.outline,
        'border-flohh-neutral-95':
          (_ctx.type === 'default' || !_ctx.type) && _ctx.outline && !_ctx.bordered,
        'border-flohh-secondary-green': _ctx.type === 'submit' && _ctx.outline,
        'border-flohh-secondary-red': _ctx.type === 'warning' && _ctx.outline,
        'border-flohh-neutral-85 border': _ctx.type === 'transparent' && !_ctx.outline,
        // text color for outline
        'text-flohh-blue': _ctx.type === 'primary' && _ctx.outline && !_ctx.blackLabel,
        'text-flohh-primary-light-pink':
          _ctx.type === 'secondary' && _ctx.outline && !_ctx.blackLabel,
        'text-flohh-neutral-70':
          (_ctx.type === 'default' || !_ctx.type) && _ctx.outline && !_ctx.blackLabel,
        'text-flohh-secondary-green':
          _ctx.type === 'submit' && _ctx.outline && !_ctx.blackLabel,
      },
      {
        'bg-flohh-secondary-blue': _ctx.type === 'primary' && !_ctx.outline && !_ctx.text,
        'bg-flohh-secondary-red': _ctx.type === 'warning' && !_ctx.outline && !_ctx.text,
        'bg-flohh-primary-light-pink':
          _ctx.type === 'secondary' && !_ctx.outline && !_ctx.text,
        'bg-flohh-neutral-95':
          (_ctx.type === 'default' || !_ctx.type) && !_ctx.outline && !_ctx.text,
        'bg-transparent': _ctx.type === 'transparent' && !_ctx.outline && !_ctx.text,
        'bg-white': _ctx.type === 'white' && !_ctx.outline && !_ctx.text,
        'bg-flohh-secondary-green': _ctx.type === 'submit' && !_ctx.outline && !_ctx.text,
        // hover
        'hover:bg-flohh-blue': _ctx.type === 'primary' && !_ctx.text,
        'hover:bg-flohh-primary-pink': _ctx.type === 'secondary' && !_ctx.text,
        'hover:bg-flohh-neutral-95': (_ctx.type === 'default' || !_ctx.type) && !_ctx.text,
        'hover:bg-flohh-secondary-green-dark':
          (_ctx.type === 'submit' || !_ctx.type) && !_ctx.text,
        // white label
        'text-white': _ctx.whiteLabel && !_ctx.outline && !_ctx.text,
        // text button
        'text-flohh-neutral-70': _ctx.text && !_ctx.outline && !_ctx.whiteLabel && !_ctx.blackLabel,
        'font-flohh-font-bold': _ctx.text && !_ctx.outline && !_ctx.whiteLabel,
        // black label
        'text-flohh-neutral-20': _ctx.blackLabel && !_ctx.whiteLabel && _ctx.outline && !_ctx.text,
        // type large
        'text-flohh-text-subtitle': _ctx.size === 'lg',
        'h-[56px]': _ctx.size === 'lg',
        // 'min-w-[150px]': size === 'lg',
        // type md
        'text-flohh-text-body': _ctx.size === 'md',
        'h-[45px]': _ctx.size === 'md',
        // 'min-w-[148px]': size === 'md',
        // sm
        'text-flohh-text-caption': _ctx.size === 'sm',
        'h-[34px]': _ctx.size === 'sm',
        // 'min-w-[110px]': size === 'sm',
        // xs
        'text-flohh-text-small': _ctx.size === 'xs',
        'h-[27px]': _ctx.size === 'xs',
        // 'min-w-[99px]': size === 'xs',
      },
      {
        'border-flohh-neutral-85': _ctx.bordered,
      },
    ]
  }, _ctx.$attrs), [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: "animate-spin",
          innerHTML: _ctx.icon[_ctx.loadingIcon]
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.iconLeft && !_ctx.loading)
      ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          innerHTML: _ctx.icon[_ctx.iconLeft]
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true),
    (_ctx.$slots.icon_left && !_ctx.loading)
      ? _renderSlot(_ctx.$slots, "icon_left", { key: 2 })
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createTextVNode(_toDisplayString(_ctx.loading ? _ctx.loadingLabel : _ctx.label), 1)
    ]),
    (_ctx.$slots.icon_right && !_ctx.loading)
      ? _renderSlot(_ctx.$slots, "icon_right", { key: 3 })
      : _createCommentVNode("", true),
    (_ctx.iconRight)
      ? (_openBlock(), _createElementBlock("span", {
          key: 4,
          innerHTML: _ctx.icon[_ctx.iconRight]
        }, null, 8, _hoisted_4))
      : _createCommentVNode("", true)
  ], 16, _hoisted_1))
}