<script lang="ts">
import { Vue, Model, Component, Prop, Emit } from "vue-facing-decorator";
import { AdvanceSettings, Assignment } from "./assignmentType";
import AssignmentAdvanceSettingsForm from "./AssignmentAdvanceSettingsForm/AssignmentAdvanceSettingsForm.vue";
import FormField from "@/components/Layout/Forms/FormField.vue";
import AssignmentInformation from "./AssignmentInformation.vue";
import AssignmentService from "@/services/AssignmentService";
import { setAssignment } from "@/store/assignment/assignment-dispatch";
import emitter from "@/config/emitter";

@Component({
  components: {
    AssignmentAdvanceSettingsForm,
    FormField,
    AssignmentInformation,
  },
})
export default class AssignmentAdvancedSettings extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  assignment!: Assignment;

  @Model({
    type: Object,
    required: true,
    name: "settings",
  })
  settingsModel!: AdvanceSettings;

  private assignmentService = new AssignmentService();

  loadingUpdateAssignment = false;
  payload: { visible: boolean } = {
    visible: false,
  };
  eventBus = emitter;

  @Emit("onClickSaveAdvanceSettings")
  async handleClickSaveAdvanceSettings() {
    let visible: boolean =
      this.settingsModel.studentVisibility !== "later" ? true : false;
    this.payload.visible = visible;
    await this.onUpdateVisibility();
    return;
  }

  async onUpdateVisibility() {
    try {
      this.loadingUpdateAssignment = true;
      const response = await this.assignmentService.updateAssignment(
        this.assignment.assignmentDetails.uuid as string,
        this.payload
      );
      if (response.data.ok) {
        await this.$store.dispatch(
          setAssignment,
          this.assignment.assignmentDetails.uuid as string
        );
        this.$router.push({
          name: "Classes",
          params: {
            id: this.assignment.assignmentDetails.class,
          },
          query: {
            assignment: this.assignment.assignmentDetails.uuid,
          },
        });
        this.eventBus.emit(
          "REFRESH_ASSIGNMENT",
          this.assignment.assignmentDetails.uuid
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingUpdateAssignment = false;
    }
  }

  @Emit("onClickSkipOrGoBack")
  handleGoBackOrSkip(index = 2) {
    return index;
  }
}
</script>
<template>
  <div class="bg-white px-2 py-2 pb-2">
    <div class="w-full">
      <FormField class="mb-6" label="Assignment Information" :required="false">
        <AssignmentInformation class="mt-5" :assignment="assignment" />
      </FormField>
      <AssignmentAdvanceSettingsForm
        v-model="settingsModel"
        :loadingSaving="loadingUpdateAssignment"
        @onClickAdvanceSetting="handleClickSaveAdvanceSettings"
        @onClickSkipOrGoBack="handleGoBackOrSkip"
      />
    </div>
  </div>
</template>
