<template>
  <div>
    <Accordion
      class="accordion-custom mb-2"
      :activeIndex="activeIndex"
      @tab-click="toggleContentBox(false)"
    >
      <AccordionTab
        :pt="{
          header: {
            class:
              '[&>a]:flex-row-reverse [&>a]:justify-between [&>a]:!p-4 [&>a]:!shadow-none [&>a]:!bg-flohh-neutral-95',
          },
          content: {
            class: '',
          },
        }"
      >
        <template #header>
          <div class="flex items-center">
            <span
              class="w-6 h-6 rounded-full flex justify-center items-center bg-flohh-primary-pink color-flohh-neutral-5 text-flohh-text-caption font-medium mr-4"
              >1</span
            >
            <span class="text-flohh-text-body color-flohh-neutral-5 font-medium"
              >Past Learning Goal</span
            >
          </div>
        </template>
        <template #default>
          <textarea
            class="input-textarea h-[200px]"
            :name="'feedback'"
            :placeholder="
              viewType === 'student'
                ? 'Your past learning goals are displayed here.'
                : 'Past learning goals are displayed here.'
            "
            :value="pastLearningGoal"
            disabled
          ></textarea>
        </template>
      </AccordionTab>

      <AccordionTab
        v-if="
          assignmentStatus === 'feedback returned' ||
          assignmentStatus === 'learning goal submitted' ||
          assignmentStatus === 'completed'
        "
        :pt="{
          header: {
            class:
              '[&>a]:flex-row-reverse [&>a]:justify-between [&>a]:!p-4 [&>a]:!shadow-none [&>a]:!bg-flohh-neutral-95',
          },
          content: {
            class: '',
          },
        }"
      >
        <template #header>
          <div class="flex items-center">
            <span
              class="w-6 h-6 rounded-full flex justify-center items-center bg-flohh-primary-pink color-flohh-neutral-5 text-flohh-text-caption font-medium mr-4"
              >2</span
            >
            <span class="text-flohh-text-body color-flohh-neutral-5 font-medium"
              >Teacher Feedback</span
            >
          </div>
        </template>
        <template #default>
          <textarea
            class="input-textarea h-[200px]"
            :name="'feedback'"
            :placeholder="
              viewType === 'student'
                ? `Your teacher's feedback will be displayed here.`
                : `Your feedback will be displayed here.`
            "
            :value="comment"
            disabled
          ></textarea>
        </template> </AccordionTab
      ><AccordionTab
        v-if="
          assignmentStatus === 'feedback returned' ||
          assignmentStatus === 'learning goal submitted' ||
          assignmentStatus === 'completed'
        "
        :pt="{
          header: {
            class:
              '[&>a]:flex-row-reverse [&>a]:justify-between [&>a]:!p-4 [&>a]:!shadow-none [&>a]:!bg-flohh-neutral-95',
          },
          content: {
            class: '',
          },
        }"
      >
        <template #header>
          <div class="flex items-center">
            <span
              class="w-6 h-6 rounded-full flex justify-center items-center bg-flohh-primary-pink color-flohh-neutral-5 text-flohh-text-caption font-medium mr-4"
              >3</span
            >
            <span
              class="text-flohh-text-body color-flohh-neutral-5 font-medium"
              >{{
                assignmentStatus === "completed"
                  ? "Learning Goal"
                  : "New Learning Goal"
              }}</span
            >
          </div>
        </template>
        <template #default>
          <textarea
            v-if="assignmentStatus === 'completed'"
            class="input-textarea h-[200px]"
            :name="'feedback'"
            v-model="studentLearningGoal"
            :disabled="true"
          ></textarea>
          <textarea
            v-else
            class="input-textarea h-[200px]"
            :name="'feedback'"
            :placeholder="
              viewType === 'student'
                ? `Enter your reflection here`
                : `Student's reflection here`
            "
            v-model="learningGoal"
            :disabled="learningGoalSubmitted"
          ></textarea>

          <AppButton
            v-if="
              viewType === 'student' &&
              assignmentStatus !== 'completed' &&
              !learningGoalSubmitted
            "
            type="submit"
            class="w-full mt-5"
            @click="handleSubmitLearningGoal"
            :loading="isSubmitting"
          >
            <span
              v-if="learningGoalSubmitted && !isSubmitting"
              v-html="icons.checkBlack"
              class="mr-2"
            ></span>
            {{ learningGoalSubmitted ? "Submitted" : "Submit" }}
          </AppButton>
        </template> </AccordionTab
      ><AccordionTab
        v-if="
          viewType === 'teacher'
            ? criteriaSheet.name &&
              (assignmentStatus === 'feedback returned' ||
                assignmentStatus === 'learning goal submitted' ||
                assignmentStatus === 'completed')
            : criteriaSheet.name && assignmentStatus === 'completed'
        "
        :pt="{
          header: {
            class:
              '[&>a]:flex-row-reverse [&>a]:justify-between [&>a]:!p-4 [&>a]:!shadow-none [&>a]:!bg-flohh-neutral-95',
          },
          content: {
            class: '',
          },
        }"
      >
        <template #header>
          <div class="flex items-center">
            <span
              class="w-6 h-6 rounded-full flex justify-center items-center bg-flohh-primary-pink color-flohh-neutral-5 text-flohh-text-caption font-medium mr-4"
              >4</span
            >
            <span class="text-flohh-text-body color-flohh-neutral-5 font-medium"
              >Score</span
            >
          </div>
        </template>
        <template #default>
          <div class="score-container mb-5">
            <div class="flex justify-between items-center">
              <p>Total Score</p>
              <p>
                {{ generatedScore.score }}/{{ generatedScore.totalScore || 0 }}
              </p>
            </div>
            <Divider layout="horizontal" />
            <div class="flex justify-between items-center">
              <p>Grade</p>
              <p>{{ generatedScore.grade }}</p>
            </div>
          </div>
          <AppButton
            type="submit"
            class="ml-auto"
            @click="toggleCriteriaSheet"
            v-tooltip.top="{
              value: 'View Criteria Sheet',
              showDelay: 500,
            }"
          >
            <span v-html="icons.squareGrid"></span>
          </AppButton>
        </template>
      </AccordionTab>
    </Accordion>

    <AppButton
      v-if="assignmentStatus === 'due' || assignmentStatus === 'overdue'"
      type="submit"
      class="w-full"
      @click="handleSubmitAssignment"
      :disabled="assignmentSubmitted"
    >
      <span
        v-if="assignmentSubmitted"
        v-html="icons.checkBlack"
        class="mr-2"
      ></span>
      <span v-else v-html="icons.uploadBlack" class="mr-2"></span>
      {{ assignmentSubmitted ? "Submitted" : "Upload my submission" }}
    </AppButton>
    <AppButton
      v-else
      type="submit"
      class="w-full"
      @click="handleDownloadSubmission"
      :disabled="isDownloading"
    >
      <span v-html="icons.iconDownload" class="mr-2"></span>
      Download Submission
    </AppButton>
  </div>
  <AssignmentSubmission
    v-model="showSubmission"
    @onClickSubmission="handleClickSubmission"
    :viewType="viewType"
    :assignment="assignment"
    :pastLearningGoal="pastLearningGoal"
  />

  <ModalUtility
    v-model="isCriteriaSheetOpen"
    :title="criteriaSheet.name"
    :fullScreen="'true'"
    :draggable="false"
  >
    <template #content>
      <div class="bg-[#f5f1f4]">
        <CriteriaSheetComponent
          :isMarking="!isMarkingCriteriaSheet"
          :isViewOnly="isViewOnly"
          :relative="true"
          :preview="true"
          :previewMode="true"
          :criteriaSheetData="criteriaSheetData"
          :assignmentUuid="assignment.uuid"
          :studentId="studentId"
          :submissionId="submissionId"
          :gradeUuid="gradeUuid"
          :gradeSchema="gradeSchema"
          :grades="grades"
          @toggleCriteriaSheet="toggleCriteriaSheet"
          @generateScore="handleGeneratedScore"
        />
      </div>
    </template>
  </ModalUtility>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-facing-decorator";
import { useToast } from "primevue/usetoast";
import Toast from "primevue/toast";
import AssignmentSubmission from "@/components/Assignment/Submission/AssignmentSubmission.vue";
import TextInputComponent from "../../Input/TextInput/TextInputComponent.vue";
import AccordionTab from "primevue/accordiontab";
import Accordion from "primevue/accordion";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { icons as AppIcons } from "@/utils/icons";
import Divider from "primevue/divider";
import LearningGoalService from "@/services/LearningGoalService";
import emitter from "@/config/emitter";
import { AxiosResponse } from "axios";
import { IGradeSchema, IGrades, ICriteriaSheet } from "@/models/CriteriaSheet";
import CriteriaSheetService from "@/services/CriteriaSheetService";
import ModalUtility from "@/components/utilities/ModalUtility.vue";
import { StorageDataKey } from "@/enums/enums";
import CriteriaSheetComponent from "@/components/PSPDFKit/CriteriaSheetComponent.vue";
import MediaService from "@/services/MediaService";

interface ICriteriaSheetData {
  uuid: string;
}
interface IGeneratedScore {
  score: number;
  grade: string;
  percentage: number;
  totalScore?: number;
}

@Component({
  components: {
    TextInputComponent,
    AppButton,
    Toast,
    AssignmentSubmission,
    AccordionTab,
    Accordion,
    Divider,
    ModalUtility,
    CriteriaSheetComponent,
  },
})
export default class StudentSidePanelComponent extends Vue {
  private criteriaSheetService = new CriteriaSheetService();
  private learningGoalService = new LearningGoalService();
  private mediaService = new MediaService();

  icons = AppIcons;
  eventBus = emitter;

  isViewOnly = true;

  @Prop({
    type: String,
    required: false,
  })
  viewType!: string;

  @Prop({
    type: String,
    required: false,
  })
  comment!: string;

  @Prop({
    type: String,
    required: false,
  })
  assignmentStatus!: string;

  @Prop({
    required: true,
  })
  assignment!: any;

  @Prop({
    type: String,
    required: false,
  })
  pastLearningGoal!: string;

  @Prop({
    type: String,
    required: true,
  })
  pdfBase64!: string;

  @Prop({
    type: String,
    required: false,
  })
  studentLearningGoal!: string;

  @Prop({
    type: String,
    required: false,
  })
  submissionId!: string;

  @Prop({
    type: String,
    required: false,
  })
  studentId!: string;

  @Prop({
    required: false,
  })
  submission!: any;

  toast = useToast();

  isCriteriaSheetOpen = false;
  isDraggable = false;
  criteriaSheet: ICriteriaSheet = {} as ICriteriaSheet;
  isMarkingCriteriaSheet = true;
  criteriaSheetData: ICriteriaSheetData = {
    uuid: "",
  };
  gradeUuid!: string;
  grades!: IGrades;
  gradeSchema!: IGradeSchema;
  generatedScore: any = {
    score: 0,
    grade: "",
    percentage: 0,
    totalScore: 0,
  };

  isSubmitting = false;

  assignmentSubmitted = false;
  learningGoalSubmitted = false;
  showSubmission = false;
  hasPrevLearningGoal = true;
  learningGoal = "";
  isContentBoxOpen = false;
  activeIndex = 0;
  isDownloading = false;

  async mounted() {
    if (this.assignmentStatus === "submitted") {
      this.assignmentSubmitted = true;
    } else if (this.assignmentStatus === "feedback returned") {
      this.activeIndex = 1;
      if (this.assignment.summary.studentLearningGoal) {
        this.learningGoal = this.assignment.summary.studentLearningGoal.content;
        this.learningGoalSubmitted = true;
        this.activeIndex = 2;
      }
    } else if (this.assignmentStatus === "learning goal submitted") {
      this.activeIndex = 2;
      this.learningGoalSubmitted = true;
    } else if (this.assignmentStatus === "completed") {
      this.activeIndex = 3;
    }

    if (this.studentLearningGoal) {
      this.learningGoal = this.studentLearningGoal;
    }

    if (
      this.studentId &&
      this.submissionId &&
      (this.assignmentStatus === "feedback returned" ||
        this.assignmentStatus === "learning goal submitted" ||
        this.assignmentStatus === "completed")
    ) {
      if (this.viewType === "teacher") {
        await this.onGetCriteriaSheet();
        await this.onGetGrades();
      } else {
        if (this.assignmentStatus === "completed") {
          await this.getGradeAsStudent();
        }
      }
    }
  }

  handleGeneratedScore(data: IGeneratedScore) {
    this.generatedScore = data;
    this.onGetGrades();
  }

  async getGradeAsStudent() {
    const res = await this.criteriaSheetService.getStudentCriteriaGrade(
      this.submissionId
    );
    if (res.data.ok) {
      //
      const data = res.data.data;

      if (data.criteriaSheet) {
        this.criteriaSheet = data.criteriaSheet;

        this.criteriaSheetData.uuid = data.criteriaSheet.sheetDetails.uuid;

        const cellsData = data.criteriaSheet.cells;
        const criteriaSheetCells: any = [];
        cellsData.forEach((o: any) => {
          criteriaSheetCells.push(o.cellConfig.detail);
        });

        localStorage.setItem(
          StorageDataKey.CriteriaSheet,
          JSON.stringify(criteriaSheetCells)
        );
        localStorage.setItem(
          StorageDataKey.CriteriaSheetConfig,
          JSON.stringify(data.criteriaSheet.sheetDetails.sheetConfig.detail)
        );

        const criteria = criteriaSheetCells;
        const criteriaConfigs =
          data.criteriaSheet.sheetDetails.sheetConfig.detail;

        if (criteria && criteriaConfigs) {
          this.criteriaSheet = criteriaConfigs;
          this.isMarkingCriteriaSheet = true;
        }
      }
      if (data.grade) {
        this.gradeUuid = data.grade.uuid;
        this.grades = data.grade;

        const percentage = this.handleCalcPercentage(
          Number(this.grades.rawScore || 0),
          Number(this.criteriaSheet.totalScore || 0)
        );
        this.generatedScore = {
          score: this.grades.rawScore,
          grade: this.grades.grade || "",
          percentage: percentage,
          totalScore: Number(this.criteriaSheet.totalScore || 0),
        };

        if (data.gradeSchema) {
          this.gradeSchema = data.gradeSchema;
        }
      } else {
        this.generatedScore.totalScore = Number(
          this.criteriaSheet.totalScore || 0
        );
      }
    }
  }

  async onGetCriteriaSheet() {
    try {
      const res = await this.criteriaSheetService.getCriteriaSheetAssignment(
        this.assignment.uuid
      );

      if (res.data.ok) {
        const data = res.data.data;
        if (data.length) {
          this.criteriaSheetData.uuid = data[0].sheetDetails.uuid;

          const cellsData = data[0].cells;
          const criteriaSheetCells: any = [];
          cellsData.forEach((o: any) => {
            criteriaSheetCells.push(o.cellConfig.detail);
          });

          localStorage.setItem(
            StorageDataKey.CriteriaSheet,
            JSON.stringify(criteriaSheetCells)
          );
          localStorage.setItem(
            StorageDataKey.CriteriaSheetConfig,
            JSON.stringify(data[0].sheetDetails.sheetConfig.detail)
          );

          const criteria = criteriaSheetCells;
          const criteriaConfigs = data[0].sheetDetails.sheetConfig.detail;

          if (criteria && criteriaConfigs) {
            this.criteriaSheet = criteriaConfigs;
            this.isMarkingCriteriaSheet = true;
          }
        } else {
          this.criteriaSheetData.uuid = "";
        }
      }
    } catch (e) {
      //
    }
  }

  async onGetGrades() {
    try {
      if (this.criteriaSheetData.uuid && this.studentId) {
        const gradeRes = await this.criteriaSheetService.getGrade(
          this.submissionId,
          this.studentId
        );

        const gradeSchemaRes = await this.criteriaSheetService.getGradeSchema(
          this.criteriaSheetData.uuid
        );

        if (gradeRes.data.ok && gradeRes.data.data) {
          this.gradeUuid = gradeRes.data.data.uuid;
          this.grades = gradeRes.data.data;

          const percentage = this.handleCalcPercentage(
            Number(this.grades.rawScore),
            Number(this.criteriaSheet.totalScore)
          );
          this.generatedScore = {
            score: this.grades.rawScore,
            grade: this.grades.grade || "",
            percentage: percentage,
            totalScore: Number(this.criteriaSheet.totalScore),
          };

          if (gradeSchemaRes.data.ok && gradeSchemaRes.data) {
            this.gradeSchema = gradeSchemaRes.data.data;
          }
        } else {
          this.generatedScore.totalScore = Number(
            this.criteriaSheet.totalScore
          );
        }
        console.log("this.generatedScore");
        console.log(this.generatedScore);
      }
    } catch (e) {
      console.error(e);
    }
  }

  handleCalcPercentage(score: number, total: number) {
    const percentage = (score / total) * 100;
    return Number(percentage.toFixed(0));
  }

  toggleCriteriaSheet() {
    this.isCriteriaSheetOpen = !this.isCriteriaSheetOpen;
  }

  toggleContentBox(status: boolean) {
    this.isContentBoxOpen = status;
  }

  handleSubmitAssignment() {
    this.eventBus.emit("EVENT_TRIGGER", "SD002");
    this.showSubmission = true;
  }

  async handleDownloadSubmission() {
    this.eventBus.emit("EVENT_TRIGGER", "SD003");
    let fileName = "";
    let fileType = "application/pdf";
    let mediaUuid = "";

    this.isDownloading = true;
    const assignment = this.assignment;
    const localInfo = localStorage.getItem("student");
    if (localInfo) {
      const student = JSON.parse(localInfo);
      fileName = `${student.profile.lastName}, ${student.profile.firstName} - ${assignment.name}.pdf`;
    }

    if (this.submission) {
      fileType = this.submission.flattenedComponent.mimeType;
      mediaUuid = this.submission.flattenedComponent.media;
    } else {
      if (assignment.summary) {
        if (assignment.summary.flattenedComponent) {
          fileType = assignment.summary.flattenedComponent.mimeType;
          mediaUuid = assignment.summary.flattenedComponent.media;
        } else {
          fileType = assignment.summary.submission.component.mimeType;
          mediaUuid = assignment.summary.submission.component.media;
        }
      }
    }

    const response: AxiosResponse = await this.mediaService.getSubmissionFile(
      mediaUuid
    );
    const blob = new Blob([response.data], {
      type: fileType,
    });
    const el = document.createElement("a");
    const href = URL.createObjectURL(blob);
    el.setAttribute("download", fileName);
    el.href = href;
    el.setAttribute("target", "_blank");
    el.click();
    URL.revokeObjectURL(href);
    this.isDownloading = false;
  }

  async handleSubmitLearningGoal() {
    this.eventBus.emit("EVENT_TRIGGER", "SD004");
    try {
      if (!this.learningGoal) {
        this.toast.add({
          severity: "error",
          detail: "Invalid learning goal",
          life: 3000,
        });
        return;
      }
      this.isSubmitting = true;
      const payload = {
        submissionUuid: this.assignment.summary.submission.uuid,
        learningGoal: {
          learningGoals: this.learningGoal,
        },
      };
      const response: AxiosResponse =
        await this.learningGoalService.postLearningGoal(payload);
      if (response.data.ok) {
        this.learningGoalSubmitted = true;
        this.toast.add({
          severity: "success",
          detail: "Learning goal submitted successfully",
          life: 3000,
        });
        this.eventBus.emit("REFRESH_DASHBOARD");
      }
      this.isSubmitting = false;
    } catch (e) {
      console.error(e);
      this.isSubmitting = false;
    }
  }

  handleClickSubmission(isSuccess: boolean) {
    if (isSuccess) {
      this.assignmentSubmitted = true;
      this.eventBus.emit("REFRESH_DASHBOARD");
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.modal-default-btn {
  background-color: rgba(37, 192, 113, 1);
  font-size: 15px;
  transition: 0.3s ease;

  &:hover {
    background-color: rgba(37, 192, 113, 1) !important;
    opacity: 0.6;
  }
}

.input-textarea {
  width: 100%;
  background: #f9f9f9;
  border: 1px solid #dedede;
  padding: 10px 15px;
  margin-top: 5px;
  border-radius: 8px;

  resize: none;
}
</style>
