import { ClassState } from "./classTypes";

const state: ClassState = {
  classes: [],
  classInformation: {
    code: "",
    createdBy: "",
    inviteCode: "",
    students: [],
    studentUuids: [],
    subject: "",
    uuid: "",
    yearLevel: "",
    teacher: "",
    teacherUuid: "",
  },
};

export default state;
