<template>
  <div class="text-input">
    <label>
      {{ label }}
      <input
        :name="inputName"
        class="input-field"
        autocomplete="false"
        v-bind="$attrs"
        :value="value"
        :placeholder="placeholder"
        :required="required"
        :type="type"
        v-if="!textType"
      />
      <textarea
        :name="name"
        class="input-textarea"
        autocomplete="false"
        v-bind="$attrs"
        :value="value"
        :placeholder="placeholder"
        :required="required"
        v-if="textType === 'textarea'"
      ></textarea>
    </label>
    <p class="error-msg" v-if="errorMsg">{{ errorMsg }}</p>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from "vue-facing-decorator";

@Component
export default class TextInputComponent extends Vue {
  errorMsg!: string;

  @Prop({
    type: String,
    required: false,
  })
  label!: string;

  @Prop({
    type: String,
    required: false,
  })
  type!: string;

  @Prop({
    type: String,
    required: false,
  })
  value!: string;

  @Prop({
    type: String,
    required: false,
  })
  placeholder!: string;

  @Prop({
    type: String,
    required: false,
  })
  textType!: string;

  @Prop({
    type: Boolean,
    required: false,
  })
  required!: boolean;

  @Prop({
    type: String,
    required: false,
  })
  inputName!: string;

  @Prop({
    type: String,
    required: false,
  })
  errMsg!: string;

  validationTimeout!: number;

  get name() {
    return this.label.toLowerCase();
  }

  // validate(event: Event) {
  //   const target = event.target as HTMLInputElement;
  //   const value = target.value;
  //   clearTimeout(this.validationTimeout);
  //   this.validationTimeout = setTimeout(() => {
  //     if (value) {
  //       if (this.type === "email") {
  //         this.errorMsg = this.validateEmail(value);
  //       } else if (this.type === "password") {
  //         this.errorMsg = this.validatePassword(value);
  //       } else {
  //         //
  //       }
  //     } else {
  //       this.errorMsg = "Value should not be empty";
  //     }
  //   }, 300);
  // }
  // @Watch("value")
  // propertyWatcher(newValue: string) {
  //   if (this.required) {
  //     if (newValue) {
  //       this.errorMsg = "";
  //     } else {
  //       this.errorMsg = "Value should not be empty";
  //     }
  //   }
  // }

  @Watch("errMsg")
  propertyWatcher(newValue: string) {
    if (this.required) {
      this.errorMsg = newValue;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$browser-context: 16; // Default
@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}

.text-input {
  display: block;
  text-align: left;
  margin-bottom: 20px;
  width: 100%;
  label {
    display: block;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.28;
    text-align: left;
    color: #333;
    .input-field {
      width: 100%;
      height: 53px;
      background: #f9f9f9;
      border: 1px solid #dedede;
      padding: 0 15px;
      margin-top: 5px;
      border-radius: 8px;
      display: block;
    }
    .input-textarea {
      width: 100%;
      background: #f9f9f9;
      border: 1px solid #dedede;
      padding: 10px 15px;
      margin-top: 5px;
      border-radius: 8px;
      height: 80px;
      max-height: 80px;
      min-height: 80px;
    }
  }
  .error-msg {
    font-size: 12px;
    color: #f00;
    margin-top: 5px;
  }
}
</style>
