<template>
  <div
    class="flex flex-col py-[26px] px-[30px] bg-[#E3F8FF] rounded-lg justify-between"
  >
    <h6 class="text-[18px] leading-[1.5] text-[#333] mb-[23px] font-bold">
      Upload your first submissions
    </h6>
    <p class="text-[#595959] text-[14px] leading-[1.5] mb-[30px] font-medium">
      Upload paper and digital submissions in bulk and get marking. Get started
      by clicking one the the options below.
    </p>
    <div class="flex gap-[14px]">
      <AppButton
        class="!bg-[#90CDDF] hover:opacity-80 w-[50%]"
        @click="
          () => {
            handleRedirect('/bulk-upload/paper');
          }
        "
        >Paper submissions</AppButton
      >
      <AppButton
        class="!bg-[#90CDDF] hover:opacity-80 w-[50%]"
        @click="
          () => {
            handleRedirect('/bulk-upload/digital');
          }
        "
        >Digital submissions</AppButton
      >
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import AppButton from "../../Layout/Buttons/AppButton.vue";
import { icons as AppIcons, icons } from "@/utils/icons";
import { StorageDataKey } from "@/enums/enums";
import AppTypographyText from "../../Layout/Typhography/AppTypographyText.vue";
import DashboardService from "@/services/DashboardService";
import axios, { AxiosResponse } from "axios";
@Component({
  components: {
    AppButton,
    AppTypographyText,
  },
})
export default class TaskAssignmentSubmissionCard extends Vue {
  //

  handleRedirect(path: string) {
    //assignments/create
    this.$router.push({
      path: path,
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
