<script lang="ts">
import { Vue, Prop, Model, Component, Watch } from "vue-facing-decorator";
import FormInputDropdown from "../Layout/Forms/FormInputDropdown.vue";
import ClassService from "@/services/ClassService";
import { getTeacherUuid } from "@/config/auth";
import { ClassResponse } from "@/models/Class";
import { icons } from "@/utils/icons";
import AppTypographyText from "../Layout/Typhography/AppTypographyText.vue";
import emitter from "@/config/emitter";

@Component({ components: { FormInputDropdown, AppTypographyText } })
export default class SelectClass extends Vue {
  icon = icons;
  eventBus = emitter;
  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  invalid!: boolean;

  @Prop({
    type: Function,
    required: false,
  })
  refreshList!: (callback?: () => void) => void;

  @Model({
    type: String,
    default: "",
    required: true,
  })
  value!: string;
  private classService = new ClassService();

  @Prop({
    type: String,
    default: "",
    required: false,
  })
  classLabel!: string;

  @Prop({
    type: String,
    default: "",
    required: false,
  })
  iconLeft!: string;

  @Prop({
    type: String,
    default: "Select a class",
    required: false,
  })
  placeholder!: string;

  @Prop({
    type: String,
    default: "",
    required: false,
  })
  defaultValue!: string;

  classes: ClassResponse[] = [];
  loadingClasses = false;
  params: { code?: string; teacher?: string } = {
    teacher: getTeacherUuid(),
  };

  emptyClass: ClassResponse = {
    uuid: "",
    subject: "",
    code: "",
    yearLevel: "",
    inviteCode: "",
    createdBy: {
      uuid: "",
      schoolName: "",
      workEmail: "",
      user: "",
      profileDetails: {
        uuid: "",
        firstName: "",
        lastName: "",
        user: "",
      },
      profile: "",
    },
    students: [],
  };

  async onFetchClasses(code?: string) {
    try {
      this.loadingClasses = true;
      if (code) {
        this.params.code = code;
      } else {
        delete this.params.code;
      }

      const response = await this.classService.fetchClasses(this.params);
      this.classes = response.data.data as ClassResponse[];
      if (this.classLabel) {
        this.insertCreateClassOption(response.data.data as ClassResponse[]);
      } else {
        this.removeCreateClassOption();
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingClasses = false;
    }
  }

  @Watch("classLabel")
  classLabelWatcher(value: string) {
    if (!this.loadingClasses) {
      if (value) {
        this.insertCreateClassOption(this.classes);
      } else {
        this.removeCreateClassOption();
      }
    }
  }

  insertCreateClassOption(classList: ClassResponse[]) {
    const allClasses = classList;
    this.emptyClass.code = this.classLabel;
    this.emptyClass.uuid = "create";
    allClasses.unshift(this.emptyClass);
    this.classes = allClasses;
  }

  removeCreateClassOption() {
    const classes = this.classes.filter(
      (item: ClassResponse) => item.uuid !== "create"
    );
    this.classes = classes;
  }

  @Watch("value")
  valueWatcher(value: string) {
    this.handleSelectedClass(value);
  }

  async mounted() {
    await this.onFetchClasses();
    this.value = this.defaultValue;
    if (this.value) {
      this.handleSelectedClass(this.value);
    }

    this.eventBus.on("REFRESH_CLASSES_DROPDOWN", async (classUuid: any) => {
      await this.onFetchClasses();
      if (classUuid) {
        this.value = classUuid;
      }
    });
  }

  handleSelectedClass(classUuid: string) {
    const classList = this.classes;
    const selectedClass = classList.find(
      (item: ClassResponse) => classUuid === item.uuid
    );
    console.log("selectedClass");
    console.log(selectedClass);
    if (selectedClass) {
      this.$emit("onClassSelect", selectedClass);
    } else {
      this.$emit("onClassSelect", this.emptyClass);
    }
  }
}
</script>
<template>
  <FormInputDropdown
    v-model="value"
    :filter="true"
    :disabled="disabled"
    :options="classes"
    optionLabel="code"
    optionValue="uuid"
    :loading="loadingClasses"
    :invalid="invalid"
    :placeholder="loadingClasses ? 'Loading classes...' : placeholder"
    :background="false"
    :iconLeft="iconLeft"
  />
</template>
