import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "fixed inset-0 bg-black bg-opacity-80 z-[1000] p-5 h-full" }
const _hoisted_2 = { class: "absolute top-10 right-10" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 0,
  class: "flex justify-center items-center h-full"
}
const _hoisted_5 = {
  key: 1,
  class: "flex justify-center items-center h-full bg-flohh-neutral-85"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_PdfViewComponent = _resolveComponent("PdfViewComponent")!
  const _component_ProgressLoader = _resolveComponent("ProgressLoader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AppButton, {
        onClick: _ctx.handleClickClose,
        size: "xs"
      }, {
        icon_left: _withCtx(() => [
          _createElementVNode("span", {
            innerHTML: _ctx.icons.closeBlack
          }, null, 8, _hoisted_3)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    (!_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_PdfViewComponent, { pdfBase64: _ctx.b64Image }, null, 8, ["pdfBase64"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_ProgressLoader, {
            label: "Loading File",
            labelType: "body"
          })
        ]))
  ]))
}