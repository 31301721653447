<template>
  <div>
    <div class="flex justify-between items-center mb-4">
      <h6 class="text-flohh-h6 font-flohh-font-bold">
        {{ title }}
      </h6>
      <CreationButton />
    </div>
    <div
      v-if="assignmentData && assignmentData.length > 0"
      class="overflow-y-auto flex flex-col space-y-7"
      :style="`max-height: ${height};`"
    >
      <AssignmentsTableComponent
        v-for="(item, index) in assignmentData"
        :key="index"
        :data="item"
      />
    </div>
    <div v-else class="flex justify-center">
      <AppTypographyText label="No active assignments yet" type="caption" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import { useToast } from "primevue/usetoast";
import { icons } from "@/utils/icons";
import AssignmentsTableComponent from "./AssignmentsTableComponent.vue";
import { AssignmentDashboard } from "@/store/dashboard/dashboardTypes";
import { ClassData } from "@/store/class/classTypes";
import { AssignmentTableData } from "../../type";
import CreationButton from "@/components/utilities/CreationButton.vue";

@Component({
  components: {
    AppTypographyText,
    AssignmentsTableComponent,
    CreationButton,
  },
})
export default class AssignmentListComponent extends Vue {
  toast = useToast();
  icon = icons;

  @Prop({
    type: Array,
    required: true,
  })
  assignments!: AssignmentDashboard[];

  @Prop({
    type: String,
    default: "",
  })
  title!: string;

  @Prop({
    type: String,
    default: "600px",
  })
  height!: string;

  assignmentData: AssignmentTableData[] = [];

  menus = [
    {
      icon: "assignmentIcon",
      name: "Open",
    },
    {
      icon: "uploadBlack",
      name: "Upload Submissions",
    },
  ];

  className = "Year 9 English";

  created() {
    this.handleCompileAssignments();
  }

  handleCompileAssignments() {
    const classes = structuredClone(this.$store.state.teacherClass.classes);
    const assignments = structuredClone(this.assignments);
    const data: AssignmentTableData[] = [];

    classes.map((classData: ClassData) => {
      const assignmentsFromClass = assignments.filter(
        (assignment: AssignmentDashboard) =>
          assignment.class.uuid === classData.uuid
      );
      data.push({
        class: classData,
        assignments: assignmentsFromClass,
      });
    });

    this.assignmentData = data;
  }

  handleMenuSelection() {
    //
  }
}
</script>

<style scoped lang="scss"></style>
