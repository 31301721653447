<template>
  <FormField :label="label" v-if="selectedGradeSchemaName">
    <div class="bg-flohh-primary-pink rounded-md">
      <div class="flex py-[12px] px-[20px] justify-between items-center gap-2">
        <Ellipsis :content="selectedGradeSchemaName" />
        <span
          class="cursor-pointer"
          v-html="icons.pencilBlack"
          @click="handleClickEditGradeSchema()"
        ></span>
      </div>
    </div>
  </FormField>
  <AppButton
    v-if="!selectedGradeSchemaName"
    label="Choose From Bank"
    @click="visible = true"
    text
    blackLabel
    :disabled="list.length === 0"
    :class="list.length === 0 ? 'opacity-[0.5]' : ''"
    :loading="listLoading"
  />
  <ModalUtility
    v-model="visible"
    width="614px"
    title="Select a Grade Schema Bank"
    @onClose="visible = false"
    :draggable="false"
  >
    <template #content>
      <div class="px-[28px] py-[30px] h-[364px] overflow-auto">
        <div v-if="!listLoading" class="flex flex-col">
          <div
            class="flex gap-2 items-center w-full px-4 py-4 hover:bg-flohh-neutral-95 cursor-pointer"
            v-for="(item, index) in list"
            :key="index"
            :class="[
              {
                'bg-flohh-neutral-95': item.uuid === selectedGradeSchema,
              },
            ]"
            @click="handleGradeSchemaClick(item.uuid)"
          >
            <FormRadioButton
              v-model="selectedGradeSchema"
              :name="index.toString()"
              :inputId="index.toString()"
              :value="item.uuid"
            />
            <label
              :for="item.name"
              class="font-flohh-font-medium text-flohh-text-body cursor-pointer"
              >{{ item.name }}</label
            >
          </div>
          <p v-if="!list.length">
            No Grade Schemas found. Create a new one via Manage Grade Schema
            link.
          </p>
        </div>
        <div v-else>
          <ProgressLoader />
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex pt-5 border-t border-solid border-flohh-neutral-85">
        <div class="flex-1 flex justify-start items-center">
          <AppButton @click="handleManageGradeSchema" text blackLabel>
            Manage Grade Schemas
          </AppButton>
        </div>
        <div class="flex-1 flex justify-end items-center gap-x-4">
          <AppButton type="submit" @click="handleClickSelect">
            <template #icon_left>
              <span v-html="icons.checkBlack"></span>
            </template>
            Select
          </AppButton>
        </div>
      </div>
    </template>
  </ModalUtility>
</template>

<script lang="ts">
import { Component, Prop, Vue, Ref, Model, Watch } from "vue-facing-decorator";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import AppDropdownButton from "@/components/Layout/Buttons/AppDropdownButton.vue";
import Ellipsis from "@/components/utilities/Ellipsis.vue";
import { useToast } from "primevue/usetoast";
import CreateAssignment from "@/components/Assignment/CreateAssignment/CreateAssignment.vue";
import ModalUtility from "@/components/utilities/ModalUtility.vue";
import { icons } from "@/utils/icons";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import CriteriaSheetService from "@/services/CriteriaSheetService";
import FormRadioButton from "@/components/Layout/Forms/FormRadioButton.vue";
import SelectGrading from "@/components/utilities/SelectGrading.vue";
import ProgressLoader from "@/components/utilities/ProgressLoader.vue";
import FormField from "@/components/Layout/Forms/FormField.vue";
import emitter from "@/config/emitter";

interface GradeSchemaList {
  uuid: string;
  name: string;
}

@Component({
  components: {
    AppTypographyText,
    AppDropdownButton,
    Ellipsis,
    CreateAssignment,
    ModalUtility,
    AppButton,
    FormRadioButton,
    SelectGrading,
    ProgressLoader,
    FormField,
  },
})
export default class GradeSchemaListModal extends Vue {
  private criteriaSheetService = new CriteriaSheetService();
  toast = useToast();
  eventBus = emitter;

  @Model({
    type: Boolean,
    required: true,
  })
  visible!: boolean;

  @Prop({
    type: String,
  })
  label!: string;

  @Prop({
    type: Object,
  })
  currentGradeSchema: GradeSchemaList = {
    uuid: "",
    name: "",
  };

  @Prop({
    type: String || Number,
  })
  totalScore!: string | number;

  @Prop({
    type: Function,
  })
  handleGradeSchemaData!: (uuid: string, data: any) => void;

  @Prop({
    type: Function,
  })
  handleEditGradeSchema!: (value: string) => void;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  isMarkingScreen!: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  isRubricBank!: boolean;

  icons = icons;
  list: GradeSchemaList[] = [];
  listLoading = true;
  selectedGradeSchema = "";
  selectedGradeSchemaName = "";
  templates = [];
  fetchingBankData = true;

  unsavedRedirectTo = "";

  gradeSchemaList = [];

  @Watch("currentGradeSchema")
  async currentGradeSchemaWatcher(value: GradeSchemaList) {
    if (value) {
      // await this.fetchGradeSchemaList();
      const item = this.list.find(
        (o: GradeSchemaList) => o.uuid === value.uuid
      );
      console.log(item);
      if (item) {
        item.name = value.name;
      } else {
        this.list.push({
          uuid: value.uuid,
          name: value.name,
        });
      }
      this.selectedGradeSchema = value.name;

      const selectedGradeSchemaData = this.list
        .filter((o) => o.name)
        .find(
          (item: GradeSchemaList) =>
            item.uuid === value.uuid && item.name === value.name
        );
      console.log("selectedGradeSchemaData");
      console.log(selectedGradeSchemaData);
      if (selectedGradeSchemaData) {
        this.selectedGradeSchemaName = selectedGradeSchemaData.name;
      } else {
        this.selectedGradeSchemaName = "";
      }
      return;
    }
  }

  async fetchGradeSchemaList() {
    try {
      this.listLoading = true;
      const res = await this.criteriaSheetService.getAllGradeSchema();
      this.list = [];
      if (res.data.ok) {
        // const templates = res.data.data.filter(
        //   (o: any) => o.schema[0].minimumScore === Number(this.totalScore)
        // );
        const templates = res.data.data;
        this.templates = templates;

        templates.map((item: any) => {
          const obj: GradeSchemaList = {
            uuid: item.uuid,
            name: item.name,
          };

          this.list.push(obj);
        });

        this.listLoading = false;
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
    }
  }

  mounted() {
    this.eventBus.on("CLEAR_FIELDS", () => {
      this.handleUnselectGradeSchema();
    });
    this.fetchGradeSchemaList();
  }

  handleClickEditGradeSchema() {
    this.handleEditGradeSchema(this.currentGradeSchema.uuid);
    // this.handleGradeSchemaData(this.currentGradeSchema.uuid);
  }

  handleGradeSchemaClick(uuid: string) {
    this.selectedGradeSchema = uuid;
  }

  async handleManageGradeSchema() {
    if (this.isRubricBank) {
      await this.$emit("handleSaveCriteria");
    } else {
      if (this.isMarkingScreen) {
        await this.eventBus.emit("SAVE_MARKING");
      }
    }
    this.$router.push({ path: "/grade-schema-bank" });
  }

  handleClickSelect() {
    if (this.selectedGradeSchema) {
      const selectedTemplate = this.templates.find(
        (o: any) => o.uuid === this.selectedGradeSchema
      );
      console.log(this.selectedGradeSchema);
      console.log(selectedTemplate);
      this.handleGradeSchemaData(this.selectedGradeSchema, selectedTemplate);
    } else {
      this.showMessage("Please select grade first", "error");
    }
  }

  handleUnselectGradeSchema() {
    this.selectedGradeSchema = "";
    this.selectedGradeSchemaName = "";
    this.handleGradeSchemaData("", "");
  }

  showMessage(
    message: string,
    severity: "success" | "info" | "warn" | "error" | undefined
  ) {
    this.toast.add({
      severity: severity,
      detail: message,
      life: 3000,
    });
  }
}
</script>

<style scoped lang="scss"></style>
