<template>
  <div v-if="data.length <= 5" class="flex pl-2">
    <div
      v-for="(user, i) in data"
      :key="i"
      class="avatar"
      :class="`avatar-${i}`"
    >
      <AppTypographyText
        :label="generateInitials(user.firstName, user.lastName)"
      />
    </div>
  </div>
  <div v-else class="flex pl-2">
    <div v-for="i in 5" :key="i" :class="`avatar avatar-container-${i}`">
      <div v-if="i <= 5">
        <AppTypographyText
          :label="generateInitials(data[i].firstName, data[i].lastName)"
        />
      </div>
    </div>
    <div class="avatar bg-flohh-neutral-85">+{{ data.length - 5 }}</div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-facing-decorator";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";

interface StudentNames {
  firstName: string;
  lastName: string;
}

@Component({ components: { AppTypographyText } })
export default class PeopleAvatars extends Vue {
  // Array of student image URLs
  @Prop({
    type: Array,
    required: true,
  })
  data!: StudentNames[];

  generateInitials(firstName: string, lastName: string) {
    return firstName[0] + lastName[0];
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.avatar {
  border-radius: 50px;
  border: 2px solid #ffffff;
  overflow: hidden;
  min-width: 40px;
  min-height: 40px;
  display: grid;
  place-items: center;

  &-container-0,
  &-0 {
    margin-left: -10px;
    z-index: 5;
    background-color: #92b2c3;
  }
  &-container-1,
  &-1 {
    margin-left: -10px;
    z-index: 4;
    background-color: #a3dbbe;
  }
  &-container-2,
  &-2 {
    margin-left: -10px;
    z-index: 3;
    background-color: #f9b2ce;
  }
  &-container-3,
  &-3 {
    margin-left: -10px;
    z-index: 2;
    background-color: #ffde7f;
  }
  &-container-4,
  &-4 {
    margin-left: -10px;
    z-index: 1;
    background-color: #fab28a;
  }
  &-container-5,
  &-5 {
    margin-left: -10px;
    z-index: 0;
    background-color: #92b2c3;
  }
}
</style>
