<template>
  <div class="classes-tabs min-h-[calc(100vh-240px)]" ref="tabContainer">
    <TabView v-model:activeIndex="active">
      <TabPanel :header="item.heading" v-for="(item, key) in tabs" :key="key">
        <div
          v-if="assignmentsLoading"
          class="flex items-center flex-col gap-2 pt-5"
        >
          <ProgressLoader
            label="Loading Assignments"
            labelVariant="md"
            labelType="subtitle"
          />
        </div>
        <div v-else class="h-full w-full">
          <component
            :is="item.component"
            :tab="item.name"
            :assignments="assignments"
            :activeIndex="active"
            :maxWidth="maxWidth"
          />
        </div>
      </TabPanel>
    </TabView>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Ref } from "vue-facing-decorator";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import AssignmentsActiveComponent from "./AssignmentsActiveComponent.vue";
import AssignmentsCompletedComponent from "./AssignmentsCompletedComponent.vue";
import AssignmentsUnassignedComponent from "./AssignmentsUnassignedComponent.vue";
import DashboardService from "@/services/DashboardService";
import { AxiosResponse } from "axios";
import { AssignmentDashboard } from "@/store/dashboard/dashboardTypes";
import ProgressLoader from "@/components/utilities/ProgressLoader.vue";
import AppTypographyText from "./../Layout/Typhography/AppTypographyText.vue";
import emitter from "@/config/emitter";

@Component({
  components: {
    TabView,
    TabPanel,
    AssignmentsActiveComponent,
    ProgressLoader,
    AppTypographyText,
  },
})
export default class AssignmentsListComponent extends Vue {
  private dashboardService = new DashboardService();
  eventBus = emitter;

  active = 0;
  tabs = [
    {
      heading: "Active",
      component: AssignmentsActiveComponent,
      name: "active",
    },
    {
      heading: "Completed",
      component: AssignmentsCompletedComponent,
      name: "completed",
    },
    {
      heading: "Draft",
      component: AssignmentsUnassignedComponent,
      name: "draft",
    },
  ];
  assignments!: AssignmentDashboard[];
  assignmentsLoading = true;

  @Ref() tabContainer!: HTMLElement;
  maxWidth!: number;

  async handleGetAssignments() {
    try {
      this.assignmentsLoading = true;
      const response: AxiosResponse =
        await this.dashboardService.getTeacherDashboard();

      if (response.data.ok) {
        this.assignments = response.data.data;
      } else {
        throw new Error();
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.assignmentsLoading = false;
    }
  }

  mounted() {
    this.getElementWidth();
    window.addEventListener("resize", this.getElementWidth);
  }

  beforeUnmount() {
    window.removeEventListener("resize", this.getElementWidth);
  }

  getElementWidth() {
    if (this.tabContainer) {
      const containerWidth = this.tabContainer.getBoundingClientRect().width;
      const offset = containerWidth * 0.06;
      this.maxWidth = containerWidth / 3 - offset;
    }
  }

  async created() {
    await this.handleGetAssignments();
  }
}
</script>

<style scoped lang="scss"></style>
