<template>
  <div>
    <AppDropdownButton
      label="Create"
      type="primary"
      width="w-13"
      @clickMenu="handleClickMenu"
      :menus="menu"
      dropdownIcon="plusBlack"
      :iconRight="false"
      :toRight="false"
    />

    <CreateClassPopup v-model="isAddClassOpen" :isEdit="false" />
  </div>
</template>
<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

import AppDropdownButton from "@/components/Layout/Buttons/AppDropdownButton.vue";
import CreateClassPopup from "@/components/CreateClass/CreateClassPopup.vue";

@Component({
  components: {
    AppDropdownButton,
    CreateClassPopup,
  },
})
export default class CreationButton extends Vue {
  isAddClassOpen = false;

  menu = [
    { name: "Class", icon: "classIcon" },
    { name: "Assignment", icon: "assignmentIconActive" },
  ];
  handleClickMenu(action: string) {
    if (action === "Class") {
      this.isAddClassOpen = true;
    } else if (action === "Assignment") {
      this.$router.push({ name: "CreateAssignment" });
    }
  }
}
</script>

<style scoped lang="scss"></style>
