<script lang="ts">
import { Vue, Component, Model, Emit, Watch, Prop } from "vue-facing-decorator";
import ModalUtility from "../utilities/ModalUtility.vue";
import FormField from "../Layout/Forms/FormField.vue";
import AppButton from "../Layout/Buttons/AppButton.vue";
import { Icon, icons } from "@/utils/icons";
import { ClassInfo } from "@/store/createClass/types";
import { checkIfAllPropertiesIsNull } from "@/utils/validator";
import ClassService from "@/services/ClassService";
import { useToast } from "primevue/usetoast";
import emitter from "@/config/emitter";

@Component({ components: { ModalUtility, FormField, AppButton } })
export default class CreateClassPopup extends Vue {
  @Model({
    type: Boolean,
    default: false,
  })
  openDialog!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  isEdit!: boolean;

  private classService = new ClassService();

  icon: Icon = icons;
  toast = useToast();

  classInfo: ClassInfo = {
    code: "",
    subject: "",
    yearLevel: "",
    description: "",
    teacher: "",
    uuid: "",
    createdBy: "",
    inviteCode: "",
    teacherUuid: "",
  };
  valid = true;
  errors = {
    code: "",
    subject: "",
    yearLevel: "",
  };
  createClassIsError = false;
  loadingCreateClass = false;
  teacherUuid = "";
  eventBus = emitter;

  @Emit("onCreateClass")
  handleOnCreateClassEmit(uuid: string) {
    this.classInfo.code = "";
    this.classInfo.subject = "";
    this.classInfo.yearLevel = "";

    this.$router.push({
      name: "Classes",
      params: { id: uuid },
    });
  }

  @Watch("classInfo.code")
  codeWatcher(value: string) {
    this.errors.code = value ? "" : "The class name field is required";
  }

  @Watch("classInfo.yearLevel")
  yearLevelWatcher(value: string) {
    this.errors.yearLevel = value ? "" : "The year level field is required";
  }

  @Watch("classInfo.subject")
  subjectWatcher(value: string) {
    this.errors.subject = value ? "" : "The subject field is required";
  }

  validateClass() {
    this.valid = true;
    this.errors.code = this.classInfo.code
      ? ""
      : "The class name field is required";
    this.errors.yearLevel = this.classInfo.yearLevel
      ? ""
      : "The year level field is required";
    this.errors.subject = this.classInfo.subject
      ? ""
      : "The subject field is required";

    if (!checkIfAllPropertiesIsNull(this.errors)) {
      this.valid = false;
      return;
    }
  }

  async handleClickSaveAssignmentDetails() {
    this.eventBus.emit("EVENT_TRIGGER", "CM001");
    this.validateClass();
    if (!this.valid) return;
    if (this.classInfo.uuid) {
      await this.onUpdateClass();
      this.handleOnCreateClassEmit(this.classInfo.uuid);
    } else {
      const classUuid = await this.onPostClass();
      this.handleOnCreateClassEmit(classUuid);
    }
    if (this.createClassIsError) return;
    this.eventBus.emit("REFRESH_SELECTED_CLASS");
  }

  async onPostClass() {
    try {
      this.classInfo.teacher = this.teacherUuid;
      this.loadingCreateClass = true;
      const response = await this.classService.postClass(this.classInfo);
      this.classInfo.uuid = response.data.data.uuid;
      this.createClassIsError = false;
      if (response.data.ok) {
        this.toast.add({
          severity: "success",
          summary: "Create Class",
          detail: `The ${this.classInfo.code} class is created successfully`,
          life: 3000,
        });
        this.$store.dispatch(
          "createClassPopup/setClassInfo",
          response.data.data
        );
        localStorage.setItem("ClassInfo", JSON.stringify(response.data.data));
        return response.data.data.uuid;
      } else {
        this.createClassIsError = true;
      }
      return "";
    } catch (error) {
      console.error(error);
      this.createClassIsError = true;
      this.toast.add({
        severity: "error",
        summary: "Error Message",
        detail: "Message Content",
        life: 3000,
      });
      return "";
    } finally {
      this.handleLoadClassess();
      this.loadingCreateClass = false;
    }
  }

  async onUpdateClass() {
    try {
      this.classInfo.teacher = this.teacherUuid;
      this.loadingCreateClass = true;
      const response = await this.classService.putClass(this.classInfo);
      this.classInfo = response.data.data;
      this.createClassIsError = false;
      if (!response.data.ok) {
        this.createClassIsError = true;
      }
    } catch (error) {
      console.error(error);
      this.createClassIsError = true;
      this.toast.add({
        severity: "error",
        summary: "Error Message",
        detail: "Message Content",
        life: 3000,
      });
    } finally {
      this.handleLoadClassess();
      this.loadingCreateClass = false;
    }
  }

  handleLoadClassess() {
    this.eventBus.emit("LOAD_CLASSES");
  }

  handleResetClassInfo() {
    this.classInfo = {
      code: "",
      subject: "",
      yearLevel: "",
      description: "",
      createdBy: "",
      inviteCode: "",
      teacherUuid: "",
      teacher: "",
      uuid: "",
    };
  }

  handleCloseCreateClass() {
    this.handleResetClassInfo();
    this.$store.dispatch("createClassPopup/resetClass");
  }

  initializeClassInfo() {
    if (!this.openDialog) return;
    let classInfoData: ClassInfo =
      this.$store.getters["createClassPopup/classInformation"];
    this.classInfo = structuredClone(classInfoData);
    this.teacherUuid = this.$store.state.auth.teacher.uuid;

    if (
      this.isEdit &&
      this.$store.state.teacherClass &&
      this.$store.state.teacherClass.classInformation &&
      this.$store.state.teacherClass.classInformation.uuid
    ) {
      this.classInfo = {
        code: this.$store.state.teacherClass.classInformation.code,
        createdBy: this.$store.state.teacherClass.classInformation.createdBy,
        inviteCode: this.$store.state.teacherClass.classInformation.inviteCode,
        subject: this.$store.state.teacherClass.classInformation.subject,
        teacherUuid: this.teacherUuid,
        yearLevel: this.$store.state.teacherClass.classInformation.yearLevel,
        description:
          this.$store.state.teacherClass.classInformation.description,
        teacher: this.$store.state.teacherClass.classInformation.teacher,
        uuid: this.$store.state.teacherClass.classInformation.uuid,
      };
    } else {
      this.classInfo = {
        code: "",
        createdBy: "",
        inviteCode: "",
        subject: "",
        teacherUuid: "",
        yearLevel: "",
        description: "",
        teacher: "",
        uuid: "",
      };
    }
  }

  @Watch("openDialog")
  openDialogWatcher(value: boolean) {
    if (value) {
      this.initializeClassInfo();
    }
  }
}
</script>
<template>
  <ModalUtility
    v-model="openDialog"
    width="45vw"
    title="Class Details"
    @onClose="handleCloseCreateClass"
  >
    <template #content>
      <div class="px-6 py-4">
        <FormField
          type="text"
          label="Class Name"
          v-model="classInfo.code"
          :error="errors.code"
          required
          placeholder="Type something"
          toolTip="Use this field to give your class a name that you and your students can use to easily identify it."
          :background="false"
        ></FormField>
        <FormField
          type="text"
          label="Subject"
          required
          :error="errors.subject"
          v-model="classInfo.subject"
          placeholder="Type something"
          toolTip="Use this field to set the subject of your class."
          :background="false"
        ></FormField>
        <FormField
          type="text"
          label="Year Level"
          required
          :error="errors.yearLevel"
          v-model="classInfo.yearLevel"
          placeholder="Type something"
          toolTip="Use this field to set the year level of your class."
          :background="false"
        ></FormField>

        <FormField
          type="textarea"
          label="Description"
          v-model="classInfo.description"
          placeholder="Type something"
          toolTip="Use this field to set the dedscription of your class."
          :background="false"
        ></FormField>
      </div>
    </template>
    <template #footer>
      <div
        class="w-full pt-4 flex justify-end items-end border-t border-solid border-flohh-neutral-85"
      >
        <AppButton
          type="submit"
          :loading="loadingCreateClass"
          label="Save"
          @click="handleClickSaveAssignmentDetails"
        >
          <template #icon_right>
            <span v-html="icon.arrowForwardBlackIcon"></span>
          </template>
        </AppButton>
      </div>
    </template>
  </ModalUtility>
</template>
<style lang="scss" scoped>
.p-dialog .p-dialog-footer button {
  margin: 0 !important;
}
</style>
