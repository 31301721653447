export const getPspdfkitLicenseKey = (): string => {
  const environment = process.env.VUE_APP_NODE_ENV || "local";
  switch (environment) {
    case "production":
      return process.env.VUE_APP_PSPDFKIT_LICENSE_KEY || "";
    case "qc":
      return process.env.VUE_APP_PSPDFKIT_LICENSE_KEY_QC || "";
    case "development":
      return process.env.VUE_APP_PSPDFKIT_LICENSE_KEY_DEVELOPMENT || "";
    default:
      return process.env.VUE_APP_PSPDFKIT_LICENSE_KEY_LOCAL || "";
  }
};
