<script lang="ts">
import { Vue, Component, Model, Emit, Watch, Prop } from "vue-facing-decorator";
import AppButton from "../Layout/Buttons/AppButton.vue";
import ModalUtility from "../utilities/ModalUtility.vue";
import AppTypographyText from "../Layout/Typhography/AppTypographyText.vue";
import FormField from "../Layout/Forms/FormField.vue";
import ClassInformation from "./ClassInformation.vue";
import { Icon, icons } from "@/utils/icons";
import { useToast } from "primevue/usetoast";

@Component({
  components: {
    AppButton,
    ModalUtility,
    AppTypographyText,
    FormField,
    ClassInformation,
  },
})
export default class CreateClassLinkPopup extends Vue {
  @Model({
    type: Boolean,
  })
  openDialog!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  isEdit!: boolean;

  icon: Icon = icons;
  link = "";
  toast = useToast();

  handleCloseInvite() {
    //
  }

  @Emit("onCloseSkipOrGoBack")
  handleClickSkipOrGoBack(index = 0) {
    return index;
  }

  handleClickSave() {
    this.openDialog = false;
  }

  handleClickCopy() {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(this.link)
        .then(() => {
          this.toast.add({
            severity: "success",
            detail: "Text copied to clipboard",
            life: 3000,
          });
        })
        .catch((err) => {
          this.toast.add({
            severity: "error",
            detail: `Unable to copy text: ${err}`,
            life: 3000,
          });
        });
    }
  }

  handleInitializeLink() {
    if (
      this.$store.state.createClassPopup.classInfo &&
      this.$store.state.createClassPopup.classInfo.inviteCode
    ) {
      this.link = `${window.location.host}/student/invite?code=${this.$store.state.createClassPopup.classInfo.inviteCode}`;
    }
  }

  @Watch("openDialog")
  opendDialogWatcher(value: boolean) {
    if (!value) return;
    this.handleInitializeLink();
  }
}
</script>
<template>
  <ModalUtility
    v-model="openDialog"
    width="45vw"
    title="Add your students"
    @onClose="handleCloseInvite"
  >
    <template #content>
      <div class="px-4">
        <div class="py-2 mt-5">
          <FormField label="Class Information">
            <ClassInformation />
          </FormField>
        </div>
        <div class="w-full mt-5 flex flex-row gap-x-2">
          <img src="@/assets/icon-copy-link.svg" />
          <AppTypographyText
            variant="bd"
            type="title"
            label="Invite Students to join your class with a Link"
            class="my-[8px]"
          />
        </div>
        <div class="w-full">
          <FormField v-model="link" label="Copy link" readonly />
        </div>
        <div class="w-full flex justify-end items-end py-2">
          <AppButton
            type="primary"
            @click="handleClickCopy"
            label="Copy Link"
          ></AppButton>
        </div>
      </div>
    </template>
    <template #footer>
      <div
        class="flex flex-row pt-5 border-t border-solid border-flohh-neutral-85"
      >
        <div class="flex-1 flex justify-start items-center">
          <AppButton text @click="handleClickSkipOrGoBack()">
            Go Back
          </AppButton>
        </div>
        <div class="flex-1 flex justify-end items-center gap-x-4">
          <AppButton text @click="handleClickSkipOrGoBack(1)" v-if="!isEdit">
            Skip this step
          </AppButton>
          <AppButton type="submit" @click="handleClickSave">
            <template #icon_left>
              <span v-html="icon.checkBlack"></span>
            </template>
            Save
          </AppButton>
        </div>
      </div>
    </template>
  </ModalUtility>
</template>
