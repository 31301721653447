import axios, { AxiosRequestConfig } from "axios";

const CLOUD_STORAGE_URL = "/cloud-storage";

interface GoogleDriveParams {
  fileId: string;
  mimeType: string;
  provider: string;
}
export default class CloudStorageService {
  getIdentificationToken() {
    return axios.get(`${CLOUD_STORAGE_URL}/identification-token`);
  }

  getGoogleDriveFile(params: GoogleDriveParams) {
    return axios.get("/cloud-storage/get-file", {
      params,
    });
  }
}
