<script lang="ts">
import AssignmentAdvanceSettingVisibility from "./AssignmentAdvanceSettingVisibility.vue";
import AssignmentAdvanceSettingStudentNotification from "./AssignmentAdvanceSettingStudentNotification.vue";
import AssignmentAdvanceSettingTaskInstruction from "./AssignmentAdvanceSettingTaskInstruction.vue";
import AssignmentAdvanceSettingCriteria from "./AssignmentAdvanceSettingCriteria.vue";
import AssignmentAdvanceSettingGrade from "./AssignmentAdvanceSettingGrade.vue";
import { Vue, Model, Component, Emit, Prop } from "vue-facing-decorator";
import { AdvanceSettings } from "../assignmentType";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { icons } from "@/utils/icons";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import emitter from "@/config/emitter";

@Component({
  components: {
    AssignmentAdvanceSettingCriteria,
    AssignmentAdvanceSettingGrade,
    AssignmentAdvanceSettingVisibility,
    AssignmentAdvanceSettingStudentNotification,
    AssignmentAdvanceSettingTaskInstruction,
    AppButton,
    AppTypographyText,
  },
})
export default class AssignmentAdvanceSettingsForm extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  loadingSaving!: boolean;

  @Model({
    type: Object,
    required: true,
  })
  settings!: AdvanceSettings;

  eventBus = emitter;
  loadingAdvanceSettings = false;
  icon = icons;

  @Emit("onClickSkipOrGoBack")
  handleClickSkipOrGoBack(index = 2) {
    return index;
  }

  @Emit("onClickAdvanceSetting")
  handleClickAdvanceSettings() {
    this.eventBus.emit("EVENT_TRIGGER", "AM006");
    return;
  }

  async onPostAdvanceSetting() {
    try {
      this.loadingAdvanceSettings = true;
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingAdvanceSettings = false;
    }
  }
}
</script>
<template>
  <div class="w-full">
    <div class="w-full flex justify-start items-start">
      <AppTypographyText label="Assignment release settings" />
    </div>
    <div class="flex flex-col w-full mt-6">
      <div class="w-full flex justify-start items-start flex-col">
        <AssignmentAdvanceSettingVisibility
          v-model="settings.studentVisibility"
        />
      </div>
      <div
        class="w-full flex justify-start items-start flex-col mt-5"
        v-if="false"
      >
        <AssignmentAdvanceSettingStudentNotification
          v-model="settings.notificationVisibility"
        />
      </div>
    </div>
    <div class="w-full flex justify-start items-start mt-6" v-if="false">
      <AppTypographyText label="Visibilty Settings" />
    </div>
    <div class="flex flex-col w-full mt-5">
      <div class="w-full flex justify-start items-start flex-col" v-if="false">
        <AssignmentAdvanceSettingTaskInstruction
          v-model="settings.instructions"
        />
      </div>
      <div
        class="w-full flex justify-start items-start flex-col mt-5"
        v-if="false"
      >
        <AssignmentAdvanceSettingCriteria v-model="settings.criteria" />
      </div>
      <div
        class="w-full flex justify-start items-start flex-col mt-5"
        v-if="false"
      >
        <AssignmentAdvanceSettingGrade v-model="settings.gradeVisibility" />
      </div>
    </div>
    <div
      class="w-full flex flex-col sm:flex-row mt-5 justify-center items-center pb-5"
    >
      <div class="flex-1 flex justify-start items-center">
        <AppButton text @click="handleClickSkipOrGoBack()"> Go Back </AppButton>
      </div>
      <div class="flex-1 justify-start items-start flex py-2">
        <div class="w-full flex justify-end items-center gap-x-4">
          <AppButton text @click="handleClickSkipOrGoBack(4)">
            Skip this step
          </AppButton>
          <AppButton
            type="submit"
            :loading="loadingSaving"
            :disabled="loadingSaving"
            @click="handleClickAdvanceSettings"
          >
            <template #icon_left>
              <span v-html="icon.checkBlack"></span>
            </template>
            Save
          </AppButton>
        </div>
      </div>
    </div>
  </div>
</template>
