<template>
  <div class="py-8 px-6 sticky top-[80px]">
    <AppTypographyText
      label="Where would you like to save your submission files?"
      variant="bd"
      type="title"
      class="mb-3"
    />
    <div class="flex flex-col gap-4">
      <FormField label="Class">
        <SelectClass
          v-model="selectedClassModel"
          :disabled="false"
          :key="refreshKey"
          :classLabel="classLabel"
          @onClassSelect="handleOnClassSelect"
          iconLeft="person"
          :defaultValue="defaultClass ? defaultClass : 'create'"
        />
      </FormField>
      <div v-if="selectedClassModel === 'create'">
        <AppTypographyText
          label="What should we call your class?"
          variant="bd"
          type="small"
          class="mb-4"
        />
        <FormField
          placeholder="Year 9 English"
          v-model="classNameModel"
          :background="false"
          :error="error.className"
          required
        />
      </div>
    </div>
    <hr class="mt-5 mb-1" />
    <div class="flex flex-col gap-4 w-full">
      <FormField label="Assignment">
        <SelectAssignment
          v-model="selectedAssignmentModel"
          :assignmentLabel="assignmentLabel"
          :classUuid="selectedClassModel"
          :disabled="selectedClassModel === 'create' ? true : false"
          @onAssignmentSelect="handleOnAssignmentSelect"
          :defaultValue="defaultAssignment ? defaultAssignment : 'create'"
        />
      </FormField>
      <div v-if="selectedAssignmentModel === 'create'">
        <AppTypographyText
          label="What should we call your assignment?"
          variant="bd"
          type="small"
          class="mb-4"
        />
        <FormField
          placeholder="Romeo and Juliet Essay"
          v-model="assignmentNameModel"
          :background="false"
          :error="error.assignmentName"
          required
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import ModalUtility from "@/components/utilities/ModalUtility.vue";
import FormRadioButton from "@/components/Layout/Forms/FormRadioButton.vue";
import DefaultFileUploader from "@/components/utilities/DefaultFileUploader.vue";
import GoogleDriveFileUploader from "@/components/utilities/GoogleDriveFileUploader.vue";
import FormField from "../Layout/Forms/FormField.vue";
import SelectClass from "@/components/utilities/SelectClass.vue";
import SelectAssignment from "@/components/utilities/SelectAssignment.vue";
import { ClassData } from "@/store/class/classTypes";
import { AssignmentDashboard } from "@/store/dashboard/dashboardTypes";
import emitter from "@/config/emitter";

@Component({
  components: {
    AppButton,
    AppTypographyText,
    ModalUtility,
    FormRadioButton,
    DefaultFileUploader,
    GoogleDriveFileUploader,
    SelectClass,
    FormField,
    SelectAssignment,
  },
})
export default class BulkUploadSidePanel extends Vue {
  eventBus = emitter;
  @Prop({
    type: Boolean,
    default: true,
    required: false,
  })
  canCreate!: boolean;

  @Prop({
    type: Object,
    required: true,
  })
  error!: { className: ""; assignmentName: "" };

  selectedClassModel = "";
  classNameModel = "";
  selectedAssignmentModel = "";
  assignmentNameModel = "";

  refreshKey = 0;
  classLabel = "+ New Class";
  assignmentLabel = "+ New Assignment";

  selectedClassObj!: ClassData;
  selectedAssignmentObj!: AssignmentDashboard;
  defaultClass = "";
  defaultAssignment = "";

  @Watch("canCreate")
  canCreateWatcher(value: boolean) {
    if (value) {
      // this.classLabel = "+ New Class";
      // this.assignmentLabel = "+ New Assignment";
    } else {
      // this.classLabel = "";
      // this.assignmentLabel = "";
    }
  }

  @Watch("selectedClassModel")
  selectedClassModelWatcher(value: string) {
    if (value === "create") {
      this.selectedAssignmentModel = "create";
    }
  }

  @Watch("classNameModel")
  classNameModelWatcher(value: string) {
    const classObj = structuredClone(this.selectedClassObj);
    classObj.uuid =
      this.selectedClassModel === "create" ? "create" : this.selectedClassModel;
    classObj.code = value;
    this.$emit("onClassSelect", classObj);
    this.eventBus.emit("UPDATE_BULK_UPLOAD_BREADCRUMBS", {
      classUuid: classObj.uuid,
      className: classObj.code,
    });
  }

  @Watch("assignmentNameModel")
  assignmentNameModelWatcher(value: string) {
    const assignmentObj = structuredClone(this.selectedAssignmentObj);
    assignmentObj.uuid =
      this.selectedAssignmentModel === "create"
        ? "create"
        : this.selectedAssignmentModel;
    assignmentObj.title = value;
    this.$emit("onAssignmentSelect", assignmentObj);
  }

  mounted() {
    this.defaultAssignment = this.$route.query.assignment
      ? (this.$route.query.assignment as string)
      : "";
    this.defaultClass = this.$route.query.class
      ? (this.$route.query.class as string)
      : "";
  }

  handleOnClassSelect(data: ClassData) {
    const classObj = structuredClone(data);
    if (data.uuid === "create") {
      classObj.code = "";
      this.$emit("onClassSelect", classObj);
      this.classNameModel = "";
    } else {
      this.$emit("onClassSelect", classObj);
      this.classNameModel = data.code;
    }
    this.selectedClassObj = classObj;
  }

  handleOnAssignmentSelect(data: AssignmentDashboard) {
    const assignmentObj = structuredClone(data);
    if (data.uuid === "create" || !data.uuid) {
      assignmentObj.title = "";
      assignmentObj.uuid = "create";
      this.$emit("onAssignmentSelect", assignmentObj);
      this.assignmentNameModel = "";
    } else {
      this.$emit("onAssignmentSelect", assignmentObj);
      this.assignmentNameModel = data.title;
    }
    this.selectedAssignmentObj = assignmentObj;
  }
}
</script>

<style scoped lang="scss"></style>
