<script lang="ts">
import Toast from "primevue/toast";
import { Vue, Component, Model, Prop, Emit, Watch } from "vue-facing-decorator";
import { useToast } from "primevue/usetoast";
import ProgressBar from "primevue/progressbar";
import CloudStorageService from "@/services/CloudStorageService";
import { AxiosResponse, AxiosProgressEvent } from "axios";
import { environment } from "@/environments/environment";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { icons } from "@/utils/icons";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import { getGoogleDriveFile } from "@/utils/googleDrive";
import { UploadProgress } from "@/components/utilities/utilitiesTypes";
import ProgressLoader from "../utilities/ProgressLoader.vue";

interface IGoogleDriveFilesDisplay {
  id: string;
  name: string;
}

@Component({
  components: {
    Toast,
    ProgressBar,
    AppButton,
    AppTypographyText,
    ProgressLoader,
  },
})
export default class GoogleDriveFileUploader extends Vue {
  private cloudStorageService = new CloudStorageService();
  icon = icons;

  @Prop({
    type: Boolean,
    default: false,
  })
  multiple!: boolean;

  @Prop({
    type: String,
    default: ".pdf,.docx,.doc,.jpg,.jpeg,.png",
  })
  acceptedFiles!: string;

  @Prop({
    type: String,
    default:
      "image/png,image/jpeg,image/jpg,application/pdf,application/vnd.google-apps.document",
  })
  gDriveAcceptedFiles!: string;

  @Prop({
    type: String,
    default: "Drag and Drop files here",
  })
  placeholder!: string;

  @Prop({
    type: String,
    default: "Support file types: PDF, JPG, Microsoft Word & Google Doc",
  })
  supportedFileTypes!: string;

  @Prop({
    type: Boolean,
    default: true,
  })
  showFileList!: boolean;

  @Prop({
    type: String,
    default: "Release to drop files here.",
  })
  draggingPlaceholder!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: Object,
    required: false,
    default: {
      fileName: "",
      progress: 0,
    },
  })
  uploadProgress!: UploadProgress;

  @Prop({
    type: Boolean,
    default: false,
  })
  startUploading!: boolean;

  @Model({
    type: Array,
    required: true,
    name: "selectedFiles",
  })
  selectedFilesModel!: File[];

  @Prop({
    type: Number,
    required: false,
    default: 0,
  })
  fileSizeLimit!: number; //mb

  @Prop({
    type: String,
    required: false,
    default: "",
  })
  fileSizeLimitPlaceholder!: string;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  hideGoogleDrive!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  fetchOnSubmit!: boolean; // If true the 'await this.cloudStorageService.getGoogleDriveFile()' should be done via submit button on where the files are needed to upload

  @Prop({
    type: Number,
    required: false,
    default: 25, // For multiple file upload
  })
  maxNumberOfFiles!: number;

  toast = useToast();
  isDragging = false;
  refreshKey = 0;
  intervalId!: any;
  googleAuthenticated = false;
  fileUploading = false;
  gDriveTokenExpiration = 0;

  fetchingFilePlaceholder = "";
  documents: IGoogleDriveFilesDisplay[] = [];
  documentsString = "[]";

  filesCompleted: string[] = [];

  @Watch("uploadProgress")
  uploadProgressWatcher(value: UploadProgress) {
    if (value.progress === 100) {
      this.filesCompleted.push(value.fileName);
    }
  }

  handleGetAuth() {
    return localStorage.getItem("cloudStorageData");
  }

  handleGetFileInfo() {
    return sessionStorage.getItem("cloudFileData");
  }

  getCurrentTimeStamp() {
    return new Date().getTime();
  }

  handleAuthenticatedCheck() {
    let isAuthenticated: boolean;
    const cloudStorageData = this.handleGetAuth();
    const currentTimeStamp = this.getCurrentTimeStamp();
    if (cloudStorageData) {
      const parsedData = JSON.parse(cloudStorageData);
      const expiration = parseInt(parsedData.tokenExpiresOn);
      if (currentTimeStamp >= expiration) {
        localStorage.removeItem("cloudFileData");
        isAuthenticated = false;
      } else {
        isAuthenticated = true;
      }
    } else {
      isAuthenticated = false;
    }
    if (isAuthenticated) {
      const cloudFileInfo = this.handleGetFileInfo();
      sessionStorage.removeItem("cloudFileData");
      if (cloudFileInfo) {
        this.handleGetDataFromGDrive(cloudFileInfo);
        // clearInterval(this.intervalId);
      }
    }
    return isAuthenticated;
  }

  startInterval() {
    this.intervalId = setInterval(() => {
      this.googleAuthenticated = this.handleAuthenticatedCheck();
    }, 1000);
  }

  stopInterval() {
    clearInterval(this.intervalId);
  }

  mounted() {
    const cloudStorageData = this.handleGetAuth();
    if (cloudStorageData) {
      const cloudStorageLocal = JSON.parse(cloudStorageData);
      this.gDriveTokenExpiration = parseInt(cloudStorageLocal.tokenExpiresOn);
    }
    this.googleAuthenticated = this.handleAuthenticatedCheck();
    this.startInterval();
  }

  unmounted() {
    this.stopInterval();
  }

  async cloudStorageAuthenticate() {
    try {
      const appUrl = window.location.origin;
      const redirectUrl = `${appUrl}/cloud-storage/authentication`;
      const response: AxiosResponse =
        await this.cloudStorageService.getIdentificationToken();
      if (response.data.ok) {
        const responseData = response.data.data;
        const authUrl = `${environment.apiEndpoint}/cloud-storage/authenticate/google?token=${responseData.token}&successRedirectUrl=${redirectUrl}&failedRedirectUrl=${redirectUrl}`;
        Object.assign(document.createElement("a"), {
          target: "_blank",
          rel: "noopener noreferrer",
          href: authUrl,
        }).click();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async handleGetDataFromGDrive(document: string) {
    this.fileUploading = true;
    if (this.fetchOnSubmit) {
      this.pushGoogleDriveFiles(document);

      const documentArray = JSON.parse(this.documentsString);
      const documentClone = JSON.parse(document);
      let fileCount = documentClone.length;
      for (let i = 0; i < documentClone.length; i++) {
        const document = documentClone[i];
        if (fileCount < this.maxNumberOfFiles) {
          documentArray.push(document);
          fileCount++;
        }
      }
      const updatedDocument = JSON.stringify(documentArray);

      this.documentsString = updatedDocument;
      this.$emit("onDocumentsUpload", updatedDocument); // string
    } else {
      await this.fetchDataFromGoogleDrive(document);
    }
    this.fileUploading = false;
  }

  async fetchDataFromGoogleDrive(document: string) {
    try {
      let maxFileCountExeeded = false;
      let fileArray: File[] = [];
      if (this.multiple) {
        fileArray = this.selectedFilesModel;
      }
      const fileData = JSON.parse(document);
      let fileCount = fileData.length;

      for (let i = 0; i < fileData.length; i++) {
        const currentFile = fileData[i];
        if (fileCount + 1 > this.maxNumberOfFiles) {
          maxFileCountExeeded = true;
        } else {
          const gDriveFile = await getGoogleDriveFile(currentFile);
          fileArray.push(gDriveFile);
          fileCount++;
        }
      }

      if (maxFileCountExeeded) {
        this.toast.add({
          severity: "error",
          summary: "Max file count limit exceeded",
          detail: `You can only upload maximum of ${this.maxNumberOfFiles} files per batch`,
          life: 6000,
        });
      }
      this.selectedFilesModel = Array.from(fileArray);
    } catch (err) {
      console.error(err);
    }
  }

  async pushGoogleDriveFiles(document: string) {
    try {
      let maxFileCountExeeded = false;
      let documentsArray: IGoogleDriveFilesDisplay[] = [];
      if (this.multiple) {
        documentsArray = this.documents;
      }
      const fileData = JSON.parse(document);
      let fileCount = fileData.length;
      for (let i = 0; i < fileData.length; i++) {
        const currentFile = fileData[i];
        if (fileCount + 1 > this.maxNumberOfFiles) {
          maxFileCountExeeded = true;
        } else {
          const fileExtension =
            currentFile.mimeType === "application/pdf" ? ".pdf" : ".docx";

          const fileName = `${currentFile.name.split(".")[0]}${fileExtension}`;

          documentsArray.push({ id: currentFile.id, name: fileName });
          fileCount++;
        }
      }

      if (maxFileCountExeeded) {
        this.toast.add({
          severity: "error",
          summary: "Max file count limit exceeded",
          detail: `You can only upload maximum of ${this.maxNumberOfFiles} files per batch`,
          life: 6000,
        });
      }
      this.documents = documentsArray;
    } catch (err) {
      console.error(err);
    }
  }

  async handleClickBrowseCloudStorage() {
    try {
      this.cloudStorageAuthenticate();
    } catch (error) {
      console.error(error);
    }
  }

  @Emit("onRemoveFile")
  handleClickRemoveFile(index: number) {
    if (this.fetchOnSubmit) {
      this.documents.splice(index, 1);

      const parsedDocumentsString = JSON.parse(this.documentsString);
      const updatedDocumentsString = parsedDocumentsString.filter(
        (item: any, i: number) => index !== i
      );
      const documentsString = JSON.stringify(updatedDocumentsString);
      this.$emit("onDocumentsUpload", documentsString); // string
      this.documentsString = documentsString;
    } else {
      this.selectedFilesModel.splice(index, 1);
    }
    return index;
  }
}
</script>
<template>
  <div
    class="w-full rounded-[8px] overflow-hidden border-solid border border-flohh-neutral-85 relative"
  >
    <div
      class="px-5 py-[0.4em] border-b border-solid border-b-flohh-neutral-85"
    >
      <img src="@/assets/google-drive-full.svg" />
    </div>
    <div class="flex flex-col justify-center items-center py-9">
      <p
        class="text-flohh-text-body font-flohh-font-medium text-flohh-neutral-20 mt-2"
      >
        <strong>Select a file from your Google Drive</strong>
      </p>
      <p
        class="text-flohh-text-caption font-flohh-font-medium text-flohh-neutral-20 mt-2"
      >
        {{ supportedFileTypes }}
      </p>
      <p
        v-if="fileSizeLimitPlaceholder"
        class="text-flohh-text-caption font-flohh-font-medium text-flohh-neutral-20"
      >
        {{ fileSizeLimitPlaceholder }}
      </p>
      <AppButton
        v-if="!googleAuthenticated"
        class="font-flohh-font-medium text-flohh-text-body mt-5 cursor-pointer g-drive-btn-bg"
        :class="disabled ? 'cursor-not-allowed' : ''"
        @click="handleClickBrowseCloudStorage"
        whiteLabel
      >
        Select file
      </AppButton>
      <AppButton
        v-else
        class="font-flohh-font-bold text-flohh-text-body mt-5 cursor-pointer g-drive-btn-bg"
        :class="`${disabled ? 'cursor-not-allowed' : ''} ${
          fileUploading ? 'pointer-events-none' : ' '
        }`"
        onclick="createPicker(event)"
        :data-multiple="multiple"
        :data-filetype="gDriveAcceptedFiles"
        whiteLabel
      >
        Select File
      </AppButton>
    </div>
    <div
      class="absolute bg-gray-500 bg-opacity-40 w-full h-full animate-pulse top-0"
      v-if="disabled"
    ></div>
    <div
      v-if="fileUploading"
      class="py-7 flex justify-center absolute top-[150px] left-[50%] bg-white w-[90%] translate-y-[-50%] translate-x-[-50%]"
    >
      <ProgressLoader
        label="Getting Files From Google Drive"
        labelType="caption"
      />
    </div>
  </div>
  <div
    class="w-full flex-col gap-2 max-h-[220px] overflow-y-auto mt-5"
    v-if="
      (showFileList && selectedFilesModel && selectedFilesModel.length) ||
      (showFileList && documents && documents.length)
    "
  >
    <div
      v-for="(file, index) in fetchOnSubmit ? documents : selectedFilesModel"
      :key="index"
      class="flex w-full rounded-[8px] justify-center items-center bg-flohh-neutral-95 px-[1.6em] py-4 mt-4"
    >
      <div class="w-[95%]">
        <div class="flex justify-between">
          <AppTypographyText variant="md" type="caption" :label="file.name" />
          <AppTypographyText
            v-if="
              uploadProgress.fileName === file.name.split('.')[0] ||
              filesCompleted.includes(file.name.split('.')[0])
            "
            variant="md"
            type="caption"
            :label="`${
              filesCompleted.includes(file.name.split('.')[0])
                ? 100
                : uploadProgress.progress
            }%`"
          />
        </div>
        <div
          v-if="
            uploadProgress.fileName === file.name.split('.')[0] ||
            filesCompleted.includes(file.name.split('.')[0])
          "
          class="w-full bg-flohh-neutral-85 rounded-full dark:bg-flohh-neutral-85 mt-2"
        >
          <div
            class="bg-flohh-secondary-green text-xs font-medium text-white text-center p-0.5 leading-none rounded-full"
            :style="{
              width: `${
                filesCompleted.includes(file.name.split('.')[0])
                  ? 100
                  : uploadProgress.progress
              }%`,
            }"
          ></div>
        </div>
      </div>
      <div class="w-[5%] flex justify-end items-center cursor-pointer">
        <span
          v-html="icon.closeBlack"
          @click="handleClickRemoveFile(index)"
        ></span>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.picker-dialog {
  &-bg {
    z-index: 5000 !important;
  }
  z-index: 5001 !important;
}
.g-drive-btn-bg {
  background-color: #007bff !important;

  &:hover {
    filter: brightness(90%);
  }
}
</style>
