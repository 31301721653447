<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-facing-decorator";
import { icons as AppIcons } from "@/utils/icons";
import { Navigation } from "@/utils/navigations";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import NavigationClassList from "@/components/Classes/navigation/NavigationClassList.vue";
import emitter from "@/config/emitter";
import AppTypographyText from "../Typhography/AppTypographyText.vue";

@Component({
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    NavigationClassList,
    AppTypographyText,
  },
})
export default class SidePanelNavigation extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  navigation!: Navigation[];

  @Prop({
    type: Array,
    required: true,
  })
  roles!: string[];

  icons = AppIcons;

  selectedNavigation!: string;
  visible = false;
  navigateTo = "#";
  classNavOpen = false;
  eventBus = emitter;

  navItemHovered = "";
  selectedNavItem = "";

  handleClickDropdown(navigation: string) {
    this.visible = !this.visible;
    this.selectedNavigation = navigation;
  }

  @Watch("$route")
  routerWatcher(value: any) {
    if (value) {
      this.selectedNavigation = value.name;
      this.visible = !this.visible;
    }
  }

  handleSelected(name: string) {
    this.selectedNavItem = name;
  }

  redirectToVersionPage() {
    this.$router.push({
      name: "Versioning",
    });
  }

  handleNavigate(
    routeName: string,
    param: Record<string, any>,
    query: Record<string, any>
  ) {
    this.handleSelected(routeName);
    this.$router.push({
      name: routeName,
      params: param,
      query: query,
    });
  }

  handleClassSelect(data: { route: string; classUuid: string }) {
    this.handleNavigate(data.route, { id: data.classUuid }, {});
  }

  handleHoverNavItem(e: Event, index: number, name: string) {
    const parent = e.target;
    if (parent) {
      let subNav = document.getElementById(`child-${index}`);
      if (subNav) {
        this.navItemHovered = name;
        subNav.classList.add("expanded");
      }
    }
  }

  handleUnhoverNavItem(e: Event, index: number) {
    const parent = e.target;
    if (parent) {
      let subNav = document.getElementById(`child-${index}`);
      if (subNav) {
        this.navItemHovered = "";
        subNav.classList.remove("expanded");
      }
    }
  }
}
</script>
<template>
  <nav class="flex flex-1 flex-col">
    <ul role="list" class="flex flex-1 flex-col gap-y-7">
      <li class="flex flex-col gap-2">
        <div
          v-for="(navItem, i) in navigation"
          :key="i"
          class="w-full relative"
        >
          <div
            v-if="!navItem.isHidden"
            :class="[
              navItem.path === $route.path ||
              navItemHovered === navItem.name ||
              selectedNavItem === navItem.name
                ? 'bg-flohh-primary-pink'
                : 'text-gray-700 hover:text-gray-600 hover:bg-flohh-primary-pink',
              'cursor-pointer w-full group flex gap-x-3 rounded-md px-[20px] py-[12px] font-flohh-font-medium text-flohh-text-body',
            ]"
            @click="handleNavigate(navItem.route, {}, {})"
            @mouseenter="handleHoverNavItem($event, i, navItem.name)"
            @mouseleave="handleUnhoverNavItem($event, i)"
          >
            <span
              v-html="navItem.icon"
              aria-hidden="true"
              class="[&>svg]:w-[20px] [&>svg]:h-[auto]"
            />
            {{ navItem.name }}
            <span
              v-if="
                (navItem.children && navItem.children.length !== 0) ||
                navItem.name === 'Classes'
              "
              v-html="icons.dropdownIconBlack"
              aria-hidden="true"
              class="ml-auto"
            />
          </div>
          <!-- Sub navigation -->
          <div
            v-if="navItem.name === 'Classes'"
            class="bg-flohh-primary-pink animate-height absolute rounded-bl-lg rounded-br-lg w-full"
            :id="`child-${i}`"
            @mouseenter="handleHoverNavItem($event, i, navItem.name)"
            @mouseleave="handleUnhoverNavItem($event, i)"
          >
            <NavigationClassList
              v-if="navItemHovered === navItem.name"
              @onClassSelect="handleClassSelect"
            />
          </div>
          <div
            v-else-if="navItem.children.length > 0"
            class="bg-flohh-primary-pink animate-height absolute rounded-bl-lg rounded-br-lg w-full"
            :id="`child-${i}`"
            @mouseenter="handleHoverNavItem($event, i, navItem.name)"
            @mouseleave="handleUnhoverNavItem($event, i)"
          >
            <div class="px-[10px] py-[10px] flex flex-col gap-1">
              <router-link
                :to="subNav.path"
                :class="[
                  subNav.path === $route.path
                    ? 'bg-white rounded-[8px]'
                    : 'text-black-700 hover:text-black-600 hover:bg-white rounded-[8px]',
                  'cursor-pointer group flex gap-x-3 justify-start px-[10px] py-[10px] items-center text-sm font-flohh-font-medium text-flohh-text-caption',
                ]"
                v-for="(subNav, index) in navItem.children"
                :key="index"
                @click="handleSelected(subNav.parent)"
              >
                {{ subNav.name }}
              </router-link>
            </div>
          </div>
        </div>
      </li>
      <li
        class="mt-auto -mx-2 mb-12"
        v-tooltip.right="{ value: 'Help', showDelay: 500 }"
      >
        <router-link
          to="/help"
          class="hover:bg-flohh-primary-pink group flex gap-x-3 rounded-md justify-start items-center px-[20px] py-[12px] text-sm font-flohh-font-medium text-flohh-text-body"
          @click="eventBus.emit('EVENT_TRIGGER', 'G014')"
        >
          <span v-html="icons.helpBlack" aria-hidden="true" />
          Help
        </router-link>
      </li>
      <li class="mb-[30%]">
        <router-link
          to="/app/version"
          class="app-info"
          @click="eventBus.emit('EVENT_TRIGGER', 'G015')"
        >
          <span
            v-html="icons.infoCircleBlack"
            v-tooltip="{
              value: 'App Information',
              autoHide: false,
            }"
          ></span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<style lang="scss" scoped>
.app-info {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 3px;
  padding-left: 11px;
  p {
    font-size: 10px;
  }
}
.animate-height {
  max-height: 0;
  transition: max-height 0.4s ease-out;
  overflow: hidden;
  z-index: 2;
  &.expanded {
    max-height: 400px;
    z-index: 50;
  }
}
</style>
