<template>
  <ModalUtility v-model="visible" :title="modalTitle" :onClose="closeModal">
    <template #content>
      <div class="bg-white px-4">
        <div class="flex flex-col w-full justify-center items-center py-8">
          <p class="text-sm mb-4">
            Please enter the verification code sent to your email.
          </p>
          <div
            class="verification-form flex w-full justify-center items-center"
          >
            <InputTextComponent
              v-for="(input, index) in inputs"
              type="tel"
              :key="index"
              v-model="input.value"
              @input="(e: InputEvent) => {
                handleInput(e, index)
              }"
              @keyup="(e: KeyboardEvent) => {
                if(e.code === 'Backspace') {
                  const prevIndex = index - 1;
                  if (prevIndex < inputs.length && prevIndex > -1) {
                    const field: any = inputFields[prevIndex];
                    field.$el.focus();
                  }
                }
              }"
              maxlength="1"
              ref="inputFields"
              size="small"
              placeholder=""
              class="!w-[40px] h-[40px] text-center mx-1"
              focusable
              @paste="handlePaste"
            />
          </div>
          <p class="text-xs mt-4">Haven't received the verification code?</p>
          <AppButton
            text
            class="!py-1 !h-[auto] text-xs text-flohh-primary-pink"
            @click="handleResend"
            >Resend</AppButton
          >
          <p class="text-xs mt-4 text-red-600" v-if="verificationError.show">
            {{ verificationError.message }}
          </p>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex w-full justify-center items-center">
        <AppButton type="submit" @click="handleVerify" :loading="isVerifying">
          <template #icon_left>
            <span v-html="icon.checkBlack" class="pr-2"></span>
          </template>
          {{ isVerifying ? "Verifying" : "Verify" }}
        </AppButton>
      </div>
    </template>
  </ModalUtility>
</template>

<script lang="ts">
import { Component, Prop, Vue, Model, Emit, Ref } from "vue-facing-decorator";

import ModalUtility from "@/components/utilities/ModalUtility.vue";
import InputTextComponent from "@/components/utilities/InputTextComponent.vue";
import { icons as AppIcons } from "@/utils/icons";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";

interface IverificationError {
  show: boolean;
  message: string;
}

interface IInputs {
  value: string;
}

@Component({
  components: {
    ModalUtility,
    InputTextComponent,
    AppButton,
  },
})
export default class ActivationModalComponent extends Vue {
  @Model({
    type: Boolean,
    required: true,
  })
  visible!: boolean;

  @Prop({
    type: Object,
    required: true,
  })
  verificationError!: IverificationError;

  @Prop({
    type: Array,
    required: true,
  })
  inputs!: IInputs[];

  @Prop({
    type: String,
    required: true,
  })
  modalTitle!: "";

  @Prop({
    type: Boolean,
    required: true,
  })
  isVerifying!: boolean;

  // @Prop({
  //   type: Array,
  //   required: true,
  // })
  // inputFields!: HTMLInputElement[];

  // @Prop({
  //   type: Function,
  //   required: true,
  // })
  // public handleInput!: (index: number) => void;

  @Prop({
    type: Function,
    required: true,
  })
  public handlePaste!: () => void;

  @Prop({
    type: Function,
    required: true,
  })
  public handleResend!: () => void;

  @Prop({
    type: Function,
    required: true,
  })
  public handleVerify!: () => void;

  @Prop({
    type: Function,
    required: true,
  })
  public handleTest!: () => void;

  @Ref() readonly inputFields!: HTMLInputElement[];

  icon = AppIcons;

  @Emit("onClose")
  closeModal() {
    return;
  }

  handleInput(event: InputEvent, index: number) {
    if (event.inputType !== "deleteContentBackward") {
      const nextIndex = index + 1;
      if (nextIndex < this.inputs.length) {
        const field: any = this.inputFields[nextIndex];
        field.$el.focus();
      }
    }
  }
}
</script>
