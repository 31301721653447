<template>
  <div class="colored-container p-3 mb-5">
    <div class="bg-white rounded-lg h-full flex flex-col p-[30px]">
      <div class="flex items-end gap-3 mb-10">
        <img src="../../assets/duck.png" />
        <div>
          <h6 class="text-[24px] leading-[1.5] text-[#333] font-bold">
            Hi {{ firstName }}!
          </h6>
          <h6 class="text-flohh-h6 font-flohh-font-bold !text-[#333]">
            What would you like to do?
          </h6>
        </div>
      </div>
      <div class="grid gap-5 justify-center grid-cols-4">
        <AppCardButton
          class=""
          label="Upload Submissions"
          variant="bd"
          type="body"
          @click="
            () => {
              // handleRedirect('/bulk-upload/digital');
              isOpen = !isOpen;
            }
          "
        />
        <AppCardButton
          class=""
          label="Create a class submission link"
          variant="bd"
          color="yellow"
          type="body"
          @click="
            () => {
              if (handleSubmissionModal) {
                handleSubmissionModal();
              }
            }
          "
        />
        <AppCardButton
          class=""
          label="Create a new class"
          variant="bd"
          color="purple"
          type="body"
          @click="toggleCreateClass"
        />
        <AppCardButton
          class=""
          label="Create an assignment"
          variant="bd"
          color="green"
          type="body"
          @click="
            () => {
              handleRedirect('/assignments/create');
            }
          "
        />
      </div>
    </div>
  </div>
  <CreateClassPopup v-model="isAddClassOpen" :isEdit="false" />

  <UploadSubmissionModal v-model="isOpen" />
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import { GridLayout, GridItem } from "grid-layout-plus";
import AppButton from "../Layout/Buttons/AppButton.vue";
import { icons as AppIcons, icons } from "@/utils/icons";
import emitter from "@/config/emitter";
import {
  AssignmentDashboard,
  StudentDashboard,
  PendingActions,
} from "@/store/dashboard/dashboardTypes";
import AppTypographyText from "../Layout/Typhography/AppTypographyText.vue";
import DashboardService from "@/services/DashboardService";
import axios, { AxiosResponse } from "axios";
import AppCardButton from "@/components/Layout/Buttons/AppCardButton.vue";
import ModalUtility from "../utilities/ModalUtility.vue";
import CreateClassPopup from "../CreateClass/CreateClassPopup.vue";
import UploadSubmissionModal from "../utilities/UploadSubmissionModal.vue";
@Component({
  components: {
    AppCardButton,
    ModalUtility,
    CreateClassPopup,
    UploadSubmissionModal,
  },
})
export default class DashboardHeaderComponent extends Vue {
  isAddClassOpen = false;
  @Prop({
    type: String,
    default: false,
  })
  firstName!: string;
  @Prop({
    type: Function,
  })
  handleSubmissionModal!: () => void;

  isOpen = false;

  async mounted() {
    //
  }

  toggleCreateClass() {
    this.isAddClassOpen = !this.isAddClassOpen;
  }

  createAssignment() {
    //assignments/create
    this.$router.push({
      path: "/assignments/create",
    });
  }

  handleRedirect(path: string) {
    this.$router.push({
      path: path,
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.colored-container {
  background-image: url("../../assets/rainbow-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  // min-height: 315px;
  width: 100%;
  border-radius: 8px;
}
</style>
