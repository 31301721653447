<script lang="ts">
import Calendar from "primevue/calendar";
import { Vue, Component, Model, Prop, Watch, Emit } from "vue-facing-decorator";
import ModalUtility from "@/components/utilities/ModalUtility.vue";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { icons } from "@/utils/icons";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import FormInputDropdown from "@/components/Layout/Forms/FormInputDropdown.vue";
import { generateHourList, generateMinuteList } from "@/utils/timeGenerator";
import Dropdown from "primevue/dropdown";
import { converToTwentyFourHoursFormat } from "@/utils/helper";
import { formatDate, formatTimezoneOffset } from "@/utils/formatter";
import FormErrorSpan from "@/components/Layout/Forms/FormErrorSpan.vue";
import TIMEZONES from "@/utils/timezones.json";

interface Timezone {
  value: string;
  abbr: string;
  offset: number;
  isdst: boolean;
  text: string;
  utc: string[];
}

@Component({
  components: {
    Calendar,
    ModalUtility,
    AppButton,
    AppTypographyText,
    FormInputDropdown,
    Dropdown,
    FormErrorSpan,
  },
})
export default class FormDateTimePicker extends Vue {
  icon = icons;
  @Prop({
    type: Boolean,
    required: true,
  })
  visible!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  invalid!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  disablePast!: boolean;

  @Model({
    type: [Date, String],
    default: "",
  })
  selectedDate!: string | Date;

  @Prop({
    type: String,
    default: "",
  })
  selectedTimezone!: string;

  @Prop({
    type: Boolean,
    default: true,
  })
  background!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  isEdit!: boolean;

  // For birthdays and other dates
  minDate = new Date(1900, 1, 1);
  isModalOpen = false;

  hours = generateHourList();
  hour = "07";
  minutes = generateMinuteList();
  minute = "00";
  clocks = [
    { label: "AM", value: "AM" },
    { label: "PM", value: "PM" },
  ];
  clock = "AM";
  timezone = "";
  timezones = TIMEZONES;
  timezoneOffset = "";
  timezoneOffsetNumber = 0;
  timezoneAbbr = "";
  dateTime = "";
  displayedDateTime = "";
  timezoneError = "";
  browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  @Watch("selectedDate")
  selectedDateWatcher(value: string | Date) {
    if (!value) {
      this.displayedDateTime = "";
    }
  }

  mounted() {
    this.handleSetDefaultTimezone();
    if (this.disablePast) {
      this.minDate = new Date();
    }

    if (this.isEdit) {
      this.handlePreSetDateTime(new Date(this.selectedDate));
      this.handleDisplayedDateTime(this.selectedDate);
    } else {
      this.handlePreSetDateTime(new Date(this.selectedDate));
      this.handleClickSave(this.selectedDate);
    }
  }

  handleSetDefaultTimezone(selectedTimezone = "") {
    const currentTimeZone = this.timezones.find((timezone: Timezone) => {
      if (selectedTimezone) {
        return timezone.value === selectedTimezone;
      } else {
        return timezone.utc.includes(this.browserTimezone);
      }
    });

    if (currentTimeZone) {
      this.timezone = currentTimeZone.value;
      this.timezoneOffset = formatTimezoneOffset(currentTimeZone.offset);
      this.timezoneOffsetNumber = currentTimeZone.offset;
      this.timezoneAbbr = currentTimeZone.abbr;
    }
  }

  handlePreSetDateTime(now = new Date()) {
    let hours = now.getHours();
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;

    const formattedHours = String(hours).padStart(2, "0");

    this.hour = formattedHours;
    this.minute = minutes;
    this.clock = ampm;
    this.selectedDate = now;
  }

  handleDisplayedDateTime(selected: string | Date) {
    const date = new Date(selected);
    const day = formatDate(date.toDateString(), "DD", false);
    const month = formatDate(date.toDateString(), "MMM", false);
    const year = formatDate(date.toDateString(), "YYYY", false);

    const formattedDate = `${day} ${month} ${year}`;
    const dateTime = `${formattedDate} | ${this.hour}:${this.minute} ${this.clock}`;
    this.displayedDateTime = dateTime;
  }

  handleFormValidation() {
    let validTimezone = true;
    if (!this.timezone) {
      this.timezoneError = "Invalid Timezone";
      validTimezone = false;
    } else {
      this.timezoneError = "";
    }
    return validTimezone;
  }

  handleClickSave(selected: string | Date) {
    const isFormValid = this.handleFormValidation();
    if (isFormValid) {
      const date = formatDate(this.selectedDate as string, "YYYY-MM-DD");
      const time = converToTwentyFourHoursFormat(
        `${this.hour}:${this.minute} ${this.clock}`
      );
      const offset = this.timezoneOffset;
      const dateTime = `${date}T${time}${offset}`;
      this.dateTime = dateTime;
      this.handleDisplayedDateTime(selected ? selected : this.selectedDate);
      this.$emit("onSelect", {
        dateTime: dateTime,
        timezoneName: this.timezone,
      });
      this.isModalOpen = false;
      this.handleCloseModal();
    }
  }

  @Watch("visible")
  visibleWatcher(value: boolean) {
    if (!value) {
      this.timezone = "";
      this.timezoneOffset = "";
      this.timezoneAbbr = "";
    } else {
      if (!this.isEdit) {
        this.handlePreSetDateTime();
      }
      this.handleSetDefaultTimezone();
    }
    this.isModalOpen = value;
  }

  // @Watch("timezone")
  // timezoneWatcher(value: string) {
  //   const currentTimeZone = this.timezones.find(
  //     (timezone: Timezone) => timezone.value === value
  //   );
  //   if (currentTimeZone) {
  //     this.timezoneOffset = formatTimezoneOffset(currentTimeZone.offset);
  //     this.timezoneAbbr = currentTimeZone.abbr;
  //   }
  // }

  handleCloseModal() {
    this.handleToggleDateTimeModal(false);
    this.$emit("onClose");
  }

  handleToggleDateTimeModal(value: boolean) {
    this.isModalOpen = value;
  }

  handleSetCurrent() {
    this.handlePreSetDateTime();
    this.handleSetDefaultTimezone();
  }
}
</script>
<template>
  <AppTypographyText
    v-if="displayedDateTime"
    variant="bd"
    type="subtitle"
    :label="displayedDateTime"
  />
  <AppTypographyText
    @click="handleToggleDateTimeModal(true)"
    variant="rg"
    type="subtitle"
    :label="dateTime ? 'Change Date & Time' : 'Set Date and Time'"
    class="underline cursor-pointer"
  />
  <ModalUtility
    v-model="isModalOpen"
    width="34%"
    title="Set Due Date"
    @onClose="handleCloseModal"
  >
    <template #content>
      <div class="px-7">
        <Calendar
          v-model="selectedDate"
          inline
          :minDate="minDate"
          :class="['w-full date-time-picker']"
        />
        <hr class="mb-5" />
        <div class="mb-5">
          <AppTypographyText
            variant="bd"
            type="title"
            label="Time"
            class="mb-5"
          />
          <div class="flex gap-3 items-center">
            <div class="w-[67px] date-time-dropdown">
              <Dropdown
                v-model="hour"
                :options="hours"
                optionLabel="label"
                option-value="value"
                :class="['h-[37px]']"
              >
                <template #dropdownicon>
                  <span v-html="icon.dropdownIconBlack" /> </template
              ></Dropdown>
            </div>
            <AppTypographyText variant="md" type="body" label=":" />
            <div class="w-[67px] date-time-dropdown">
              <Dropdown
                v-model="minute"
                :options="minutes"
                optionLabel="label"
                option-value="value"
                :class="['h-[37px]']"
              >
                <template #dropdownicon>
                  <span v-html="icon.dropdownIconBlack" /> </template
              ></Dropdown>
            </div>
            <AppTypographyText variant="md" type="body" label=":" />
            <div class="w-[70px] date-time-dropdown">
              <Dropdown
                v-model="clock"
                :options="clocks"
                optionLabel="label"
                option-value="value"
                :class="['h-[37px]']"
              >
                <template #dropdownicon>
                  <span v-html="icon.dropdownIconBlack" /> </template
              ></Dropdown>
            </div>
          </div>
        </div>
        <!-- <div class="mb-5">
          <AppTypographyText
            variant="bd"
            type="title"
            label="Timezone"
            class="mb-5"
          />
          <FormInputDropdown
            filter
            v-model="timezone"
            :options="timezones"
            option-value="value"
            optionLabel="text"
            :background="false"
            height="50px"
            :invalid="timezoneError ? true : false"
          />
          <FormErrorSpan v-if="timezoneError">
            {{ timezoneError }}
          </FormErrorSpan>
        </div> -->
      </div>
    </template>
    <template #footer>
      <div class="flex pt-5 border-t border-solid border-flohh-neutral-85">
        <div class="flex-1 flex justify-start items-center">
          <AppButton @click="handleSetCurrent" text blackLabel>
            Set to Current
          </AppButton>
        </div>
        <div class="flex-1 flex justify-end items-center gap-x-4">
          <AppButton type="submit" @click="handleClickSave('')">
            <template #icon_left>
              <span v-html="icon.checkBlack"></span>
            </template>
            Save
          </AppButton>
        </div>
      </div>
    </template>
  </ModalUtility>
</template>
<style lang="scss">
.date-time-picker {
  .p-datepicker {
    border: none;
    table td.p-datepicker-today > span {
      background-color: transparent;
      color: #f9b2ce;
    }

    table td > span {
      border-radius: 8px;
      border: none;
      &:hover {
        color: #ffffff;
        background-color: #f9b2ce;
        border-radius: 8px;
      }
    }
    .p-datepicker-header {
      border-bottom: none;
    }
    thead {
      background-color: #f2f2f2;
    }
  }
}

.date-time-dropdown {
  .p-dropdown {
    width: 100%;
    .p-dropdown-label {
      padding: 0px 0px 0px 1em;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .p-dropdown-trigger {
      width: 2rem;
    }
  }
}
</style>
