import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-end items-center gap-[5px]" }
const _hoisted_2 = { class: "w-[280px] mr-1" }
const _hoisted_3 = { class: "max-w-[240px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormInputDropdown = _resolveComponent("FormInputDropdown")!
  const _component_FormTextInput = _resolveComponent("FormTextInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FormInputDropdown, {
        modelValue: _ctx.sortBy,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sortBy) = $event)),
        options: _ctx.sortData,
        "option-value": "value",
        optionLabel: "label",
        placeholder: "Sort By",
        background: false
      }, null, 8, ["modelValue", "options"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_FormTextInput, {
        type: "text",
        name: "search",
        modelValue: _ctx.searchText,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchText) = $event)),
        onInput: _ctx.handleSearch,
        placeholder: "Search...",
        iconLeft: 'searchBlack',
        background: false
      }, null, 8, ["modelValue", "onInput"])
    ])
  ]))
}