<template>
  <div class="flex justify-end items-center gap-[5px]">
    <div class="w-[280px] mr-1">
      <FormInputDropdown
        v-model="sortBy"
        :options="sortData"
        option-value="value"
        optionLabel="label"
        placeholder="Sort By"
        :background="false"
      >
      </FormInputDropdown>
    </div>
    <div class="max-w-[240px]">
      <FormTextInput
        type="text"
        name="search"
        v-model="searchText"
        @input="handleSearch"
        placeholder="Search..."
        :iconLeft="'searchBlack'"
        :background="false"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Model, Watch } from "vue-facing-decorator";
import FormTextInput from "@/components/Layout/Forms/FormTextInput.vue";
import { icons as AppIcons } from "@/utils/icons";
import FormInputDropdown from "@/components/Layout/Forms/FormInputDropdown.vue";
import { SortDefault, Sort } from "../type";

@Component({
  components: { FormTextInput, FormInputDropdown },
})
export default class TabUtilityComponent extends Vue {
  icons = AppIcons;

  @Prop({
    type: Object,
    required: false,
  })
  sortDefault!: SortDefault;

  @Prop({
    type: String,
    required: false,
  })
  classView!: string;

  searchText = "";
  sortBy = "title_alphabetical";
  sortData!: Sort[];

  mounted() {
    this.sortData = this.sortDefault.sortData;
  }

  @Watch("sortBy")
  handleSort(value: string) {
    this.$emit("onSort", value);
  }

  @Emit("onSearch")
  handleSearch() {
    return this.searchText;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
