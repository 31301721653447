<template>
  <div class="p-8">
    <p
      class="text-flohh-neutral-20 text-flohh-h6 font-flohh-font-bold mb-[10px]"
    >
      Oops! Page Not Found
    </p>
    <p class="text-flohh-neutral-20 text-[16px] font-normal mb-[25px]">
      Looks like you took a wrong turn! The page you're looking for might have
      been moved or deleted.
    </p>
    <button @click="goToDashboard" class="text-[16px] text-[#333] underline">
      Go to {{ authenticatedAs === "super admin" ? "back" : "dashboard" }}
    </button>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

@Component({
  components: {},
})
export default class PageNotFoundView extends Vue {
  /**
   * mounted
   */

  authenticatedAs = "";
  isTeacher = false;
  public mounted() {
    //
    this.authenticatedAs = localStorage.getItem("authenticatedAs") || "";
  }

  /**
   * destroyed
   */
  public destroyed() {
    //
  }

  goToDashboard() {
    if (this.authenticatedAs === "super admin") {
      this.$router.back();
    } else if (this.authenticatedAs === "student") {
      this.$router.push("/student/dashboard");
    } else {
      this.$router.push("/dashboard");
    }
  }
}
</script>

<style>
body {
  margin: 0;
}
</style>
