import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { converToTwelveHoursFormat } from "./helper";

export function formatDate(
  date: string,
  format = "MM/DD/YYYY",
  convertToLocale = true
): string {
  const stringDate = convertToLocale ? new Date(date) : date;
  if (!date) return "";
  if (typeof date === "string") {
    return dayjs(stringDate).format(format);
  }
  return dayjs(date).format(format);
}

export function getTimeFromString(time: string): string {
  // Sample parameter 2024-25-12T05:00:00-10:00
  let newTime = "";
  const splitTime = time.split("T")[1];

  if (splitTime.includes("-")) {
    newTime = splitTime.split("-")[0];
  } else {
    newTime = splitTime.split("+")[0];
  }
  return converToTwelveHoursFormat(newTime);
}

export function formatTimezoneOffset(offset: number) {
  const sign = offset >= 0 ? "+" : "-";
  const absoluteOffset = Math.abs(offset);

  const hours = Math.floor(absoluteOffset);
  const minutes = (absoluteOffset - hours) * 60;
  const paddedHours = String(hours).padStart(2, "0");
  const paddedMinutes = String(minutes).padStart(2, "0");

  return `${sign}${paddedHours}:${paddedMinutes}`;
}

export function formatStringToNumberMinutes(value: string) {
  let hours = 0;
  let minutes = 0;
  let seconds = 0;

  const regex = /(\d+)\s*hour[s]?|(\d+)\s*minute[s]?|(\d+)\s*second[s]?/g;
  let match;

  while ((match = regex.exec(value)) !== null) {
    if (match[1]) {
      hours = parseInt(match[1], 10);
    }
    if (match[2]) {
      minutes = parseInt(match[2], 10);
    }
    if (match[3]) {
      seconds = parseInt(match[3], 10);
    }
  }

  const totalMinutes = hours * 60 + minutes + seconds / 60;

  return totalMinutes;
}

export function getCurrentISODateTime() {
  const now = new Date();

  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");

  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");

  const isoString = `${year}-${month}-${day}T${hours}:${minutes}:00`;

  const timezoneOffset = now.getTimezoneOffset();
  const absOffset = Math.abs(timezoneOffset);
  const offsetHours = String(Math.floor(absOffset / 60)).padStart(2, "0");
  const offsetMinutes = String(absOffset % 60).padStart(2, "0");

  const offsetSign = timezoneOffset <= 0 ? "+" : "-";

  const finalISODateTime = `${isoString}${offsetSign}${offsetHours}:${offsetMinutes}`;

  return finalISODateTime;
}
