<template>
  <section
    class="main-container min-h-[calc(100vh_-_80px)] overflow-hidden relative bg-flohh-neutral-95 px-5 py-3"
  >
    <template v-if="newTeacher && !loadingDashboard">
      <div class="flex flex-col p-[34px] bg-white rounded-lg">
        <div class="mb-[29px] relative" v-if="newTeacher">
          <h6 class="text-[24px] leading-[1.5] text-[#333] font-bold">
            Hi {{ firstName }}!
          </h6>
          <p class="text-[18px] leading-[1.5] text-[#333] font-bold">
            Welcome to Flohh!
          </p>
          <button
            class="p-0 text-[#333] absolute text-[12px] underline right-0 bottom-0"
            @click="
              () => {
                handleOnboardingVideoComponent();
              }
            "
          >
            {{ showOnboardingVideo ? "Hide" : "Show" }} video
          </button>
        </div>
        <template v-if="showOnboardingVideo">
          <OnboardingVideoComponent />
        </template>
        <OnboardingTasksComponent
          :handleSubmissionModal="handleSubmissionModal"
        />
      </div>
    </template>
    <template v-if="!newTeacher && !loadingDashboard">
      <div>
        <DashboardHeaderComponent
          :firstName="firstName"
          :handleSubmissionModal="handleSubmissionModal"
        />
        <DashboardAssignmentComponent
          v-if="!loadingDashboard"
          :assignments="assignments"
        />
        <div
          class="flex flex-col p-[30px] bg-white rounded-lg gap-7 mb-5"
          v-if="!loadingTask && !hasSubmissions"
        >
          <OnboardingTasksComponent
            :titleSize="'24px'"
            :hasAssignments="hasAssignments"
            :hasSubmissions="hasSubmissions"
            :handleSubmissionModal="handleSubmissionModal"
          />
        </div>
        <DashboardJourneyComponent />
      </div>
    </template>

    <template v-if="loadingDashboard">
      <div
        class="w-full flex items-center justify-center h-[calc(100vh_-_120px)]"
      >
        <ProgressLoader label="Preparing Dashboard" />
      </div>
    </template>
  </section>

  <ModalUtility v-model="isOpenAssignment" title="Create an assignment">
    <template #content>
      <div class="bg-white">
        <CreateAssignment
          @onClickDone="isOpenAssignment = false"
          :maximize="true"
        />
      </div>
    </template>
  </ModalUtility>

  <UpdatesModal
    v-if="pendingActions && pendingActions.length > 0"
    v-model="isSessionModalOpen"
    :updates="pendingActions"
    @onUpdatesModalClose="handleShowOnboardingVideo"
  />
  <GenerateSubmissionLinkModal v-model="submissionModalOpen" />
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import { GridLayout, GridItem } from "grid-layout-plus";
import AppButton from "../Layout/Buttons/AppButton.vue";
import { icons as AppIcons, icons } from "@/utils/icons";
import CreateClassButton from "../CreateClassPopup/GroupPopup/CreateClassButton.vue";
import KanbanCellComponent from "./KanbanCellComponent.vue";
import UpdatesModal from "./Updates/UpdatesModal.vue";

import { getTeacherUuid } from "@/config/auth";
import ModalUtility from "../utilities/ModalUtility.vue";
import CreateAssignment from "../Assignment/CreateAssignment/CreateAssignment.vue";
import emitter from "@/config/emitter";
import {
  AssignmentDashboard,
  StudentDashboard,
  PendingActions,
} from "@/store/dashboard/dashboardTypes";
import AppTypographyText from "../Layout/Typhography/AppTypographyText.vue";
import axios, { AxiosResponse } from "axios";
import AppCardButton from "@/components/Layout/Buttons/AppCardButton.vue";
import AssignmentCardComponent from "@/components/Assignments/utilities/AssignmentCardComponent.vue";
import OnboardingVideoComponent from "./OnboardingVideoComponent.vue";
import OnboardingTasksComponent from "./OnboardingTasksComponent.vue";
import DashboardHeaderComponent from "./DashboardHeaderComponent.vue";
import DashboardAssignmentComponent from "./DashboardAssignmentComponent.vue";
import DashboardJourneyComponent from "./DashboardJourneyComponent.vue";
import GenerateSubmissionLinkModal from "@/components/Classes/SubmissionLinkModal/GenerateSubmissionLinkModal.vue";
import ProgressLoader from "../utilities/ProgressLoader.vue";
import { TELEMETRY_HEAD } from "@/utils/telemetry/data";
import DashboardService from "@/services/DashboardService";
import { setClassess } from "@/store/class/class-dispatch";

@Component({
  components: {
    GridLayout,
    GridItem,
    KanbanCellComponent,
    AppButton,
    ModalUtility,
    CreateAssignment,
    CreateClassButton,
    AppTypographyText,
    UpdatesModal,
    AppCardButton,
    AssignmentCardComponent,
    OnboardingVideoComponent,
    OnboardingTasksComponent,
    DashboardHeaderComponent,
    DashboardAssignmentComponent,
    DashboardJourneyComponent,
    GenerateSubmissionLinkModal,
    ProgressLoader,
  },
})
export default class DashboardComponent extends Vue {
  private dashboardService = new DashboardService();

  newTeacher = true;
  showOnboardingVideo = true;
  loadingDashboard = true;

  isTeacher = true;
  submissionModalOpen = false;

  icons = AppIcons;
  assignments: AssignmentDashboard[] | StudentDashboard[] = [];

  dashboardLoading = true;

  isOpenAssignment = false;
  eventBus = emitter;
  isSessionModalOpen = false;
  pendingActions!: PendingActions[];

  firstName = "";
  onboardingVideoSession = false;
  loadingTask = true;
  hasAssignments = false;
  hasSubmissions = false;

  handleClickAssignment() {
    this.eventBus.emit("EVENT_TRIGGER", "TD001");
    this.isOpenAssignment = true;
  }
  async handleOnboardingVideoComponent() {
    if (this.showOnboardingVideo && !this.onboardingVideoSession) {
      await axios.post("/teachers/onboarding", {
        watchedOnboardingVideo: true,
      });
      this.onboardingVideoSession = true;
    }
    this.showOnboardingVideo = !this.showOnboardingVideo;
  }
  async handleOnboardingVideo(status?: string) {
    const storageKey = "ShowOnboardingView";
    const video = localStorage.getItem(storageKey);
    if (video) {
      this.showOnboardingVideo = false;
    } else {
      if (this.isTeacher) {
        this.showOnboardingVideo = true;
      } else {
        this.showOnboardingVideo = false;
      }
      if (status === "hide") {
        this.eventBus.emit("EVENT_TRIGGER", "TD004");
        localStorage.setItem(storageKey, JSON.stringify(false));
        const response: AxiosResponse = await axios.post(
          "/teachers/onboarding",
          { watchedOnboardingVideo: true },
          {
            headers: TELEMETRY_HEAD.postWatchedOnboardingVideo,
          }
        );
      }
    }
  }

  intervalId!: any;
  showNoListMessage = false;

  get assignmentState() {
    return this.$store.state.assignment.selectedAssignment;
  }

  unmounted() {
    const storageKey = "ShowOnboardingView";
    localStorage.removeItem(storageKey);
  }

  handleOpenInviteModal() {
    // this.inviteModalOpen = true;
  }

  async mounted() {
    const teacherData = localStorage.getItem("teacher");
    if (teacherData) {
      this.firstName = JSON.parse(teacherData).profile.firstName;
    }
    this.isTeacher =
      this.$route.path === "/dashboard" || this.$route.path === "/dashboard/";

    this.eventBus.on("RELOAD_DASHBOARD", () => {
      location.reload();
    });

    this.eventBus.on("REFRESH_DASHBOARD_ASSIGNMENTS", async () => {
      this.loadingDashboard = true;
      await this.getTeachersClasses();
      await this.getAssignmentData();
      this.loadingDashboard = false;
    });

    await this.getTeachersClasses();
    await this.getAssignmentData();

    const response: AxiosResponse = await axios.get(
      `/session?role=${this.isTeacher ? "teacher" : "student"}`
    );

    if (response.data.ok) {
      const pendingActions = response.data.data.pendingActions;
      if (pendingActions && pendingActions.length > 0) {
        const learningGoalActions = pendingActions.find(
          (item: PendingActions) => item.type === "learningGoals"
        );
        const onboardingVideo = pendingActions.find(
          (item: PendingActions) => item.type === "onboardingVideo"
        );

        if (learningGoalActions) {
          this.pendingActions = pendingActions;
          this.isSessionModalOpen = true;
        } else {
          if (onboardingVideo) {
            this.isSessionModalOpen = false;
            this.handleOnboardingVideo();
          }
        }
        localStorage.setItem("userSession", JSON.stringify(pendingActions));
      } else {
        localStorage.removeItem("userSession");
        this.showOnboardingVideo = false;
        this.onboardingVideoSession = true;
      }
    }

    if (localStorage.getItem("UpdateModalClose")) {
      this.isSessionModalOpen = false;
    }

    this.dashboardLoading = false;

    // } else {
    //   const onboardingVideo = JSON.parse(userSession).find(
    //     (item: PendingActions) => item.type === "onboardingVideo"
    //   );

    //   if (!onboardingVideo) {
    //     this.showOnboardingVideo = false;
    //     this.onboardingVideoSession = true;
    //   }
    // }

    this.eventBus.on("UPDATE_MY_ACCOUNT", () => {
      const updatedTeacherData = localStorage.getItem("teacher");
      if (updatedTeacherData) {
        this.firstName = JSON.parse(updatedTeacherData).profile.firstName;
      }
    });
  }

  handleShowOnboardingVideo() {
    // const userSession = localStorage.getItem("userSession");
    // if (userSession) {
    //   const onboardingVideo = JSON.parse(userSession).find(
    //     (item: PendingActions) => item.type === "onboardingVideo"
    //   );
    //   if (this.isTeacher && onboardingVideo) {
    //     this.showOnboardingVideo = true;
    //   } else {
    //     this.showOnboardingVideo = false;
    //   }
    // }

    localStorage.setItem("UpdateModalClose", "true");
  }

  async getTeachersClasses() {
    const params: { teacher: string; code?: string } = {
      teacher: getTeacherUuid(),
    };
    await this.$store.dispatch(setClassess, params);
  }

  async getAssignmentData() {
    try {
      const res = await this.dashboardService.getTeacherDashboard();
      if (res.data.ok) {
        this.assignments = res.data.data;
        this.hasAssignments = res.data.data.length > 1;
        if (this.hasAssignments) {
          this.newTeacher = false;
        }
        this.hasSubmissions = this.hasTotalSubmission(res.data.data);
      }
    } catch (e) {
      //
    } finally {
      this.loadingTask = false;
      this.loadingDashboard = false;
    }
  }

  hasTotalSubmission(data: any) {
    let count = 0;
    data.map((item: any) => {
      count += item.submissionCounts.totalSubmissions;
    });
    return count > 1;
  }

  handleSubmissionModal() {
    this.submissionModalOpen = !this.submissionModalOpen;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
