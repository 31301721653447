import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "bg-flohh-primary-pink rounded-md" }
const _hoisted_2 = { class: "flex py-[12px] px-[20px] justify-between items-center gap-2" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "px-[28px] py-[30px] h-[364px] overflow-auto" }
const _hoisted_5 = {
  key: 0,
  class: "flex flex-col"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["for"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "flex pt-5 border-t border-solid border-flohh-neutral-85" }
const _hoisted_11 = { class: "flex-1 flex justify-start items-center" }
const _hoisted_12 = { class: "flex-1 flex justify-end items-center gap-x-4" }
const _hoisted_13 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Ellipsis = _resolveComponent("Ellipsis")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_FormRadioButton = _resolveComponent("FormRadioButton")!
  const _component_ProgressLoader = _resolveComponent("ProgressLoader")!
  const _component_ModalUtility = _resolveComponent("ModalUtility")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.selectedGradeSchemaName)
      ? (_openBlock(), _createBlock(_component_FormField, {
          key: 0,
          label: _ctx.label
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_Ellipsis, { content: _ctx.selectedGradeSchemaName }, null, 8, ["content"]),
                _createElementVNode("span", {
                  class: "cursor-pointer",
                  innerHTML: _ctx.icons.pencilBlack,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleClickEditGradeSchema()))
                }, null, 8, _hoisted_3)
              ])
            ])
          ]),
          _: 1
        }, 8, ["label"]))
      : _createCommentVNode("", true),
    (!_ctx.selectedGradeSchemaName)
      ? (_openBlock(), _createBlock(_component_AppButton, {
          key: 1,
          label: "Choose From Bank",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.visible = true)),
          text: "",
          blackLabel: "",
          disabled: _ctx.list.length === 0,
          class: _normalizeClass(_ctx.list.length === 0 ? 'opacity-[0.5]' : ''),
          loading: _ctx.listLoading
        }, null, 8, ["disabled", "class", "loading"]))
      : _createCommentVNode("", true),
    _createVNode(_component_ModalUtility, {
      modelValue: _ctx.visible,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.visible) = $event)),
      width: "614px",
      title: "Select a Grade Schema Bank",
      onOnClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.visible = false)),
      draggable: false
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          (!_ctx.listLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(["flex gap-2 items-center w-full px-4 py-4 hover:bg-flohh-neutral-95 cursor-pointer", [
              {
                'bg-flohh-neutral-95': item.uuid === _ctx.selectedGradeSchema,
              },
            ]]),
                    key: index,
                    onClick: ($event: any) => (_ctx.handleGradeSchemaClick(item.uuid))
                  }, [
                    _createVNode(_component_FormRadioButton, {
                      modelValue: _ctx.selectedGradeSchema,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedGradeSchema) = $event)),
                      name: index.toString(),
                      inputId: index.toString(),
                      value: item.uuid
                    }, null, 8, ["modelValue", "name", "inputId", "value"]),
                    _createElementVNode("label", {
                      for: item.name,
                      class: "font-flohh-font-medium text-flohh-text-body cursor-pointer"
                    }, _toDisplayString(item.name), 9, _hoisted_7)
                  ], 10, _hoisted_6))
                }), 128)),
                (!_ctx.list.length)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_8, " No Grade Schemas found. Create a new one via Manage Grade Schema link. "))
                  : _createCommentVNode("", true)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createVNode(_component_ProgressLoader)
              ]))
        ])
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_AppButton, {
              onClick: _ctx.handleManageGradeSchema,
              text: "",
              blackLabel: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Manage Grade Schemas ")
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_AppButton, {
              type: "submit",
              onClick: _ctx.handleClickSelect
            }, {
              icon_left: _withCtx(() => [
                _createElementVNode("span", {
                  innerHTML: _ctx.icons.checkBlack
                }, null, 8, _hoisted_13)
              ]),
              default: _withCtx(() => [
                _createTextVNode(" Select ")
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}