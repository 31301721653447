<template>
  <PageTitle title="Marking" :routes="routes" />
  <Teleport to="#top_navigation_buttons_teleport">
    <div class="flex items-center justify-end">
      <Dropdown
        v-model="selectedStudent"
        :options="studentLists"
        optionLabel="name"
        placeholder="-- --"
        filter
        :filterIcon="'pi pi-search'"
        class="w-[227px] student-dropdown h-[48px] mr-[22px] rounded-[8px] !border-none"
        :loading="loading"
        @change="
          (item: any) => {
            handleChangeSubmission(item.value);
          }
        "
      >
        <template #value="slotProps">
          <div
            class="flex items-center justify-start"
            v-tooltip.bottom="{
              value: slotProps.value
                ? slotProps.value.name
                : slotProps.placeholder,
              showDelay: 500,
            }"
          >
            <span v-html="icons.person"></span>
            <span
              class="block items-center text-[14px] font-medium text-[#0D0D0D] ml-[12px] truncate w-[119px]"
            >
              {{
                slotProps.value ? slotProps.value.name : slotProps.placeholder
              }}</span
            >
          </div>
        </template>
        <template #option="slotProps">
          <div class="flex items-center">
            <div class="flex items-center">
              <div
                v-if="slotProps.option.status == 'marked'"
                class="bg-flohh-secondary-pink w-[10px] h-[10px] rounded-lg mr-[10px]"
              ></div>
              <!-- making in progress -->
              <div
                v-if="slotProps.option.status == 'marking in progress'"
                class="bg-flohh-secondary-yellow w-[10px] h-[10px] rounded-lg mr-[10px]"
              ></div>
              <!-- requested -->
              <div
                v-if="slotProps.option.status == 'learning goal requested'"
                class="bg-flohh-secondary-blue w-[10px] h-[10px] rounded-lg mr-[10px]"
              ></div>
              <!-- student submitted the learning goal -->
              <div
                v-if="slotProps.option.status == 'learning goal submitted'"
                class="bg-flohh-secondary-orange w-[10px] h-[10px] rounded-lg mr-[10px]"
              ></div>
              <!-- completed -->
              <div
                v-if="slotProps.option.status == 'completed'"
                class="bg-flohh-secondary-green w-[10px] h-[10px] rounded-lg mr-[10px]"
              ></div>

              {{ slotProps.option.name }}
            </div>
          </div>
        </template>
      </Dropdown>
      <template v-if="isTimerLoaded">
        <MarkingNavComponent :markingTime="timer" :startTime="startTime" />
      </template>

      <template v-if="!isTimerLoaded">
        <MarkingNavComponent :markingTime="0" :startTime="0" />
      </template>
    </div>
  </Teleport>
  <div class="marking">
    <template v-if="pdfBase64 && !loading">
      <PSPDFKitContainer
        ref="PSPDFKitContainerRef"
        :pdfBase64="pdfBase64"
        :annotationData="annotationData"
        :revisionId="revisionId"
        :assignmentId="assignmentId"
        :assignmentName="assignmentName"
        :isFlattened="isFlattened"
        :submissionId="id"
        :studentId="studentId"
        :classId="classId"
        @loaded="handleLoaded"
        @refresh="handleRefreshMarkingView"
      />
    </template>
    <template v-if="!pdfBase64 && loading">
      <div
        class="w-[calc(100%-288px)] h-[calc(100vh-80px)] absolute z-[100] overflow-hidden flex align-center items-center"
      >
        <LoadingState
          state="Fetching data. Please wait."
          backdrop="95"
          textCls="!text-flohh-neutral-10"
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Ref } from "vue-facing-decorator";
import Emitter from "@/config/emitter";
import PSPDFKitContainer from "@/components/PSPDFKit/PSPDFKitContainer.vue";
import PageTitle from "@/components/Layout/Partials/PageTitle.vue";
import { Teleport } from "vue";
import MarkingNavComponent from "@/components/Navigation/MarkingNavComponent.vue";
import AssignmentService from "@/services/AssignmentService";
import MediaService from "@/services/MediaService";
import { AxiosResponse } from "axios";
import { StorageDataKey } from "@/enums/enums";
import LoadingState from "@/components/utilities/LoadingState.vue";
import { icons as AppIcons } from "@/utils/icons";
import Dropdown from "primevue/dropdown";

interface IStudent {
  studentId: string;
  name: string;
  code: string;
  status?: string;
}

@Component({
  components: {
    PSPDFKitContainer,
    PageTitle,
    Teleport,
    MarkingNavComponent,
    LoadingState,
    Dropdown,
  },
})
export default class MarkingView extends Vue {
  private assignmentService = new AssignmentService();
  private mediaService = new MediaService();

  public pdfFile!: string;
  public pdfBase64!: string;
  public annotationData!: [];
  public revisionId!: string;
  public assignmentId!: string;
  public classId!: string;
  public studentId!: string;
  public assignmentName!: string;

  isFlattened = false;

  selectedStudent!: any;
  studentLists: IStudent[] = [];
  icons = AppIcons;

  @Ref
  public PSPDFKitContainerRef!: any;

  @Prop({ type: String, required: false })
  id!: string;
  loading = true;
  timer!: number;
  startTime!: number;
  isTimerLoaded = false;
  routes = [
    {
      title: "Classes",
      routeTo: "",
      current: false,
    },
    {
      title: "Assignment",
      routeTo: "",
      current: false,
    },
    {
      title: "Student",
      current: true,
    },
  ];
  /**
   * handleLoaded
   */
  public handleLoaded(instance: unknown) {
    //
  }

  /**
   * created
   */
  public async created() {
    // this.pdfFile = "/flohh-next/document.pdf";
    // this.pdfFile = "/document.pdf";

    await this.onGetSubmissionById(this.id);
    this.handleGetSubmissions();
  }

  /**
   * mounted
   */
  public mounted() {
    // this.pageUnload();
  }

  /**
   * beforeUnmount
   */
  public beforeUnmount() {
    // Emitter.emit("SAVE_MARKING");
  }

  async handleRefreshMarkingView() {
    await this.onGetSubmissionById(this.id);
    await this.handleGetSubmissions();
    if (this.PSPDFKitContainerRef) {
      this.PSPDFKitContainerRef.handleRefresh(this.studentId);
    }
  }

  /**
   * onGetSubmissionById
   */
  private async onGetSubmissionById(id: string) {
    try {
      const response: AxiosResponse =
        await this.assignmentService.getSubmission(id);

      console.log("onGetSubmissionById");
      console.log(response);
      if (response.data.ok) {
        //
        const data = response.data.data;
        this.routes[0].title = data.assignment.class.code;
        this.routes[0].routeTo = `/classes/${data.assignment.class.uuid}`;
        this.routes[1].title = data.assignment.title;
        this.routes[1].routeTo = `${this.routes[0].routeTo}?assignment=${data.assignment.uuid}`;
        if (data.student) {
          this.routes[2].title = `${data.student.profile.firstName} ${data.student.profile.lastName}`;
          this.studentId = data.student.uuid;
        }

        this.startTime = data.markingTime ? data.markingTime.value : 0;

        this.classId = data.assignment.class.uuid;
        this.assignmentId = data.assignment.uuid;
        this.assignmentName = data.assignment.title;

        this.isFlattened = data.flattenedComponent ? true : false;

        if (data.assignment.estimatedTimeToMark.unit === "minutes") {
          this.timer = this.convertToMs(
            data.assignment.estimatedTimeToMark.value
          );
        } else {
          this.timer = data.assignment.estimatedTimeToMark.value;
        }

        this.isTimerLoaded = true;
        localStorage.setItem(
          StorageDataKey.FinalComment,
          data.finalTeacherComments ? data.finalTeacherComments : " "
        );
        const annotationResponse: AxiosResponse =
          await this.assignmentService.getAnnotationBySubmissionId(id);
        if (annotationResponse.data.ok) {
          this.annotationData = annotationResponse.data.data.annotations;
        } else {
          console.error(annotationResponse.data.message);
        }

        const mediaResponse: AxiosResponse = await this.mediaService.getMedia(
          data.component.media
        );
        if (mediaResponse.data.ok) {
          this.pdfBase64 = mediaResponse.data.data.data;
        } else {
          console.error(mediaResponse.data.message);
        }
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      if (error instanceof ReferenceError) {
        console.error(error.message);
      } else {
        // throw error;
      }
    } finally {
      this.loading = false;
    }
  }

  /**
   * onPostAssignmentSubmission
   */
  //  public async onPostAssignmentSubmission(studentData: []) {
  //   try {
  //     const response: AxiosResponse =
  //       await this.assignmentService.postSubmission({
  //         assignment: generateGuid(),
  //         document: generateGuid(),
  //         components: studentData,
  //       });
  //     if (response.data.ok) {
  //       //
  //     } else {
  //       console.error(response.data.message);
  //     }
  //   } catch (error) {
  //     if (error instanceof ReferenceError) {
  //       console.error(error.message);
  //     } else {
  //       // throw error;
  //     }
  //   }
  // }

  async handleChangeSubmission(item: IStudent) {
    this.loading = true;
    this.pdfBase64 = "";
    this.$router.push({
      name: "Marking",
      params: { id: item.code },
    });
    this.isTimerLoaded = false;
    await this.onGetSubmissionById(item.code);
  }

  convertToMs(val: number) {
    return val * 1000;
  }

  async handleGetSubmissions() {
    try {
      if (this.assignmentId) {
        // const res = await this.assignmentService.getAssignmentSubmission(
        //   this.assignmentId
        // );

        const res = await this.assignmentService.getAssignmentSubmissionSummary(
          this.assignmentId
        );

        if (res.data.ok) {
          console.log("handleGetSubmissions");
          console.log("res.data.data");
          console.log(res.data.data);

          const studentData = res.data.data.students;
          console.log(studentData);
          if (studentData) {
            studentData.map((item: any) => {
              console.log(item.student);
              if (item && item.student && item.submission) {
                this.studentLists.push({
                  code: item.submission.uuid,
                  name: `${item.student.profile.firstName} ${item.student.profile.lastName}`,
                  studentId: item.student.uuid,
                  status: item.submission.status,
                });
              }
            });
          }

          console.log(res.data.data.students);
          console.log("this.selectedStudent");
          console.log(this.selectedStudent);
          console.log(this.studentId);
          console.log(this.studentLists);

          if (!this.selectedStudent) {
            this.selectedStudent = this.studentLists.find(
              (o: any) => o.studentId === this.studentId
            );
          }
          console.log("this.studentLists");
          console.log(this.studentLists);
        }
      }
    } catch (e) {
      //
    } finally {
      //
    }
  }

  pageUnload() {
    window.addEventListener("beforeunload", function (e) {
      var confirmationMessage = "Are you want to close flohh?";

      (e || window.event).returnValue = confirmationMessage; //Gecko + IE
      return confirmationMessage; //Webkit, Safari, Chrome
    });
  }
}
</script>

<style lang="scss">
body {
  margin: 0;
}

input[type="file"] {
  display: none;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background-color: #ccc !important;
  color: #000 !important;
}

.custom-file-upload {
  border: 1px solid #ccc;
  border-radius: 4px;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background: #4a8fed;
  padding: 10px;
  color: #fff;
  font: inherit;
  font-size: 16px;
  font-weight: bold;
}
.student-dropdown {
  &.p-dropdown:not(.p-disabled).p-focus {
    box-shadow: none !important;
  }
  .p-dropdown-panel .p-dropdown-items {
    padding: 0 !important;
  }
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  height: 36px !important;
}
</style>
