import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full py-7 px-4"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "w-full flex justify-end items-center" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 1,
  class: "w-full py-7 px-4 text-center"
}
const _hoisted_7 = {
  key: 0,
  class: "flex flex-col gap-2 mb-5"
}
const _hoisted_8 = { class: "w-full flex justify-end items-center" }
const _hoisted_9 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppTypographyText = _resolveComponent("AppTypographyText")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_StudentItem = _resolveComponent("StudentItem")!
  const _component_ModalUtility = _resolveComponent("ModalUtility")!

  return (_openBlock(), _createBlock(_component_ModalUtility, {
    title: "Edit Student Details",
    modelValue: _ctx.modalOpen,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modalOpen) = $event))
  }, {
    content: _withCtx(() => [
      (_ctx.showResult)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_ctx.successStudents.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_AppTypographyText, {
                    label: "The following student have beed updated successfully:",
                    variant: "bd",
                    type: "body",
                    class: "mb-2"
                  }),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.successStudents, (student, i) => {
                    return (_openBlock(), _createBlock(_component_AppTypographyText, {
                      key: i,
                      label: `• ${student.profile.firstName} ${student.profile.lastName}`,
                      type: "body",
                      class: "mb-7"
                    }, null, 8, ["label"]))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            (_ctx.failedStudents.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_AppTypographyText, {
                    label: "Failed to update the following student details:",
                    variant: "bd",
                    type: "subtitle",
                    class: "mb-2"
                  }),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.failedStudents, (student, i) => {
                    return (_openBlock(), _createBlock(_component_AppTypographyText, {
                      key: i,
                      label: `• ${student.profile.firstName} ${student.profile.lastName}`,
                      type: "body",
                      class: "mb-7"
                    }, null, 8, ["label"]))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_AppButton, {
                type: "submit",
                onClick: _ctx.handleCloseModal
              }, {
                icon_left: _withCtx(() => [
                  _createElementVNode("span", {
                    innerHTML: _ctx.icon.checkBlack
                  }, null, 8, _hoisted_5)
                ]),
                default: _withCtx(() => [
                  _createTextVNode(" Finish ")
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_AppTypographyText, {
              label: "Some of your student accounts don't have an email. Add them below to continue.",
              variant: "bd",
              type: "title",
              class: "mb-7"
            }),
            (_ctx.studentDataClone && _ctx.studentDataClone.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.studentDataClone, (item, index) => {
                    return (_openBlock(), _createBlock(_component_StudentItem, {
                      key: index,
                      index: index,
                      student: item,
                      onOnStudentUpdate: _ctx.handleStudentUpdate,
                      onOnRemoveStudent: _ctx.handleRemoveStudent
                    }, null, 8, ["index", "student", "onOnStudentUpdate", "onOnRemoveStudent"]))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_AppButton, {
                type: "submit",
                loading: _ctx.isLoading,
                disabled: _ctx.isLoading,
                onClick: _ctx.updateStudentAccounts
              }, {
                icon_left: _withCtx(() => [
                  _createElementVNode("span", {
                    innerHTML: _ctx.icon.checkBlack
                  }, null, 8, _hoisted_9)
                ]),
                default: _withCtx(() => [
                  _createTextVNode(" Update Student Accounts ")
                ]),
                _: 1
              }, 8, ["loading", "disabled", "onClick"])
            ])
          ]))
    ]),
    _: 1
  }, 8, ["modelValue"]))
}