import { ActionContext } from "vuex";
import { AuthProfileState, AuthState } from "../AuthStateTypes";
import * as mutationType from "../mutation-types";
import AuthenticationService from "@/services/AuthService";
import { getAuthUser } from "@/config/auth";
import { RootState } from "@/store";

export const GetAuthRefreshTokenAction = async (
  context: ActionContext<AuthState, RootState>
) => {
  try {
    const authService = new AuthenticationService();
    const auth = getAuthUser();
    const params = {
      refreshToken: auth.authUser.refreshToken as string,
    };

    context.commit(mutationType.SET_LOADING_AUTH, true);
    const response = await authService.getAuthMe(params);
    if (response.data.ok) {
      localStorage.setItem("auth", JSON.stringify(response.data.data));
      context.commit(
        mutationType.SET_AUTH,
        response.data.data as AuthProfileState
      );
    }
  } catch (error) {
    console.error(error);
  } finally {
    context.commit(mutationType.SET_LOADING_AUTH, false);
  }
};
