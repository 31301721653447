import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22aaa3d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "registraiton-form" }
const _hoisted_2 = { class: "flex flex-col lg:flex-row gap-5" }
const _hoisted_3 = { class: "flex flex-col lg:flex-row gap-5" }
const _hoisted_4 = { class: "flex flex-col lg:flex-row gap-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextInputComponent = _resolveComponent("TextInputComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_TextInputComponent, {
        type: "text",
        name: "firstName",
        label: "First Name",
        value: _ctx.firstName,
        required: _ctx.isRequired,
        errMsg: _ctx.errMsg.firstName,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onInput($event, 'update:firstName'))),
        placeholder: "Enter First Name"
      }, null, 8, ["value", "required", "errMsg"]),
      _createVNode(_component_TextInputComponent, {
        type: "text",
        name: "lastName",
        label: "Last Name",
        value: _ctx.lastName,
        required: _ctx.isRequired,
        errMsg: _ctx.errMsg.lastName,
        onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onInput($event, 'update:lastName'))),
        placeholder: "Enter Last Name"
      }, null, 8, ["value", "required", "errMsg"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_TextInputComponent, {
        type: "email",
        name: "email",
        label: "Email",
        value: _ctx.email,
        required: _ctx.isRequired,
        errMsg: _ctx.errMsg.email,
        onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onInput($event, 'update:email'))),
        placeholder: "Enter Email"
      }, null, 8, ["value", "required", "errMsg"]),
      _createVNode(_component_TextInputComponent, {
        type: "email",
        name: "confirmEmail",
        label: "Confirm Email",
        value: _ctx.confirmEmail,
        required: _ctx.isRequired,
        errMsg: _ctx.errMsg.confirmEmail,
        onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onInput($event, 'update:confirmEmail'))),
        placeholder: "Confirm Email"
      }, null, 8, ["value", "required", "errMsg"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_TextInputComponent, {
        type: "password",
        name: "password",
        label: "Password",
        value: _ctx.password,
        required: _ctx.isRequired,
        errMsg: _ctx.errMsg.password,
        onInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onInput($event, 'update:password'))),
        placeholder: "Enter Password",
        iconRight: "eyeActive"
      }, null, 8, ["value", "required", "errMsg"]),
      _createVNode(_component_TextInputComponent, {
        type: "password",
        name: "confirmPassword",
        label: "Confirm Password",
        value: _ctx.confirmPassword,
        required: _ctx.isRequired,
        errMsg: _ctx.errMsg.confirmPassword,
        onInput: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onInput($event, 'update:confirmPassword'))),
        placeholder: "Confirm Password",
        iconRight: "eyeActive"
      }, null, 8, ["value", "required", "errMsg"])
    ])
  ]))
}