<script lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { Vue, Component, Prop } from "vue-facing-decorator";
import { icons as AppIcons } from "../../../utils/icons";
import MyAccount from "@/components/User/MyAccount.vue";
import EditMyAccountPopup from "@/components/User/EditMyAccountPopup.vue";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import ConfirmDialog from "primevue/confirmdialog";
import emitter from "@/config/emitter";
import UpdatesModal from "@/components/Dashboard/Updates/UpdatesModal.vue";

@Component({
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    MyAccount,
    EditMyAccountPopup,
    ConfirmDialog,
    UpdatesModal,
  },
})
export default class UserMenu extends Vue {
  @Prop({
    type: Boolean,
    required: false,
  })
  isSuperAdmin!: boolean;

  @Prop({
    type: String,
    required: true,
  })
  role!: string;

  icons = AppIcons;
  isOpenMyAccount = false;
  isOpenMyEditMyAccount = false;
  isUpdatesModalOpen = false;

  confirm = useConfirm();
  toast = useToast();
  eventBus = emitter;

  handleClickMyAccount() {
    this.eventBus.emit("EVENT_TRIGGER", "G007");
    this.isOpenMyAccount = true;
  }

  handleClickEditMyAccount() {
    this.isOpenMyAccount = true;
    this.isOpenMyEditMyAccount = true;
  }

  handleClickCloseEditMyAccount() {
    this.isOpenMyEditMyAccount = false;
  }

  handleConfirm() {
    this.confirm.require({
      message: "Are you sure you want to logout?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptClass:
        "flex justify-center items-center gap-x-[8px] rounded-[8px] font-flohh-font-medium px-[20px] py-[12px] bg-flohh-secondary-green hover:!bg-flohh-secondary-green-dark text-flohh-text-body h-[45px] !shadow-none !border-0",
      accept: () => {
        this.handleLogout();
      },
    });
  }

  async handleLogout() {
    if (this.role) {
      const isTeacher = this.role === "teacher";
      const isStudent = this.role === "student";
      const isSuperAdmin = this.role === "super admin";

      if (!isStudent && !isSuperAdmin) {
        if (this.$route.name === "Marking") {
          await this.eventBus.emit("SAVE_MARKING");
        }
      }

      localStorage.clear();
      this.$store.dispatch("clearUser");
      if (isTeacher) {
        this.handleRedirect("/login");
      } else if (isStudent) {
        this.handleRedirect("/student/login");
      } else {
        this.handleRedirect("/superadmin/login");
      }
    }
  }

  handleClickUpdates() {
    this.eventBus.emit("EVENT_TRIGGER", "G006");
    this.isUpdatesModalOpen = true;
  }

  handleRedirect(link: string) {
    window.location.href = link;
  }
}
</script>
<template>
  <Menu as="div" class="relative">
    <MenuButton
      class="flex items-center p-3 bg-white rounded-full"
      v-tooltip.left="{
        value: 'Your profile menu',
        showDelay: 500,
      }"
    >
      <span class="sr-only">Open user menu</span>
      <span v-html="icons.userBlackBlack"></span>
      <span class="hidden lg:flex lg:items-center">
        <span v-html="icons.arrowDropDownBlack" aria-hidden="true" />
      </span>
    </MenuButton>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white shadow-lg"
      >
        <MenuItem v-if="!isSuperAdmin">
          <button
            class="w-full py-2 px-4 flex justify-start hover:bg-flohh-primary-light-pink hover:rounded-t-[4px] items-center text-flohh-text-body"
            @click="handleClickMyAccount"
            v-tooltip.left="{
              value: 'Your account',
              showDelay: 500,
            }"
          >
            My Account
          </button>
        </MenuItem>
        <MenuItem v-if="!isSuperAdmin">
          <button
            class="w-full py-2 px-4 flex justify-start hover:bg-flohh-primary-light-pink hover:rounded-t-[4px] items-center text-flohh-text-body"
            @click="handleClickUpdates"
          >
            Updates
          </button>
        </MenuItem>
        <MenuItem>
          <button
            class="w-full py-2 px-4 flex justify-start hover:bg-flohh-primary-light-pink hover:rounded-b-[4px] items-center text-flohh-text-body"
            @click="handleConfirm"
            v-tooltip.left="{ value: 'Logout', showDelay: 500 }"
          >
            Logout
          </button>
        </MenuItem>
      </MenuItems>
    </transition>
    <MyAccount
      v-model="isOpenMyAccount"
      @onEditMyAccount="handleClickEditMyAccount"
    />
    <EditMyAccountPopup
      v-model="isOpenMyEditMyAccount"
      @onCloseMyAccount="handleClickCloseEditMyAccount"
    />
    <UpdatesModal v-model="isUpdatesModalOpen" />
  </Menu>
  <ConfirmDialog />
</template>
