<template>
  <div
    class="bg-white rounded-lg m-3 p-8 relative"
    :class="loadingClasses ? 'h-full' : ''"
  >
    <ClassesTableComponent
      :isFull="true"
      :hideHeader="true"
      v-if="classes.length > 0"
      :data="classes"
      :sortDefault="sortDefault"
      :hideTableUtility="true"
    >
      <Column field="code" header="Class Name">
        <template #body="props">
          <router-link
            :to="`/classes/${props.data.uuid}`"
            class="hover:underline"
          >
            {{ props.data.code }}
          </router-link>
        </template>
      </Column>
      <Column field="description" header="Class Description"> </Column>
      <Column>
        <template #header>Members</template>
        <template #body="props">
          <div v-if="props.data.students">
            {{ props.data.students.length }} students
          </div></template
        ></Column
      >
      <!-- <Column :style="{ textAlign: 'center' }">
        <template #header>
          <div class="mx-auto">Marked Submissions</div>
        </template>
        <template #body> <div></div></template>
      </Column> -->
      <!-- <Column field="subject" header="Subject"> </Column>
      <Column :style="{ textAlign: 'center' }">
        <template #header>
          <div class="mx-auto">Year Level</div>
        </template>
        <template #body="props">
          <div>{{ props.data.yearLevel }}</div></template
        >
      </Column> -->
      <Column :style="{ textAlign: 'center' }">
        <template #header>
          <div class="mx-auto">Marked Submissions</div>
        </template>
        <template #body="props">
          <div>{{ getSubmissionCount(props.data) }}</div></template
        >
      </Column>
      <Column style="width: 60px; position: relative; padding: 0px"
        ><template #header
          ><div class="actions-cell bg-flohh-secondary-orange">
            <div class="action-icon-container">
              <span v-html="icons.menuBarSmallBlack"></span>
            </div></div
        ></template>
        <template #body="{ data }">
          <div
            class="flex items-center justify-center actions-cell actions-body"
            v-tooltip.top="{
              value: 'More Options',
              showDelay: 500,
            }"
          >
            <AppDropdownButton
              size="sm"
              @clickMenu="(action: string) => {
                handleClickMenu(action, data)
              }"
              :menus="actions"
              type="default"
              text
              width="w-40"
              dropdownIcon="ellepsisVerticalBlack"
            />
          </div>
        </template>
      </Column>
      <template #empty> No Students found. </template>
    </ClassesTableComponent>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Emit } from "vue-facing-decorator";
import Column from "primevue/column";
import LoadingState from "../../utilities/LoadingState.vue";
import { ClassData, ClassStudentsDetails } from "@/store/class/classTypes";
import ClassesTableComponent from "@/components/Classes/ClassesTableComponent.vue";
import { icons as AppIcons } from "@/utils/icons";
import Menu from "primevue/menu";
import Button from "primevue/button";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import { AssignmentDashboard } from "@/store/dashboard/dashboardTypes";
import AppDropdownButton from "@/components/Layout/Buttons/AppDropdownButton.vue";

@Component({
  components: {
    LoadingState,
    ClassesTableComponent,
    Button,
    Menu,
    Column,
    AppTypographyText,
    AppDropdownButton,
  },
})
export default class ClassesListTableViewComponent extends Vue {
  icons = AppIcons;

  @Prop({
    type: Array,
    required: true,
  })
  classes!: ClassData[];

  @Prop({
    type: Boolean,
    required: true,
  })
  loadingClasses!: boolean;

  @Prop({
    type: Array,
    required: true,
  })
  assignments!: AssignmentDashboard[];

  sortDefault = {
    sortBy: "last_alphabetical",
    sortData: [
      { label: "Last Name: Alphabetical", value: "last_alphabetical" },
      { label: "First Name: Alphabetical", value: "first_alphabetical" },
      { label: "Date Submitted: Recent", value: "date_recent" },
      { label: "Marking Status", value: "marking_status" },
      { label: "Marking Time: Fastest", value: "marking_time_fastest" },
    ],
  };

  classInfo!: ClassData;

  actions = [
    {
      name: "Edit Class Details",
      icon: "pencilBlack",
    },
    {
      name: "Invite Students",
      icon: "inviteStudentBlack",
    },
    {
      name: "Delete Class",
      icon: "trashSmallRedIcon",
      class: "!text-[#be1d17]",
    },
  ];

  handleDeleteStudent(data: ClassStudentsDetails) {
    //
  }

  getSubmissionCount(data: ClassData) {
    let marked = 0;
    let total = 0;
    const assignments = structuredClone(this.assignments);
    const classAssignments = assignments.filter(
      (item: AssignmentDashboard) => item.class.uuid === data.uuid
    );
    for (let i = 0; i < classAssignments.length; i++) {
      const counts = classAssignments[i].submissionCounts;
      marked +=
        Number(counts.markedSubmissions) +
        Number(counts.learningGoalsRequested) +
        Number(counts.learningGoalsToReview);
      total += Number(counts.totalSubmissions);
    }
    return `${marked}/${total}`;
  }

  @Emit("onClassEdit")
  handleEditClass(classInfo: ClassData) {
    return classInfo;
  }

  @Emit("onClassDelete")
  handleDeleteClass(classInfo: ClassData) {
    return classInfo;
  }

  @Emit("onStudentInvite")
  handleInviteStudents(classInfo: ClassData) {
    return classInfo;
  }

  handleClickMenu(action: string, data: ClassData) {
    switch (action) {
      case "Edit Class Details":
        this.handleEditClass(data);
        break;
      case "Invite Students":
        this.handleInviteStudents(data);
        break;
      case "Delete Class":
        this.handleDeleteClass(data);
        break;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.actions {
  &-cell {
    left: 0;
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-body {
    background-color: #ffc9ad;
  }
}
.action-icon-container {
  max-height: 24px;
  max-width: 24px;
  margin: 0 auto;
}
.delete-menu {
  color: #f59289 !important;
}
</style>
