import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppTypographyText = _resolveComponent("AppTypographyText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AppTypographyText, {
      label: "How does the scanned paper upload and splitting work?",
      variant: "bd",
      type: "subtitle",
      class: "mb-5"
    }),
    _createVNode(_component_AppTypographyText, {
      label: "Flohh’s scanned paper upload and splitting feature allows you to easily import class sets of paper student submissions. You can scan an entire class set of paper submissions at your school photocopier and upload it to Flohh and the system will automatically split the PDF into individual submissions for you.",
      variant: "md",
      type: "body",
      class: "mb-5"
    }),
    _createVNode(_component_AppTypographyText, {
      label: "How to upload paper submissions using Flohh’s upload and split",
      variant: "bd",
      type: "subtitle",
      class: "mb-5"
    }),
    _createVNode(_component_AppTypographyText, {
      label: "1. Download the student coversheets for your class task and print",
      variant: "md",
      type: "body"
    }),
    _createVNode(_component_AppTypographyText, {
      label: "2. Add a unique coversheet to each student submission",
      variant: "md",
      type: "body"
    }),
    _createVNode(_component_AppTypographyText, {
      label: "3. Collect the class set of submissions and coversheets into one pile and scan them at your school photocopier",
      variant: "md",
      type: "body"
    }),
    _createVNode(_component_AppTypographyText, {
      label: "4. Upload the scanned document here for processing and splitting",
      variant: "md",
      type: "body",
      class: "mb-5"
    }),
    _createVNode(_component_AppTypographyText, {
      label: "Note: there is a 125 page/32MB limit on bulk upload files. If you are close to this limit we recommend scanning in batches.",
      variant: "md",
      type: "body"
    })
  ]))
}