import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c782994"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "px-6 py-4" }
const _hoisted_2 = { class: "w-full pt-4 flex justify-end items-end border-t border-solid border-flohh-neutral-85" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_ModalUtility = _resolveComponent("ModalUtility")!

  return (_openBlock(), _createBlock(_component_ModalUtility, {
    modelValue: _ctx.openDialog,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.openDialog) = $event)),
    width: "45vw",
    title: "Class Details",
    onOnClose: _ctx.handleCloseCreateClass
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_FormField, {
          type: "text",
          label: "Class Name",
          modelValue: _ctx.classInfo.code,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.classInfo.code) = $event)),
          error: _ctx.errors.code,
          required: "",
          placeholder: "Type something",
          toolTip: "Use this field to give your class a name that you and your students can use to easily identify it.",
          background: false
        }, null, 8, ["modelValue", "error"]),
        _createVNode(_component_FormField, {
          type: "text",
          label: "Subject",
          required: "",
          error: _ctx.errors.subject,
          modelValue: _ctx.classInfo.subject,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.classInfo.subject) = $event)),
          placeholder: "Type something",
          toolTip: "Use this field to set the subject of your class.",
          background: false
        }, null, 8, ["error", "modelValue"]),
        _createVNode(_component_FormField, {
          type: "text",
          label: "Year Level",
          required: "",
          error: _ctx.errors.yearLevel,
          modelValue: _ctx.classInfo.yearLevel,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.classInfo.yearLevel) = $event)),
          placeholder: "Type something",
          toolTip: "Use this field to set the year level of your class.",
          background: false
        }, null, 8, ["error", "modelValue"]),
        _createVNode(_component_FormField, {
          type: "textarea",
          label: "Description",
          modelValue: _ctx.classInfo.description,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.classInfo.description) = $event)),
          placeholder: "Type something",
          toolTip: "Use this field to set the dedscription of your class.",
          background: false
        }, null, 8, ["modelValue"])
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_AppButton, {
          type: "submit",
          loading: _ctx.loadingCreateClass,
          label: "Save",
          onClick: _ctx.handleClickSaveAssignmentDetails
        }, {
          icon_right: _withCtx(() => [
            _createElementVNode("span", {
              innerHTML: _ctx.icon.arrowForwardBlackIcon
            }, null, 8, _hoisted_3)
          ]),
          _: 1
        }, 8, ["loading", "onClick"])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "onOnClose"]))
}