<template>
  <div
    class="border border-solid border-neutral-gray-85 rounded-lg p-5 flex flex-col gap-4 cursor-pointer"
    @click="handleRedirectToClassPage"
  >
    <div class="flex justify-between w-full">
      <AppTypographyText variant="bd" :label="data.code" />
      <div
        class="flex items-center justify-end actions-cell actions-body"
        v-tooltip.top="{
          value: 'More Options',
          showDelay: 500,
        }"
      >
        <AppDropdownButton
          size="sm"
          @clickMenu="handleSelectMenu"
          :menus="actions"
          type="default"
          text
          width="w-40"
          dropdownIcon="ellepsisVerticalBlack"
        />
      </div>
    </div>
    <AppTypographyText
      type="caption"
      :label="`${data.subject} - ${data.yearLevel}`"
    />
    <PeopleAvatars v-if="studentNames.length > 0" :data="studentNames" />
    <AppTypographyText
      v-if="data.description"
      type="caption"
      :label="data.description"
    />
    <AppTypographyText
      type="small"
      :label="`
          Marked
          ${totalMarkedAssignments}/${totalSubmissionsDone}
          submissions`"
      class="mb-[9px]"
    />
    <div
      class="h-[4px] w-full bg-white rounded-[4px] overflow-hidden relative progress-bar"
    >
      <div
        :class="['absolute bg-flohh-secondary-green h-full animate-width']"
        ref="completedProgressBar"
        :progress="submissionProgressBar"
      ></div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Ref } from "vue-facing-decorator";
import { ClassData } from "@/store/class/classTypes";
import { icons as AppIcons } from "@/utils/icons";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import PeopleAvatars from "@/components/utilities/PeopleAvatars.vue";
import { StudentNames } from "./types";
import AppDropdownButton from "@/components/Layout/Buttons/AppDropdownButton.vue";
import { AssignmentDashboard } from "@/store/dashboard/dashboardTypes";

@Component({
  components: {
    AppTypographyText,
    PeopleAvatars,
    AppDropdownButton,
  },
})
export default class ClassesListTableViewComponent extends Vue {
  icons = AppIcons;

  @Prop({
    type: Object,
    required: true,
  })
  data!: ClassData;

  @Prop({
    type: Number,
    required: true,
  })
  index!: number;

  @Prop({
    type: Array,
    required: true,
  })
  assignments!: AssignmentDashboard[];

  @Ref() completedProgressBar!: HTMLElement;

  totalMarkedAssignments = 0;
  totalSubmissionsDone = 0;
  submissionProgressBar = "0%";
  actions = [
    {
      name: "Edit Class Details",
      icon: "pencilBlack",
    },
    {
      name: "Invite Students",
      icon: "inviteStudentBlack",
    },
    {
      name: "Delete Class",
      icon: "trashSmallRedIcon",
      class: "!text-[#be1d17]",
    },
  ];

  studentNames: StudentNames[] = [];
  marked = 0;
  total = 0;

  @Emit("onClassEdit")
  handleEditClass(classInfo: ClassData) {
    return classInfo;
  }

  @Emit("onClassDelete")
  handleDeleteClass(classInfo: ClassData) {
    return classInfo;
  }

  @Emit("onStudentInvite")
  handleInviteStudents(classInfo: ClassData) {
    return classInfo;
  }

  animateProgressBars() {
    setTimeout(() => {
      if (this.completedProgressBar) {
        const progress = this.completedProgressBar.getAttribute(
          "progress"
        ) as string;

        this.completedProgressBar.style.width = progress;
      }
    }, 1000);
  }

  handleSelectMenu(menuName: string) {
    if (menuName === "Edit Class Details") {
      this.handleEditClass(this.data);
    } else if (menuName === "Delete Class") {
      this.handleDeleteClass(this.data);
    } else if (menuName === "Invite Students") {
      this.handleInviteStudents(this.data);
    }
  }

  async mounted() {
    const names: StudentNames[] = [];
    if (this.data.students) {
      this.data.students.forEach((student) => {
        names.push({
          firstName: student.profile.firstName,
          lastName: student.profile.lastName,
        });
      });
      this.studentNames = names;
    }
    await this.getSubmissionComputation();
    this.animateProgressBars();
  }

  async getSubmissionComputation() {
    let marked = 0;
    let total = 0;
    const assignments = structuredClone(this.assignments);
    const classAssignments = assignments.filter(
      (item: AssignmentDashboard) => item.class.uuid === this.data.uuid
    );
    for (let i = 0; i < classAssignments.length; i++) {
      const counts = classAssignments[i].submissionCounts;
      marked +=
        Number(counts.markedSubmissions) +
        Number(counts.learningGoalsRequested) +
        Number(counts.learningGoalsToReview);
      total += Number(counts.totalSubmissions);
    }
    const percentage = (marked / total) * 100;
    this.totalSubmissionsDone = total;
    this.totalMarkedAssignments = marked;
    this.submissionProgressBar = `${percentage.toFixed(0)}%`;
  }

  handleRedirectToClassPage() {
    this.$router.push({ name: "Classes", params: { id: this.data.uuid } });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.progress-bar {
  background-color: #f2f2f2;
}

.animate-width {
  width: 0%;
  transition: width 2s cubic-bezier(0.695, 0.035, 0.05, 0.95);
}
</style>
