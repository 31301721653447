<template>
  <div class="w-full flex-col gap-2" v-if="files && files.length">
    <div
      v-for="(file, index) in files"
      :key="index"
      class="flex w-full rounded-[8px] justify-center items-center bg-flohh-neutral-95 px-[1.6em] py-4 mt-4"
    >
      <div class="w-[95%]">
        <div class="flex justify-between">
          <AppTypographyText variant="md" type="caption" :label="file.name" />
          <AppTypographyText
            variant="md"
            type="caption"
            :label="type === 'success' ? '100%' : 'Failed'"
          />
        </div>
        <div
          class="w-full bg-flohh-neutral-85 rounded-full dark:bg-flohh-neutral-85 mt-2"
        >
          <div
            class="text-xs font-medium text-white text-center p-0.5 leading-none rounded-full w-full"
            :class="[
              {
                'bg-flohh-secondary-green': type === 'success',
                'bg-flohh-secondary-red': type === 'failed',
              },
            ]"
          ></div>
        </div>
      </div>
      <div class="w-[5%] flex justify-end items-center">
        <span v-if="type === 'success'" v-html="icon.checkBlack"></span>
        <span v-else v-html="icon.closeBlack"></span>
      </div>
    </div>
  </div>
  <div v-else class="text-center pt-5">
    <AppTypographyText
      v-if="type === 'success'"
      variant="md"
      type="body"
      label="No Successful Files"
    />
    <AppTypographyText
      v-else
      variant="md"
      type="body"
      label="No Failed Files"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-facing-decorator";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import { icons } from "@/utils/icons";

@Component({
  components: {
    AppTypographyText,
  },
})
export default class UploadResultListComponent extends Vue {
  icon = icons;
  @Prop({
    type: Array,
    required: false,
  })
  files!: File[];

  @Prop({
    type: String,
    required: false,
  })
  type!: "success" | "failed";
}
</script>

<style lang="scss" scoped></style>
