export const sortAssignmentTable = (filter: string, data: any) => {
  if (filter === "last_alphabetical") {
    return data.sort((a: any, b: any) =>
      a.student.profile.lastName
        .trim()
        .localeCompare(b.student.profile.lastName.trim())
    );
  } else if (filter === "date_recent") {
    const sortedData = data.sort((a: any, b: any) => {
      if (a.submission === null) return 1;
      if (b.submission === null) return -1;
      return b.submission?.submittedAt.localeCompare(a.submission?.submittedAt);
    });
    return sortedData;
  } else if (filter === "marking_status") {
    const statusOrder = [
      "unmarked",
      "marked",
      "marking in progress",
      "learning goal requested",
      "learning goal submitted",
      "completed",
    ];
    return data.sort(
      (a: any, b: any) =>
        statusOrder.indexOf(a.submission?.status) -
        statusOrder.indexOf(b.submission?.status)
    );
  } else if (filter === "marking_time_fastest") {
    const sortedData = data.sort((a: any, b: any) => {
      if (a.submission === null) return -1;
      if (b.submission === null) return 1;
      return (
        a.submission?.markingTime?.value - b.submission?.markingTime?.value
      );
    });
    return sortedData;
  }
};

export const sortMembersTable = (filter: string, data: any) => {
  if (filter === "last_alphabetical") {
    return data.sort((a: any, b: any) =>
      a.profile.lastName.trim().localeCompare(b.profile.lastName.trim())
    );
  } else if (filter === "first_alphabetical") {
    return data.sort((a: any, b: any) =>
      a.profile.firstName.trim().localeCompare(b.profile.firstName.trim())
    );
  } else if (filter === "email_alphabetical") {
    return data.sort((a: any, b: any) => {
      if (!a.email) return -1;
      if (!b.email) return 1;

      return a.email.trim().localeCompare(b.email.trim());
    });
  } else if (filter === "user_type") {
    return data;
  }
};

export const sortMarkbookTable = (filter: string, data: any, type: string) => {
  if (filter === "last_alphabetical") {
    return data.sort((a: any, b: any) =>
      a.lastName.trim().localeCompare(b.lastName.trim())
    );
  } else if (filter === "first_alphabetical") {
    return data.sort((a: any, b: any) =>
      a.firstName.trim().localeCompare(b.firstName.trim())
    );
  } else if (filter === "score_descending") {
    if (type === "all") {
      return data.sort(
        (a: any, b: any) => b.overallPercentage - a.overallPercentage
      );
    } else {
      return data.sort(
        (a: any, b: any) =>
          b.specificAssignmentRawScore - a.specificAssignmentRawScore
      );
    }
  } else if (filter === "score_ascending") {
    if (type === "all") {
      return data.sort(
        (a: any, b: any) => a.overallPercentage - b.overallPercentage
      );
    } else {
      return data.sort(
        (a: any, b: any) =>
          a.specificAssignmentRawScore - b.specificAssignmentRawScore
      );
    }
  }
};

export const sortLearningGoalsTable = (filter: string, data: any) => {
  if (filter === "last_alphabetical") {
    return data.sort((a: any, b: any) =>
      a.lastName.trim().localeCompare(b.lastName.trim())
    );
  } else if (filter === "first_alphabetical") {
    return data.sort((a: any, b: any) =>
      a.firstName.trim().localeCompare(b.firstName.trim())
    );
  }
};

export const sortAssignmentsPageData = (filter: string, data: any) => {
  if (filter === "title_alphabetical") {
    return data.sort((a: any, b: any) =>
      a.title.trim().localeCompare(b.title.trim())
    );
  } else if (filter === "time_fastest") {
    return data.sort(
      (a: Record<string, any>, b: Record<string, any>) =>
        a.elapsedTimeValue - b.elapsedTimeValue
    );
  } else if (filter === "due_earliest") {
    return data.sort((a: Record<string, any>, b: Record<string, any>) => {
      const dateA = new Date(a.dueDateValue).getTime();
      const dateB = new Date(b.dueDateValue).getTime();

      return dateA - dateB;
    });
  } else if (filter === "marked_lowest") {
    return data.sort(
      (a: Record<string, any>, b: Record<string, any>) =>
        a.submissionProgressBar - b.submissionProgressBar
    );
  } else if (filter === "completed_highest") {
    return data.sort((a: any, b: any) =>
      a.dueDate
        .split(" ")[0]
        .trim()
        .localeCompare(b.dueDate.split(" ")[0].trim())
    );
  }
};
