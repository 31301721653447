import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "text-flohh-text-subtitle font-flohh-font-bold text-flohh-neutral-10" }
const _hoisted_3 = {
  key: 1,
  class: "flex flex-col gap-4 items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressLoader = _resolveComponent("ProgressLoader")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["w-full h-full flex justify-center items-center", 'backdrop-brightness-' + _ctx.backdrop])
  }, [
    (_ctx.errorMsg)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.errorMsg), 1)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_ProgressLoader),
          (_ctx.state)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass(["text-flohh-text-subtitle font-flohh-font-bold", [
          _ctx.state.includes('document') ? 'text-flohh-neutral-10' : 'text-white',
          _ctx.textCls,
        ]])
              }, _toDisplayString(_ctx.state), 3))
            : _createCommentVNode("", true)
        ]))
  ], 2))
}