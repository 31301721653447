<template>
  <ModalUtility title="My Account" class="w-[800px]" v-model="isVisible">
    <template #content>
      <div class="bg-white w-full h-[500px] px-4 py-2 pb-10">
        <!-- Avatar -->
        <!-- <div class="w-full">
          <div class="w-44 h-44 relative">
            <div
              class="bg-gray-light rounded-full w-full h-full"
              v-if="!userProfile.profile"
            ></div>
            <img
              :src="(userProfile.profile.uuid as string)"
              v-else
              class="rounded-full w-full h-full object-cover"
              alt="Profile picture"
            />
          </div>
        </div> -->
        <!-- End of avatar -->
        <!-- profile information -->
        <div class="w-full">
          <div class="w-full flex justify-start mt-5">
            <h4 class="font-semibold">Personal Details</h4>
          </div>
          <div class="px-6 py-6">
            <div
              class="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4"
            >
              <div class="col-span-1 flex justify-start flex-col items-start">
                <p class="font-semibold">Name</p>
                <p class="text-sm">
                  {{ userProfile?.profile.firstName }}
                  {{ userProfile?.profile.lastName }}
                </p>
              </div>
              <div class="col-span-1 flex justify-start flex-col items-start">
                <p class="font-semibold">Email</p>
                <p class="text-sm">{{ userProfile?.email }}</p>
              </div>
              <!-- <div class="col-span-1 flex justify-start flex-col items-start">
                <p class="font-semibold">Your phone number</p>
                <p class="text-sm">
                  {{ userProfile.profile.phoneNumber }}
                </p>
              </div> -->
            </div>
          </div>
          <div class="w-full flex justify-start">
            <h4 class="font-semibold">School Details</h4>
          </div>
          <div class="px-6 py-6">
            <div
              class="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4"
            >
              <div class="col-span-1 flex justify-start flex-col items-start">
                <p class="font-semibold">School</p>
                <p class="text-sm">
                  {{ userProfile.schoolName }}
                </p>
              </div>
              <div class="col-span-1 flex justify-start flex-col items-start">
                <p class="font-semibold">State/Province</p>
                <p class="text-sm">
                  {{ userProfile?.schoolAddress?.state }}
                </p>
              </div>
              <div class="col-span-1 flex justify-start flex-col items-start">
                <p class="font-semibold">Country</p>
                <p class="text-sm">
                  {{ userProfile?.schoolAddress?.country }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- end of profile information -->
        <!-- actions -->
        <div class="w-full h-10 flex">
          <div class="grow" />
          <button
            @click="handleClickEdit"
            class="flex justify-center items-center gap-x-[8px] rounded-[8px] font-flohh-font-medium px-[20px] py-[12px] bg-flohh-secondary-green hover:bg-flohh-secondary-green-dark text-flohh-text-body h-[45px]"
          >
            <img src="@/assets/icon-pencil.svg" class="" />
            Edit
          </button>
        </div>
        <!-- end of actions -->
      </div>
    </template>
  </ModalUtility>
</template>
<script lang="ts">
import { Vue, Model, Component, Emit, Watch } from "vue-facing-decorator";
import { AccountProfile } from "./types";
import ModalUtility from "../utilities/ModalUtility.vue";
import EventBus from "@/config/emitter";
import { ProfileDetails, getProfileDetails } from "@/config/auth";
@Component({ components: { ModalUtility } })
export default class MyAccount extends Vue {
  @Model({
    type: Boolean,
    default: false,
  })
  isVisible!: boolean;
  name = "";
  userProfile!: ProfileDetails;
  role = "teacher";

  @Watch("isVisible")
  onVisibleChange(status: boolean) {
    if (status) {
      this.userProfile = getProfileDetails(this.role);
    }
  }

  @Emit("onEditMyAccount")
  handleClickEdit() {
    this.isVisible = false;
  }

  handleClickClose() {
    this.isVisible = false;
  }

  generateName() {
    this.name = `${this.userProfile.profile.firstName} ${this.userProfile.profile.lastName}`;
  }

  mounted() {
    this.role = localStorage.getItem("authenticatedAs") || "";
    this.userProfile = getProfileDetails(this.role);
    EventBus.on("UPDATE_MY_ACCOUNT", () => {
      // this.userProfile = getTeacherProfile();
      this.userProfile = getProfileDetails(this.role);
      this.isVisible = true;
    });
  }
}
</script>
