import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0d3c637e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = {
  key: 0,
  class: "assignments-wrapper"
}
const _hoisted_3 = {
  key: 1,
  class: "assignments-wrapper-empty"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabUtilityComponent = _resolveComponent("TabUtilityComponent")!
  const _component_AssignmentCardComponent = _resolveComponent("AssignmentCardComponent")!
  const _component_AppTypographyText = _resolveComponent("AppTypographyText")!
  const _component_PaginationUtility = _resolveComponent("PaginationUtility")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TabUtilityComponent, {
      sortDefault: _ctx.sortDefault,
      onOnSort: _ctx.handleSort,
      onOnSearch: _ctx.handleSearch,
      class: "mb-[29px]"
    }, null, 8, ["sortDefault", "onOnSort", "onOnSearch"]),
    (_ctx.assignmentsList && _ctx.assignmentsList.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.assignmentsList, (item, i) => {
            return (_openBlock(), _createBlock(_component_AssignmentCardComponent, {
              key: i,
              data: item,
              tab: _ctx.tab,
              activeIndex: _ctx.activeIndex,
              maxWidth: _ctx.maxWidth,
              onOnDelete: _ctx.handleDeleteAssignment
            }, null, 8, ["data", "tab", "activeIndex", "maxWidth", "onOnDelete"]))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_AppTypographyText, {
            variant: "rg",
            type: "body",
            label: "No Completed Assignments"
          })
        ])),
    (_ctx.assignmentsListClone && _ctx.assignmentsListClone.length > 0)
      ? (_openBlock(), _createBlock(_component_PaginationUtility, {
          key: 2,
          data: _ctx.assignmentsListClone,
          searchText: _ctx.searchText,
          config: _ctx.paginatorConfig,
          onOnPageChange: _ctx.handlePageChange
        }, null, 8, ["data", "searchText", "config", "onOnPageChange"]))
      : _createCommentVNode("", true)
  ]))
}