<template>
  <div class="flex flex-col bg-white gap-7">
    <h6
      class="text-[18px] font-flohh-font-bold text-[#333]"
      :class="titleSize ? '!text-[' + titleSize + ']' : ''"
    >
      Complete your onboarding tasks
    </h6>
    <div class="grid gap-[24px] grid-cols-2 justify-center">
      <TaskMarkingCard
        v-if="showMarkingCard"
        :hideMarkingCard="hideMarkingCard"
      />
      <TaskAssignmentSubmissionCard />
      <TaskClassSubmissionCard
        v-if="!hasAssignments"
        :handleSubmissionModal="handleSubmissionModal"
      />
      <TaskAssignmentCard v-if="!hasAssignments" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import AppButton from "../Layout/Buttons/AppButton.vue";
import { icons as AppIcons, icons } from "@/utils/icons";
import { StorageDataKey } from "@/enums/enums";
import AppTypographyText from "../Layout/Typhography/AppTypographyText.vue";
import DashboardService from "@/services/DashboardService";
import axios, { AxiosResponse } from "axios";
import TaskMarkingCard from "./OnboardingTaskCards/TaskMarkingCard.vue";
import TaskAssignmentSubmissionCard from "./OnboardingTaskCards/TaskAssignmentSubmissionCard.vue";
import TaskClassSubmissionCard from "./OnboardingTaskCards/TaskClassSubmissionCard.vue";
import TaskAssignmentCard from "./OnboardingTaskCards/TaskAssignmentCard.vue";
@Component({
  components: {
    AppButton,
    AppTypographyText,
    TaskMarkingCard,
    TaskAssignmentSubmissionCard,
    TaskClassSubmissionCard,
    TaskAssignmentCard,
  },
})
export default class OnboardingTasksComponent extends Vue {
  //
  showMarkingCard = true;

  @Prop({
    type: String,
    default: false,
  })
  titleSize!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  hasAssignments!: boolean;

  @Prop({
    type: Function,
  })
  handleSubmissionModal!: () => void;

  loading = true;

  mounted() {
    this.showMarkingCard = localStorage.getItem("MarkDemoAssignment")
      ? false
      : true;
  }

  hideMarkingCard() {
    this.showMarkingCard = false;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
