import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full flex-col gap-2"
}
const _hoisted_2 = { class: "w-[95%]" }
const _hoisted_3 = { class: "flex justify-between" }
const _hoisted_4 = { class: "w-full bg-flohh-neutral-85 rounded-full dark:bg-flohh-neutral-85 mt-2" }
const _hoisted_5 = { class: "w-[5%] flex justify-end items-center" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = {
  key: 1,
  class: "text-center pt-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppTypographyText = _resolveComponent("AppTypographyText")!

  return (_ctx.files && _ctx.files.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.files, (file, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "flex w-full rounded-[8px] justify-center items-center bg-flohh-neutral-95 px-[1.6em] py-4 mt-4"
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_AppTypographyText, {
                  variant: "md",
                  type: "caption",
                  label: file.name
                }, null, 8, ["label"]),
                _createVNode(_component_AppTypographyText, {
                  variant: "md",
                  type: "caption",
                  label: _ctx.type === 'success' ? '100%' : 'Failed'
                }, null, 8, ["label"])
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", {
                  class: _normalizeClass(["text-xs font-medium text-white text-center p-0.5 leading-none rounded-full w-full", [
              {
                'bg-flohh-secondary-green': _ctx.type === 'success',
                'bg-flohh-secondary-red': _ctx.type === 'failed',
              },
            ]])
                }, null, 2)
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              (_ctx.type === 'success')
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    innerHTML: _ctx.icon.checkBlack
                  }, null, 8, _hoisted_6))
                : (_openBlock(), _createElementBlock("span", {
                    key: 1,
                    innerHTML: _ctx.icon.closeBlack
                  }, null, 8, _hoisted_7))
            ])
          ]))
        }), 128))
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_8, [
        (_ctx.type === 'success')
          ? (_openBlock(), _createBlock(_component_AppTypographyText, {
              key: 0,
              variant: "md",
              type: "body",
              label: "No Successful Files"
            }))
          : (_openBlock(), _createBlock(_component_AppTypographyText, {
              key: 1,
              variant: "md",
              type: "body",
              label: "No Failed Files"
            }))
      ]))
}