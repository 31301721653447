<template>
  <AppTypographyText
    label="Upload your scanned class submissions here for automatic splitting"
    variant="bd"
    type="title"
    class="mb-5"
  />
  <div class="px-2 flex gap-10 mb-5">
    <div class="flex gap-2 items-center">
      <FormRadioButton
        v-model="selectedUploadMethod"
        name="fileUpload"
        inputId="fileUpload"
        :value="0"
      />
      <label
        for="fileUpload"
        class="font-flohh-font-medium text-flohh-text-body cursor-pointer"
        :class="selectedUploadMethod === 0 ? 'text-flohh-primary-pink' : ''"
        >File Upload</label
      >
    </div>
    <div class="flex gap-2 items-center">
      <FormRadioButton
        v-model="selectedUploadMethod"
        name="googleDriveUpload"
        inputId="googleDriveUpload"
        :value="1"
      />
      <label
        for="googleDriveUpload"
        class="font-flohh-font-medium text-flohh-text-body cursor-pointer"
        :class="selectedUploadMethod === 1 ? 'text-flohh-primary-pink' : ''"
        >Google Drive</label
      >
    </div>
  </div>
  <div>
    <div :class="[{ 'invalid-file': fileError }]">
      <DefaultFileUploader
        v-if="selectedUploadMethod === 0"
        v-model:selectedFiles="selectedFiles"
        :multiple="false"
        :fileSizeLimit="32"
        fileSizeLimitPlaceholder="File size limit: 125 pages/32MB"
        acceptedFiles=".pdf,.docx,.doc"
        supportedFileTypes="Supported file types: PDF, Microsoft Word & Google Docs"
        :uploadProgerss="uploadProgerss"
        @onRemoveFile="handleRemoveFile"
      /><GoogleDriveFileUploader
        v-else-if="selectedUploadMethod === 1"
        v-model:selectedFiles="selectedFiles"
        :multiple="false"
        :fileSizeLimit="32"
        fileSizeLimitPlaceholder="File size limit: 125 pages/32MB"
        gDriveAcceptedFiles="application/pdf,application/vnd.google-apps.document"
        supportedFileTypes="Supported file types: PDF & Google Docs"
        :uploadProgerss="uploadProgerss"
        @onRemoveFile="handleRemoveFile"
      />
    </div>
    <ErrorSpan v-if="fileError">{{ fileError }}</ErrorSpan>
  </div>
  <hr class="my-5" />
  <AppButton
    type="submit"
    @click="handleSplitSubmission"
    :loading="submitting"
    :disabled="submitting"
    class="ml-auto"
  >
    <template #icon_left>
      <span v-html="icon.scissorBlack"></span>
    </template>
    Split my submissions
  </AppButton>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-facing-decorator";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import DefaultFileUploader from "@/components/utilities/DefaultFileUploader.vue";
import GoogleDriveFileUploader from "@/components/utilities/GoogleDriveFileUploader.vue";
import FormRadioButton from "@/components/Layout/Forms/FormRadioButton.vue";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { icons } from "@/utils/icons";
import emitter from "@/config/emitter";
import ErrorSpan from "@/components/utilities/ErrorSpan.vue";
import { UploadProgress } from "@/components/utilities/utilitiesTypes";

@Component({
  components: {
    AppTypographyText,
    DefaultFileUploader,
    GoogleDriveFileUploader,
    FormRadioButton,
    AppButton,
    ErrorSpan,
  },
})
export default class UploadSubmissionsComponent extends Vue {
  icon = icons;
  eventBus = emitter;

  @Prop({
    type: Boolean,
    default: false,
    required: false,
  })
  hasStudents!: boolean;

  @Prop({
    type: Boolean,
    default: false,
    required: false,
  })
  submitting!: boolean;

  selectedUploadMethod = 0;
  uploadProgerss: UploadProgress = {
    fileName: "",
    progress: 0,
  };
  selectedFiles: File[] = [];
  fileError = "";

  @Watch("selectedFiles")
  selectedFilesWatcher(value: File[]) {
    if (value.length > 0) {
      this.fileError = "";
    } else {
      this.fileError = "A file is required";
    }
  }

  handleRemoveFile() {
    //
  }

  handleSplitSubmission() {
    if (this.selectedFiles.length < 1) {
      this.fileError = "A file is required";
    }

    this.$emit("onUploadAndSplit", this.selectedFiles);
  }
}
</script>

<style scoped lang="scss">
.invalid-file {
  border: 1px solid #f59289;
  border-radius: 8px;
}
</style>
