import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-63af735c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "assignments" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_AssignmentFormComponent = _resolveComponent("AssignmentFormComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageTitle, {
      title: "Create Assignment",
      routes: _ctx.routes
    }, null, 8, ["routes"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_AssignmentFormComponent, { isEdit: false })
    ])
  ], 64))
}