import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d2baa738"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bulk-upload" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_BulkUploadComponent = _resolveComponent("BulkUploadComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PageTitle, {
      title: "Bulk Upload",
      routes: _ctx.routes
    }, null, 8, ["routes"]),
    (_ctx.type === 'paper' || _ctx.type === 'digital')
      ? (_openBlock(), _createBlock(_component_BulkUploadComponent, {
          key: 0,
          bulkUploadType: _ctx.type
        }, null, 8, ["bulkUploadType"]))
      : _createCommentVNode("", true)
  ]))
}