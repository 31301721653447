import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e5d14d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify-between items-center px-2 py-7" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "assignment-table" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "w-[50px] !pr-0" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "relative" }
const _hoisted_9 = {
  key: 1,
  class: "empty-class"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppTypographyText = _resolveComponent("AppTypographyText")!
  const _component_AppDropdownButton = _resolveComponent("AppDropdownButton")!
  const _component_Ellipsis = _resolveComponent("Ellipsis")!
  const _component_CreateClassPopup = _resolveComponent("CreateClassPopup")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_AppTypographyText, {
        variant: "md",
        type: "title",
        label: _ctx.data.class.code,
        class: "hover:underline cursor-pointer",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleRedirectToClassPage()))
      }, null, 8, ["label"]),
      _createVNode(_component_AppDropdownButton, {
        size: "xs",
        onClickMenu: _ctx.handleMenuSelection,
        menus: _ctx.menus,
        type: "default",
        width: "w-40",
        text: "",
        dropdownIcon: "moreOptionBlack"
      }, null, 8, ["onClickMenu", "menus"])
    ]),
    (_ctx.data.assignments.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("table", _hoisted_3, [
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.assignments, (item) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: item.uuid,
                  onClick: ($event: any) => (_ctx.handleRedirectToClassPage(item.uuid))
                }, [
                  _createElementVNode("td", _hoisted_5, [
                    (item.visible)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          innerHTML: _ctx.icon.assignmentIconActive
                        }, null, 8, _hoisted_6))
                      : (_openBlock(), _createElementBlock("span", {
                          key: 1,
                          innerHTML: _ctx.icon.assignmentIconInactive
                        }, null, 8, _hoisted_7))
                  ]),
                  _createElementVNode("td", _hoisted_8, [
                    _createVNode(_component_AppTypographyText, {
                      variant: "md",
                      type: "small",
                      class: "translate-y-[-50%]"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Ellipsis, {
                          width: "300px",
                          content: item.title
                        }, null, 8, ["content"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _createElementVNode("td", null, [
                    _createVNode(_component_AppTypographyText, {
                      class: "translate-y-[-50%]",
                      variant: "md",
                      type: "small",
                      label: item.visible ? _ctx.formatDueDate(item.dueDate) : 'Draft'
                    }, null, 8, ["label"])
                  ]),
                  _createElementVNode("td", null, [
                    _createVNode(_component_AppTypographyText, {
                      class: "translate-y-[-50%]",
                      variant: "md",
                      type: "small",
                      label: _ctx.computeMarked(item.submissionCounts)
                    }, null, 8, ["label"])
                  ])
                ], 8, _hoisted_4))
              }), 128))
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createVNode(_component_AppTypographyText, {
            variant: "md",
            type: "small",
            label: "You’ll see class assignments here once you have created them."
          })
        ])),
    _createVNode(_component_CreateClassPopup, {
      modelValue: _ctx.editClassModalOpen,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editClassModalOpen) = $event)),
      isEdit: true
    }, null, 8, ["modelValue"])
  ]))
}