import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GoogleDriveFileUploader = _resolveComponent("GoogleDriveFileUploader")!

  return (_openBlock(), _createBlock(_component_GoogleDriveFileUploader, {
    selectedFiles: _ctx.selectedFileModel,
    "onUpdate:selectedFiles": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedFileModel) = $event)),
    multiple: false,
    acceptedFiles: ".pdf,.docx,.doc",
    gDriveAcceptedFiles: "application/pdf,application/vnd.google-apps.document",
    supportedFileTypes: "Supported file types: PDF, Microsoft Word & Google Doc",
    progress: _ctx.progress,
    onOnRemoveFile: _ctx.handleRemoveFile
  }, null, 8, ["selectedFiles", "progress", "onOnRemoveFile"]))
}