import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-662c8404"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "classes" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_ClassesComponent = _resolveComponent("ClassesComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageTitle, {
      title: "Class",
      routes: _ctx.routes
    }, null, 8, ["routes"]),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.id)
        ? (_openBlock(), _createBlock(_component_ClassesComponent, {
            key: 0,
            classUuid: _ctx.id,
            onOnClassInfoLoaded: _ctx.handleClassInfoLoaded
          }, null, 8, ["classUuid", "onOnClassInfoLoaded"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}