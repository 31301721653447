<template>
  <div
    class="flex flex-col py-[26px] px-[30px] bg-[#FFF6D1] rounded-lg justify-between"
  >
    <h6 class="text-[18px] leading-[1.5] text-[#333] mb-[23px] font-bold">
      Create a class submission link
    </h6>
    <p class="text-[#595959] text-[14px] leading-[1.5] mb-[30px] font-medium">
      Want your students to upload their work so you can mark it? Start by
      creating a class submission link to share with your students.
    </p>
    <AppButton
      class="!bg-[#FFCC8A] hover:opacity-80"
      @click="
        () => {
          if (handleSubmissionModal) {
            handleSubmissionModal();
          }
        }
      "
      >Create a class submission link</AppButton
    >
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import AppButton from "../../Layout/Buttons/AppButton.vue";
import { icons as AppIcons, icons } from "@/utils/icons";
import { StorageDataKey } from "@/enums/enums";
import AppTypographyText from "../../Layout/Typhography/AppTypographyText.vue";
import DashboardService from "@/services/DashboardService";
import axios, { AxiosResponse } from "axios";
@Component({
  components: {
    AppButton,
    AppTypographyText,
  },
})
export default class TaskClassSubmissionCard extends Vue {
  //
  @Prop({
    type: Function,
  })
  handleSubmissionModal!: () => void;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
