<template>
  <div class="navigation">
    <div class="wrapper">
      <div class="left-wrap">
        <div class="burger-wrap">
          <button @click="handleBurger" class="btn-burger">
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
        <div class="logo-wrap">
          <router-link to="/">
            <img src="@/assets/flohh-logo.svg" />
          </router-link>
        </div>
      </div>

      <template v-if="isCurrentPath('/dashboard')">
        <DashboardNavComponent />
      </template>
      <template v-else-if="isCurrentPath('/marking')">
        <MarkingNavComponent />
      </template>

      <div class="btn-profile-wrap relative">
        <button @click="handleProfileIconClick(!isProfileClicked)">
          <img src="@/assets/icon-user.svg" />
        </button>
        <div
          class="profile-menu"
          v-if="isProfileClicked"
          v-on:blur="handleProfileIconClick(false)"
        >
          <div class="mb-[5px] cursor-pointer" @click="handleClickMyAccount">
            My Account
          </div>
          <div>Logout</div>
        </div>
      </div>
    </div>
    <MyAccount
      v-model="isOpenMyAccount"
      @onEditMyAccount="handleClickEditMyAccount"
    />
    <EditMyAccountPopup
      v-model="isOpenMyEditMyAccount"
      @onCloseMyAccount="handleClickCloseEditMyAccount"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
// import { useStore } from "vuex";

import DashboardNavComponent from "./DashboardNavComponent.vue";
import MarkingNavComponent from "./MarkingNavComponent.vue";
import MyAccount from "@/components/User/MyAccount.vue";
import EditMyAccountPopup from "@/components/User/EditMyAccountPopup.vue";

// import * as getters from "@/store/user/getter-keys";

// interface IFormattedTime {
//   time: number;
//   formatted: string;
// }

@Component({
  components: {
    DashboardNavComponent,
    MarkingNavComponent,
    MyAccount,
    EditMyAccountPopup,
  },
})
export default class NavigationComponent extends Vue {
  isProfileClicked = false;
  isOpenMyAccount = false;
  isOpenMyEditMyAccount = false;

  isCurrentPath(path: string): boolean {
    return this.$route.path === path;
  }
  handleBurger() {
    this.$emit("updateSideNavigation", "");
  }

  handleProfileIconClick(value: boolean) {
    this.isProfileClicked = value;
  }

  handleClickMyAccount() {
    this.isOpenMyAccount = true;
    this.isProfileClicked = false;
  }

  handleClickEditMyAccount() {
    this.isOpenMyAccount = false;
    this.isOpenMyEditMyAccount = true;
  }

  handleClickCloseEditMyAccount() {
    this.isOpenMyEditMyAccount = false;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.navigation {
  position: fixed;
  top: 0;
  display: flex;
  width: 100%;
  height: 80px;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  z-index: 20;
  background-color: #f4cddc;
  box-shadow: none;

  .logo-wrap {
    width: 200px;
  }
  a {
    color: #333;
    text-decoration: none;
    font-size: 14px;
    &.router-link-active {
      font-weight: 700;
    }
    &:hover {
      text-decoration: underline;
    }
  }
  .wrapper {
    padding: 0 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    ul {
      width: 412px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      li {
        margin-left: 15px;
      }
    }
  }

  .left-wrap,
  .right-wrap {
    display: flex;
    align-items: center;
  }
  .left-wrap {
    justify-content: flex-start;
  }
  .right-wrap {
    justify-content: flex-end;
  }
  .burger-wrap {
    margin-right: 15px;
    display: flex;
    align-items: center;
  }
  .btn-burger {
    width: 36px;
    height: 20px;
    position: relative;
    span {
      width: 100%;
      height: 4px;
      background-color: #555;
      display: block;
      border-radius: 2px;
      position: relative;
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .btn-profile-wrap {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-left: auto;
    margin-right: 10px;

    width: 100%;
    max-width: 50px;

    button {
      display: flex;
      align-items: center;
      background-color: rgb(208, 178, 154);
      border-radius: 4px;
      height: 44px;
      padding: 0 15px;

      transition: 0.3s ease;
      &:hover {
        opacity: 0.6;
      }
      img {
        width: 100%;
      }
      span {
        display: block;
        color: #fff;
        font-weight: bold;
        font-size: 18px;
      }
    }
  }

  .profile-menu {
    position: absolute;
    width: 200px;
    background-color: #f5f1f4;
    top: 55px;
    padding: 10px;
  }
}
</style>
