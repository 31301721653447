import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54d884a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-white rounded-lg h-full classes-wrapper mt-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ClassesGridCard = _resolveComponent("ClassesGridCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.classes, (item, index) => {
      return (_openBlock(), _createBlock(_component_ClassesGridCard, {
        key: index,
        index: index,
        data: item,
        assignments: _ctx.assignments,
        onOnClassEdit: _ctx.handleEditClass,
        onOnStudentInvite: _ctx.handleInviteStudents,
        onOnClassDelete: _ctx.handleDeleteClass
      }, null, 8, ["index", "data", "assignments", "onOnClassEdit", "onOnStudentInvite", "onOnClassDelete"]))
    }), 128))
  ]))
}