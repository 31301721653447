import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex justify-between items-center mb-4" }
const _hoisted_2 = { class: "text-flohh-h6 font-flohh-font-bold" }
const _hoisted_3 = {
  key: 1,
  class: "flex justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CreationButton = _resolveComponent("CreationButton")!
  const _component_AssignmentsTableComponent = _resolveComponent("AssignmentsTableComponent")!
  const _component_AppTypographyText = _resolveComponent("AppTypographyText")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h6", _hoisted_2, _toDisplayString(_ctx.title), 1),
      _createVNode(_component_CreationButton)
    ]),
    (_ctx.assignmentData && _ctx.assignmentData.length > 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "overflow-y-auto flex flex-col space-y-7",
          style: _normalizeStyle(`max-height: ${_ctx.height};`)
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.assignmentData, (item, index) => {
            return (_openBlock(), _createBlock(_component_AssignmentsTableComponent, {
              key: index,
              data: item
            }, null, 8, ["data"]))
          }), 128))
        ], 4))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_AppTypographyText, {
            label: "No active assignments yet",
            type: "caption"
          })
        ]))
  ]))
}