import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "px-4" }
const _hoisted_2 = { class: "py-2 my-5 w-[60%]" }
const _hoisted_3 = { class: "w-full" }
const _hoisted_4 = { class: "flex gap-3 mb-5" }
const _hoisted_5 = { class: "flex gap-2 items-center" }
const _hoisted_6 = { class: "flex gap-2 items-center" }
const _hoisted_7 = { class: "flex gap-2 items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectClass = _resolveComponent("SelectClass")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_AppTypographyText = _resolveComponent("AppTypographyText")!
  const _component_FormRadioButton = _resolveComponent("FormRadioButton")!
  const _component_InviteViaLink = _resolveComponent("InviteViaLink")!
  const _component_InviteViaGoogleClassroom = _resolveComponent("InviteViaGoogleClassroom")!
  const _component_InviteViaFileUpload = _resolveComponent("InviteViaFileUpload")!
  const _component_InviteManually = _resolveComponent("InviteManually")!
  const _component_ModalUtility = _resolveComponent("ModalUtility")!

  return (_openBlock(), _createBlock(_component_ModalUtility, {
    modelValue: _ctx.openDialog,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.openDialog) = $event)),
    width: "50vw",
    title: "Invite Students",
    onOnClose: _ctx.handleCloseDialog
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_FormField, {
            label: "Select the Flohh class you'd like to invite students to",
            error: _ctx.classError,
            required: true
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_component_SelectClass, {
                modelValue: _ctx.selectedClassModel,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedClassModel) = $event)),
                disabled: false,
                key: _ctx.refreshKey,
                invalid: _ctx.classError !== '',
                defaultValue: _ctx.defaultClassUuid,
                onOnClassSelect: _ctx.handleClassSelect
              }, null, 8, ["modelValue", "invalid", "defaultValue", "onOnClassSelect"]))
            ]),
            _: 1
          }, 8, ["error"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_AppTypographyText, {
            label: "Select how you'd like to invite students",
            variant: "bd",
            type: "title",
            class: "mb-5"
          }),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_FormRadioButton, {
                modelValue: _ctx.selectedMethod,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedMethod) = $event)),
                name: "link",
                inputId: "link",
                value: 0
              }, null, 8, ["modelValue"]),
              _createElementVNode("label", {
                for: "link",
                class: _normalizeClass(["font-flohh-font-medium text-flohh-text-body cursor-pointer", _ctx.selectedMethod === 0 ? 'text-flohh-primary-pink' : ''])
              }, "Share a Link", 2)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_FormRadioButton, {
                modelValue: _ctx.selectedMethod,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedMethod) = $event)),
                name: "classroom",
                inputId: "classroom",
                value: 1
              }, null, 8, ["modelValue"]),
              _createElementVNode("label", {
                for: "classroom",
                class: _normalizeClass(["font-flohh-font-medium text-flohh-text-body cursor-pointer", _ctx.selectedMethod === 1 ? 'text-flohh-primary-pink' : ''])
              }, "Google Classroom", 2)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_FormRadioButton, {
                modelValue: _ctx.selectedMethod,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedMethod) = $event)),
                name: "upload",
                inputId: "upload",
                value: 2
              }, null, 8, ["modelValue"]),
              _createElementVNode("label", {
                for: "upload",
                class: _normalizeClass(["font-flohh-font-medium text-flohh-text-body cursor-pointer", _ctx.selectedMethod === 2 ? 'text-flohh-primary-pink' : ''])
              }, "Upload", 2)
            ]),
            _createVNode(_component_FormRadioButton, {
              modelValue: _ctx.selectedMethod,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedMethod) = $event)),
              name: "manual",
              inputId: "manual",
              value: 3
            }, null, 8, ["modelValue"]),
            _createElementVNode("label", {
              for: "manual",
              class: _normalizeClass(["font-flohh-font-medium text-flohh-text-body cursor-pointer", _ctx.selectedMethod === 3 ? 'text-flohh-primary-pink' : ''])
            }, "Manual", 2)
          ]),
          (_ctx.selectedMethod === 0)
            ? (_openBlock(), _createBlock(_component_InviteViaLink, {
                key: 0,
                selectedClass: _ctx.selectedClassInfo,
                onOnComplete: _ctx.handleInvitationCompleted,
                onOnClose: _ctx.handleCloseDialog,
                onOnClassError: _ctx.handleClassError
              }, null, 8, ["selectedClass", "onOnComplete", "onOnClose", "onOnClassError"]))
            : (_ctx.selectedMethod === 1)
              ? (_openBlock(), _createBlock(_component_InviteViaGoogleClassroom, {
                  key: 1,
                  selectedClass: _ctx.selectedClassInfo,
                  onOnGoogleRedirectBack: _ctx.handleGoogleCallBack,
                  onOnComplete: _ctx.handleInvitationCompleted,
                  onOnClassError: _ctx.handleClassError
                }, null, 8, ["selectedClass", "onOnGoogleRedirectBack", "onOnComplete", "onOnClassError"]))
              : (_ctx.selectedMethod === 2)
                ? (_openBlock(), _createBlock(_component_InviteViaFileUpload, {
                    key: 2,
                    selectedClass: _ctx.selectedClassInfo,
                    onOnComplete: _ctx.handleInvitationCompleted,
                    onOnClose: _ctx.handleCloseDialog,
                    onOnClassError: _ctx.handleClassError
                  }, null, 8, ["selectedClass", "onOnComplete", "onOnClose", "onOnClassError"]))
                : (_openBlock(), _createBlock(_component_InviteManually, {
                    key: 3,
                    selectedClass: _ctx.selectedClassInfo,
                    onOnComplete: _ctx.handleInvitationCompleted,
                    onOnClassError: _ctx.handleClassError
                  }, null, 8, ["selectedClass", "onOnComplete", "onOnClassError"]))
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "onOnClose"]))
}