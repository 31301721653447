import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col bg-white gap-7" }
const _hoisted_2 = { class: "grid gap-[24px] grid-cols-2 justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TaskMarkingCard = _resolveComponent("TaskMarkingCard")!
  const _component_TaskAssignmentSubmissionCard = _resolveComponent("TaskAssignmentSubmissionCard")!
  const _component_TaskClassSubmissionCard = _resolveComponent("TaskClassSubmissionCard")!
  const _component_TaskAssignmentCard = _resolveComponent("TaskAssignmentCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h6", {
      class: _normalizeClass(["text-[18px] font-flohh-font-bold text-[#333]", _ctx.titleSize ? '!text-[' + _ctx.titleSize + ']' : ''])
    }, " Complete your onboarding tasks ", 2),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.showMarkingCard)
        ? (_openBlock(), _createBlock(_component_TaskMarkingCard, {
            key: 0,
            hideMarkingCard: _ctx.hideMarkingCard
          }, null, 8, ["hideMarkingCard"]))
        : _createCommentVNode("", true),
      _createVNode(_component_TaskAssignmentSubmissionCard),
      (!_ctx.hasAssignments)
        ? (_openBlock(), _createBlock(_component_TaskClassSubmissionCard, {
            key: 1,
            handleSubmissionModal: _ctx.handleSubmissionModal
          }, null, 8, ["handleSubmissionModal"]))
        : _createCommentVNode("", true),
      (!_ctx.hasAssignments)
        ? (_openBlock(), _createBlock(_component_TaskAssignmentCard, { key: 2 }))
        : _createCommentVNode("", true)
    ])
  ]))
}