<script lang="ts">
import { Vue, Component, Prop } from "vue-facing-decorator";

export interface TRoute {
  title: string;
  routeName?: string;
  routeTo?: string;
  current: boolean;
}

@Component({ components: {} })
export default class PageTitle extends Vue {
  @Prop({
    type: String,
    default: "",
  })
  title!: string;

  @Prop({
    type: Array,
  })
  routes!: TRoute[];
}
</script>
<template>
  <Teleport to="#top_navigation_title">
    <div class="w-full flex flex-col ml-3 leading-tight">
      <div
        class="text-flohh-text-subtitle font-flohh-font-medium text-flohh-neutral-20"
      >
        {{ title }}
      </div>
      <div class="w-full flex items-center justify-start mt-1">
        <template v-for="(route, index) in routes" :key="index">
          <router-link
            class="text-flohh-text-small font-flohh-font-medium flex items-center"
            :class="{ '!font-bold': route.current }"
            :to="route.routeTo ? route.routeTo : { name: route.routeName }"
            v-if="route.routeName || route.routeTo"
          >
            <span
              class="truncate max-w-[200px] inline-block"
              v-tooltip.bottom="{
                value: route.title,
                showDelay: 500,
              }"
            >
              {{ route.title }}
            </span>
            <span v-if="index + 1 !== routes.length">&nbsp;/&nbsp;</span>
          </router-link>
          <p
            class="text-flohh-text-small font-flohh-font-medium flex items-center"
            :class="{ '!font-bold': route.current }"
            v-if="!route.routeName && !route.routeTo"
          >
            <span
              class="truncate max-w-[200px] inline-block"
              v-tooltip.bottom="{
                value: route.title,
                showDelay: 500,
              }"
            >
              {{ route.title }}
            </span>
            <span v-if="index + 1 !== routes.length"> / </span>
          </p>
        </template>
      </div>
    </div>
  </Teleport>
</template>
