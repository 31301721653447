import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-5" }
const _hoisted_2 = { class: "flex gap-5" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppTypographyText = _resolveComponent("AppTypographyText")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AppTypographyText, {
      label: "Download and print class coversheets for automatic splitting",
      variant: "bd",
      type: "title",
      class: "mb-5"
    }),
    (!_ctx.hasStudents)
      ? (_openBlock(), _createBlock(_component_AppTypographyText, {
          key: 0,
          label: "How many students are in your class?",
          variant: "bd",
          type: "body",
          class: "mb-1"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.hasStudents)
        ? (_openBlock(), _createBlock(_component_FormField, {
            key: 0,
            class: "w-[60%]",
            modelValue: _ctx.studentCount,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.studentCount) = $event)),
            options: _ctx.options,
            optionLabel: "label",
            "option-value": "value",
            type: "select",
            required: true,
            background: false
          }, null, 8, ["modelValue", "options"]))
        : _createCommentVNode("", true),
      _createVNode(_component_AppButton, {
        class: _normalizeClass(_ctx.hasStudents ? 'w-full' : 'w-[40%] min-w-[250px]'),
        type: "primary",
        loading: _ctx.downloadingCoversheets,
        disabled: _ctx.downloadingCoversheets,
        onClick: _ctx.handleDownloadCoversheets
      }, {
        icon_left: _withCtx(() => [
          _createElementVNode("span", {
            innerHTML: _ctx.icon.iconDownload
          }, null, 8, _hoisted_3)
        ]),
        default: _withCtx(() => [
          _createTextVNode(" Download Coversheets ")
        ]),
        _: 1
      }, 8, ["class", "loading", "disabled", "onClick"])
    ])
  ]))
}