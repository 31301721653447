import { ActionContext } from "vuex";
import { RootState } from "@/store";
import * as mutationType from "../mutation-types";
import { AssignmentState, SelectedAssignmentDetails } from "../assignmentTypes";

export const SetSelectedAssignmentAction = async (
  context: ActionContext<AssignmentState, RootState>,
  assignment: SelectedAssignmentDetails
) => {
  context.commit(mutationType.SET_SELECTED_ASSIGNMENT, assignment);
};
