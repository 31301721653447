<template>
  <div class="w-100 flex justify-between items-center">
    <div class="z-[1] flex relative flex-col justify-center">
      <h6
        v-if="hideAssignmentDetails"
        class="text-flohh-h6 font-flohh-font-bold"
      >
        <span v-if="tabType === 'markbook'">Markbook</span>
        <span v-else-if="tabType === 'member'">Members</span>
        <span v-else-if="tabType === 'learning-goal'">Learning Goal</span>
        <span v-else-if="tabType && tabType.includes('all')"
          >All Assignments</span
        >
      </h6>
      <div v-else>
        <div class="flex gap-3">
          <p
            class="text-flohh-text-title font-flohh-font-bold text-flohh-neutral-20 mb-[5px] text-flohh-text-body underline cursor-pointer"
            @click="handleClickViewAssignment"
            href="javascript:"
          >
            {{ selectedAssignmentData?.title }}
          </p>
          <button
            class="translate-y-[-3px]"
            @click="handleEditAssignment"
            v-tooltip.top="{
              value: 'Edit Assignment',
              showDelay: 500,
            }"
          >
            <span v-html="icon.pencilBlack"></span>
          </button>
        </div>
        <p class="text-sm text-neutral-gray-500 mb-[15px]">
          Due Date: {{ formatDate(selectedAssignmentData?.dueTimestamp) }}
        </p>
        <div
          class="w-full max-w-[500px]"
          v-if="
            assignmentSubmissionSummary &&
            assignmentSubmissionSummary.totalStudents &&
            assignmentSubmissionSummary.completedSubmissions >= 0 &&
            tabType === 'assignment'
          "
        >
          <p class="mb-[9px] text-flohh-text-caption text-flohh-neutral-20">
            Marked
            {{ totalMarkedAssignments }}/{{ totalSubmissionsDone }}
            submissions
          </p>
          <div
            class="h-[4px] w-full bg-white rounded-[4px] overflow-hidden relative progress-bar"
          >
            <div
              :style="{
                width: submissionProgressBar,
              }"
              :class="['absolute bg-flohh-secondary-green h-full']"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showUtilities" class="flex gap-2">
      <FormInputDropdown
        v-model="sortBy"
        :options="sortData"
        option-value="value"
        optionLabel="label"
        placeholder="Sort By..."
        :background="false"
        class="min-w-[250px]"
      />
      <FormTextInput
        type="text"
        name="search"
        v-model="searchText"
        placeholder="Search"
        @input="handleSearch"
        :iconLeft="'searchBlack'"
        :background="false"
        class="min-w-[150px]"
      />
      <AppDropdownButton
        v-if="tabType === 'markbook' || tabType === 'all-markbook'"
        size="sm"
        type="default"
        width="w-40"
        text
        @clickMenu="handleClickDownload"
        :menus="downloadMenu"
        dropdownIcon="iconDownload"
      />
    </div>
  </div>
  <ModalUtility v-model="isViewAssignment" :title="title">
    <template #content>
      <div
        v-if="selectedAssignmentData.assignmentType === 'document'"
        class="h-[77vh]"
      >
        <div v-if="assignmentPdfBase64" class="h-full">
          <PdfViewComponent :pdfBase64="assignmentPdfBase64" />
        </div>
        <LoadingState v-else state="Loading document" backdrop="95" />
      </div>
      <div v-else>
        <div
          v-if="selectedAssignmentData.component.content"
          v-html="selectedAssignmentData.component.content"
          class="p-5 instruction-text"
        ></div>
        <div v-else class="py-7 flex justify-center">
          <AppTypographyText
            label="No instructions found for this assignment"
            type="body"
            variant="md"
          />
        </div>
      </div>
    </template>
  </ModalUtility>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from "vue-facing-decorator";
import DataTable from "primevue/datatable";
import { SortDefault, Sort } from "./types";
import { useToast } from "primevue/usetoast";
import { icons as AppIcons } from "@/utils/icons";
import {
  AssigmentSubmissionSummaryData,
  AssignmentData,
} from "@/store/assignment/assignmentTypes";
import ModalUtility from "@/components/utilities/ModalUtility.vue";
import { formatDate as DateFormatter, formatDate } from "@/utils/formatter";
import AppButton from "../Layout/Buttons/AppButton.vue";
import FormInputDropdown from "../Layout/Forms/FormInputDropdown.vue";
import FormTextInput from "../Layout/Forms/FormTextInput.vue";
import PdfViewComponent from "@/components/BulkUpload/PdfViewComponent.vue";
import LoadingState from "@/components/utilities/LoadingState.vue";
import emitter from "@/config/emitter";
import AppDropdownButton from "@/components/Layout/Buttons/AppDropdownButton.vue";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";

@Component({
  components: {
    DataTable,
    ModalUtility,
    FormInputDropdown,
    AppButton,
    FormTextInput,
    PdfViewComponent,
    LoadingState,
    AppTypographyText,
    AppDropdownButton,
  },
})
export default class ClassesTableUtilityComponent extends Vue {
  icon = AppIcons;
  toast = useToast();
  eventBus = emitter;

  @Prop({ type: Boolean, required: false })
  hideAssignmentDetails = false;

  @Prop({ type: Object, required: false })
  selectedAssignmentData!: AssignmentData;

  @Prop({ type: String, required: true })
  assignmentPdfBase64!: string;

  @Prop({
    type: Object,
    required: false,
  })
  assignmentSubmissionSummary!: AssigmentSubmissionSummaryData;

  @Prop({
    type: String,
    required: false,
  })
  submissionProgressBar!: string;

  @Prop({
    type: Number,
    required: false,
  })
  totalSubmissionsDone!: number;

  @Prop({
    type: Number,
    required: false,
  })
  totalMarkedAssignments!: number;

  @Prop({
    type: Object,
    required: true,
  })
  sortDefault!: SortDefault;

  @Prop({ type: String, required: false })
  tabType!: string;

  @Prop({
    type: Boolean,
    default: true,
  })
  showUtilities!: boolean;

  title = "";
  isViewAssignment = false;
  filteredAssignmentSubmissionSummary!: AssigmentSubmissionSummaryData;
  sortBy = "";
  sortData: Sort[] = [];
  searchText = "";

  downloadMenu = [{ name: "Export To Excel" }, { name: "Export To CSV" }];

  @Watch("sortBy")
  sortByWatcher(value: string) {
    if (value) {
      this.handleSort();
    }
  }

  formatDate(date: string) {
    if (!date) return "";
    return DateFormatter(date, "MM/DD/YYYY hh:mm A");
  }

  mounted() {
    // this.sortBy = this.sortDefault.sortBy;
    this.sortData = this.sortDefault.sortData;
  }

  async handleClickViewAssignment() {
    this.eventBus.emit("EVENT_TRIGGER", "G003");
    this.isViewAssignment = !this.isViewAssignment;

    this.title = `${this.selectedAssignmentData.title} - ${this.selectedAssignmentData.class.code} - ${this.selectedAssignmentData.class.subject}`;
  }

  @Emit("onDownloadMarkbook")
  handleClickDownload(fileType: string) {
    const downloadType = fileType;
    return downloadType;
  }

  handleEditAssignment() {
    this.$router.push({
      path: `/assignments/${this.selectedAssignmentData?.uuid}`,
    });
  }

  showToast(
    message: string,
    severity: "success" | "info" | "warn" | "error" | undefined
  ) {
    this.toast.add({
      severity: severity,
      detail: message,
      life: 3000,
    });
  }

  @Emit("onSort")
  handleSort() {
    this.eventBus.emit("EVENT_TRIGGER", "G004");
    return this.sortBy;
  }

  @Emit("onSearch")
  handleSearch() {
    return this.searchText;
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.progress-bar {
  background-color: #f2f2f2 !important;
}
.p-5.instruction-text > p > a {
  text-decoration: underline !important;
  color: #1a73e8 !important;
}
</style>
