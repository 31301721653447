<script lang="ts">
import Dialog from "primevue/dialog";
import { Vue, Model, Component, Prop, Emit, Watch } from "vue-facing-decorator";
import Button from "primevue/button";
import {
  ResponsiveBreakpoints,
  defaultModalBreakPoints,
} from "./utilitiesTypes";

@Component({ components: { Dialog, Button } })
export default class ModalUtility extends Vue {
  @Prop({
    type: String,
    default: "",
    required: false,
  })
  title!: string;

  @Prop({
    type: String,
    default: "",
    required: false,
  })
  subtitle!: string;

  @Prop({
    type: Boolean,
    default: true,
  })
  modal!: boolean;

  @Prop({
    type: Object,
    default: defaultModalBreakPoints,
    required: false,
  })
  breakpoints!: ResponsiveBreakpoints;

  @Prop({
    type: String,
    required: false,
    default: "50vw",
  })
  width!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  maximize!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  fullScreen!: boolean;

  @Prop({
    type: Boolean,
    default: true,
  })
  showHeader!: boolean;

  @Prop({
    type: String,
    required: false,
  })
  containerStyle!: string;

  @Prop({
    type: String,
    default: "",
  })
  contentStyle!: string;

  @Prop({
    type: Boolean,
    default: true,
  })
  showCustomCloseButton!: boolean;

  @Prop({
    type: Number,
    required: false,
  })
  baseZIndex!: number;

  @Model({
    type: Boolean,
    required: true,
  })
  visible!: boolean;

  @Model({
    type: Boolean,
    required: false,
    default: false,
  })
  draggable!: boolean;

  @Watch("visible")
  isModalOpenWatcher(value: boolean) {
    const el = document.getElementsByTagName("body");
    if (el.length > 0) {
      if (value) {
        el[0].style.overflow = "hidden";
      } else {
        el[0].removeAttribute("style");
      }
    }
  }

  @Emit("onClose")
  handleOnCloseEmit() {
    this.visible = false;
    return;
  }

  handleClickClose() {
    this.handleOnCloseEmit();
  }

  beforeUnmount() {
    const body = document.querySelector("body");
    if (body) {
      body.style.overflow = "";
    }
  }
}
</script>
<template>
  <Dialog
    v-model:visible="visible"
    :modal="modal"
    class="ModalUtility"
    :class="[
      fullScreen ? '!max-w-[calc(100%_-_90px)] !w-full !h-full' : '',
      containerStyle,
    ]"
    :header="title"
    :style="{ width: width, minWidth: '450px', zIndex: 1000 }"
    :breakpoints="breakpoints"
    :maximizable="maximize"
    :showHeader="showHeader"
    :contentStyle="contentStyle"
    :closable="false"
    :base-z-index="baseZIndex"
    :draggable="draggable"
  >
    <template #header>
      <slot name="header" :handleClickClose="handleClickClose">
        <div
          class="w-full h-[76px] bg-flohh-primary-pink rounded-t-[4px] sm:py-0 flex flex-col justify-center items-start bg-[url('@/assets/modal-header-bg.png')] bg-repeat !bg-contain z-[1]"
        >
          <div
            class="absolute top-50 right-50 px-4 flex flex-col items-start justify-center w-full"
          >
            <h1
              class="text-flohh-h6 font-flohh-font-bold text-flohh-neutral-20"
            >
              {{ title }}
            </h1>
            <p
              v-if="subtitle"
              class="font-flohh-font-medium text-flohh-text-body"
            >
              {{ subtitle }}
            </p>
            <!-- Use for teleportation don't remove this tag -->
            <p id="modal-subtitle-teleport" class="font-semibold"></p>
            <!-- end of teleportation -->
          </div>
          <div class="btn-close-wrap absolute top-[10px] right-[10px]">
            <button
              class="p-2 text-white rounded-full"
              @click="handleClickClose"
            >
              <img src="@/assets/close.svg" class="w-4" />
            </button>
          </div>
        </div>
      </slot>
    </template>
    <div>
      <slot name="content" :handleClickClose="handleClickClose" />
    </div>
    <template v-if="$slots.footer" #footer>
      <slot name="footer" :handleClickClose="handleClickClose">
        <div id="modal_utility_teleport" />
      </slot>
    </template>
  </Dialog>
</template>
<style lang="css">
.ModalUtility .p-dialog-content {
  padding: 0 !important;
  height: 100%;
}

.ModalUtility .p-dialog-header {
  padding: 0 !important;
}
</style>
