<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-facing-decorator";
import { icons as AppIcons } from "@/utils/icons";
import { Navigation } from "@/utils/navigations";
import SidePanelNavigation from "@/components/Layout/Partials/SidePanelNavigation.vue";

@Component({
  components: {
    SidePanelNavigation,
  },
})
export default class FullSidePanel extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  navigation!: Navigation[];

  @Prop({
    type: Boolean,
    required: false,
  })
  isSuperAdmin = false;

  icons = AppIcons;

  selectedNavigation!: string;
  visible = false;
  navigateTo = "#";
  roles!: string[];

  @Watch("$route")
  routerWatcher(value: any) {
    if (value) {
      this.selectedNavigation = value.name;
      this.visible = !this.visible;
    }
  }

  mounted() {
    const auth = localStorage.getItem("auth");
    if (auth) {
      const roles = JSON.parse(auth).data.roles;
      if (roles.length > 0) {
        this.navigateTo = roles[0].includes("student")
          ? "/student/dashboard"
          : "/dashboard";
        this.roles = roles;
      }
    }
  }
}
</script>
<template>
  <div class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
    <!-- Sidebar component, swap this element with another sidebar if you like -->
    <div
      class="flex grow flex-col overflow-y-auto border-r border-flohh-primary-light-pink px-1 pb-4 bg-flohh-primary-light-pink bg-[url('@/assets/sidepanel-bg.png')] bg-repeat bg-[length:100%_auto]"
    >
      <div
        class="flex shrink-0 items-center justify-center pt-5 gap-y-3 px-6 flex-col"
      >
        <router-link :to="navigateTo">
          <img
            src="@/assets/flohh-logo.svg"
            class="w-[239px] h-[63.18px] m-[8px]"
            alt="Your Company"
        /></router-link>
        <div
          class="border-b border-flohh-primary-pink border w-[239px] border-solid"
        />
      </div>
      <SidePanelNavigation :navigation="navigation" :roles="roles" />
    </div>
  </div>
</template>
