import { RouteRecordRaw } from "vue-router";
const GradeSchemaBankModule = () => import("./GradeSchemaBankModule.vue");

const moduleRoute: RouteRecordRaw = {
  path: "/grade-schema-bank",
  component: GradeSchemaBankModule,
  props: true,
  children: [
    {
      path: "",
      name: "Grade Schema Bank View",
      component: () => import("./Views/GradeSchemaBankView.vue"),
      meta: {
        title: "Grade Schema Bank",
      },
    },
  ],
};

export default moduleRoute;
