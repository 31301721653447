<template>
  <ModalUtility title="Edit Student" v-model="modalOpen" width="35vw">
    <template #content>
      <div class="w-full py-7 px-4">
        <div v-if="email" class="flex flex-col gap-1 mb-5">
          <p class="font-semibold text-sm">Email</p>
          <FormTextInput
            type="email"
            v-model="email"
            name="email"
            :disabled="true"
          />
        </div>
        <div class="flex flex-col gap-1 mb-5">
          <p class="font-semibold text-sm mb-1">First Name</p>
          <FormField
            type="text"
            v-model="firstName"
            placeholder="Enter First Name"
            :error="errMsg.firstName"
            required
            name="firstName"
          />
        </div>
        <div class="flex flex-col gap-1 mb-5">
          <p class="font-semibold text-sm">Last Name</p>
          <FormField
            type="text"
            v-model="lastName"
            placeholder="Enter Last Name"
            :error="errMsg.lastName"
            required
            name="lastName"
          />
        </div>

        <div class="w-full flex justify-end items-center">
          <AppButton
            type="submit"
            :loading="isLoading"
            :disabled="isLoading"
            @click="onSubmit"
          >
            <template #icon_left>
              <span v-html="icon.checkBlack"></span>
            </template>
            Save
          </AppButton>
        </div>
      </div>
    </template>
  </ModalUtility>
</template>

<script lang="ts">
import { Component, Prop, Vue, Model, Watch } from "vue-facing-decorator";
import { useToast } from "primevue/usetoast";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { icons as AppIcons } from "@/utils/icons";
import ModalUtility from "@/components/utilities/ModalUtility.vue";
import FormTextInput from "@/components/Layout/Forms/FormTextInput.vue";
import StudentService from "@/services/StudentService";
import { AxiosResponse } from "axios";
import FormField from "@/components/Layout/Forms/FormField.vue";
import emitter from "@/config/emitter";
import { AssignmentSubmissionSummaryStudent } from "@/store/assignment/assignmentTypes";

@Component({
  components: {
    AppButton,
    ModalUtility,
    FormTextInput,
    FormField,
  },
})
export default class EditStudentModal extends Vue {
  private studentService: StudentService = new StudentService();
  eventBus = emitter;

  @Model({
    default: false,
    required: false,
  })
  modalOpen!: false;

  @Prop({
    default: null,
    required: false,
  })
  studentData!: AssignmentSubmissionSummaryStudent;

  @Prop({
    type: String,
    required: true,
  })
  classUuid!: string;

  icon = AppIcons;
  toast = useToast();

  errMsg = {
    firstName: "",
    lastName: "",
  };

  isLoading = false;

  firstName = "";
  lastName = "";
  email = "";

  @Watch("modalOpen")
  openModalWatcher(value: boolean) {
    if (value) {
      const profile = this.studentData.profile;
      this.firstName = profile.firstName;
      this.lastName = profile.lastName;
      this.email = this.studentData.email;
    }
  }

  @Watch("firstName")
  firstNameWatcher(value: string) {
    this.errMsg.firstName = value ? "" : "Invalid First Name";
  }

  @Watch("lastName")
  lastNameWatcher(value: string) {
    this.errMsg.lastName = value ? "" : "Invalid Last Name";
  }

  validateForm() {
    let isFormValid = true;
    if (!this.firstName) {
      this.errMsg.firstName = "Invalid First Name";
      isFormValid = false;
    }

    if (!this.lastName) {
      this.errMsg.lastName = "Invalid Last Name";
      isFormValid = false;
    }

    return isFormValid;
  }

  public async onSubmit() {
    this.isLoading = true;

    const payload = {
      firstName: this.firstName,
      lastName: this.lastName,
    };
    try {
      const isValid: boolean = this.validateForm();

      if (isValid) {
        const response: AxiosResponse = await this.studentService.putStudent(
          payload,
          this.studentData.uuid,
          this.classUuid
        );
        if (response.data.ok) {
          this.showToast("success", "Student details updated successfully");
          // this.eventBus.emit("REFRESH_ASSIGNMENT");
          this.eventBus.emit("REFRESH_SELECTED_CLASS_NO_LOADING");
        } else {
          throw new Error();
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
      this.modalOpen = false;
    }
  }

  showToast(
    severity: "success" | "info" | "warn" | "error" | undefined,
    message: string
  ) {
    this.toast.add({
      severity: severity,
      detail: message,
      life: 3000,
    });
  }
}
</script>
