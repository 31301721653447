<template>
  <ModalUtility
    v-model="modalOpen"
    width="55vw"
    :title="modalTitle"
    @onClose="handleModalClose"
    :draggable="true"
    containerStyle="min-w-[600px]"
  >
    <template #content>
      <div v-if="!inviteLink" class="px-6 py-4">
        <div class="text-center mb-[24px]">
          <AppTypographyText
            label="Let's get your submission link set up. Select your class and assignment below. "
            variant="bd"
            type="subtitle"
          />
          <AppTypographyText
            class="underline"
            label="Note: Generating this link will immediately make the assignment visible to students for submissions."
            type="body"
          />
        </div>

        <AppTypographyText
          label="Class name"
          variant="md"
          type="body"
          class="mb-2"
        />
        <FormField class="mb-3" :error="error.selectedClass" required>
          <SelectClass
            v-model="selectedClass"
            :classLabel="classLabel"
            placeholder="Select your class"
            :defaultValue="selectedClassDefault"
            :invalid="error && error.selectedClass ? true : false"
            @onClassSelect="handleSelectedClass"
        /></FormField>
        <div v-if="selectedClass === 'create'" class="mb-5">
          <AppTypographyText
            label="What should we call your class?"
            variant="md"
            type="body"
            class="mb-2"
          />
          <FormField
            required
            :error="error.className"
            :background="false"
            placeholder="Year 9 English"
            v-model="className"
          />
        </div>

        <AppTypographyText
          label="Assignment name"
          variant="md"
          type="body"
          class="mb-2"
        />
        <FormField class="mb-3" :error="error.selectedAssignment" required>
          <SelectAssignment
            v-model="selectedAssignment"
            :assignmentLabel="assignmentLabel"
            :classUuid="selectedClass"
            placeholder="Select your assignment"
            :defaultValue="selectedAssignmentDefault"
            :invalid="error && error.selectedAssignment ? true : false"
            :disabled="selectedClass === 'create' ? true : false"
            :publishedOnly="true"
        /></FormField>
        <div v-if="selectedAssignment === 'create'" class="mb-5">
          <AppTypographyText
            label="What should we call your assignment?"
            variant="md"
            type="body"
            class="mb-2"
          />
          <FormField
            required
            :error="error.assignmentName"
            :background="false"
            placeholder="Romeo and Juliet Essay"
            v-model="assignmentName"
          />
        </div>
      </div>
      <div v-else class="px-6 py-4">
        <div class="mb-[24px] text-center">
          <AppTypographyText
            label="Awesome! Your submission link has been created and the assignment is now visible to students. Copy the link below and share it with your class."
            variant="bd"
            type="subtitle"
          />
        </div>

        <AppTypographyText variant="md" type="body" class="mb-2" />
        <div class="flex gap-2">
          <FormField
            class="mb-3 w-[70%]"
            v-model="inviteLink"
            type="text"
            readonly
          /><AppButton class="w-[30%]" type="primary" @click="handleCopyLink">
            <template #icon_left>
              <span v-html="icon.copyContent"></span>
            </template>
            Copy Link
          </AppButton>
        </div>
      </div></template
    >
    <template #footer
      ><div
        class="flex flex-row pt-5 border-t border-solid border-flohh-neutral-85"
      >
        <div v-if="inviteLink" class="flex-1 flex justify-start items-center">
          <AppButton text @click="handleGoBack"> Go Back </AppButton>
        </div>
        <div class="flex-1 flex justify-end items-center gap-x-4">
          <AppButton
            v-if="!inviteLink"
            type="submit"
            @click="handleGenerateLink"
            :disabled="generatingLink"
            :loading="generatingLink"
          >
            <template #icon_left>
              <span v-html="icon.iconLink"></span>
            </template>
            Generate Submission Link (Assignment Visible to Students)
          </AppButton>
          <AppButton v-else type="submit" @click="handleRedirectToClassPage">
            <template #icon_left>
              <span v-html="icon.checkBlack"></span>
            </template>
            Take me to my class page
          </AppButton>
        </div>
      </div></template
    ></ModalUtility
  >
</template>

<script lang="ts">
import { Vue, Component, Prop, Model, Watch } from "vue-facing-decorator";
import ModalUtility from "@/components/utilities/ModalUtility.vue";
import SelectAssignment from "@/components/utilities/SelectAssignment.vue";
import SelectClass from "@/components/utilities/SelectClass.vue";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import FormField from "@/components/Layout/Forms/FormField.vue";
import { icons } from "@/utils/icons";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import AssignmentService from "@/services/AssignmentService";
import { AxiosResponse } from "axios";
import { useToast } from "primevue/usetoast";
import { InstantAssignmentDetails } from "@/models/Assignment";
import emitter from "@/config/emitter";

@Component({
  components: {
    ModalUtility,
    SelectAssignment,
    SelectClass,
    AppTypographyText,
    FormField,
    AppButton,
  },
})
export default class GenerateSubmissionLinkModal extends Vue {
  private assignmentService: AssignmentService = new AssignmentService();
  icon = icons;
  toast = useToast();
  eventBus = emitter;

  @Model({
    type: Boolean,
    required: true,
    default: false,
  })
  modalOpen!: boolean;

  @Prop({
    type: Boolean,
    required: true,
    default: false,
  })
  createNew!: boolean;

  classLabel = "";
  assignmentLabel = "";

  selectedClass = "";
  selectedAssignment = "";

  className = "";
  assignmentName = "";

  selectedClassDefault = "";
  selectedAssignmentDefault = "";

  modalTitle = "Generate a Class Submission Link";
  generatingLink = false;

  inviteLink = "";

  error = {
    selectedClass: "",
    className: "",
    selectedAssignment: "",
    assignmentName: "",
  };

  handleSelectedClass(cls: any) {
    this.className = cls.code === "+ Create New Class" ? "" : cls.code;
  }

  @Watch("createNew")
  modalOpenWatcher(value: boolean) {
    this.toggleCreateInputText(value);
  }

  @Watch("selectedClass")
  selectedClassWatcher(value: string) {
    if (!value) {
      this.error.selectedClass = "Class is required";
    } else {
      if (value === "create") {
        this.selectedAssignment = "create";
      }
      this.error.selectedClass = "";
    }
  }

  @Watch("className")
  classNameWatcher(value: string) {
    if (!value) {
      this.error.className = "Class name is required";
    } else {
      this.error.className = "";
    }
  }

  @Watch("selectedAssignment")
  selectedAssignmentWatcher(value: string) {
    if (!value) {
      this.error.selectedAssignment = "Assignment is required";
    } else {
      this.error.selectedAssignment = "";
    }
  }

  @Watch("assignmentName")
  assignmentNameWatcher(value: string) {
    if (!value) {
      this.error.assignmentName = "Assignment name is required";
    } else {
      this.error.assignmentName = "";
    }
  }

  mounted() {
    this.classLabel = "+ Create New Class";
    this.assignmentLabel = "+ Create New Assignment";

    this.toggleCreateInputText(this.createNew);
  }

  toggleCreateInputText(value: boolean) {
    if (value) {
      this.selectedClassDefault = "create";
      this.selectedAssignmentDefault = "create";
      this.selectedClass = "create";
      this.selectedAssignment = "create";
    } else {
      this.selectedClassDefault = "";
      this.selectedAssignmentDefault = "";
    }
  }

  handleModalClose() {
    this.modalOpen = false;
  }

  validateForm() {
    let isValid = true;

    if (!this.selectedClass) {
      this.error.selectedClass = "Class is required";
      isValid = false;
    }

    if (!this.selectedAssignment) {
      this.error.selectedAssignment = "Assignment is required";
      isValid = false;
    }

    if (!this.className && this.selectedClass === "create") {
      this.error.className = "Class name is required";
      isValid = false;
    }

    if (!this.assignmentName && this.selectedAssignment === "create") {
      this.error.assignmentName = "Assignment name is required";
      isValid = false;
    }

    return isValid;
  }

  async handleGenerateLink() {
    try {
      const isValid = this.validateForm();
      if (isValid) {
        this.generatingLink = true;
        let assignmentResponse!: AxiosResponse;
        if (this.selectedAssignment === "create") {
          let payload: InstantAssignmentDetails = {
            assignmentName: this.assignmentName,
            className: this.className,
          };

          if (this.selectedClass !== "create") {
            payload.classUuid = this.selectedClass;
          }

          assignmentResponse =
            await this.assignmentService.instantCreateAssignment(payload);
        }

        const payload = {
          type: "assignment",
          uuid: assignmentResponse
            ? assignmentResponse.data.data.uuid
            : this.selectedAssignment,
        };

        const response: AxiosResponse =
          await this.assignmentService.generateAssignmentShareCode(payload);
        if (response.data.ok) {
          const code = response.data.data.code;
          const protocol = window.location.protocol;
          const hostname = window.location.host;
          this.inviteLink = `${protocol}//${hostname}/student/shared-assignment?share-code=${code}`;
        } else {
          throw new Error();
        }

        this.selectedAssignment =
          this.selectedAssignment === "create"
            ? assignmentResponse.data.data.uuid
            : this.selectedAssignment;

        this.selectedClass =
          this.selectedClass === "create"
            ? assignmentResponse.data.data.class.uuid
            : this.selectedClass;

        this.eventBus.emit("REFRESH_DASHBOARD_ASSIGNMENTS");
        this.eventBus.emit("LOAD_CLASSES");
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.generatingLink = false;
      this.modalTitle = "Share Your Class Submission Link";
    }
  }

  handleRedirectToClassPage() {
    this.$router.push({ name: "Classes", params: { id: this.selectedClass } });
  }

  handleCopyLink() {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(this.inviteLink)
        .then(() => {
          this.toast.add({
            severity: "success",
            summary: "Invite Link Copied",
            detail: "The invite link has been copied to your clipboard.",
            life: 3000,
          });
        })
        .catch((err) => {
          console.error("Unable to copy text: ", err);
        });
    }
  }

  handleGoBack() {
    this.inviteLink = "";
    this.modalTitle = "Generate a Class Submission Link";
  }
}
</script>

<style scoped></style>
