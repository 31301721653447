// ColorPicker.ts
//

import PSPDFKit, {
  Instance,
  Annotation,
  Color,
  CustomOverlayItem,
} from "pspdfkit";
import { TColorPreset, IColorPreset, IOption } from "./types";
import { ANNOTATION_BANK, SHAPE_ICONS } from "./data";
import { StorageDataKey } from "@/enums/enums";
import { escapeRegExp, generateGuid } from "@/utils/helper";

type CallbackFunction = (annotation: Annotation, instance: Instance) => void;
interface AnnotationTailObject {
  id: string;
}
interface InsertedStyles {
  [key: string]: boolean;
}

interface IShape {
  name: string;
  icon: string; // Assuming that 'icon' is a string representing the SVG icon
  selected: boolean;
}

interface ILibraryItem {
  customData: {
    libraryId: string;
    filterTag?: string;
  };
  color: Color;
  icon: string; // Assuming 'icon' is a string representing the icon identifier
  text: {
    value: string;
  };
}

const insertedStyles: InsertedStyles = {};
let styleTag!: HTMLStyleElement;
let toolColorPicker: CustomOverlayItem | null = null;

let activeTag!: string | undefined;

const selectedShapes: IShape[] = [
  {
    name: "COMMENT",
    icon: SHAPE_ICONS.COMMENT,
    selected: false,
  },
  {
    name: "CHECK",
    icon: SHAPE_ICONS.CHECK,
    selected: false,
  },
  {
    name: "CIRCLE",
    icon: SHAPE_ICONS.CIRCLE,
    selected: false,
  },
  {
    name: "CROSS",
    icon: SHAPE_ICONS.CROSS,
    selected: false,
  },
  {
    name: "HELP",
    icon: SHAPE_ICONS.HELP,
    selected: false,
  },
  {
    name: "STAR",
    icon: SHAPE_ICONS.STAR,
    selected: false,
  },
];

let inputSt!: any;

export const ColorPickerComponent = (
  instance: Instance,
  presetColors: TColorPreset
) => {
  styleTag = instance.contentDocument.head.appendChild(
    instance.contentDocument.createElement("style")
  );

  handleAnnotationFocus(instance, presetColors);
  handleAnnotationSelectionChange(instance, presetColors);
  handleAnnotationUpdate(instance);
  handleAnnotationDelete(instance);
  handlePagePress(instance);
};

export const handleAnnotationFocus = (
  instance: Instance,
  presetColors: TColorPreset
) => {
  instance.addEventListener("annotations.focus", (event) => {
    handleColorPickerOverlay(event.annotation, instance, presetColors);
  });
};

const handleAnnotationSelectionChange = (
  instance: Instance,
  presetColors: TColorPreset
) => {
  instance.addEventListener("annotations.willChange", (event) => {
    if (event.reason === "MOVE_START") {
      removeColorPickerOverlay(instance);
    }

    if (event.reason === "TEXT_EDIT_END") {
      removeColorPickerOverlay(instance);
    }

    if (event.reason === "MOVE_END") {
      const annotationsArray = event.annotations.toArray();
      if (annotationsArray.length > 0) {
        const annotation = annotationsArray[0];
        handleColorPickerOverlay(annotation, instance, presetColors);
      }
    }
  });
};

const handleAnnotationUpdate = (instance: Instance) => {
  instance.addEventListener("annotations.update", (event) => {
    const annotationsArray = event.toArray();
    if (annotationsArray.length > 0) {
      const updatedAnnotation = annotationsArray[0];
      if (updatedAnnotation instanceof PSPDFKit.Annotations.NoteAnnotation) {
        noteContentColor(updatedAnnotation, instance);
        if (updatedAnnotation) {
          handleColorPickerPosition(instance, updatedAnnotation);
        }

        handleColorPickerPosition(instance, updatedAnnotation);
      }
    }
  });
};

const handleAnnotationDelete = (instance: Instance) => {
  instance.addEventListener("annotations.delete", () => {
    removeColorPickerOverlay(instance);
  });
};

const handlePagePress = (instance: Instance) => {
  instance.addEventListener("page.press", () => {
    if (
      instance.contentDocument.querySelector(
        ".PSPDFKit-Popover-Note-Annotation"
      )
    ) {
      instance.setViewState((viewState) =>
        viewState.set("interactionMode", PSPDFKit.InteractionMode.NOTE)
      );
      instance.setViewState((viewState) =>
        viewState.set("interactionMode", null)
      );
    }
    instance.setViewState((viewState) =>
      viewState.set("interactionMode", null)
    );
    removeColorPickerOverlay(instance);
  });
  const viewport = instance.contentDocument.querySelector(
    ".PSPDFKit-ejvfc31fwjv43pf6dxbymn8xw"
  );
  viewport?.addEventListener("click", (event: Event) => {
    const elZoom: HTMLElement | null =
      instance.contentDocument.querySelector(".PSPDFKit-Zoom");
    const elViewport: HTMLElement | null =
      instance.contentDocument.querySelector(
        ".PSPDFKit-ejvfc31fwjv43pf6dxbymn8xw"
      );
    if (elZoom) {
      if (elZoom.parentNode) {
        if (event.target === elZoom.parentNode) {
          removeColorPickerOverlay(instance);
        }
      }
    }
    if (elViewport) {
      if (event.target === elViewport) {
        removeColorPickerOverlay(instance);
      }
    }
  });
};

const handleColorPickerOverlay = (
  annotation: Annotation,
  instance: Instance,
  presetColors: TColorPreset
) => {
  if (annotation && annotation instanceof PSPDFKit.Annotations.NoteAnnotation) {
    createColorPickerOverlay(
      "annotations.focus",
      instance,
      annotation,
      presetColors
    );
  } else {
    removeColorPickerOverlay(instance);
  }
};

export const createColorPickerOverlay = (
  event: string,
  instance: Instance,
  annotation: Annotation,
  presetColors: TColorPreset
) => {
  if (event === "annotations.focus") {
    getAnnotationById(instance, annotation.pageIndex, annotation.id).then(
      (res: any) => {
        instance.setCustomOverlayItem(
          colorPickerOverlay(res, presetColors, instance)
        );
        handlePopOverColor(instance, res);
        const container: HTMLElement | null =
          instance.contentDocument.querySelector(".extended-menu");
        if (container) {
          const parentElement = container.parentNode;
          const grandparentElement = parentElement
            ? parentElement.parentNode
            : null;
          const greatGrandparentElement = grandparentElement
            ? (grandparentElement.parentNode as HTMLElement)
            : null;
          if (greatGrandparentElement) {
            greatGrandparentElement.classList.add("not-clickable");
          }

          const listItem: NodeListOf<HTMLDivElement> =
            container.querySelectorAll<HTMLDivElement>(".pick-color");
          listItem.forEach((item: HTMLDivElement, index: number) =>
            item.addEventListener(
              "click",
              (e: Event) => {
                e.stopPropagation();
                const targetElement = e.target as HTMLElement;

                if (
                  targetElement &&
                  targetElement.classList.contains("your-target-class")
                ) {
                  e.stopPropagation();

                  const clickedElement = targetElement;

                  const parentElement = clickedElement.parentElement;
                  if (parentElement) {
                    const siblings = Array.from(
                      parentElement.children
                    ) as HTMLElement[];
                    for (const sibling of siblings) {
                      if (sibling !== clickedElement) {
                        sibling.classList.remove("active");
                      }
                    }
                  }

                  clickedElement.classList.add("active");
                }

                handleColorPick(
                  e,
                  index,
                  presetColors,
                  res,
                  instance,
                  noteContentColor
                );

                const element: HTMLElement | null =
                  instance.contentDocument.querySelector<HTMLElement>(
                    ".PSPDFKit-85vqs4jnfn7c69cf79rkfqesmn"
                  );

                if (element) {
                  // element.focus();
                }
              },
              {
                once: false,
              }
            )
          );

          const shapeItem =
            container.querySelectorAll<HTMLButtonElement>(".btn-shape");
          shapeItem.forEach((item: HTMLButtonElement, index: number) =>
            item.addEventListener(
              "click",
              (e: Event) => {
                e.stopPropagation();
                handleShapeItem(instance, index, annotation);
              },
              {
                once: false,
              }
            )
          );

          const filterTags =
            container.querySelectorAll<HTMLButtonElement>(".filter-item");
          filterTags.forEach((item: HTMLButtonElement, index: number) =>
            item.addEventListener(
              "click",
              (e: Event) => {
                e.stopPropagation();
                if (item) {
                  item.classList.remove("in-active");
                  if (item.classList.contains("active")) {
                    filterTags.forEach(function (element) {
                      element.classList.remove("active");
                      element.classList.remove("in-active");
                    });
                    handleFilterTags(instance, item, true);
                  } else {
                    filterTags.forEach(function (element, key) {
                      if (key !== index) {
                        element.classList.add("in-active");
                        element.classList.remove("active");
                      } else {
                        element.classList.add("active");
                      }
                    });
                    handleFilterTags(instance, item, false);
                  }
                }
              },
              {
                once: false,
              }
            )
          );
        }
        handleAnnotationItems(instance, annotation, presetColors);
        inputSt = setTimeout(() => {
          handleLibrarySearch(instance);
        }, 1);
      }
    );
  }
};

const handleAnnotationItemDisplay = (instance: Instance, isReturn: boolean) => {
  const storageAnnotationLibrary: string | null = localStorage.getItem(
    StorageDataKey.AnnotationLibrary
  );
  let annotationItem = "";
  const shape: Record<string, string> = SHAPE_ICONS;
  if (storageAnnotationLibrary) {
    const library: ILibraryItem[] = JSON.parse(storageAnnotationLibrary);
    library.map((item: ILibraryItem) => {
      const bgColor = `rgb(${item.color.r}, ${item.color.g}, ${item.color.b})`;
      annotationItem += `
      <button class="annotation-item annotation-${
        item.customData.libraryId
      }" annotation-id="${item.customData.libraryId}" filter-tag="${
        item.customData
          ? item.customData.filterTag
            ? item.customData.filterTag
            : ""
          : ""
      }" style="background-color:${bgColor}" >
        <i>${shape[item.icon]}</i>
        <span>${item.text.value}</span>
      </button>
      `;
    });
  }
  const el = instance.contentDocument.querySelector(
    ".extended-menu .annotation-bank"
  );
  if (isReturn) {
    return annotationItem;
  } else {
    if (el) {
      el.innerHTML = annotationItem;
    }
  }
};

const handleAnnotationItems = (
  instance: Instance,
  annotation: Annotation,
  presetColors: TColorPreset
) => {
  const container: HTMLElement | null =
    instance.contentDocument.querySelector(".extended-menu");
  if (container) {
    const annotationItem =
      container.querySelectorAll<HTMLButtonElement>(".annotation-item");
    annotationItem.forEach((item: HTMLButtonElement, index: number) =>
      item.addEventListener(
        "click",
        (e: Event) => {
          e.stopPropagation();
          handleAnnotationItem(instance, index, annotation, presetColors);
        },
        {
          once: false,
        }
      )
    );
  }
};

const removeColorPickerOverlay = (instance: Instance) => {
  if (
    instance.contentDocument.querySelector(".extended-menu") &&
    toolColorPicker
  ) {
    clearTimeout(inputSt);
    activeTag = undefined;
    instance.removeCustomOverlayItem("color-picker-tool");
    toolColorPicker = null;
    instance.contentDocument
      .querySelector(".PSPDFKit-c8up8r9n155axqjtb8dat8e5t")
      ?.removeEventListener("input", (event: Event) => {
        handleSearch(event, instance);
      });
  }
};

const colorPickerOverlay = (
  annotation: Annotation,
  presetColors: TColorPreset,
  instance: Instance
) => {
  const overlayElement = document.createElement("div");

  const posInitial = "position:absolute; z-index:9; width:105px;";
  const pos = posInitial;
  let posString = "";
  if (instance) {
    if (instance.contentDocument.querySelector(".PSPDFKit-Popover-Top")) {
      posString = "top";
    } else if (
      instance.contentDocument.querySelector(".PSPDFKit-Popover-Left")
    ) {
      posString = "left";
    } else if (
      instance.contentDocument.querySelector(".PSPDFKit-Popover-Right")
    ) {
      posString = "right";
    } else {
      posString = "bottom";
    }

    if (
      instance.contentDocument.querySelector(".PSPDFKit-Popover-Left") ||
      instance.contentDocument.querySelector(".PSPDFKit-Popover-Right")
    ) {
      //
      if (annotation.boundingBox.top > 520) {
        posString += " bot-mid";
      }
    } else {
      if (
        annotation.boundingBox.top > 144.5 &&
        annotation.boundingBox.top < 355
      ) {
        posString += " mid";

        if (annotation.boundingBox.left < 106) {
          posString += " left-mid";
        } else if (annotation.boundingBox.left > 300) {
          posString += " right-mid";
        }
      }
    }
  }

  overlayElement.style.cssText = pos;
  const color = `rgb(${annotation.color.r}, ${annotation.color.g}, ${annotation.color.b})`;
  let buttonShapes = "";
  selectedShapes.map((item: IShape) => {
    if (item) {
      buttonShapes += `<button class="btn-shape">${item.icon}</button>`;
    }
  });

  let filterItem = "";
  presetColors.map((item: IColorPreset) => {
    if (item.color) {
      const bgColor = `rgb(${item.color.r}, ${item.color.g}, ${item.color.b})`;
      filterItem += `
      <button class="filter-item" filter="${item.tag}" style="background-color:${bgColor}">
        ${item.localization.defaultMessage}
      </button>
      `;
    }
  });

  let html = "";
  html += `
    <div class="extended-menu ${posString}" style="background-color:#fff">
      <div class="top-wrap">
        <div class="shape-picker">
          ${buttonShapes}
        </div>
        <div class="color-picker"><ul style="list-style-type:none; padding:1px 0 0px;margin:0; cursor:pointer;">
            ${presetColors.map((item: IColorPreset, key: number) => {
              if (item.color) {
                const color = `rgb(${item.color.r}, ${item.color.g}, ${item.color.b})`;
                return `<li 
                class="pick-color" 
                title="${item.localization.defaultMessage}" 
                key="${key}"
                style="width:12px;height:12px;display:inline-block;border-radius:12px; overflow:hidden; margin:0 2px; text-indent:-1000px; cursor:pointer; background-color: ${color};"
              $nbsp;</li>`;
              }
            })}</ul>
          </div>
      </div>
      <div class="bottom-wrap">
        <div class="annotation-filter">
            ${filterItem}
        </div>
        <div class="annotation-bank">
        ${handleAnnotationItemDisplay(instance, true)}
        </div>
      </div>
    </div>
    `;
  overlayElement.innerHTML = html;
  overlayElement.onpointerup = (e) => {
    e.stopPropagation();
  };

  toolColorPicker = new PSPDFKit.CustomOverlayItem({
    id: "color-picker-tool",
    node: overlayElement,
    pageIndex: annotation ? annotation.pageIndex : 0,
    position: new PSPDFKit.Geometry.Point({
      x: annotation ? annotation.boundingBox.left : 0,
      y: annotation ? annotation.boundingBox.top : 0,
    }),
  });

  return toolColorPicker;
};

const setColorPreset = (instance: Instance, color: Color) => {
  const colorPreset = instance.annotationPresets;
  for (const property in colorPreset) {
    const item = colorPreset[property];
    if ("strokeColor" in item) {
      item.strokeColor = new PSPDFKit.Color(color);
    }
    if ("color" in item) {
      item.color = new PSPDFKit.Color(color);
    }
    if ("backgroundColor" in item) {
      item.backgroundColor = new PSPDFKit.Color(color);
    }
  }
  instance.setAnnotationPresets(colorPreset);
};

const handleColorPick = (
  e: Event,
  index: number,
  presetColors: TColorPreset,
  annotation: Annotation | null, // Adjust type here, assuming it can be null
  instance: Instance,
  callback: CallbackFunction | null | undefined
): void => {
  e.preventDefault();
  e.stopPropagation();
  if (annotation) {
    getAnnotationById(
      instance,
      annotation ? annotation.pageIndex : 0,
      annotation?.id
    ).then((res) => {
      const item = presetColors[index];
      if (item.color) {
        const color = new PSPDFKit.Color({
          r: item.color.r,
          g: item.color.g,
          b: item.color.b,
        });
        setColorPreset(instance, color);

        if (res) {
          const options: IOption = {
            filterTag: findColorTag(color, presetColors),
          };
          if (res.customData && typeof res.customData.annotationId === "number")
            options.annotationId = res.customData.annotationId;
          const editedAnnotation = res
            .set("color", color)
            .set("customData", options);

          if (callback) {
            callback(editedAnnotation, instance);
          }
          instance.update(editedAnnotation).then(() => {
            handlePopOverColor(instance, editedAnnotation);
          });
        }
      }
    });
  }
};

const handlePopOverColor = (instance: Instance, annotation: Annotation) => {
  if (!instance && !annotation) return;
  const element = instance.contentDocument.querySelector(
    "#NoteAnnotationContent-" + annotation.id
  );
  if (element) {
    element.setAttribute("data-color", annotation.color.toCSSValue());
  }
};

const findColorTag = (color: Color, presetColors: TColorPreset) => {
  const filter = presetColors.find((obj: IColorPreset) => {
    return obj.color &&
      color.r === obj.color.r &&
      color.g === obj.color.g &&
      color.b === obj.color.b
      ? obj
      : "";
  });
  return filter ? filter.tag : "";
};

const noteContentColor = (annotation: Annotation, instance: Instance) => {
  if (!insertedStyles[annotation.id + Date.now()] && instance) {
    insertedStyles[annotation.id + Date.now()] = true;

    const color = annotation.color ? annotation.color : annotation.strokeColor;
    const newStyles = instance.contentDocument.createTextNode(`
        #NoteAnnotationContent-${annotation.id} {
          background: ${color.toCSSValue()};
        }
      `);

    styleTag.appendChild(newStyles);
  }
};

const handleAnnotationItem = (
  instance: Instance,
  index: number,
  selectedAnnotation: Annotation,
  presetColor: TColorPreset
) => {
  const storageAnnotationLibrary: string | null = localStorage.getItem(
    StorageDataKey.AnnotationLibrary
  );
  if (storageAnnotationLibrary) {
    const library = JSON.parse(storageAnnotationLibrary);
    const annotation = library[index];
    const color = new PSPDFKit.Color({
      r: annotation.color.r,
      g: annotation.color.g,
      b: annotation.color.b,
    });

    const text = annotation.text;
    const currentAnnotation: Annotation | null | undefined =
      instance.getSelectedAnnotation();

    const options: IOption = {
      filterTag: findColorTag(color, presetColor),
    };
    if (currentAnnotation) {
      if (currentAnnotation.customData) {
        options.annotationId = currentAnnotation.customData
          .annotationId as number;
      }

      const editedAnnotation = currentAnnotation
        .set("color", color)
        .set("text", text)
        .set("customData", options)
        .set("icon", annotation.icon)
        .set("boundingBox", selectedAnnotation.boundingBox);
      instance.update(editedAnnotation).then(() => {
        try {
          // const matchIndex = library.findIndex(
          //   (a: Annotation) =>
          //     a && a.text.value && a.text.value == editedAnnotation["text"].value
          // )
          //   ? library.findIndex(
          //       (a: Annotation) =>
          //         a &&
          //         a.text.value &&
          //         a.text.value == editedAnnotation["text"].value
          //     )
          //   : null;
        } catch (err) {
          console.error(err, "ERROR");
        }
      });
    }
  }
};

const handleShapeItem = (
  instance: Instance,
  index: number,
  annotation: Annotation
) => {
  getAnnotationById(
    instance,
    annotation ? annotation.pageIndex : 0,
    annotation?.id
  ).then((res) => {
    if (res) {
      const color = new PSPDFKit.Color({
        r: res.color.r,
        g: res.color.g,
        b: res.color.b,
      });

      const editedAnnotation = res
        .set("color", color)
        .set("icon", selectedShapes[index].name)
        .set("text", res.text);
      instance.update(editedAnnotation);
    }
  });
};

export const getAnnotationById = (
  instance: Instance,
  pageIndex: number,
  annotationId: string
) => {
  const annotations = instance.getAnnotations(pageIndex ? pageIndex : 0);
  return annotations.then((annotation) => {
    const annotationsArray = annotation.toArray();
    if (annotationsArray) {
      return annotationsArray.find(function (obj: AnnotationTailObject) {
        return obj.id ? obj.id === annotationId : null;
      });
    } else {
      return null;
    }
  });
};

const handleColorPickerPosition = (
  instance: Instance,
  annotation: Annotation
) => {
  if (toolColorPicker && annotation) {
    const pos = new PSPDFKit.Geometry.Point({
      x: annotation.boundingBox.left,
      y: annotation.boundingBox.top,
    });
    const colorPickerUpdate = toolColorPicker.set("position", pos);
    instance.setCustomOverlayItem(colorPickerUpdate);
    const color = annotation.color;
    const bgColor = `rgb(${color.r}, ${color.g}, ${color.b})`;
    const extendedMenuElement: HTMLElement | null =
      instance.contentDocument?.querySelector(".extended-menu");
    if (extendedMenuElement) {
      // extendedMenuElement.style.backgroundColor = bgColor;
    }
  }
};

const handleFilterTags = (
  instance: Instance,
  item: HTMLButtonElement,
  showAll: boolean
) => {
  const filter: string | null = item.getAttribute("filter");
  if (filter) {
    hideElementsByFilterTag(instance, filter, showAll);
  }
};

const hideElementsByFilterTag = (
  instance: Instance,
  targetValue: string,
  showAll: boolean
): void => {
  const elements: NodeListOf<Element> =
    instance.contentDocument.querySelectorAll("[filter-tag]");
  elements.forEach((element: Element) => {
    const filterTagValue: string | null = element.getAttribute("filter-tag");
    if (showAll) {
      activeTag = undefined;
      (element as HTMLElement).style.display = "block"; // Hide the element
    } else {
      activeTag = targetValue;
      if (filterTagValue === targetValue) {
        (element as HTMLElement).style.display = "block"; // Hide the element
      } else {
        (element as HTMLElement).style.display = "none"; // Hide the element
      }
    }
  });
};

const handleSearch = (event: Event, instance: Instance) => {
  const evt = event.target as HTMLDivElement;
  const searchText = evt.innerHTML.trim();
  searchAnnotationBank(searchText, instance);
};

export const searchAnnotationBank = (search: string, instance: Instance) => {
  const library = handleAnnotationLibrary();
  const filteredData = filterAnnotationLibrary(library, activeTag) || library;
  const data = searchAnnotationLibrary(filteredData, search);
  if (search && search !== "") {
    const buttons =
      instance.contentDocument.querySelectorAll(".annotation-item");
    buttons.forEach((o: Element) => {
      const element = o as HTMLElement;
      element.style.display = "none";
    });
    data.map((item: Annotation) => {
      if (item.customData) {
        const libraryId = item.customData
          ? item.customData.libraryId
            ? item.customData.libraryId
            : generateGuid()
          : generateGuid();
        const buttons: HTMLElement | null =
          instance.contentDocument.querySelector(".annotation-" + libraryId);
        if (buttons) {
          buttons.style.display = "block";
        }
      }
    });
  } else {
    if (activeTag) {
      //
      handleAnnotationButtons(filteredData, instance);
    } else {
      const buttons =
        instance.contentDocument.querySelectorAll(".annotation-item");
      if (buttons) {
        buttons.forEach((o) => {
          const button = o as HTMLElement;
          button.style.display = "block";
        });
      }
    }
  }
};

const handleAnnotationButtons = (data: Annotation[], instance: Instance) => {
  const buttons = instance.contentDocument.querySelectorAll(".annotation-item");
  buttons.forEach((o) => {
    const button = o as HTMLElement;
    button.style.display = "none";
  });
  data.map((item: Annotation) => {
    const libraryId = item.customData
      ? item.customData.libraryId
        ? item.customData.libraryId
        : generateGuid()
      : generateGuid();
    const buttons: HTMLElement | null = instance.contentDocument.querySelector(
      ".annotation-" + libraryId
    );
    if (buttons) {
      buttons.style.display = "block";
    }
  });
};

const filterAnnotationLibrary = (
  library: Annotation[],
  tag: string | undefined
) => {
  if (tag) {
    const arr: Annotation[] = [];
    library.map((o: Annotation) => {
      if (o.customData && tag === o.customData.filterTag) {
        arr.push(o);
      }
    });
    return arr;
  } else {
    return library;
  }
};

const searchAnnotationLibrary = (
  library: Annotation[],
  text: string | undefined
) => {
  if (text === "" || !text) return library;
  return library.filter((o: Annotation) => {
    if (o) {
      return o.text.value
        .toLowerCase()
        .search(escapeRegExp(text.toLowerCase())) > -1
        ? o
        : null;
    }
  });
};

const handleAnnotationLibrary = () => {
  const storageAnnotationLibrary: string | null = localStorage.getItem(
    StorageDataKey.AnnotationLibrary
  );
  if (storageAnnotationLibrary) {
    return JSON.parse(storageAnnotationLibrary);
  } else {
    localStorage.setItem(
      StorageDataKey.AnnotationLibrary,
      JSON.stringify(ANNOTATION_BANK)
    );
  }
};

export const handleLibrarySearch = (instance: Instance) => {
  instance.contentDocument
    .querySelector(".PSPDFKit-c8up8r9n155axqjtb8dat8e5t")
    ?.addEventListener("input", (event: Event) => {
      handleSearch(event, instance);
    });
};
