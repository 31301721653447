import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-581fa7e7"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "flex pl-2"
}
const _hoisted_2 = {
  key: 1,
  class: "flex pl-2"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "avatar bg-flohh-neutral-85" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppTypographyText = _resolveComponent("AppTypographyText")!

  return (_ctx.data.length <= 5)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (user, i) => {
          return (_openBlock(), _createElementBlock("div", {
            key: i,
            class: _normalizeClass(["avatar", `avatar-${i}`])
          }, [
            _createVNode(_component_AppTypographyText, {
              label: _ctx.generateInitials(user.firstName, user.lastName)
            }, null, 8, ["label"])
          ], 2))
        }), 128))
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (i) => {
          return _createElementVNode("div", {
            key: i,
            class: _normalizeClass(`avatar avatar-container-${i}`)
          }, [
            (i <= 5)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_AppTypographyText, {
                    label: _ctx.generateInitials(_ctx.data[i].firstName, _ctx.data[i].lastName)
                  }, null, 8, ["label"])
                ]))
              : _createCommentVNode("", true)
          ], 2)
        }), 64)),
        _createElementVNode("div", _hoisted_4, "+" + _toDisplayString(_ctx.data.length - 5), 1)
      ]))
}