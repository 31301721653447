<template>
  <Teleport to="body">
    <div class="absolute z-[99] right-[300px] top-[157px]">
      <div
        class="criteria-sheet-panel relative overflow-hidden max-h-[80vh] pb-[20px] min-h-[605px] px-4 w-64 border border-solid border-flohh-neutral-85 rounded-lg bg-flohh-neutral-95"
        ref="criteriaSheetPopup"
      >
        <div
          class="flex w-full items-center pt-5 pb-4 mb-4 justify-between border-b border-solid border-flohh-neutral-85"
        >
          <p class="text-flohh-text-body color-flohh-neutral-5 font-medium">
            Criteria Sheet
          </p>
        </div>
        <div class="border-solid border-flohh-neutral-85">
          <div class="pagination flex justify-between w-full mb-[15px]">
            <button
              class="w-[32px] h-[32px] flex justify-center items-center bg-[#F9B2CE] rotate-180 rounded-[8px]"
              @click="handleCarousel('prev')"
              :class="isCarouselFirst ? 'opacity-60 pointer-events-none' : ''"
              v-tooltip.left="{
                value: 'Previous criteria',
                showDelay: 500,
              }"
            >
              <span v-html="appIcon.chevronLeftDark"></span>
            </button>
            <button
              class="w-[32px] h-[32px] flex justify-center items-center bg-[#F9B2CE] rounded-[8px]"
              @click="handleCarousel('next')"
              :class="isCarouselLast ? 'opacity-60 pointer-events-none' : ''"
              v-tooltip.right="{
                value: 'Next criteria',
                showDelay: 500,
              }"
            >
              <span v-html="appIcon.chevronRightDark"></span>
            </button>
          </div>

          <div
            class="panel-loader min-h-[465px] flex flex-col items-center justify-center"
            v-if="isLoading || isSaving"
            :class="
              isSaving
                ? 'absolute top-0 left-0 z-[9] w-full h-full min-h-[auto] bg-[rgba(0,0,0,0.5)] flex flex-col items-center justify-center'
                : ''
            "
          >
            <ProgressLoader
              v-if="isSaving"
              label="Saving"
              labelVariant="md"
              labelType="subtitle"
            />
          </div>
          <div>
            <Carousel
              ref="carouselRef"
              :value="layoutData"
              :numVisible="1"
              :numScroll="1"
              :showNavigators="false"
              :showIndicators="false"
            >
              <template #item="slotProps">
                <div
                  class="cell-wrap min-h-[465px] flex flex-col gap-[9px] justify-start items-center max-h-[65vh] overflow-y-auto overflow-hidden"
                >
                  <template v-for="item in slotProps.data" :key="item.key">
                    <div
                      class="cell w-[200px] h-[70px] min-h-[70px] bg-[#9CCEE2] rounded-[8px] relative overflow-hidden"
                      :class="!item.o.isCriteria ? 'cursor-pointer' : ''"
                      :style="{
                        backgroundColor: item.o.color,
                      }"
                      @click="handleSelectCell(item)"
                    >
                      <div
                        class="cell-bg h-[70px] absolute top-0 left-0 w-full pointer-events-none z-[1]"
                        :class="[
                          item.o.isSelected ? 'active' : '',
                          item.o.isHalfScore ? 'is-half' : '',
                        ]"
                      ></div>
                      <div
                        class="absolute z-[2] top-0 left-0 flex justify-between px-[4px] py-[5px] w-full"
                      >
                        <p
                          class="text-[10px] font-bold"
                          v-if="!item.o.isCriteria"
                        >
                          {{ item.o.criterionNumber }}
                        </p>
                        <p
                          v-if="
                            !item.o.isCriteria &&
                            item.o.isScorable &&
                            item.o.score > 0
                          "
                          class="text-[10px] font-bold"
                        >
                          {{ item.o.score }}
                          {{ item.o.score > 1 ? "pts" : "pt" }}
                        </p>
                        <p
                          class="text-[10px] font-bold"
                          v-if="!item.o.isScorable && !item.o.isCriteria"
                        >
                          0 pt
                        </p>
                      </div>
                      <div
                        class="py-[17px] px-[4px] relative z-[2]"
                        v-tooltip.left="{
                          value: renderText(item),
                          autoHide: false,
                          showDelay: 300,
                        }"
                      >
                        <p
                          class="text-[12px] leading-[1.5] overflow-hidden line-clamp-2 text-ellipsis"
                          :class="
                            item.o.isCriteria ? 'font-bold' : 'font-medium'
                          "
                        >
                          {{ renderText(item) }}
                        </p>
                      </div>
                      <div
                        class="absolute bottom-0 left-0 px-[4px] py-[4px] z-[2]"
                        v-if="item.o.criterionResourceLink"
                      >
                        <a
                          :href="item.o.criterionResourceLink"
                          target="_blank"
                          class="block w-[18px] h-[18px]"
                        >
                          <span
                            v-html="appIcon.iconLink"
                            class="block h-[18px] w-[18px] [&>*]:w-full [&>*]:h-full"
                          ></span>
                        </a>
                      </div>
                    </div>
                  </template>
                </div>
              </template>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script lang="ts">
import { Teleport, ref } from "vue";
import { Component, Prop, Vue, Watch, Ref } from "vue-facing-decorator";
import { AxiosResponse } from "axios";
import { icons as AppIcons } from "@/utils/icons";
import OverlayPanel from "primevue/overlaypanel";
import AppButton from "../Layout/Buttons/AppButton.vue";
import { useToast } from "primevue/usetoast";
import Emitter from "@/config/emitter";
import Carousel from "primevue/carousel";
import CriteriaSheetService from "@/services/CriteriaSheetService";
import {
  IAttachedResource,
  ICriteriaSheet,
  IGrades,
  IGradeSchema,
  IGradeSchemaItem,
} from "@/models/CriteriaSheet";
import {
  ILayoutItemObject,
  ILayoutItemRequired,
  TLayoutItems,
  ICategoryMap,
  ILayoutItem,
} from "./types";
import ProgressLoader from "@/components/utilities/ProgressLoader.vue";

interface IGeneratedScore {
  score?: number;
  grade?: string;
  percentage?: number;
  totalScore?: number;
}

@Component({
  components: {
    OverlayPanel,
    AppButton,
    Teleport,
    Carousel,
    ProgressLoader,
  },
})
export default class CriteriaSheetPanelComponent extends Vue {
  private criteriaSheetService = new CriteriaSheetService();
  toast = useToast();
  appIcon = AppIcons;
  @Ref("carouselRef") carouselRef!: any;

  @Prop({
    type: String,
    required: false,
  })
  studentId!: string;

  @Prop({
    type: String,
    required: false,
  })
  assignmentUuid!: string;

  @Prop({
    type: String,
    required: false,
  })
  submissionId!: string;

  @Prop({
    type: String,
    required: false,
  })
  gradeUuid!: string;

  @Prop({
    type: Object,
    required: true,
  })
  gradeSchema!: IGradeSchema;

  @Prop({
    type: Object,
    required: false,
  })
  grades!: IGrades;

  layout!: any;
  criteriaSheet!: any;
  criteriaSheetData!: any;
  generatedScore: IGeneratedScore = {
    score: 0,
    grade: "",
  };
  layoutData!: any;

  isCarouselLast = false;
  isCarouselFirst = true;
  isLoading = true;
  isSaving = false;
  sheetDetails!: any;

  async mounted() {
    await this.getData();
  }

  async saveCriteriaSheetGrade() {
    //
    this.isSaving = true;
    try {
      const matchedValues: any = [];

      this.criteriaSheetData.forEach((obj: any) => {
        const matchingObj = this.layoutData
          .flat()
          .find(
            (item: any) =>
              item.i === obj.cellConfig.detail.i && item.o.isSelected
          );
        if (matchingObj) {
          matchedValues.push({
            uuid: obj.uuid,
            score: matchingObj.o.isHalfScore ? obj.score / 2 : obj.score,
          });
        }
      });
      let res;
      let payload = {
        cells: matchedValues,
        student: this.studentId,
        submission: this.submissionId,
      };

      if (this.gradeUuid) {
        if (payload.cells.length) {
          res = await this.criteriaSheetService.updateGrade(
            payload,
            this.gradeUuid
          );
        } else {
          this.showToast(
            "Oops! Looks like you've left some criteria blank. Select the cells you want to mark before you try closing again.",
            "error",
            5000
          );
        }
      } else {
        //
        if (payload.cells.length) {
          res = await this.criteriaSheetService.postGrade(payload);
        } else {
          this.showToast(
            "Oops! Looks like you've left some criteria blank. Select the cells you want to mark before you try closing again.",
            "error",
            5000
          );
        }
      }

      if (res?.data.ok) {
        this.showToast("Criteria Sheet has been marked.", "success");
      }

      return res;
    } catch (e) {
      //
      console.error(e);
      this.isSaving = false;
    }
  }

  handleCarousel(event: string) {
    try {
      if (event === "next") {
        this.carouselRef.navForward();
      } else {
        this.carouselRef.navBackward();
      }
    } catch (e) {
      //
    }

    if (this.carouselRef.d_page === 0) {
      this.isCarouselFirst = true;
    } else {
      this.isCarouselFirst = false;
    }

    if (this.carouselRef.d_page === this.carouselRef.d_oldValue.length - 1) {
      this.isCarouselLast = true;
    } else {
      this.isCarouselLast = false;
    }
  }

  async getData() {
    let layoutData;
    const isTeacher = localStorage.getItem("authenticatedAs") === "teacher";
    if (isTeacher) {
      layoutData = await this.handleGetCriteriaData();
    }

    this.isLoading = false;
    // this.layout = layoutData;
  }

  async handleGetCriteriaData() {
    const res = await this.criteriaSheetService.getCriteriaSheetAssignment(
      this.assignmentUuid
    );

    if (res.data.data.length && res.data.data[0].cells.length) {
      return this.handleCriteriaLayout(res.data.data[0]);
    }
  }

  handleCriteriaLayout(criteriaData: any) {
    const data = criteriaData.cells;
    const sheetDetails = criteriaData.sheetDetails;
    const criterionPoint = sheetDetails.sheetConfig.detail.criterionPoints;
    const criteriaSheetCells: any = [];
    const selectedUUids: any = [];

    this.sheetDetails = sheetDetails.sheetConfig.detail;
    data.forEach((item: any) => {
      if (this.grades) {
        const selectedItem: any = this.grades.cells.find(
          (o: any) => o.uuid === item.uuid
        );
        if (selectedItem) {
          item.cellConfig.detail.o.isSelected = true;
          item.cellConfig.detail.o.isHalfScore =
            selectedItem.score === criterionPoint / 2 ? true : false;
        }
      }
      criteriaSheetCells.push(item.cellConfig.detail);
    });

    const lt =
      sheetDetails.sheetConfig.detail.layoutType === "criteria" ? "y" : "x";
    this.layoutData = this.handleLayoutData(criteriaSheetCells, lt, "x");

    this.layoutData.forEach((item: any) => {
      item.forEach((o: any) => {
        if (selectedUUids.includes(o.uuid)) {
          o.cellConfig.detail.o.isSelected = true;
        }
        o.o.score = o.o.isScorable
          ? o.o.score === 0
            ? o.o.score
            : sheetDetails.sheetConfig.detail.criterionPoints
          : 0;
      });
    });

    this.criteriaSheet = sheetDetails.sheetConfig.detail;
    this.criteriaSheetData = data;

    if (this.grades) {
      const score: any = this.grades.rawScore;
      this.generatedScore = {
        score: score,
        grade: this.grades.grade || "",
      };
    }

    return this.layoutData;
  }

  handleLayoutData(data: any, groupKey: string, sortKey: string) {
    const groupedData = Object.values(
      data.reduce((result: any, currentItem: any) => {
        (result[currentItem[groupKey]] =
          result[currentItem[groupKey]] || []).push(currentItem);
        return result;
      }, {})
    );

    Object.values(groupedData).forEach((group: any, index: number) => {
      const isCriteriaTrue = group.filter(
        (obj: any) => obj.o.isCriteria || obj.o.isBlank
      );
      const isCriteriaFalse = group.filter(
        (obj: any) => !obj.o.isCriteria && !obj.o.isBlank
      );
      group.length = 0;
      group.push(...isCriteriaFalse, ...isCriteriaTrue);

      group.sort((a: any, b: any) => {
        return a[sortKey] - b[sortKey];
      });

      // Find index of the item with isCriteria set to true
      const criteriaIndex = group.findIndex((item: any) => item.o.isCriteria);

      // If found, move it to index 0
      if (criteriaIndex !== -1) {
        const itemToMove = group.splice(criteriaIndex, 1)[0];
        group.unshift(itemToMove);
      }
    });

    // remove index 0 for level labels
    delete groupedData[0];

    return Object.values(groupedData);
  }

  handleSelectCell(item: ILayoutItem) {
    if (item.o.isLevel || item.o.isCriteria) return;
    if (!item.o.criterionNumber) return;
    if (!item.o.unscoredCriterionLabel && item.o.criterionNumber === "0")
      return;
    const key = this.carouselRef.d_page;
    const isCriteria =
      this.criteriaSheet.layoutType?.toLocaleLowerCase() === "criteria";
    const cellType = isCriteria ? "y" : "x";

    const selectedIndex = this.layoutData[key].findIndex(
      (o: ILayoutItemRequired) => o.i === item.i
    );
    let totalScore = 0;
    let st: any = null;
    this.layoutData.map((data: any, keyIndex: number) => {
      data.map((obj: ILayoutItemRequired, index: number) => {
        if (key === keyIndex) {
          if (selectedIndex === index) {
            // for half score implementation
            if (obj.o.isSelected) {
              if (obj.o.isScorable) {
                obj = this.handleHalfScore(obj);
              } else {
                obj.o.isSelected = !obj.o.isSelected;
              }
            } else {
              obj.o.isSelected = !obj.o.isSelected;
            }
          } else {
            if (obj[cellType] === item[cellType]) {
              if (item.o.criterionType === "unscoredCriterionLabel") {
                if (item.i === obj.i) {
                  obj.o.isSelected = !obj.o.isSelected;
                } else {
                  obj.o.isSelected = false;
                  obj.o.isHalfScore = false;
                }
              } else {
                if (obj.o.criterionType === "unscoredCriterionLabel") {
                  obj.o.isSelected = false;
                  obj.o.isHalfScore = false;
                } else {
                  if (item.i === obj.i) {
                    obj.o.isSelected = !obj.o.isSelected;
                  }
                }
              }
            }
            // if (key === keyIndex) {
            //   if (obj.o.isScorable) {
            //     obj.o.isSelected = false;
            //   }
            // }
          }
        }

        if (obj.o.isSelected && obj.o.score) {
          if (obj.o.isHalfScore) {
            totalScore += obj.o.score / 2;
          } else {
            totalScore += obj.o.score;
          }
        }
      });
    });

    this.generatedScore = {
      score: totalScore,
      totalScore: Number(this.criteriaSheet.totalScore || 0),
      grade: this.handleGrades(totalScore) || "",
    };

    this.$emit("generateScore", { ...this.generatedScore }, false);
  }

  handleGrades(computedScore: number): string {
    const grades = this.criteriaSheet.gradesData;
    if (
      computedScore === null ||
      computedScore === undefined ||
      isNaN(computedScore) ||
      !Array.isArray(grades) ||
      grades.length === 0
    )
      return "";
    let grade = "";
    for (const item of grades) {
      const score = parseInt(item.score);
      if (!isNaN(score) && computedScore >= score) {
        grade = item.grade;
        break;
      }
    }
    return grade;
  }

  handleHalfScore(obj: ILayoutItemRequired) {
    if (obj.o.isScorable) {
      if (obj.o.isHalfScore) {
        obj.o.isSelected = false;
        obj.o.isHalfScore = false;
      } else {
        obj.o.isHalfScore = true;
      }
    } else {
      obj.o.isSelected = !obj.o.isSelected;
    }
    return obj;
  }

  handleText(item: ILayoutItem) {
    return (
      item.o.levelLabel ||
      item.o.criteriaLabel ||
      item.o.criterionLabel ||
      item.o.unscoredCriterionLabel
    );
  }
  renderText(item: ILayoutItem) {
    const text = this.handleText(item);
    return item.o.isBlank
      ? ""
      : item.o.isScorable || text
      ? text
      : !item.o.isScorable &&
        !item.o.isCriteria &&
        !item.o.isLevel &&
        !item.o.unscoredCriterionLabel
      ? "Not Shown"
      : "";
  }

  showToast(
    message: string,
    severity: "success" | "info" | "warn" | "error" | undefined,
    duration = 3000
  ) {
    this.toast.add({
      severity: severity,
      detail: message,
      life: duration,
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.cell {
  .cell-bg {
    background-color: #f9d4d0;
    opacity: 0;
    transition: opacity 0.3s ease, clip-path 0.1s ease 0.3s;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    &.active {
      transition: opacity 0.3s ease, clip-path 0.3s ease;
      opacity: 1;
    }
    &.is-half {
      transition: clip-path 0.3s ease, opacity 0.3s ease;
      clip-path: polygon(0 100%, 100% 0, 100% 100%, 0% 100%);
    }
  }
}

@media screen and (max-height: 767px) {
  .criteria-sheet-panel {
    min-height: auto !important;
    .cell-wrap {
      min-height: auto !important;
      height: calc(100vh - 320px) !important;
    }

    .panel-loader {
      min-height: auto !important;
      height: 100% !important;
    }
  }
}
</style>
