import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ed504838"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify-end items-center gap-3" }
const _hoisted_2 = { class: "max-w-[300px]" }
const _hoisted_3 = { class: "border border-solid border-neutral-gray-85 rounded-lg flex overflow-hidden" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormTextInput = _resolveComponent("FormTextInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FormTextInput, {
        type: "text",
        name: "search",
        modelValue: _ctx.text,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.text) = $event)),
        placeholder: "Search...",
        iconLeft: 'searchBlack',
        background: false
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: _normalizeClass(`w-auto p-3 cursor-pointer ${
          _ctx.classView === 'grid' ? 'bg-flohh-primary-light-pink' : ''
        }`),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleChangeView('grid')))
      }, [
        _createElementVNode("span", {
          innerHTML: _ctx.icons.gridIcon
        }, null, 8, _hoisted_4)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(`w-auto p-3 cursor-pointer ${
          _ctx.classView === 'table' ? 'bg-flohh-primary-light-pink' : ''
        }`),
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleChangeView('table')))
      }, [
        _createElementVNode("span", {
          innerHTML: _ctx.icons.tableIcon
        }, null, 8, _hoisted_5)
      ], 2)
    ])
  ]))
}