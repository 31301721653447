<template>
  <div class="relative w-full">
    <div
      ref="ref-library"
      class="ref-library relative w-full bg-transparent m-h z-[1] pt-4 px-4 transition-[top] duration-[0.3s] ease-[ease] delay-[0s] right-0 top-0"
    >
      <template v-if="!isToggleFilterTags">
        <div class="w-full">
          <div class="relative mb-4">
            <button
              class="w-[24px] h-[24px] absolute top-[50%] translate-y-[-50%] z-2 pointer-events-none left-[19px]"
            >
              <img src="@/assets/icon-search.svg" class="w-full" />
            </button>
            <input
              type="text"
              placeholder="Search Library"
              @input="handleSearch"
              class="w-full h-[40px] bg-flohh-neutral-100 text-flohh-text-body color-flohh-neutral-70 rounded-lg border border-flohh-neutral-85 border-solid pl-[48px]"
            />
          </div>
        </div>
        <div class="mb-4 border-b border-solid border-flohh-neutral-85 pb-3">
          <div class="filter-header" v-if="isToggleFilterTags">
            <a href="#" @click="toggleFilterTags">{{
              isToggleFilterTags ? "Save Tags" : "Edit Tags"
            }}</a>
          </div>
          <div class="" v-if="!isToggleFilterTags">
            <template v-for="(item, key) in colorPresets" :key="key">
              <button
                class="filter-item text-flohh-text-small color-flohh-neutral-20 rounded-lg py-1.5 px-3.5 font-medium mr-1 mb-1"
                :class="{
                  '!outline-2 !outline-flohh-neutral-20 !outline-offset-[-2px] !outline active':
                    activeTag && activeTag === item.tag,
                }"
                v-if="item?.color"
                :style="{
                  'background-color':
                    'rgb(' +
                    item.color.r +
                    ',' +
                    item.color.g +
                    ',' +
                    item.color.b +
                    ')',
                }"
                @click="handleFilterTags(item.tag)"
              >
                {{ item.localization.defaultMessage }}
              </button>
            </template>
          </div>
        </div>
        <div class="max-h-[32vh] overflow-y-auto" ref="annotationOptionPopup">
          <template v-for="(item, key) in library.data" :key="key">
            <div
              class="flex justify-between min-h-9 text-left text-xs leading-[1.4] text-black relative bg-white rounded items-center mx-0 mb-1 pl-4 pr-2 py-2"
              draggable="true"
              v-if="item?.color"
              @dragstart="
                (event) => {
                  handleDragStart(event, item);
                }
              "
              @dragend="
                (event) => {
                  handleDragEnd(event, item);
                }
              "
              :style="{
                'background-color':
                  'rgb(' +
                  item.color.r +
                  ',' +
                  item.color.g +
                  ',' +
                  item.color.b +
                  ')',
              }"
            >
              <div
                class="flex items-center w-[85%]"
                v-tooltip.left="{
                  value: item.text.value,
                  pt: {
                    text: tootltipStyle(item.text.value),
                  },
                  autoHide: false,
                }"
              >
                <i
                  v-html="icon[item.icon]"
                  class="block h-5 w-5 [&>svg]:w-5 [&>svg]:h-5 mr-2"
                >
                </i>
                <p class="text-flohh-text-caption font-medium line-clamp-3">
                  {{ item.text.value }}
                </p>
              </div>

              <div class="">
                <button
                  class="w-6 h-6 flex items-center justify-center"
                  @click="handleDeleteAnnotation(item)"
                >
                  <img src="@/assets/icon-trash.svg" class="w-[13px]" />
                </button>
                <!-- <button
                  class="w-6 h-6 flex items-center justify-center"
                  @click="toggleAnnotationOption(item, key)"
                >
                  <img src="@/assets/icon-trash.svg" class="w-[13px]" />
                </button> -->

                <!-- <div class="annotation-option-menu" v-if="item.showOption">
                  <button @click="handleDeleteAnnotation(item)">
                    <img src="@/assets/icon-trash.svg" />
                  </button>
                </div> -->
              </div>
            </div>
          </template>
        </div>
      </template>
      <template v-if="isToggleFilterTags">
        <div>
          <template v-for="(item, key) in colorPresets" :key="key">
            <div class="flex items-center justify-start mb-2" v-if="item.color">
              <span
                class="min-w-[1.5rem] w-6 h-6 rounded-full block mr-1.5"
                :style="{
                  'background-color':
                    'rgb(' +
                    item.color.r +
                    ',' +
                    item.color.g +
                    ',' +
                    item.color.b +
                    ')',
                }"
              ></span>

              <input
                type="text"
                class="w-full h-[40px] bg-flohh-neutral-100 text-flohh-text-body color-flohh-neutral-70 rounded-lg border border-flohh-neutral-85 border-solid px-4 font-medium"
                :class="
                  item.localization.defaultMessage === '' ? 'invalid' : ''
                "
                :value="item.localization.defaultMessage"
                @change="
                  (e) => {
                    handleTagNames(e, key);
                  }
                "
              />
            </div>
          </template>
          <div class="flex justify-end pt-2 pb-4">
            <AppButton type="submit" @click="toggleFilterTags">
              <span v-html="appIcon.checkBlack"></span>
              Save
            </AppButton>
          </div>
        </div>
      </template>
    </div>

    <div class="flex justify-end pt-2 pb-4 px-4" v-if="!isToggleFilterTags">
      <button
        class="mr-2 flex items-center justify-center w-6 h-6"
        @click="toggleFilterTags"
        v-tooltip.left="{ value: 'Edit annotation bank names', showDelay: 500 }"
      >
        <span v-html="appIcon.pencilBlack"></span>
      </button>
      <button
        class="flex items-center justify-center w-6 h-6"
        @click="
          () => {
            eventBus.emit('EVENT_TRIGGER', 'MS004');
            toggleContentBox(true);
          }
        "
        v-tooltip.left="{ value: 'Add annotations to bank', showDelay: 500 }"
      >
        <span v-html="appIcon.plusBlack"></span>
      </button>
    </div>
    <Teleport to="body">
      <div
        class="absolute z-[9999] right-[300px] top-[157px]"
        v-if="isContentBoxOpen"
      >
        <div
          class="relative overflow-auto h-[502px] px-4 w-64 border border-solid border-flohh-neutral-85 rounded-lg bg-flohh-neutral-95"
          ref="annotationPopup"
        >
          <div
            class="flex w-full items-center pt-5 pb-4 mb-4 justify-between border-b border-solid border-flohh-neutral-85"
          >
            <p class="text-flohh-text-body color-flohh-neutral-5 font-medium">
              Add Annotations to Bank
            </p>
            <button
              @click="toggleContentBox(false)"
              class="w-6 h-6 flex justify-center items-center"
            >
              <span
                v-html="appIcon.closeBlack"
                class="[&>svg]:w-3.5 [&>svg]:h-3.5"
              ></span>
            </button>
          </div>
          <p
            class="text-flohh-text-caption font-flohh-font-regular color-flohh-neutral-20 mb-4"
          >
            Type in the box below, you can press "enter" to separate and add
            multiple annotations.
          </p>
          <textarea
            @input="handleAnnotationText"
            class="w-full mb-4 rounded-lg border border-solid border-flohh-neutral-85 h-28 resize-none py-4 px-6 text-flohh-text-caption color-flohh-neutral-70"
          ></textarea>

          <p
            class="text-flohh-text-caption font-flohh-font-regular color-flohh-neutral-20 mb-4"
          >
            Select a tag to add in the bank.
          </p>
          <div class="filter-container">
            <div class="filter-wrap p-0">
              <div class="filter-menu">
                <template v-for="(item, key) in presetColors" :key="key">
                  <button
                    class="text-flohh-text-small color-flohh-neutral-20 rounded-lg py-1.5 px-3.5 font-medium mr-1 mb-1"
                    :class="{
                      '!outline-2 !outline-flohh-neutral-20 !outline-offset-[-2px] !outline':
                        selectedTag && selectedTag === item.tag,
                    }"
                    v-if="item.color"
                    :style="{
                      'background-color':
                        'rgb(' +
                        item.color.r +
                        ',' +
                        item.color.g +
                        ',' +
                        item.color.b +
                        ')',
                    }"
                    @click="selectFilter(item.tag)"
                  >
                    {{ item.localization.defaultMessage }}
                  </button>
                </template>
              </div>
            </div>
          </div>

          <div
            class="flex justify-end pt-4 mt-4 border-t border-solid border-flohh-neutral-85"
          >
            <AppButton type="submit" @click="saveToAnnotationLibrary">
              <span v-html="appIcon.plusBlack"></span>
              Add to bank
            </AppButton>
          </div>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script lang="ts">
import { Teleport } from "vue";
import PSPDFKit, { Instance, Annotation, Color, Rect } from "pspdfkit";
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import { showNotePopUp } from "./AnnotationComponent";
import {
  TColorPreset,
  IColorPreset,
  IOption,
  INoteAnnotationData,
  AnnotationProperties,
} from "./types";
import ButtonComponent from "@/components/Button/ButtonComponent.vue";

import { StorageDataKey } from "@/enums/enums";
import { ANNOTATION_BANK, SHAPE_ICONS } from "./data";
import { escapeRegExp, generateGuid } from "@/utils/helper";
import { searchAnnotationBank } from "./ColorPickerComponent";

import AnnotationService from "@/services/AnnotationService";
import { AxiosResponse } from "axios";
import { icons as AppIcons } from "@/utils/icons";
import OverlayPanel from "primevue/overlaypanel";
import AppButton from "../Layout/Buttons/AppButton.vue";
import { useToast } from "primevue/usetoast";
import emitter from "@/config/emitter";
interface IShowOption {
  showOption: boolean;
}

interface IAnnotationWithShowOption extends Annotation, IShowOption {}

interface MarkingData {
  annotationBank: TLibrary;
  presetColors: IColorPreset;
}

interface IToggleRef {
  toggle(event: Event): void;
}

type TLibrary = {
  data: IAnnotationWithShowOption[];
};

@Component({
  components: {
    ButtonComponent,
    OverlayPanel,
    AppButton,
    Teleport,
  },
})
export default class LibraryComponent extends Vue {
  toast = useToast();
  eventBus = emitter;

  private annotationService = new AnnotationService();

  @Prop({
    type: Instance,
    required: true,
  })
  instance!: Instance;

  @Prop({
    type: Array<IColorPreset>,
    required: true,
  })
  presetColors!: Array<IColorPreset>;

  @Prop({
    type: Array,
    required: true,
  })
  library!: TLibrary;

  @Prop({
    type: Array,
    required: true,
  })
  annotations!: any;

  @Prop({
    type: Boolean,
    required: true,
  })
  public toggleContentBox!: (status: boolean) => void;

  @Prop({
    type: Boolean,
    required: true,
  })
  isContentBoxOpen!: boolean;

  icon: Record<string, string> = SHAPE_ICONS;

  appIcon = AppIcons;

  activeTag!: string | undefined;
  selectedTag!: string | undefined;
  search!: string;
  annotationText!: string;
  isToggleFilterTags = false;
  colorPresets!: Array<IColorPreset>;
  showOption = false;

  isMenuPopupVisible = [false, false, false];
  libraryRef!: any;

  markings!: any;
  // markings!: MarkingData;

  @Watch("library")
  onLibraryChanged(val: IAnnotationWithShowOption) {
    //
  }

  async handleCheckAnnotation() {
    const response: AxiosResponse =
      await this.annotationService.getAnnotationBank();
    if (response.data) {
      return true;
    }
  }

  async mounted() {
    window.addEventListener("click", this.onClickOutside);

    this.library.data = await this.handleAnnotationLibrary();
    this.colorPresets = this.handlePresetColor() || this.presetColors;
    this.instance.contentDocument.ondragover = (event: DragEvent) => {
      const evt = event.target as HTMLElement;
      const pageElement = this.closestByClass(
        evt,
        "PSPDFKit-Page"
      ) as HTMLElement | null;
      const popOverElement = this.closestByClass(evt, "PSPDFKit-Popover");
      if (pageElement || popOverElement) {
        event.preventDefault();
      }
    };

    this.instance.contentDocument.ondrop = (event: DragEvent) => {
      const target = event.target as HTMLInputElement;
      const dataTransfer = event.dataTransfer as DataTransfer;
      const pageElement = this.closestByClass(
        target,
        "PSPDFKit-Page"
      ) as HTMLElement | null;

      if (pageElement) {
        const pageIndex = parseInt(pageElement.dataset.pageIndex || "");

        const isExternalDrop = dataTransfer.files.length > 0;
        if (!isExternalDrop) {
          this.handleInternalDrop(event, pageIndex);
          this.handleReorderAnnotationLibrary(event);
        }
      }

      const popOverElement = this.closestByClass(
        target,
        "PSPDFKit-Popover"
      ) as HTMLElement | null;
      if (popOverElement) {
        const pageIndex = parseInt(popOverElement.dataset.pageIndex || "");

        const isExternalDrop = dataTransfer.files.length > 0;

        if (!isExternalDrop) {
          this.hanldePopoverDrop(
            event,
            pageIndex,
            this.instance,
            this.colorPresets
          );
        }
      }
    };
  }

  beforeDestroy() {
    window.removeEventListener("click", this.onClickOutside);
  }

  async handleGetUpdatedAnnotation() {
    const ab: string | null = localStorage.getItem(
      StorageDataKey.AnnotationLibrary
    );
    const pc: string | null = localStorage.getItem(StorageDataKey.PresetColors);
    const annotationPayload = {
      data: {
        annotationBank: ab && JSON.parse(ab),
        presetColors: pc && JSON.parse(pc),
      },
    };

    // perform put request here
    this.eventBus.emit("REFRESH_FILTER");
    await this.annotationService.putAnnotationUpdates(annotationPayload);
  }

  async saveAnnotation() {
    const ab: string | null = localStorage.getItem(
      StorageDataKey.AnnotationLibrary
    );
    const pc: string | null = localStorage.getItem(StorageDataKey.PresetColors);
    const annotationPayload = {
      data: {
        annotationBank: ab && JSON.parse(ab),
        presetColors: pc && JSON.parse(pc),
      },
    };

    // perform put request here
    this.eventBus.emit("REFRESH_LIBRARY");

    const res = await this.annotationService.putAnnotationUpdates(
      annotationPayload
    );
  }

  /**
   * onGetAnnotationBank
   */
  private async onGetAnnotationBank() {
    try {
      const response: AxiosResponse =
        await this.annotationService.getAnnotationBank();
      if (response.data.ok) {
        if (response.data.data.data.annotationBank.length) {
          localStorage.setItem(
            StorageDataKey.AnnotationLibrary,
            JSON.stringify(response.data.data.data.annotationBank)
          );
          // this.handleGetUpdatedAnnotation();
          this.handlePresetColor();
        } else {
          localStorage.setItem(
            StorageDataKey.AnnotationLibrary,
            JSON.stringify(ANNOTATION_BANK)
          );
          this.handlePresetColor();

          // this.handleGetUpdatedAnnotation();
        }
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      if (error instanceof ReferenceError) {
        console.error(error.message);
      } else {
        // throw error;
      }
    }
  }

  private onClickOutside(event: Event) {
    const annotationPopup = this.$refs.annotationPopup as HTMLElement;
    const optionPopup = this.$refs.optionPopup as HTMLElement;
    const annotationOptionPopup = this.$refs
      .annotationOptionPopup as HTMLElement;
    if (event.target instanceof Element) {
      if (
        annotationPopup &&
        !annotationPopup.contains(event.target as Node) &&
        !event.target.classList.contains("btn-add-annotation")
      ) {
        // this.isContentBoxOpen = false;
      }
    }
    if (optionPopup && !optionPopup.contains(event.target as Node)) {
      this.showOption = false;
    }
    if (
      annotationOptionPopup &&
      !annotationOptionPopup.contains(event.target as Node)
    ) {
      this.library.data?.map((o: Annotation) => (o.showOption = false));
    }
  }

  handleFilterTags(tag: string | undefined) {
    if (tag === "") return this.handleAnnotationLibrary();
    let libData = this.handleAnnotationLibrary();
    if (this.activeTag !== tag) {
      this.eventBus.emit("EVENT_TRIGGER", "MS001");
      const annotationLibrary = this.handleAnnotationLibrary();
      libData = annotationLibrary.filter((o: Annotation) => {
        if (o && o.color) {
          const ftag = this.findColorTag(o.color, this.colorPresets);
          return ftag == tag;
        } else {
          return false;
        }
      });
      this.activeTag = tag;
    } else {
      this.activeTag = "";
    }
    this.library.data = this.searchAnnotationLibrary(libData, this.search);
  }

  handleDeleteAnnotation(annotation: Annotation) {
    this.eventBus.emit("EVENT_TRIGGER", "MS002");
    this.$emit("deleteAnnotation", annotation);
  }

  handleDragStart(event: DragEvent, annotation: Annotation) {
    const target = event.target as HTMLInputElement;
    const dataTransfer = event.dataTransfer as DataTransfer;
    dataTransfer.setData("data", JSON.stringify(annotation));
    dataTransfer.setDragImage && dataTransfer.setDragImage(target, 0, 0);
    event.stopPropagation();
  }

  handleDragEnd(event: DragEvent, annotation: Annotation) {
    //
  }

  toggleAnnotationOption(item: IAnnotationWithShowOption, key: number) {
    //
    this.library.data[key].showOption = !item.showOption;
    this.$emit("deleteAnnotation", item);
  }

  hanldePopoverDrop(
    event: DragEvent,
    pageIndex: number,
    instance: Instance,
    presetColor: TColorPreset
  ) {
    const dataTransfer = event.dataTransfer as DataTransfer;
    const data = dataTransfer.getData("data");
    if (data) {
      const annotation = JSON.parse(data);

      const color = new PSPDFKit.Color({
        r: annotation.color.r,
        g: annotation.color.g,
        b: annotation.color.b,
      });

      const text = annotation.text;
      // const currentAnnotation: Annotation = instance.getSelectedAnnotation();
      const currentAnnotation = instance.getSelectedAnnotation();

      if (currentAnnotation) {
        let options: IOption = {
          filterTag: this.findColorTag(color, presetColor),
        };
        if (currentAnnotation.customData) {
          options.annotationId = currentAnnotation.customData
            .annotationId as number;
        }

        const editedAnnotation = currentAnnotation
          .set("color", color)
          .set("text", text)
          .set("customData", {
            ...options,
            libraryId:
              annotation.customData && annotation.customData.libraryId
                ? annotation.customData.libraryId
                : generateGuid(),
          });
        instance.update(editedAnnotation).then(() => {
          searchAnnotationBank(editedAnnotation.text.value, instance);
          if (
            instance.contentDocument.querySelector(
              ".PSPDFKit-85vqs4jnfn7c69cf79rkfqesmn"
            )
          ) {
            // instance.contentDocument
            //   .querySelector('.PSPDFKit-85vqs4jnfn7c69cf79rkfqesmn')
            //   .focus();
            // instance.contentDocument.querySelector(
            //   '.PSPDFKit-85vqs4jnfn7c69cf79rkfqesmn',
            // ).textContent = text;
            // instance.contentDocument.querySelector('.TooltipItem-AddToLibrary').textContent = 'Update library'
          }
          try {
            const matchIndex = this.library.data.findIndex(
              (a: Annotation) =>
                a &&
                a.text.value &&
                a.text.value == editedAnnotation["text"].value
            )
              ? this.library.data.findIndex(
                  (a: Annotation) =>
                    a &&
                    a.text.value &&
                    a.text.value == editedAnnotation["text"].value
                )
              : null;

            if (matchIndex) {
              const reorderedAnnotationLibrary = this.reorderIndex(
                this.library.data,
                matchIndex,
                0
              );

              localStorage.setItem(
                StorageDataKey.AnnotationLibrary,
                JSON.stringify(reorderedAnnotationLibrary)
              );
              this.library.data = this.handleAnnotationLibrary();
            }
          } catch (err) {
            console.error(err, "ERROR");
          }
        });

        event.preventDefault();
      }
    }
  }

  tootltipStyle(str: string) {
    if (str.length > 400) {
      return "font-normal text-sm w-[500px] left-[-308px] relative";
    } else {
      return "font-normal text-sm";
    }
  }

  handleReorderAnnotationLibrary(event: DragEvent) {
    const dataTransfer = event.dataTransfer as DataTransfer;
    const data = dataTransfer.getData("data");
    if (data) {
      const annotation: any = JSON.parse(data);
      const storageData = localStorage.getItem(
        StorageDataKey.AnnotationLibrary
      );
      const library = storageData ? JSON.parse(storageData) : [];

      const soloAnnotation = library.findIndex(
        (o: Annotation) =>
          o &&
          o.text.value.trim() === annotation.text.value.trim() &&
          o.id === annotation.id &&
          o.icon === annotation.icon
      );

      const syncAnnotation = library.findIndex(
        (o: Annotation) =>
          o &&
          o.text.value.trim() === annotation.text.value.trim() &&
          o.customData &&
          annotation.customData &&
          o.customData.filterTag === annotation.customData.filterTag &&
          o.icon === annotation.icon
      );

      const matchIndex =
        syncAnnotation && syncAnnotation > -1
          ? syncAnnotation
          : soloAnnotation && soloAnnotation > -1
          ? soloAnnotation
          : null;

      if (matchIndex && matchIndex > -1) {
        const reorderedAnnotationLibrary = this.reorderIndex(
          library,
          matchIndex,
          0
        );
        localStorage.setItem(
          StorageDataKey.AnnotationLibrary,
          JSON.stringify(reorderedAnnotationLibrary)
        );
        this.library.data = this.handleAnnotationLibrary();
        this.handleGetUpdatedAnnotation();
      }
    }
  }

  handleInternalDrop(event: DragEvent, pageIndex: number) {
    const dataTransfer = event.dataTransfer as DataTransfer;
    const clientRect = new PSPDFKit.Geometry.Rect({
      left: event.clientX,
      top: event.clientY,
      width: 32 * this.instance.currentZoomLevel,
      height: 32 * this.instance.currentZoomLevel,
    });
    // const pageRect: Rect | Point =
    //   this.instance.transformContentClientToPageSpace(clientRect, pageIndex);

    const pageRect: Rect | null | undefined =
      this.instance.transformContentClientToPageSpace(clientRect, pageIndex) as
        | Rect
        | null
        | undefined;

    const data = dataTransfer.getData("data");
    if (data) {
      this.insertTextAnnotation(pageRect, pageIndex, data);
      event.preventDefault();
    }
  }

  insertTextAnnotation(
    pageRect: Rect | null | undefined,
    pageIndex: number,
    data: string
  ) {
    const annotation = JSON.parse(data);

    const color = new PSPDFKit.Color({
      r: annotation.color ? annotation.color.r : annotation.strokeColor.r,
      g: annotation.color ? annotation.color.g : annotation.strokeColor.g,
      b: annotation.color ? annotation.color.b : annotation.strokeColor.b,
    });

    const updatedAnnotations = new PSPDFKit.Annotations.NoteAnnotation({
      pageIndex: pageIndex,
      text: annotation.text,
      color: color,
      boundingBox: pageRect,
      icon: annotation.icon,
      customData: {
        annotationId: annotation.customData ? annotation.customData.id : null,
        filterTag: annotation.customData
          ? annotation.customData.filterTag
            ? annotation.customData.filterTag
            : this.findColorTag(color, this.colorPresets)
          : this.findColorTag(color, this.colorPresets),
        libraryId: annotation.customData
          ? annotation.customData.libraryId
          : null,
      },
    });

    this.addAnnotationToDocument(this.instance, updatedAnnotations);
  }

  addAnnotationToDocument(instance: Instance, annotation: Annotation) {
    instance.create(annotation).then((res) => {
      const createdAnnotation = res[0] as Annotation;
      showNotePopUp(instance, createdAnnotation);
    });
  }

  closestByClass(el: Element | null, className: string): Element | null {
    if (el && el.classList && el.classList.contains(className)) {
      return el;
    } else if (el) {
      return this.closestByClass(el.parentNode as Element, className);
    } else {
      return null;
    }
  }
  findColorTag = (color: Color, presetColor: TColorPreset) => {
    const filter = presetColor.find((obj: IColorPreset) => {
      return obj.color &&
        color.r === obj.color.r &&
        color.g === obj.color.g &&
        color.b === obj.color.b
        ? obj
        : "";
    });
    return filter ? filter.tag : "";
  };

  reorderIndex<T>(array: T[], oldIndex: number, newIndex: number): T[] {
    if (newIndex >= array.length) {
      var key = newIndex - array.length + 1;
      while (key--) {
        array.push(array[oldIndex]);
      }
    }
    array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
    return array;
  }

  handlePresetColor() {
    const presetColors: string | null = localStorage.getItem(
      StorageDataKey.PresetColors
    );
    if (presetColors) {
      let presets = JSON.parse(presetColors);
      return presets;
    } else {
      localStorage.setItem(
        StorageDataKey.PresetColors,
        JSON.stringify(this.presetColors)
      );
      // this.handleGetUpdatedAnnotation();
    }
  }

  handleAnnotationLibrary = () => {
    const storageAnnotationLibrary: string | null = localStorage.getItem(
      StorageDataKey.AnnotationLibrary
    );
    if (storageAnnotationLibrary) {
      let library = JSON.parse(storageAnnotationLibrary);

      library.map((o: IAnnotationWithShowOption) => {
        return {
          ...o,
          showOption: false,
        };
      });

      return library;
    } else {
      this.onGetAnnotationBank();
    }
  };

  filterAnnotationLibrary(
    library: IAnnotationWithShowOption[],
    tag: string | undefined
  ) {
    if (tag === "") return library;
    return library.filter((o: IAnnotationWithShowOption) => {
      if (o && o.color) {
        const ftag = this.findColorTag(o.color, this.colorPresets);
        return ftag == tag;
      } else {
        return false;
      }
    });
  }

  searchAnnotationLibrary = (
    library: IAnnotationWithShowOption[],
    text: string | undefined
  ) => {
    if (text === "" || !text) return library;
    return library.filter((o: IAnnotationWithShowOption) => {
      if (o) {
        return o.text.value
          .toLowerCase()
          .search(escapeRegExp(text.toLowerCase())) > -1
          ? o
          : null;
      }
    });
  };

  handleSearch(event: Event) {
    const evt = event.target as HTMLInputElement;
    this.search = evt.value;
    const library = this.handleAnnotationLibrary();
    const filteredData = this.filterAnnotationLibrary(library, this.activeTag);
    if (evt.value) {
      this.library.data = this.searchAnnotationLibrary(
        filteredData.length ? filteredData : library,
        evt.value
      );
    } else {
      if (!this.activeTag) {
        this.library.data = this.handleAnnotationLibrary();
      } else {
        this.library.data = filteredData;
      }
    }
  }

  selectFilter(tag: string | undefined) {
    //
    if (this.selectedTag !== tag) {
      this.selectedTag = tag;
    } else {
      this.selectedTag = "";
    }
  }

  handleAnnotationText(event: Event) {
    const evt = event.target as HTMLInputElement;
    this.annotationText = evt.value;
  }

  saveToAnnotationLibrary() {
    this.eventBus.emit("EVENT_TRIGGER", "MS006");
    if (!this.annotationText) {
      this.showMessage("Please add text first", "warn");
    } else if (!this.selectedTag) {
      this.showMessage("Please select filter tag", "warn");
    } else {
      // save
      const tag: IColorPreset | null =
        this.colorPresets.find(
          (o: IColorPreset) => o.tag === this.selectedTag
        ) || null;

      let annotationText = this.annotationText.split(/\r|\n/);

      const selectedTag = this.selectedTag;
      let annotationLibrary = this.handleAnnotationLibrary();
      annotationText.map((item) => {
        if (item && item !== "") {
          const annotation = this.noteAnnotationTemplate({
            text: item,
            tag: selectedTag,
            color: tag ? tag.color : null,
          });
          annotationLibrary.unshift(annotation);
        }
      });

      localStorage.setItem(
        StorageDataKey.AnnotationLibrary,
        JSON.stringify(annotationLibrary)
      );
      this.library.data = annotationLibrary;

      this.saveAnnotation();

      this.showMessage("Annotations saved", "success");
      this.toggleContentBox(false);
      this.selectedTag = "";
    }
  }

  toggleOption() {
    this.showOption = !this.showOption;
  }

  noteAnnotationTemplate = (data: INoteAnnotationData) => {
    const date = new Date().toISOString();
    return {
      id: "",
      name: "",
      subject: null,
      pdfObjectId: null,
      pageIndex: 0,
      boundingBox: {
        left: 0,
        top: 0,
        width: 32,
        height: 32,
      },
      opacity: 1,
      action: null,
      note: null,
      creatorName: null,
      createdAt: date,
      updatedAt: date,
      customData: { filterTag: data.tag, annotationId: "" },
      noPrint: false,
      noZoom: false,
      noRotate: false,
      noView: false,
      hidden: false,
      blendMode: "normal",
      additionalActions: null,
      isCommentThreadRoot: false,
      rotation: 0,
      text: { format: "plain", value: data.text },
      icon: "COMMENT",
      color: data.color
        ? {
            r: data.color.r,
            g: data.color.g,
            b: data.color.b,
            transparent: false,
          }
        : null,
    };
  };

  // toggleContentBox(status: boolean) {
  //   this.isContentBoxOpen = status;
  // }

  toggleFilterTags(event: Event) {
    event.preventDefault();

    if (this.isToggleFilterTags) {
      this.eventBus.emit("EVENT_TRIGGER", "MS005");
      //save
      localStorage.setItem(
        StorageDataKey.PresetColors,
        JSON.stringify(this.colorPresets)
      );
      this.handleGetUpdatedAnnotation();
    } else {
      this.eventBus.emit("EVENT_TRIGGER", "MS003");
    }

    this.isToggleFilterTags = !this.isToggleFilterTags;
  }

  handleTagNames(event: Event, index: number) {
    const evt = event.target as HTMLInputElement;
    this.colorPresets[index].localization.defaultMessage = evt.value;
  }

  toggleAnnotationBank(event: Event) {
    const ref = this.$refs["op-annotation"] as IToggleRef | undefined;
    if (ref) {
      this.libraryRef = document.querySelector(".ref-library");
      ref.toggle(event);
    }
  }

  private showMessage(
    message: string,
    severity: "success" | "info" | "warn" | "error" | undefined
  ) {
    this.toast.add({
      severity: severity,
      detail: message,
      life: 3000,
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.option-menu {
  position: absolute;
  top: 100%;
  right: 10px;
  width: 180px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.48);
  border-radius: 4px;
  z-index: 4;
  a {
    font-size: 12px;
    padding: 10px;
    border-bottom: 1px solid #555;
    display: block;
    transition: background 0.3s ease;
    &:hover {
      background-color: #ccc;
    }
    &:last-child {
      border: 0;
    }
  }
}
.annotation-option-menu {
  position: absolute;
  top: 100%;
  right: 10px;
  width: 40px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.48);
  border-radius: 4px;
  z-index: 4;
  padding: 5px;
  display: flex;
  align-items: center;
  a {
    font-size: 12px;
    padding: 10px;
    border-bottom: 1px solid #555;
    display: block;
    transition: background 0.3s ease;
    &:hover {
      background-color: #ccc;
    }
    &:last-child {
      border: 0;
    }
  }
  button {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease;
    &:hover {
      opacity: 0.5;
    }
  }
}
.library-component {
  position: relative;
  width: 100%;
}
.pdf-library {
  width: 100%;
  background-color: rgb(255, 236, 234);
  position: relative;
  right: 0px;
  top: 0;
  height: calc(100vh - 80px);
  z-index: 1;
  transition: top 0.3s ease 0s;
  overflow-y: auto;
}
.header-wrap {
  padding: 20px 23px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .search-field {
    width: 100%;
    input {
      width: 100%;
      display: block;
      border-radius: 4px;
      height: 2.857em;
      border: 0px;
      position: relative;
      font-size: 12px;
      color: rgb(85, 85, 85);
      text-align: center;
      padding: 0px 1.6em;
    }
  }
  .btn-wrap {
    margin-top: 0;
    display: flex;
    align-items: center;
    position: relative;
    right: -10px;
    button {
      width: 25px;
      height: 34px;
      transition: 0.3s ease;
      &:hover {
        opacity: 0.5;
      }
    }
  }
}

.filter-wrap {
  padding: 0 23px;
  text-align: left;

  &.p-0 {
    padding: 0px;
  }
  .filter-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    h4 {
      text-transform: uppercase;
      padding: 0px;
      margin: 0px;
      font-size: 1.143em;
      font-weight: bold;
      color: rgb(85, 85, 85);
    }
    a {
      font-weight: bold;
      color: rgb(99, 169, 196);
      text-decoration: underline;
      font-size: 0.9em;
      position: relative;
    }
  }

  .filter-menu {
    margin: 0 -0.385em;
    padding: 0;
    .filter-item {
      border: 0;
      display: inline-block;
      font-size: 12px;
      padding: 5px 10px;
      background-color: rgb(255, 255, 255);
      color: rgba(0, 0, 0, 0.63);
      border-radius: 4px;
      margin: 5px;
      text-align: center;
      font-weight: bold;
      font-family: Poppins, sans-serif;
      transition: all 0.3s ease 0s;
      line-height: 1.3;
      cursor: pointer;
      min-height: 26px;
      vertical-align: middle;
      transition: opacity 0.3s ease;
      &.disabled {
        opacity: 0.5;
      }
    }

    .field-wrap {
      vertical-align: middle;
      margin-top: 5px;
      font-size: 12px;
      span {
        width: 0.857em;
        height: 0.857em;
        border-radius: 0.857em;
        background-color: rgb(0, 0, 0);
        display: inline-block;
        margin-right: 0.857em;
        vertical-align: middle;
      }
      input {
        width: calc(100% - 1.714em);
        vertical-align: middle;
        display: inline-block;
        border-radius: 4px;
        height: 2.857em;
        border: 1px solid transparent;
        font-family: Poppins, sans-serif;
        position: relative;
        font-size: 1em;
        color: rgb(85, 85, 85);
        text-align: left;
        padding: 0px 1.6em;
        transition: border 0.3s ease 0s;
      }
    }
  }
}
.filter-container {
  font-size: 11px;

  .filter-menu {
    .filter-item {
      display: inline-block;
      font-size: 0.929em;
      padding: 5px 10px;
      background-color: rgb(255, 255, 255);
      color: rgba(0, 0, 0, 0.63);
      border-radius: 4px;
      margin: 5px;
      text-align: center;
      font-weight: bold;
      font-family: Poppins, sans-serif;
      transition: all 0.3s ease 0s;
      line-height: 1.3;
      cursor: pointer;
      min-height: 26px;
      vertical-align: middle;
    }
  }
}
.annotation-library {
  padding: 0px 1.071em 40px;
  .annotation-item {
    display: block;
    margin: 5px 0;
    padding: 5px 5px 5px 35px;
    min-height: 30px;
    text-align: left;
    font-size: 12px;
    line-height: 1.4;
    color: #000;
    position: relative;
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    // box-shadow: 0.143em 0.214em 0.214em 0px rgba(0, 0, 0, 0.3);
    &:hover {
      color: #000;
    }

    i {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 100%;
      border-radius: 4px;
      padding: 6px;
      position: absolute;
      top: 0;
      left: 0;
      svg {
        width: 100%;
      }
    }

    p {
      margin: 0;
      padding-right: 2.813em;
      font-size: 14px;
      line-height: 1.2;
      font-family: "Poppins", sans-serif;
      color: #555555;
    }

    span {
      position: absolute;
      top: calc(50%);
      right: 0.714em;
      transform: translateY(-50%);
      display: block;
      width: 0.857em;
      height: 0.857em;
      border-radius: 0.857em;
      background-color: #000;
      right: 3.143em;
    }

    a,
    .btn-toggle {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      display: block;
      width: 30px;
      padding: 0;
      margin: 0;
      border: 0;
      background-color: transparent;
      cursor: pointer;
      &:hover,
      &:focus {
        outline: none;
      }
      img {
        width: 100%;
      }
    }
  }
}

.library-content-box {
  position: absolute;
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  z-index: 6;
  top: 1.571em;
  left: -29.786em;
  box-shadow: rgba(0, 0, 0, 0.3) 0.143em 0.143em 0.357em 0.071em;
  display: block;
  font-size: 12px;
  .content {
    position: relative;
    height: 32.1em;
    overflow: auto;
    width: 29.071em;
    padding: 2.143em;
    text-align: left;
    .header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      h1 {
        text-transform: uppercase;
        padding: 0px;
        margin: 0px;
        font-size: 1.143em;
        font-weight: bold;
        font-family: Poppins, sans-serif;
        color: rgb(85, 85, 85);
      }

      button {
        border: 0;
        background: none;
        padding: 0;
        width: 12px;
        height: 12px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    p {
      font-size: 1em;
      color: rgb(85, 85, 85);
      margin-bottom: 0px;
      margin-top: 1.429em;
      font-weight: 400;
      line-height: 1.4;
    }
    textarea {
      margin-top: 1.071em;
      resize: none;
      height: 12.857em;
      width: 100%;
      border-radius: 4px;
      background-color: transparent;
      font-size: 1em;
      padding: 1.429em 1.214em;
      line-height: 1.4;
      font-family: Poppins, sans-serif;
      color: rgb(85, 85, 85);
      position: relative;
      z-index: 1;
      border: 1px solid rgb(204, 204, 204);
      max-height: 6.429em;
    }

    .btn-wrap {
      border-top: 1px solid rgb(204, 204, 204);
      padding-top: 20px;
      margin-top: 10px;
    }
  }
  .arrow {
    position: absolute;
    width: 0px;
    height: 0px;
    right: -1.5em;
    top: 8.786em;
    box-sizing: border-box;
    border-width: 0.857em;
    border-style: solid;
    border-image: initial;
    border-color: transparent transparent rgb(255, 255, 255) rgb(255, 255, 255);
    transform-origin: 0px 0px;
    transform: rotate(-135deg);
    box-shadow: rgba(0, 0, 0, 0.3) -0.214em 0.143em 0.214em 0px;
    z-index: 2;
    display: block;
  }
}
</style>
