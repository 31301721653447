<script lang="ts">
import { Vue, Component, Model, Prop, Watch } from "vue-facing-decorator";
import ModalUtility from "../utilities/ModalUtility.vue";
import FormField from "../Layout/Forms/FormField.vue";
import AppButton from "../Layout/Buttons/AppButton.vue";
import { icons } from "@/utils/icons";
import { Class, GoogleClassPayload } from "@/models/Class";
import ClassService from "@/services/ClassService";
import { ClassInfo } from "@/store/createClass/types";
import { useToast } from "primevue/usetoast";
import AuthService from "@/services/AuthService";
import ProgressLoader from "@/components/utilities/ProgressLoader.vue";
import emitter from "@/config/emitter";

@Component({
  components: {
    ModalUtility,
    FormField,
    AppButton,
    ProgressLoader,
  },
})
export default class InviteManually extends Vue {
  private authService: AuthService = new AuthService();
  private classService = new ClassService();
  icon = icons;
  eventBus = emitter;

  @Prop({
    type: Object,
    required: true,
  })
  selectedClass!: ClassInfo;

  toast = useToast();
  @Model({
    type: Boolean,
  })
  openDialog!: boolean;

  googleClasses = [];

  isLoading = true;
  classLoading = true;
  isSaving = false;

  classUuid = "";
  selectedGoogleClass = "";
  token = "";
  googleClassError = "";

  mounted() {
    if (this.selectedClass && this.selectedClass.uuid) {
      this.classUuid = this.selectedClass.uuid;
    }
    this.onGetClassProfile();
  }

  async onGetClassProfile() {
    try {
      this.isLoading = true;
      if (this.classUuid) {
        const response = await this.classService.getClassById(this.classUuid);
        const classInformation = response.data.data as Class;
        await this.$store.dispatch(
          "createClassPopup/setClassInfo",
          classInformation
        );
      }
      let token = this.$route.query.token;
      if (token) {
        this.token = token as string;
        this.getGoogleClassroom(this.token);
      } else {
        this.token = "";
      }
    } catch (error) {
      this.isLoading = false;
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  async getGoogleClassroom(token: string) {
    this.classLoading = true;
    try {
      this.classLoading = false;
      const res = await this.classService.getGoogleClassroom(token);
      if (res.data.ok) {
        const data: any = res.data.data;
        if (data.length) {
          this.googleClasses = data.map((o: any) => ({
            value: o.id,
            label: o.code,
          }));
        } else {
          this.showMessage("warn", "No google class found");
        }
      }
    } catch (e) {
      this.classLoading = false;
    } finally {
      this.classLoading = false;
    }
  }

  validateForm() {
    let isFormValid = true;
    if (!this.classUuid) {
      this.$emit("onClassError", true);
      isFormValid = false;
    }
    if (!this.selectedGoogleClass) {
      this.googleClassError = "Google class is required";
      isFormValid = false;
    }

    return isFormValid;
  }

  async handleClickSave() {
    const isValid = this.validateForm();
    if (!isValid) return;

    this.isSaving = true;
    try {
      if (this.selectedGoogleClass) {
        const payload: GoogleClassPayload = {
          flohhClassUuid: this.classUuid,
          token: this.token,
        };
        const res = await this.classService.postGoogleClassroomImport(
          this.selectedGoogleClass,
          payload
        );
        this.isSaving = false;

        const cls: any = this.googleClasses.find(
          (o: any) => o.id === this.selectedGoogleClass
        );
        if (cls) {
          this.$store.dispatch("createClassPopup/setClass", cls.code);
        }
        let message = "Import Students successfully";
        let severity: "success" | "error" | "warn" = "success";
        if (res.data.ok) {
          const data = res.data.data;
          if (data.failedToAddStudentCount > 0) {
            if (data.addedStudentCount > 0) {
              message = `${data.addedStudentCount} student imported and ${data.failedToAddStudentCount} student already in class.`;
              severity = "success";
            } else {
              message = `${data.failedToAddStudentCount} student already in class.`;
              severity = "error";
            }
          } else {
            message = `${data.addedStudentCount} students imported.`;
            severity = "success";
          }
        }

        this.showMessage(severity, message);

        this.$emit("onComplete", this.selectedClass.uuid);
      } else {
        this.showMessage("error", "Please select a class.");
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.isSaving = false;
    }
  }

  async handleGoogleClassroom() {
    try {
      const path = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
      const successRedirectUrl = path;
      const failedRedirectUrl = path;

      if (path) {
        const res = await this.authService.getIdentifcationToken();

        if (res?.data.ok) {
          const identificationToken = res.data.data.token;
          const googleUrl = `${process.env.VUE_APP_API_URL}/google-classroom/authenticate?token=${identificationToken}&successRedirectUrl=${successRedirectUrl}&failedRedirectUrl=${failedRedirectUrl}`;
          window.location.href = googleUrl;

          localStorage.setItem("classUuidFromClassroomMethod", this.classUuid);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  @Watch("selectedClass.uuid")
  selectedClassUuidWatcher(value: string) {
    if (this.selectedClass && value) {
      this.classUuid = value;
    }
  }

  showMessage(type: "error" | "success" | "warn", message: string) {
    this.toast.add({
      severity: type,
      detail: message,
      life: 3000,
    });
  }
}
</script>
<template>
  <ProgressLoader v-if="isLoading" />
  <div v-else>
    <FormField
      v-if="token"
      :loading="classLoading"
      v-model="selectedGoogleClass"
      label="Invite your students from Google Classroom"
      option-value="value"
      option-label="label"
      type="select"
      :options="googleClasses"
      placeholder="Select a Google Class"
      :filter="true"
      :error="googleClassError"
      required
    />
    <FormField v-else label="Invite your students from Google Classroom">
      <AppButton type="primary" @click="handleGoogleClassroom">
        <template #icon_left>
          <img src="@/assets/google.svg" />
        </template>
        Connect Google Classroom
      </AppButton>
    </FormField>
  </div>
  <hr class="my-5" />
  <div class="w-full flex justify-end items-end pb-5">
    <AppButton
      type="submit"
      @click="handleClickSave"
      :loading="isSaving"
      :disabled="selectedGoogleClass && selectedClass ? false : true"
      :class="
        selectedGoogleClass && selectedClass ? 'opacity-100' : 'opacity-40'
      "
    >
      <template #icon_left>
        <span v-html="icon.mailBlack"></span>
      </template>
      Send Invite
    </AppButton>
  </div>
</template>
