<template>
  <div class="flex justify-between items-center">
    <div class="flex gap-3 items-center">
      <h6 class="text-flohh-h6 font-flohh-font-bold">
        {{ classInfo.code }}
      </h6>
      <button
        @click="handleEditClass"
        v-tooltip.top="{
          value: 'Edit Class',
          showDelay: 500,
        }"
      >
        <span v-html="icon.pencilBlack"></span>
      </button>
    </div>
    <div class="flex gap-2">
      <AppButton type="submit" @click="handleCreateAssignment">
        <template #icon_left>
          <span v-html="icon.plusBlack"></span>
        </template>
        Assignment
      </AppButton>
      <AppButton type="submit" @click="handleInviteStudents">
        <template #icon_left>
          <span v-html="icon.personAdd"></span>
        </template>
        Invite Students
      </AppButton>
    </div>
  </div>
  <hr class="my-6" />
  <CreateClassPopup v-model="createClassModalOpen" :isEdit="true" />
  <InviteStudentPopup
    v-model="inviteStudentModalOpen"
    :defaultClass="classUuid"
  />
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-facing-decorator";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { icons as AppIcons } from "@/utils/icons";
import { ClassData } from "@/store/class/classTypes";
import { useToast } from "primevue/usetoast";
import CreateClassPopup from "@/components/CreateClass/CreateClassPopup.vue";
import InviteStudentPopup from "@/components/InviteStudent/InviteStudentPopup.vue";

@Component({
  components: {
    AppButton,
    CreateClassPopup,
    InviteStudentPopup,
  },
})
export default class ClassesHeaderComponent extends Vue {
  toast = useToast();
  icon = AppIcons;
  link = "";

  @Prop({
    type: String,
    required: false,
  })
  classUuid!: string;

  @Prop({
    type: Object,
    required: true,
  })
  classInfo!: ClassData;

  createClassModalOpen = false;
  inviteStudentModalOpen = false;

  handleInviteStudents() {
    this.inviteStudentModalOpen = true;
  }

  handleEditClass() {
    this.createClassModalOpen = true;
  }

  handleCreateAssignment() {
    this.$router.push({
      path: `/assignments/create`,
      query: { class: this.classUuid },
    });
  }

  showToast(
    severity: "success" | "info" | "warn" | "error" | undefined,
    summary: string,
    detail: string
  ) {
    this.toast.add({
      severity: severity,
      summary: summary,
      detail: detail,
      life: 3000,
    });
  }
}
</script>

<style scoped lang="scss"></style>
