<template>
  <PageTitle title="Super Admin" :routes="routes" />
  <SuperAdminWandeComponent />
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import axios, { AxiosResponse } from "axios";
import PageTitle from "@/components/Layout/Partials/PageTitle.vue";
import SuperAdminWandeComponent from "@/components/SuperAdmin/SuperAdminWandeComponent.vue";
@Component({
  components: {
    PageTitle,
    SuperAdminWandeComponent,
  },
})
export default class SuperAdminWandeView extends Vue {
  public user!: object | null;

  routes = [];

  /**
   * created
   */
  public async created() {
    //
  }
}
</script>

<style scoped lang="scss">
body {
  margin: 0;
  overflow: hidden !important;
}
</style>
