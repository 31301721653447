import { RouteRecordRaw } from "vue-router";
const AnnotationBanksModule = () => import("./AnnotationBanksModule.vue");

const moduleRoute: RouteRecordRaw = {
  path: "/annotation-banks",
  component: AnnotationBanksModule,
  props: true,
  children: [
    {
      path: "",
      name: "Annotation Banks View",
      component: () => import("./Views/AnnotationBanksView.vue"),
      meta: {
        title: "Annotation Banks",
      },
    },
  ],
};

export default moduleRoute;
