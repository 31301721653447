<template>
  <div class="h-full">
    <FroalaEditorComponent
      :config="fraolaConfig"
      v-model="typeAssignmentModel"
      :disabled="disabledTextEditor"
      @onEditorEmpty="handleDisableUpload"
    />
  </div>
</template>

<script lang="ts">
import { Component, Model, Vue, Watch } from "vue-facing-decorator";
import FroalaEditorComponent from "@/components/Froala/FroalaEditorComponent.vue";
import emitter from "@/config/emitter";

@Component({
  components: { FroalaEditorComponent },
})
export default class TextInstructionComponent extends Vue {
  eventBus = emitter;

  @Model({
    type: String,
    required: false,
  })
  typeAssignmentModel!: string;

  @Watch("typeAssignmentModel")
  typeAssignmentModelWatcher(value: string) {
    this.$emit("onInstructionUpdate", value);
  }

  disabledTextEditor = false;
  disabledFileUploader = false;

  errors = {
    typeAssignmentModel: "",
    selectedFileModel: "",
  };

  fraolaConfig = {
    toolbarButtonssssssssssssssssss: {
      moreText: {
        buttons: ["bold", "italic", "underline"],
      },
      moreParagraph: {
        buttons: ["alignLeft", "alignCenter", "alignRight", "alignJustify"],
      },
      moreRich: {
        buttons: ["insertLink"],
      },
      moreMisc: {
        buttons: ["undo", "redo"],
      },
    },
    toolbarButtons: [
      ["bold", "italic", "underline"],
      ["formatOL", "formatUL"],
      ["alignJustify", "alignLeft", "alignRight", "alignCenter"],
      ["outdent", "indent"],
      ["insertLink"],
      ["clear"],
    ],
  };

  mounted() {
    this.eventBus.on("CLEAR_FIELDS", () => {
      this.typeAssignmentModel = "";
    });
  }

  handleDisableUpload(value: boolean) {
    this.disabledFileUploader = value;
  }
}
</script>

<style scoped lang="scss"></style>
