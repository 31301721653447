<template>
  <div
    class="w-full bg-flohh-primary-pink rounded-t-[4px] sm:py-0 flex flex-col justify-center items-start bg-[url('@/assets/modal-header-bg.png')] bg-repeat bg-[length:100%_auto] z-[1]"
  >
    <div
      class="z-[1] text-left p-[16px] flex justify-between w-full items-center"
    >
      <div class="gap-[5px] flex flex-col">
        <p class="font-extrabold text-[18px]">{{ infos.title }}</p>
        <div class="flex gap-[20px]">
          <p>Class: {{ infos.class }}</p>
        </div>
        <div v-if="!isStudentView" class="flex gap-[20px]">
          <p>Student Name: {{ infos.student }}</p>
        </div>
        <div class="flex gap-[20px]">
          <p>Due Date: {{ infos.dates.due }}</p>
          <p>Due By: {{ infos.dates.dueBy }}</p>
          <p v-if="!infos.assignmentStatus?.includes('due')">
            Submitted: {{ infos.dates.submitted }}
          </p>
        </div>
      </div>
      <div class="gap-[20px] justify-end items-center pr-[30px] mr-10">
        <!-- add eye icon -->
        <!-- <AppButton type="submit">
          <span v-html="icons.plusBlack" class="mr-2"></span>
          View Criteria
        </AppButton> -->

        <!-- <AppButton type="default" @click="openPdf">
          <span v-html="icons.iconDownload" class="mr-2"></span>
          Task Instructions
        </AppButton> -->

        <!-- <a
          :href="pdfLink"
          class="hidden"
          target="_blank"
          download="asignment.pdf"
          ref="pdfDownloadRef"
        ></a> -->

        <!-- add print icon -->
        <!-- <AppButton type="submit">
          <span v-html="icons.download" class="mr-2" @click="openPdf"></span>
          Print
        </AppButton> -->
      </div>
    </div>

    <div class="btn-close-wrap absolute top-[10px] right-[10px] z-[1]">
      <button class="p-2 text-white rounded-full" @click="handleClickClose">
        <img src="@/assets/close.svg" class="w-4" />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import AppButton from "../Layout/Buttons/AppButton.vue";
import { icons as AppIcons } from "@/utils/icons";
import Button from "primevue/button";
import { IInfos } from "../Classes/types";

@Component({ components: { Button, AppButton } })
export default class ViewAssignmentHeader extends Vue {
  @Prop({
    type: Object,
    required: false,
  })
  infos!: IInfos;

  @Prop({
    type: Boolean,
    required: false,
  })
  isStudentView!: boolean;

  @Prop({
    type: Function,
    required: false,
  })
  handleClickClose!: () => void;

  icons = AppIcons;

  title = "English Composition";
  subjectClass = "English 5-A";
  studentName = "Janice Tran";
  dueDate = "25/10/22";
  dueTime = "4:00pm";
  submittedDate = "22/10/22 | 5:53pm";

  pdfLink =
    "https://res.cloudinary.com/dzafs9h3x/image/upload/v1693472367/asignment-coversheet_vo1lzn.pdf";

  handlePrint() {
    window.print();
  }

  openPdf() {
    let fileInputElement = this.$refs.pdfDownloadRef as HTMLInputElement;
    fileInputElement.click();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
