import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a79fab1a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-1 flex-col" }
const _hoisted_2 = {
  role: "list",
  class: "flex flex-1 flex-col gap-y-7"
}
const _hoisted_3 = { class: "flex flex-col gap-2" }
const _hoisted_4 = ["onClick", "onMouseenter", "onMouseleave"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["id", "onMouseenter", "onMouseleave"]
const _hoisted_8 = ["id", "onMouseenter", "onMouseleave"]
const _hoisted_9 = { class: "px-[10px] py-[10px] flex flex-col gap-1" }
const _hoisted_10 = { class: "mt-auto -mx-2 mb-12" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { class: "mb-[30%]" }
const _hoisted_13 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavigationClassList = _resolveComponent("NavigationClassList")!
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      _createElementVNode("li", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigation, (navItem, i) => {
          return (_openBlock(), _createElementBlock("div", {
            key: i,
            class: "w-full relative"
          }, [
            (!navItem.isHidden)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass([
              navItem.path === _ctx.$route.path ||
              _ctx.navItemHovered === navItem.name ||
              _ctx.selectedNavItem === navItem.name
                ? 'bg-flohh-primary-pink'
                : 'text-gray-700 hover:text-gray-600 hover:bg-flohh-primary-pink',
              'cursor-pointer w-full group flex gap-x-3 rounded-md px-[20px] py-[12px] font-flohh-font-medium text-flohh-text-body',
            ]),
                  onClick: ($event: any) => (_ctx.handleNavigate(navItem.route, {}, {})),
                  onMouseenter: ($event: any) => (_ctx.handleHoverNavItem($event, i, navItem.name)),
                  onMouseleave: ($event: any) => (_ctx.handleUnhoverNavItem($event, i))
                }, [
                  _createElementVNode("span", {
                    innerHTML: navItem.icon,
                    "aria-hidden": "true",
                    class: "[&>svg]:w-[20px] [&>svg]:h-[auto]"
                  }, null, 8, _hoisted_5),
                  _createTextVNode(" " + _toDisplayString(navItem.name) + " ", 1),
                  (
                (navItem.children && navItem.children.length !== 0) ||
                navItem.name === 'Classes'
              )
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        innerHTML: _ctx.icons.dropdownIconBlack,
                        "aria-hidden": "true",
                        class: "ml-auto"
                      }, null, 8, _hoisted_6))
                    : _createCommentVNode("", true)
                ], 42, _hoisted_4))
              : _createCommentVNode("", true),
            (navItem.name === 'Classes')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "bg-flohh-primary-pink animate-height absolute rounded-bl-lg rounded-br-lg w-full",
                  id: `child-${i}`,
                  onMouseenter: ($event: any) => (_ctx.handleHoverNavItem($event, i, navItem.name)),
                  onMouseleave: ($event: any) => (_ctx.handleUnhoverNavItem($event, i))
                }, [
                  (_ctx.navItemHovered === navItem.name)
                    ? (_openBlock(), _createBlock(_component_NavigationClassList, {
                        key: 0,
                        onOnClassSelect: _ctx.handleClassSelect
                      }, null, 8, ["onOnClassSelect"]))
                    : _createCommentVNode("", true)
                ], 40, _hoisted_7))
              : (navItem.children.length > 0)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: "bg-flohh-primary-pink animate-height absolute rounded-bl-lg rounded-br-lg w-full",
                    id: `child-${i}`,
                    onMouseenter: ($event: any) => (_ctx.handleHoverNavItem($event, i, navItem.name)),
                    onMouseleave: ($event: any) => (_ctx.handleUnhoverNavItem($event, i))
                  }, [
                    _createElementVNode("div", _hoisted_9, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(navItem.children, (subNav, index) => {
                        return (_openBlock(), _createBlock(_component_router_link, {
                          to: subNav.path,
                          class: _normalizeClass([
                  subNav.path === _ctx.$route.path
                    ? 'bg-white rounded-[8px]'
                    : 'text-black-700 hover:text-black-600 hover:bg-white rounded-[8px]',
                  'cursor-pointer group flex gap-x-3 justify-start px-[10px] py-[10px] items-center text-sm font-flohh-font-medium text-flohh-text-caption',
                ]),
                          key: index,
                          onClick: ($event: any) => (_ctx.handleSelected(subNav.parent))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(subNav.name), 1)
                          ]),
                          _: 2
                        }, 1032, ["to", "class", "onClick"]))
                      }), 128))
                    ])
                  ], 40, _hoisted_8))
                : _createCommentVNode("", true)
          ]))
        }), 128))
      ]),
      _withDirectives((_openBlock(), _createElementBlock("li", _hoisted_10, [
        _createVNode(_component_router_link, {
          to: "/help",
          class: "hover:bg-flohh-primary-pink group flex gap-x-3 rounded-md justify-start items-center px-[20px] py-[12px] text-sm font-flohh-font-medium text-flohh-text-body",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.eventBus.emit('EVENT_TRIGGER', 'G014')))
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", {
              innerHTML: _ctx.icons.helpBlack,
              "aria-hidden": "true"
            }, null, 8, _hoisted_11),
            _createTextVNode(" Help ")
          ]),
          _: 1
        })
      ])), [
        [
          _directive_tooltip,
          { value: 'Help', showDelay: 500 },
          void 0,
          { right: true }
        ]
      ]),
      _createElementVNode("li", _hoisted_12, [
        _createVNode(_component_router_link, {
          to: "/app/version",
          class: "app-info",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.eventBus.emit('EVENT_TRIGGER', 'G015')))
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("span", {
              innerHTML: _ctx.icons.infoCircleBlack
            }, null, 8, _hoisted_13), [
              [_directive_tooltip, {
              value: 'App Information',
              autoHide: false,
            }]
            ])
          ]),
          _: 1
        })
      ])
    ])
  ]))
}