<template>
  <div class="mb-[30px] w-[50%]">
    <div
      style="position: relative; padding-bottom: 47.39583333333333%; height: 0"
    >
      <iframe
        src="https://www.loom.com/embed/cacbafb0688c44e39a22f7c87f6fd998?sid=6178b99c-1da3-4bd6-af6f-623ce532162b"
        frameborder="0"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
      ></iframe>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import AppButton from "../Layout/Buttons/AppButton.vue";
import { icons as AppIcons, icons } from "@/utils/icons";
import { StorageDataKey } from "@/enums/enums";
import AppTypographyText from "../Layout/Typhography/AppTypographyText.vue";
import DashboardService from "@/services/DashboardService";
import axios, { AxiosResponse } from "axios";
@Component({
  components: {
    AppButton,
    AppTypographyText,
  },
})
export default class OnboardingVideoComponent extends Vue {
  //
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
