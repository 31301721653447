import { RouteRecordRaw } from "vue-router";
const RubricsBanksModule = () => import("./RubricsBanksModule.vue");

const moduleRoute: RouteRecordRaw = {
  path: "/criteria-sheet-bank",
  component: RubricsBanksModule,
  props: true,
  children: [
    {
      path: "",
      name: "Criteria Sheet Banks View",
      component: () => import("./Views/RubricsBankView.vue"),
      meta: {
        title: "Criteria Sheet Bank",
        auth: true,
        restriction: ["super admin", "teacher"],
      },
    },
  ],
};
export default moduleRoute;
