<template>
  <div class="submission-split">
    <PageTitle title="Bulk Upload Split" :routes="routes" />
    <BulkUploadSplitComponent
      v-if="assignmentUuid && status === 'pending'"
      :classUuid="classUuid"
      :assignmentUuid="assignmentUuid"
      :submissions="submissions"
      @onStatusUpdate="handleStatusUpdate"
    />

    <div
      v-if="assignmentUuid && status === 'processing'"
      class="bg-white rounded-lg m-3 p-8 h-[80vh] flex items-center justify-center"
    >
      <div
        class="max-w-[700px] flex flex-col items-center justify-center text-center"
      >
        <AppTypographyText variant="md" type="subtitle" class="mb-[1em]"
          >Your bulk upload is currently being processed and split. Depending on
          the size of the file, it might take a couple of minutes. We will send
          you an email when it is done!</AppTypographyText
        >
        <AppTypographyText variant="md" type="subtitle" class="mb-[1em]"
          >You can leave this page and check back anytime by clicking the
          'Upload Submissions' button on your class page and selecting Scanned
          Paper Upload and Splitting option.</AppTypographyText
        >
        <div v-if="fetchDisabled" class="flex flex-col gap-5 items-center">
          <ProgressLoader
            label="Processing Files"
            labelVariant="md"
            labelType="subtitle"
          />
        </div>
        <div v-else>
          <div v-if="fetchingStatus" class="flex flex-col gap-5">
            <ProgressLoader
              label="Fetching Bulk Upload Status"
              labelVariant="md"
              labelType="subtitle"
            />
          </div>
          <div v-else>
            <AppButton
              type="submit"
              @click="checkUploadStatusViaButton(assignmentUuid)"
            >
              <span v-html="icon.refreshIconBlack" class="mr-2"></span>
              Check Status
            </AppButton>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="assignmentUuid && status === 'checking'"
      class="bg-white rounded-lg m-3 p-8 h-[80vh] flex items-center justify-center"
    >
      <ProgressLoader
        label="Loading..."
        labelVariant="md"
        labelType="subtitle"
      />
    </div>

    <div
      v-else-if="assignmentUuid && status === 'completed'"
      class="bg-white rounded-lg m-3 p-8 h-[80vh] flex items-center justify-center text-center"
    >
      <div
        class="max-w-[700px] flex flex-col items-center justify-center text-center"
      >
        <AppTypographyText variant="md" type="subtitle" class="mb-5"
          >Bulk Upload for this assignment has been completed, you can now mark
          your students' submissions by going to the class page or upload
          another set of paper student submissions
        </AppTypographyText>
        <AppTypographyText variant="md" type="subtitle" class="mb-5">
          {{
            `${totalSubmissionsDone} out of ${totalSubmissions} submissions are successfully processed`
          }}
        </AppTypographyText>
        <div class="flex gap-4 items-center justify-center">
          <AppButton
            v-tooltip.top="{
              value: 'Go to the assignment submission class page',
              showDelay: 500,
            }"
            type="primary"
            @click="handleRedirectToClassPage"
          >
            Go To The Class Page
          </AppButton>
          <AppTypographyText variant="md" type="subtitle">
            OR
          </AppTypographyText>
          <AppButton
            v-tooltip.top="{
              value: 'Upload another set of paper student submissions',
              showDelay: 500,
            }"
            type="submit"
            @click="redirectToBulkUploadPage"
            iconLeft="uploadBlack"
          >
            Upload Submissions
          </AppButton>
        </div>
      </div>
    </div>

    <div
      v-else-if="status === 'failed'"
      class="bg-white rounded-lg m-3 p-8 h-[80vh] flex items-center justify-center text-center"
    >
      <div
        class="max-w-[700px] flex flex-col items-center justify-center text-center"
      >
        <AppTypographyText variant="md" type="subtitle" class="mb-5"
          >Bulk Upload for this assignment has been failed, {{ failReason }}
        </AppTypographyText>
        <div class="flex gap-4 items-center justify-center">
          <AppButton
            v-tooltip.top="{
              value: 'Go to the assignment submission class page',
              showDelay: 500,
            }"
            type="primary"
            @click="handleRedirectToClassPage"
          >
            Go To The Class Page
          </AppButton>
          <AppTypographyText variant="md" type="subtitle">
            OR
          </AppTypographyText>
          <AppButton
            v-tooltip.top="{
              value: 'Upload another set of paper student submissions',
              showDelay: 500,
            }"
            type="submit"
            @click="redirectToBulkUploadPage"
            iconLeft="uploadBlack"
          >
            Upload Submissions
          </AppButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import PageTitle from "@/components/Layout/Partials/PageTitle.vue";
import BulkUploadSplitComponent from "@/components/BulkUploadNew/Split/BulkUploadSplitComponent.vue";
import { AxiosResponse } from "axios";
import BulkUploadService from "@/services/BulkUploadService";
import { BulkUpload } from "@/store/bulkUpload/bulkUploadStateTypes";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import { icons } from "@/utils/icons";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import ProgressLoader from "@/components/utilities/ProgressLoader.vue";
import emitter from "@/config/emitter";
import { ClassData } from "@/store/class/classTypes";
import { useToast } from "primevue/usetoast";

@Component({
  components: {
    PageTitle,
    BulkUploadSplitComponent,
    AppButton,
    AppTypographyText,
    ProgressLoader,
  },
})
export default class BulkUploadSplitView extends Vue {
  toast = useToast();
  eventBus = emitter;
  private bulkUploadService = new BulkUploadService();
  icon = icons;

  routes = [
    {
      title: "Classes",
      routeTo: "/classes/all",
      current: false,
    },
    {
      title: "Bulk Upload - Paper",
      routeTo: "/bulk-upload/paper",
      current: false,
    },
    {
      title: "Split",
      routeName: "BulkUploadSplit",
      current: true,
    },
  ];

  assignmentUuid = "";
  status = "checking";
  submissions: BulkUpload[] = [];
  fetchDisabled = true;
  fetchingStatus = false;
  totalSubmissions = 0;
  totalSubmissionsDone = 0;
  classUuid = "";
  failReason = "";

  async created() {
    const assignmentUuid = this.$route.params.id as string;
    await this.checkUploadStatus(assignmentUuid);
    if (this.status === "processing") {
      this.startTheInterval(20000);
    } else if (this.status === "completed") {
      this.redirectToBulkUploadPage();
    }
  }

  async checkUploadStatusViaButton(uuid: string) {
    this.startTheInterval(3000);
    await this.checkUploadStatus(uuid);
  }

  async checkUploadStatus(uuid: string) {
    try {
      this.fetchingStatus = true;
      const response: AxiosResponse =
        await this.bulkUploadService.getBulkUploadStatus(uuid);
      if (response.data.ok) {
        const responseData = response.data.data;
        if (!responseData) {
          this.redirectToBulkUploadPage();
        } else {
          this.submissions = responseData.details.groupedDocuments;
          this.assignmentUuid = uuid;
          this.classUuid = responseData.class;

          if (responseData.status === "completed") {
            this.totalSubmissions =
              responseData.details.finalGroupedDocuments.length;
            const successDocuments =
              responseData.details.submissionResults.filter(
                (item: any) => item.status === "success"
              );
            this.totalSubmissionsDone = successDocuments.length;
          } else if (responseData.status === "failed") {
            this.failReason = responseData.failReason;
          } else if (responseData.status === "processing") {
            if (responseData.details.confirmBulkUploadStartDate) {
              this.toast.add({
                severity: "info",
                summary: "Processing",
                detail:
                  "Your submissions are being split. Please refresh your class page if you don't see them immediately.",
                life: 6000,
              });
              this.handleRedirectToClassPage();
              return;
            }
          }

          this.status = responseData.status;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.fetchingStatus = false;
    }
  }

  updateBreadcrumbs(classUuid: string, className: string) {
    if (this.routes.length === 4) {
      this.routes.splice(1, 1);
    }

    if (this.routes.length === 3) {
      const additionalRoute = {
        title: className,
        routeTo: `/classes/${classUuid}`,
        current: false,
      };
      this.routes.splice(1, 0, additionalRoute);
    }
  }

  handleStatusUpdate(status: string) {
    this.status = status;
    if (status === "processing") {
      this.fetchDisabled = true;
      this.startTheInterval(20000);
    }
  }

  startTheInterval(seconds: number) {
    const fetchButtonShowInterval = setInterval(() => {
      this.fetchDisabled = false;
      clearInterval(fetchButtonShowInterval);
    }, seconds);
  }

  redirectToBulkUploadPage() {
    this.$router.push({
      name: "BulkUpload",
      params: { type: "paper" },
      query: { class: this.classUuid, assignment: this.assignmentUuid },
    });
  }

  handleRedirectToClassPage() {
    this.$router.push({
      name: "Classes",
      params: { id: this.classUuid },
      query: { assignment: this.assignmentUuid },
    });
  }
}
</script>

<style scoped lang="scss">
body {
  margin: 0;
  overflow: hidden !important;
}
.submission-split {
  margin-left: 0;
  height: auto;
  position: relative;
  background-color: #f1f3f3;
}
</style>
