<script lang="ts">
import { Vue, Component, Model, Emit, Prop, Watch } from "vue-facing-decorator";
import ModalUtility from "../../utilities/ModalUtility.vue";
import Button from "primevue/button";
import RadioButton from "primevue/radiobutton";
import FileUploader from "@/components/utilities/FileUploader.vue";
import StudentInformation from "@/components/Student/StudentInformation.vue";
import AssignmentInformationCard from "./AssignmentInformationCard.vue";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import FormField from "@/components/Layout/Forms/FormField.vue";
import SubmissionServive from "@/services/SubmissionService";
import { AxiosProgressEvent, AxiosRequestConfig, AxiosResponse } from "axios";
import { InitializeMedia } from "@/utils/initializer";
import MediaService from "@/services/MediaService";
import { MediaResponse } from "@/models/Media";
import { CreateSubmission } from "@/models/Submission";
import emitter from "@/config/emitter";
import { useToast } from "primevue/usetoast";
import { arrayBufferToFile, fileToBase64 } from "@/utils/helper";
import PSPDFKit from "pspdfkit";
import { getPspdfkitLicenseKey } from "@/utils/EnvironmentUtils";
import FormRadioButton from "@/components/Layout/Forms/FormRadioButton.vue";
import LearningGoalsReflection from "./LearningGoalsReflection.vue";
import AssignmentUploader from "./AssignmentUploader.vue";

@Component({
  components: {
    ModalUtility,
    Button,
    RadioButton,
    FileUploader,
    StudentInformation,
    AssignmentInformationCard,
    AppTypographyText,
    AppButton,
    FormField,
    FormRadioButton,
    LearningGoalsReflection,
    AssignmentUploader,
  },
})
export default class AssignmentSubmission extends Vue {
  @Model({
    type: Boolean,
    default: false,
  })
  openModal!: boolean;

  @Prop({
    type: String,
    default: "Submit Your Assignment",
  })
  title!: string;

  @Prop({
    type: String,
    default: "teacher",
  })
  viewType!: string;

  @Prop({
    required: false,
  })
  assignment!: any;

  @Prop({
    type: String,
    required: false,
  })
  pastLearningGoal!: any;

  private submissionService: SubmissionServive = new SubmissionServive();
  private mediaService: MediaService = new MediaService();
  PSPDFKIT_LICENSE_KEY = getPspdfkitLicenseKey();
  toast = useToast();

  isGoalMet: boolean | null = null;
  eventBus = emitter;

  selectedFile: File[] = [];
  uploadProgress = 0;
  loadingMedia = false;
  loadingSubmission = false;
  mediaResponse!: MediaResponse;
  createAssignmentSubmission: CreateSubmission = {
    assignment: "",
    assignmentType: "document",
    component: "",
    student: "",
  };

  docTypes = [
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/msword",
  ];

  allowedFileTypes = [
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/msword",
    "application/pdf",
  ];

  async handleClickSubmit() {
    this.eventBus.emit("EVENT_TRIGGER", "G001");
    try {
      if (
        this.viewType === "student" &&
        this.pastLearningGoal &&
        this.isGoalMet === null
      ) {
        throw new Error(
          "Please provide an answer if you met your recent learning goal or not"
        );
      }
      if (this.selectedFile.length > 0) {
        if (
          this.selectedFile.length > 0 &&
          !this.allowedFileTypes.includes(this.selectedFile[0].type)
        ) {
          throw new Error("Invalid file type, please upload a PDF file.");
        }
        await this.postAssignmentSubmission();
        this.openModal = false;
        return;
      }
      throw new Error("Please upload a file");
    } catch (error: any) {
      this.toast.add({
        severity: "error",
        summary: "Failed",
        detail: error.message,
        life: 3000,
      });
    }
  }

  @Emit("onClickSubmission")
  async postAssignmentSubmission() {
    try {
      //
      this.loadingSubmission = true;
      this.eventBus.emit("SHOW_LOADING", {
        showLoader: true,
        message: "Processing",
      });
      await this.onPostMedia();
      if (this.assignment) {
        const auth: any = localStorage.getItem("auth");
        const student = JSON.parse(auth);
        this.createAssignmentSubmission.assignment =
          this.assignment.summary.uuid;
        this.createAssignmentSubmission.student =
          student.data.roleDetails.student.uuid;
      } else {
        if (
          this.$store.state.assignment.selectedStudentSummary &&
          this.$store.state.assignment.selectedStudentSummary.student
        ) {
          this.createAssignmentSubmission.student =
            this.$store.state.assignment.selectedStudentSummary.student.uuid;
        }
        if (this.$store.state.assignment.selectedAssignment) {
          this.createAssignmentSubmission.assignment =
            this.$store.state.assignment.selectedAssignment.uuid;
        }
      }
      if (this.viewType === "student" && this.pastLearningGoal) {
        this.createAssignmentSubmission.remarks = {
          hasMetLearningGoal: this.isGoalMet,
          learningGoalUuid: this.pastLearningGoal?.uuid,
        };
      }

      const response: AxiosResponse =
        this.viewType === "student"
          ? await this.submissionService.studentCreateSubmission(
              this.createAssignmentSubmission
            )
          : await this.submissionService.createSubmission(
              this.createAssignmentSubmission
            );
      if (response.data.ok) {
        // this.toast.add({
        //   severity: "success",
        //   detail: "Assignment submitted successfully",
        //   life: 3000,
        // });
        return response.data.ok;
      } else {
        console.error(response);
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.loadingSubmission = false;
      this.eventBus.emit("REFRESH_ASSIGNMENT");
      this.eventBus.emit("SHOW_TOAST", {
        message: "Assignment submitted successfully",
        severity: "success",
      });
    }
  }

  async onPostMedia() {
    try {
      this.uploadProgress = 0;
      this.loadingMedia = true;
      const config: AxiosRequestConfig = {
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          if (progressEvent.total !== undefined) {
            this.uploadProgress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
          }
        },
      };
      let file = structuredClone(this.selectedFile[0]);
      if (this.docTypes.includes(file.type)) {
        const fileBase64 = await fileToBase64(file);

        const pspdfkitResponse = await PSPDFKit.convertToPDF({
          document: `data:application/pdf;base64,${fileBase64}`,
          licenseKey: this.PSPDFKIT_LICENSE_KEY,
          container: ".pdf-preview-container",
        });

        if (pspdfkitResponse) {
          let convertedFile = arrayBufferToFile(
            pspdfkitResponse,
            file.name.replace(/\.[^/.]+$/, ".pdf")
          );

          file = structuredClone(convertedFile);
        }
      }
      const payload = InitializeMedia(file, "revision");
      const response = await this.mediaService.postMedia(payload, config);
      if (response.data.ok) {
        this.mediaResponse = response.data.data;
        this.createAssignmentSubmission.component =
          this.mediaResponse.accessToken;
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingMedia = false;
    }
  }

  handleRemoveFile(index: number) {
    this.selectedFile.splice(index, 1);
  }

  @Watch("openModal")
  openModalWatcher(value: boolean) {
    if (value) {
      this.selectedFile = [];
      this.isGoalMet = null;
    }
  }

  @Watch("isGoalMet")
  studentReflectionWatcher(value: boolean) {
    this.isGoalMet = value;
  }

  handleSelectFile(value: File[]) {
    this.selectedFile = value;
  }
}
</script>
<template>
  <ModalUtility
    v-model="openModal"
    :title="title"
    width="40vw"
    subtitle="Upload your assignment file below to submit your assignment"
  >
    <template #content>
      <div class="w-full px-6 py-4">
        <!-- Student Information -->
        <div class="w-full pt-4" v-if="viewType !== 'student'">
          <FormField label="Student Information">
            <StudentInformation class="my-[8px]" />
          </FormField>
        </div>
        <!-- End of student information -->
        <!-- Student Reflection -->
        <div class="w-full" v-if="viewType === 'student' && pastLearningGoal">
          <FormField label="Recent Learning Goal:"
            ><AppTypographyText :label="pastLearningGoal?.content" />
          </FormField>
          <FormField
            label="Have you met your recent learning goal on this assignment?"
            ><LearningGoalsReflection v-model="isGoalMet" />
          </FormField>
        </div>
        <!-- End of student information -->
        <!-- Assignment Information -->
        <div class="w-full">
          <div class="w-full">
            <div class="pdf-preview-container hidden"></div>
            <FormField
              label="Assignment Information"
              v-if="viewType !== 'student'"
            >
              <AssignmentInformationCard class="my-[8px]" />
            </FormField>
            <div class="flex flex-col justify-start items-start gap-y-3">
              <FormField label="Assignment Attachment">
                <AssignmentUploader @onSelect="handleSelectFile" />
              </FormField>
            </div>
          </div>
        </div>
        <!-- End of assignment information -->
      </div>
    </template>
    <template #footer>
      <div class="w-full flex justify-end items-end mt-4">
        <AppButton
          type="submit"
          label="Submit"
          iconLeft="checkBlack"
          :disabled="loadingSubmission || loadingMedia"
          :loading="loadingMedia || loadingSubmission"
          @click="handleClickSubmit"
          severity="help"
        />
      </div>
    </template>
  </ModalUtility>
</template>
