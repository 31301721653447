<template>
  <Dialog
    :visible="visible"
    :closable="false"
    :draggable="false"
    modal
    :style="{ width: '515px' }"
    :pt="{
      header: { class: '!py-4 !pl-6' },
      content: { class: '!p-0' },
      footer: { class: '!p-0' },
    }"
  >
    <template #header>
      <div class="">
        <h5
          class="text-default text-neutral-gray-500 flex font-bold items-center justify-start"
        >
          {{ title }}
        </h5>
      </div>
    </template>
    <div class="border-t border-solid border-neutral-gray-500 py-4 px-6">
      <p class="text-sm text-neutral-gray-500">
        {{ body }}
      </p>
    </div>
    <template #footer>
      <div class="py-2 px-6 flex items-center justify-end">
        <Button
          label="Cancel"
          @click="visible = false"
          class="border border-solid border-neutral-gray-70 bg-transparent text-sm text-neutral-gray-70 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5"
        />
        <Button
          label="Detach"
          @click="handleDetach"
          class="border border-solid border-neutral-gray-500 bg-neutral-gray-85 text-sm text-neutral-gray-500 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5 !mr-0"
        />
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts">
import { Vue, Component, Model, Prop } from "vue-facing-decorator";
import Dialog from "primevue/dialog";
import { icons } from "@/utils/icons";
import Button from "primevue/button";

@Component({
  components: { Dialog, Button },
})
export default class DetachDialogComponent extends Vue {
  icon = icons;

  @Model({
    type: Boolean,
    required: true,
  })
  visible!: boolean;

  @Prop({
    type: String,
    required: true,
  })
  title!: string;

  @Prop({
    type: String,
    required: true,
  })
  body!: string;

  handleDetach() {
    this.$emit("onConfirmDetach");
    this.visible = false;
  }
}
</script>

<style scoped lang="scss"></style>
