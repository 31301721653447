<template>
  <div class="bg-white rounded-lg h-full classes-wrapper mt-10">
    <ClassesGridCard
      v-for="(item, index) in classes"
      :key="index"
      :index="index"
      :data="item"
      :assignments="assignments"
      @onClassEdit="handleEditClass"
      @onStudentInvite="handleInviteStudents"
      @onClassDelete="handleDeleteClass"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Emit } from "vue-facing-decorator";
import { ClassData } from "@/store/class/classTypes";
import { icons as AppIcons, icons } from "@/utils/icons";
import ClassesGridCard from "./ClassesGridCard.vue";
import { AssignmentDashboard } from "@/store/dashboard/dashboardTypes";

@Component({
  components: {
    ClassesGridCard,
  },
})
export default class ClassesListGriViewComponent extends Vue {
  icons = AppIcons;

  @Prop({
    type: Array,
    required: true,
  })
  classes!: ClassData[];

  @Prop({
    type: Boolean,
    required: true,
  })
  loadingClasses!: boolean;

  @Prop({
    type: Array,
    required: true,
  })
  assignments!: AssignmentDashboard[];

  @Emit("onClassEdit")
  handleEditClass(classInfo: ClassData) {
    return classInfo;
  }

  @Emit("onClassDelete")
  handleDeleteClass(classInfo: ClassData) {
    return classInfo;
  }

  @Emit("onStudentInvite")
  handleInviteStudents(classInfo: ClassData) {
    localStorage.setItem("ClassInfo", JSON.stringify(classInfo));
    return classInfo;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.classes-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1em;
}
</style>
