<template>
  <ModalUtility title="Edit My Account" v-model="isVisible">
    <template #content>
      <div class="bg-white w-full h-[600px] px-4 py-2">
        <EditMyAccountForm
          v-if="userProfile"
          v-model:firstName="userProfile.profile.firstName"
          v-model:lastName="userProfile.profile.lastName"
          v-model:email="userProfile.email"
          v-model:country="userProfile.schoolAddress.country"
          v-model:name="userProfile.schoolName"
          v-model:state="userProfile.schoolAddress.state"
        />
      </div>
    </template>
    <template #footer>
      <div class="w-full flex justify-center items-center pt-4">
        <AppButton
          type="submit"
          v-bind="$attrs"
          :loading="isSaving"
          @click="handleClickSaveMyAccount"
        >
          <template #icon_left>
            <span v-html="icons.checkBlack" class="pr-2"></span>
          </template>
          {{ isSaving ? "Loading" : "Save" }}
        </AppButton>
      </div>
    </template>
  </ModalUtility>
</template>
<script lang="ts">
import { Vue, Model, Component, Watch } from "vue-facing-decorator";
import EditMyAccountForm from "./MyAccountForm/EditMyAccountForm.vue";
import ModalUtility from "../utilities/ModalUtility.vue";
import { AccountProfile, EditMyAccount } from "./types";
import Button from "primevue/button";
import EventBus from "@/config/emitter";
import { getProfileDetails, ProfileDetails } from "@/config/auth";
import axios, { AxiosResponse } from "axios";
import AppButton from "../Layout/Buttons/AppButton.vue";
import { icons as AppIcons } from "@/utils/icons";
import emitter from "@/config/emitter";
import { TELEMETRY_HEAD } from "@/utils/telemetry/data";

import { useToast } from "primevue/usetoast";
@Component({
  components: { EditMyAccountForm, ModalUtility, Button, AppButton },
})
export default class EditMyAccountPopup extends Vue {
  toast = useToast();
  @Model({
    type: Boolean,
    default: false,
  })
  isVisible!: boolean;

  userProfile!: ProfileDetails;

  myAccount: AccountProfile = {
    profile: {
      email: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      profile: "",
    },
    school: {
      country: "",
      name: "",
      state: "",
    },
  };

  imageUrl = "";
  roles = "";
  isSaving = false;

  icons = AppIcons;
  eventBus = emitter;

  @Watch("isVisible")
  onVisibleChange(status: boolean) {
    if (status) {
      this.eventBus.emit("EVENT_TRIGGER", "G008");
      this.userProfile = getProfileDetails(this.handleCheckRole());
    }
  }

  handleClickClose() {
    this.isVisible = false;
  }

  async handleClickSaveMyAccount() {
    try {
      this.eventBus.emit("EVENT_TRIGGER", "G009");
      const isStudent = this.handleCheckRole() === "student";
      this.isSaving = true;

      const payload = {
        firstName: this.userProfile.profile.firstName,
        lastName: this.userProfile.profile.lastName,
        schoolName: this.userProfile.schoolName,
        schoolAddress: {
          country: this.userProfile.schoolAddress.country,
          state: this.userProfile.schoolAddress.state,
        },
      };

      if (payload.schoolName) {
        const endpoint = isStudent
          ? `/student/profile`
          : `/teachers/${this.userProfile.uuid}`;
        const response: AxiosResponse = await axios.put(endpoint, payload, {
          headers: TELEMETRY_HEAD.updateAccount,
        });

        if (response.data.ok) {
          localStorage.setItem(
            isStudent ? "student" : "teacher",
            JSON.stringify(this.userProfile)
          );
          EventBus.emit("UPDATE_MY_ACCOUNT");
          this.isVisible = false;
          this.isSaving = false;
          this.showMessage("Account has been updated.", "success");
        } else {
          this.isSaving = false;
          console.error(response.data.message);
        }
      } else {
        //
        this.isSaving = false;
        // this.showMessage("Country/State is required.", "error");
        this.showMessage("School is required.", "error");
      }
    } catch (e) {
      this.isSaving = false;
      console.error(e);
    } finally {
      this.isSaving = false;
      //
    }
  }

  handleCheckRole(): string {
    const auth = localStorage.getItem("authenticatedAs") || "";
    return auth;
  }

  mounted() {
    this.userProfile = getProfileDetails(this.handleCheckRole());
  }

  showMessage(
    message: string,
    severity: "success" | "info" | "warn" | "error" | undefined
  ) {
    this.toast.add({
      severity: severity,
      detail: message,
      life: 3000,
    });
  }
}
</script>
