import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import DashboardView from "@/views/DashboardView.vue";

import MarkingView from "@/views/MarkingView.vue";
import CriteriaSheetView from "@/views/CriteriaSheetView.vue";
import StylesView from "@/views/StylesView.vue";
import LoginView from "../views/Authentication/LoginView.vue";
import RegistrationSelectionView from "../views/Authentication/RegistrationSelectionView.vue";
import RegistrationOnboardingView from "../views/Authentication/RegistrationOnboardingView.vue";
import RegistrationView from "../views/Authentication/RegistrationView.vue";
import BulkUploadView from "@/views/BulkUploadView.vue";
import BulkUploadSplitView from "@/views/BulkUploadSplitView.vue";
import AssignmentView from "@/views/AssignmentView.vue";
import StudentDashboardView from "@/views/StudentDashboardView.vue";
import StudentInviteView from "@/views/Authentication/Student/StudentInviteView.vue";
import StudentRegistrationView from "@/views/Authentication/Student/StudentRegistrationView.vue";
import StudentShareAssignmentView from "@/views/Authentication/Student/StudentShareAssignmentView.vue";
import StudentActivationView from "@/views/Authentication/Student/StudentActivationView.vue";
import ActivationView from "@/views/Authentication/ActivationView.vue";
import StudentSuccessView from "@/views/Authentication/Student/StudentSuccessView.vue";
import StudentLoginView from "@/views/Authentication/Student/StudentLoginView.vue";
import ForgotPasswordView from "../views/Authentication/ForgotPasswordView.vue";
import ResetPasswordView from "../views/Authentication/ResetPasswordView.vue";
import ResetSuccessView from "../views/Authentication/ResetSuccessView.vue";
import TeacherInviteView from "@/views/Authentication/TeacherInviteView.vue";

import SuperAdminLoginView from "../views/Authentication/SuperAdminLoginView.vue";
import SuperAdminTeacherView from "@/views/SuperAdminTeacherView.vue";
import SuperAdminStudentView from "@/views/SuperAdminStudentView.vue";
import SuperAdminWandeView from "@/views/SuperAdminWandeView.vue";

import VersioningView from "@/views/VersioningView.vue";
import SocialAuthentication from "@/views/Authentication/SocialAuthentication.vue";
import CloudStorageAuthView from "@/views/Authentication/CloudStorageAuthView.vue";
import ChangeLogView from "@/views/ChangeLogView.vue";
import HelpCenterView from "@/views/HelpCenterView.vue";

import AssignmentsListView from "@/views/AssignmentsListView.vue";
import CreateAssignmentView from "@/views/CreateAssignmentView.vue";
import EditAssignmentView from "@/views/EditAssignmentView.vue";
import PageNotFoundView from "@/views/PageNotFoundView.vue";

import PopupView from "@/views/PopupView.vue";
import ClassesView from "@/views/ClassesView.vue";
import ClassesListView from "@/views/ClassesListView.vue";
import { AuthProfileState } from "@/store/auth/AuthStateTypes";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
    meta: {
      auth: false,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: DashboardView,
    meta: {
      auth: true,
      restriction: ["teacher"],
    },
  },
  {
    path: "/assignment",
    name: "Assignment",
    component: AssignmentView,
    meta: {
      auth: true,
      restriction: ["teacher"],
    },
  },
  {
    path: "/marking/:id",
    name: "Marking",
    props: true,
    component: MarkingView,
    meta: {
      auth: true,
      restriction: ["teacher"],
    },
  },
  {
    path: "/criteria-sheet",
    name: "CriteriaSheet",
    component: CriteriaSheetView,
    meta: {
      auth: true,
      restriction: ["teacher"],
    },
  },
  {
    path: "/bulk-upload/:type",
    name: "BulkUpload",
    component: BulkUploadView,
    meta: {
      auth: true,
      restriction: ["teacher"],
    },
  },
  {
    path: "/classes/all",
    props: true,
    name: "ClassesList",
    component: ClassesListView,
    meta: {
      auth: true,
      restriction: "teacher",
    },
  },
  {
    path: "/submission/:type/:id",
    name: "BulkUploadSplit",
    component: BulkUploadSplitView,
    meta: {
      auth: true,
      restriction: "teacher",
    },
  },
  {
    path: "/classes/:id?",
    props: true,
    name: "Classes",
    component: ClassesView,
    meta: {
      auth: true,
      restriction: ["teacher"],
    },
  },
  {
    path: "/styles",
    name: "Styles",
    component: StylesView,
    meta: {
      auth: true,
      restriction: ["teacher"],
    },
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
    meta: {
      auth: false,
    },
  },
  {
    path: "/register",
    name: "RegistrationSelection",
    component: RegistrationSelectionView,
    meta: {
      auth: false,
    },
  },
  {
    path: "/teacher/invite",
    name: "TeacherInvite",
    component: TeacherInviteView,
    meta: {
      auth: false,
    },
  },

  // {
  //   path: "/register/form",
  //   name: "RegistrationForm",
  //   component: RegistrationView,
  // },
  {
    path: "/register/details",
    name: "RegistrationForm",
    component: RegistrationView,
    meta: {
      auth: false,
    },
  },
  {
    path: "/register/onboarding",
    name: "RegistrationOnboarding",
    component: RegistrationOnboardingView,
    meta: {
      auth: true,
    },
  },

  // Student

  {
    path: "/student/invite",
    name: "StudentInvite",
    component: StudentInviteView,
    meta: {
      auth: false,
    },
  },
  {
    path: "/student/registration",
    name: "StudentRegistration",
    component: StudentRegistrationView,
    meta: {
      auth: false,
    },
  },
  {
    path: "/activation",
    name: "Activation",
    component: ActivationView,
    meta: {
      auth: false,
    },
  },
  {
    path: "/student/success",
    name: "StudentSuccess",
    component: StudentSuccessView,
    meta: {
      auth: false,
    },
  },
  {
    path: "/student/login",
    name: "StudentLogin",
    component: StudentLoginView,
    meta: {
      auth: false,
    },
  },
  {
    path: "/student/dashboard",
    name: "StudentDashboard",
    component: StudentDashboardView,
    meta: {
      auth: true,
      restriction: ["student"],
    },
  },
  {
    path: "/student/shared-assignment",
    name: "StudentShareAssignment",
    component: StudentShareAssignmentView,
    meta: {
      auth: false,
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPasswordView,
    meta: {
      auth: false,
    },
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPasswordView,
    meta: {
      auth: false,
    },
  },
  {
    path: "/reset-success",
    name: "ResetSuccessView",
    component: ResetSuccessView,
    meta: {
      auth: false,
    },
  },
  {
    path: "/popups",
    name: "Popup",
    component: PopupView,
    meta: {
      auth: true,
      restriction: ["teacher"],
    },
  },
  {
    path: "/superadmin/login",
    name: "SuperAdminLogin",
    component: SuperAdminLoginView,
    meta: {
      auth: false,
    },
  },
  {
    path: "/superadmin/teacher",
    name: "SuperAdminTeacher",
    component: SuperAdminTeacherView,
    meta: {
      auth: true,
      restriction: ["super admin"],
    },
  },
  {
    path: "/superadmin/student",
    name: "SuperAdminStudent",
    component: SuperAdminStudentView,
    meta: {
      auth: true,
      restriction: ["super admin"],
    },
  },
  {
    path: "/superadmin/wonde",
    name: "SuperAdminWande",
    component: SuperAdminWandeView,
    meta: {
      auth: true,
      restriction: "super admin",
    },
  },
  {
    path: "/app/version",
    name: "Versioning",
    component: VersioningView,
    meta: {
      auth: false,
    },
  },
  {
    path: "/authenticate/google",
    name: "Google Authentication",
    component: SocialAuthentication,
    meta: {
      auth: false,
    },
  },
  {
    path: "/authenticate/microsoft",
    name: "Microsoft Authentication",
    component: SocialAuthentication,
    meta: {
      auth: false,
    },
  },
  {
    path: "/changelog",
    name: "ChangeLog",
    component: ChangeLogView,
    meta: {
      auth: false,
    },
  },
  {
    path: "/help",
    name: "HelpCenter",
    component: HelpCenterView,
    meta: {
      auth: false,
    },
  },
  {
    path: "/cloud-storage/authentication",
    name: "Cloud Storage Authentication",
    component: CloudStorageAuthView,
    meta: {
      auth: false,
    },
  },
  {
    path: "/assignments/all",
    name: "AssignmentsList",
    component: AssignmentsListView,
    meta: {
      auth: true,
      restriction: "teacher",
    },
  },
  {
    path: "/assignments/create",
    name: "CreateAssignment",
    component: CreateAssignmentView,
    meta: {
      auth: true,
      restriction: "teacher",
    },
  },
  {
    path: "/assignments/:id",
    name: "EditAssignment",
    component: EditAssignmentView,
    meta: {
      auth: true,
      restriction: "teacher",
    },
  },

  {
    path: "/:pathMatch(.*)*",
    name: "PageNotFound",
    component: PageNotFoundView,
  },
];

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const AUTH_SCREEN = [
  "/",
  "/login",
  "/register",
  "/register/details",
  "/register/onboarding",
  "/teacher/invite",
  "/student/invite",
  "/student/registration",
  "/student/activation",
  "/student/success",
  "/student/login",
  "/forgot-password",
  "/reset-password",
  "/superadmin/login",
  "/activation",
  "/student/shared-assignment",
];

const unauthenticatedGlobalRoutes: string[] = [
  "ForgotPassword",
  "ResetPassword",
  "Activation",
  "StudentInvite",
  "StudentShareAssignment",
  "StudentRegistration",
  "StudentSuccess",
  "StudentLogin",
  "Login",
  "RegistrationSelection",
  "RegistrationForm",
  "SuperAdminLogin",
];

router.beforeEach((to: any, from: any) => {
  console.log("beforeEach");
  console.log(to);
  console.log(from);
  const authLocalStorage = localStorage.getItem("auth");
  const authenticatedRoutes = structuredClone(
    routes
      .filter((route) => route.meta && route.meta.auth)
      .map((route) => route.name)
  );
  let authProfile!: AuthProfileState;
  if (authLocalStorage) {
    authProfile = JSON.parse(authLocalStorage);
  }
  // const onBoardingVisible = localStorage.getItem("onboarding");
  const onBoardingVisible = AUTH_SCREEN.includes(to.fullPath)
    ? "visible"
    : "hide";
  const authenticatedAs = localStorage.getItem("authenticatedAs");
  console.log("authProfile");
  console.log(authProfile);
  // Page restrictions per roles, redirect to dashboard if not accessible.
  if (!from.name && to.meta.auth && to.meta.restriction) {
    let canAccess = false;
    authProfile.data.roles.forEach((role) => {
      if (to.meta.restriction.includes(role)) {
        canAccess = true;
      }
    });
    console.log("ZXCadqweqw");
    console.log(canAccess);
    if (!canAccess) {
      if (
        authProfile.data.roles.includes("student") &&
        authenticatedAs === "student"
      ) {
        router.push({ name: "StudentDashboard" });
      } else if (
        authProfile.data.roles.includes("teacher") &&
        authenticatedAs === "teacher"
      ) {
        router.push({ name: "Dashboard" });
      } else {
        router.push({ name: "SuperAdminTeacher" });
      }
    }
  }

  // accessin on boarding page when reload the page
  if (
    !from.name &&
    to.name === "RegistrationOnboarding" &&
    authProfile.data.roles.includes("teacher") &&
    authenticatedAs === "teaceher" &&
    onBoardingVisible &&
    onBoardingVisible === "hide"
  ) {
    router.push({ name: "Dashboard" });
  }

  if (
    !from.name &&
    to.name === "RegistrationOnboarding" &&
    authProfile.data.roles.includes("student") &&
    authenticatedAs === "student" &&
    onBoardingVisible &&
    onBoardingVisible === "hide"
  ) {
    if (
      window.location.pathname !== "/student/invite" &&
      window.location.pathname !== "/student/shared-assignment"
    ) {
      router.push({ name: "StudentDashboard" });
    }
  }

  if (
    !from.name &&
    to.name === "RegistrationOnboarding" &&
    authProfile.data.roles.includes("super admin") &&
    onBoardingVisible &&
    onBoardingVisible === "hide"
  ) {
    router.push({ name: "SuperAdminTeacher" });
  }
  // --------------------------------------------------------------------

  if (
    unauthenticatedGlobalRoutes.includes(to.name as string) &&
    authLocalStorage &&
    authProfile &&
    authProfile.data.roles.includes("teacher") &&
    authenticatedAs === "teacher"
  ) {
    router.push({ name: "Dashboard" });
  }

  // condition for pages that can't be access after login or from authenticated routes
  if (
    authenticatedRoutes.includes(from.name as string) &&
    authLocalStorage &&
    authProfile &&
    authProfile.data.roles.includes("teacher") &&
    authenticatedAs === "teacher" &&
    to.name === "RegistrationOnboarding"
  ) {
    router.push({ name: "Dashboard" });
  }
  // end ----------------------------------------------------------------

  if (
    unauthenticatedGlobalRoutes.includes(to.name as string) &&
    authLocalStorage &&
    authProfile &&
    authProfile.data.roles.includes("student") &&
    authenticatedAs === "student"
  ) {
    if (
      window.location.pathname !== "/student/invite" &&
      window.location.pathname !== "/student/shared-assignment"
    ) {
      router.push({ name: "StudentDashboard" });
    }
  }

  if (
    unauthenticatedGlobalRoutes.includes(to.name as string) &&
    authLocalStorage &&
    authProfile &&
    authProfile.data.roles.includes("super admin")
  ) {
    router.push({ name: "SuperAdminTeacher" });
  }
});

export default router;
