<template>
  <div class="registraiton-form">
    <div class="flex flex-col lg:flex-row gap-5">
      <TextInputComponent
        type="text"
        name="firstName"
        label="First Name"
        :value="firstName"
        :required="isRequired"
        :errMsg="errMsg.firstName"
        @input="onInput($event, 'update:firstName')"
        placeholder="Enter First Name"
      />
      <TextInputComponent
        type="text"
        name="lastName"
        label="Last Name"
        :value="lastName"
        :required="isRequired"
        :errMsg="errMsg.lastName"
        @input="onInput($event, 'update:lastName')"
        placeholder="Enter Last Name"
      />
    </div>
    <div class="flex flex-col lg:flex-row gap-5">
      <TextInputComponent
        type="email"
        name="email"
        label="Email"
        :value="email"
        :required="isRequired"
        :errMsg="errMsg.email"
        @input="onInput($event, 'update:email')"
        placeholder="Enter Email"
      />
      <TextInputComponent
        type="email"
        name="confirmEmail"
        label="Confirm Email"
        :value="confirmEmail"
        :required="isRequired"
        :errMsg="errMsg.confirmEmail"
        @input="onInput($event, 'update:confirmEmail')"
        placeholder="Confirm Email"
      />
    </div>
    <div class="flex flex-col lg:flex-row gap-5">
      <TextInputComponent
        type="password"
        name="password"
        label="Password"
        :value="password"
        :required="isRequired"
        :errMsg="errMsg.password"
        @input="onInput($event, 'update:password')"
        placeholder="Enter Password"
        iconRight="eyeActive"
      />
      <TextInputComponent
        type="password"
        name="confirmPassword"
        label="Confirm Password"
        :value="confirmPassword"
        :required="isRequired"
        :errMsg="errMsg.confirmPassword"
        @input="onInput($event, 'update:confirmPassword')"
        placeholder="Confirm Password"
        iconRight="eyeActive"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import TextInputComponent from "@/components/Input/TextInput/TextInputComponent.vue";
import { validateEmail, validatePassword } from "@/utils/helper";

interface IFormData extends IErrorMessage {
  firstName: string;
  lastName: string;
  email: string;
  confirmEmail: string;
  password: string;
  confirmPassword: string;
}

interface IErrorMessage {
  [key: string]: string;
}

@Component({
  components: {
    TextInputComponent,
  },
  emits: [
    "update:firstName",
    "update:lastName",
    "update:email",
    "update:confirmEmail",
    "update:password",
    "update:confirmPassword",
  ],
})
export default class RegistrationFormComponent extends Vue {
  @Prop({
    type: String,
  })
  firstName!: string;

  @Prop({
    type: String,
  })
  lastName!: string;

  @Prop({
    type: String,
    required: true,
  })
  email!: string;

  @Prop({
    type: String,
    required: true,
  })
  confirmEmail!: string;

  @Prop({
    type: String,
    required: true,
  })
  password!: string;

  @Prop({
    type: String,
    required: true,
  })
  confirmPassword!: string;

  @Prop({
    type: Function,
    required: true,
  })
  private handleValidation!: (data: IFormData) => void;

  isRequired = true;
  errMsg: IFormData = {
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    password: "",
    confirmPassword: "",
  };

  validationTimeout!: any;
  /**
   * onInput
   */
  public onInput(event: Event, emitType: string) {
    const evt = event.target as HTMLInputElement;
    const name = evt.getAttribute("name");

    clearTimeout(this.validationTimeout);
    this.validationTimeout = setTimeout(() => {
      if (name) {
        if (evt.value) {
          this.errMsg[name] = "";

          if (name.toLocaleLowerCase().search("email") > -1) {
            this.errMsg[name] = validateEmail(evt.value);
          } else if (name.toLocaleLowerCase().search("password") > -1) {
            this.errMsg[name] = validatePassword(evt.value);
          } else {
            //
          }

          if (this.email && this.confirmEmail) {
            const validEmail =
              validateEmail(this.email) && validateEmail(this.confirmEmail);
            const doEmailsMatch = this.email === this.confirmEmail;
            if (!validEmail && !doEmailsMatch) {
              this.errMsg.confirmEmail = "Email and Confirm Email should match";
            } else {
              this.errMsg.confirmEmail = validEmail;
            }
          } else if (this.password && this.confirmPassword) {
            const validPassword =
              validatePassword(this.password) &&
              validatePassword(this.confirmPassword);
            const doPasswordsMatch = this.password === this.confirmPassword;
            if (!validPassword && !doPasswordsMatch) {
              this.errMsg.confirmPassword =
                "Password and Confirm Password should match";
            } else {
              this.errMsg.confirmPassword = validPassword;
            }
          }
        } else {
          this.errMsg[name] = "Field is required";
        }
      }
      this.handleValidation(this.errMsg);
    }, 200);
    this.$emit(emitType, evt.value);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.registration-form {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  max-width: 926px;
}
</style>
