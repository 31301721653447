<template>
  <div
    class="flex flex-col py-[26px] px-[30px] bg-[#E2F8F2] rounded-lg justify-between"
  >
    <h6 class="text-[18px] leading-[1.5] text-[#333] mb-[23px] font-bold">
      Create your first assignment
    </h6>
    <p class="text-[#595959] text-[14px] leading-[1.5] mb-[30px] font-medium">
      Organise all of your classwork with assignments to easily keep track of
      submissions, grades, and progress.
    </p>
    <AppButton class="!bg-[#ABEAD9] hover:opacity-80" @click="createAssignment"
      >Create an assignment</AppButton
    >
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import AppButton from "../../Layout/Buttons/AppButton.vue";
import { icons as AppIcons, icons } from "@/utils/icons";
import { StorageDataKey } from "@/enums/enums";
import AppTypographyText from "../../Layout/Typhography/AppTypographyText.vue";
import DashboardService from "@/services/DashboardService";
import axios, { AxiosResponse } from "axios";
@Component({
  components: {
    AppButton,
    AppTypographyText,
  },
})
export default class TaskAssignmentCard extends Vue {
  //

  createAssignment() {
    //assignments/create
    this.$router.push({
      path: "/assignments/create",
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
