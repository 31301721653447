import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-row" }
const _hoisted_2 = { class: "flex-1 flex flex-row" }
const _hoisted_3 = { class: "w-full" }
const _hoisted_4 = {
  key: 0,
  class: "w-full"
}
const _hoisted_5 = {
  key: 1,
  class: "flex justify-center items-center px-[8px]"
}
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormTextInput = _resolveComponent("FormTextInput")!
  const _component_SelectCommentBankStatus = _resolveComponent("SelectCommentBankStatus")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _withDirectives((_openBlock(), _createElementBlock("span", null, [
          _createVNode(_component_FormTextInput, {
            size: "small",
            modelValue: _ctx.comment.content,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.comment.content) = $event)),
            placeholder: "Add comment here...",
            class: "truncate",
            disabled: _ctx.isView
          }, null, 8, ["modelValue", "disabled"])
        ])), [
          [
            _directive_tooltip,
            { value: _ctx.comment.content, showDelay: 500 },
            void 0,
            { bottom: true }
          ]
        ])
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["w-72 flex justify-center items-center px-2", _ctx.isView ? 'pointer-events-none' : ''])
    }, [
      (_ctx.comment.metadata)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", {
              class: _normalizeClass(["w-full", _ctx.isView ? 'opacity-[0.5] pointer-events-none' : ''])
            }, [
              _createVNode(_component_SelectCommentBankStatus, {
                modelValue: _ctx.comment.metadata.tag,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.comment.metadata.tag) = $event))
              }, null, 8, ["modelValue"])
            ], 2)
          ]))
        : _createCommentVNode("", true),
      (!_ctx.isView)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_ctx.index + 1 === _ctx.length && _ctx.length === 1)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: "cursor-not-allowed",
                  innerHTML: _ctx.icons.trashGrayIcon
                }, null, 8, _hoisted_6))
              : (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleClickRemoveComment && _ctx.handleClickRemoveComment(...args))),
                  class: "cursor-pointer",
                  innerHTML: _ctx.icons.trashRedIcon
                }, null, 8, _hoisted_7))
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}