<script lang="ts">
import FormField from "@/components/Layout/Forms/FormField.vue";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import { Student } from "@/store/createClass/types";
import { Vue, Component, Prop, Emit } from "vue-facing-decorator";
import { ClassStudentsDetails } from "@/store/class/classTypes";

type Error = {
  email?: string;
  firstName?: string;
  lastName?: string;
};

@Component({
  components: { FormField, AppTypographyText },
})
export default class StudentItem extends Vue {
  @Prop({
    type: Number,
    required: true,
  })
  index!: number;

  @Prop({
    type: Object,
    required: false,
  })
  student!: ClassStudentsDetails;

  @Prop({
    type: Object,
  })
  error!: Error;

  @Prop({
    type: Number,
    required: true,
  })
  studentCount!: number;

  studentModel = {
    firstName: "",
    lastName: "",
    email: "",
  };

  handleClickRemoveStudent() {
    this.$emit("onRemoveStudent", this.index);
  }

  mounted() {
    const studentProfile = this.student.profile;
    this.studentModel.firstName = studentProfile.firstName;
    this.studentModel.lastName = studentProfile.lastName;
  }

  updated() {
    const student = structuredClone(this.student);
    student.profile.firstName = this.studentModel.firstName;
    student.profile.lastName = this.studentModel.lastName;
    student.profile.email = this.studentModel.email;

    this.$emit("onStudentUpdate", {
      index: this.index,
      student: student,
    });
  }
}
</script>
<template>
  <div class="flex flex-row gap-x-4 mb-2">
    <div class="flex-1">
      <AppTypographyText
        v-if="index === 0"
        label="First Name"
        variant="md"
        type="body"
        class="mb-2"
      />
      <FormField
        type="text"
        v-model="studentModel.firstName"
        :error="error && error.firstName ? error.firstName : ''"
        placeholder="Jessica"
        required
      ></FormField>
    </div>
    <div class="flex-1">
      <AppTypographyText
        v-if="index === 0"
        label="Last Name"
        variant="md"
        type="body"
        class="mb-2"
      />
      <FormField
        type="text"
        v-model="studentModel.lastName"
        :error="error && error.lastName ? error.lastName : ''"
        placeholder="Jones"
        required
      ></FormField>
    </div>
    <div class="flex-1">
      <AppTypographyText
        v-if="index === 0"
        label="Email"
        variant="md"
        type="body"
        class="mb-2"
      />
      <FormField
        type="text"
        v-model="studentModel.email"
        :error="error && error.email ? error.email : ''"
        placeholder="Jessica@school.com"
        required
      ></FormField>
    </div>
    <div class="px-1 flex justify-center items-center">
      <i
        v-if="studentCount === 1"
        :class="index === 0 ? 'translate-y-2/4' : ''"
        class="pi pi-times-circle text-lg rounded-full cursor-not-allowed"
      ></i>
      <i
        v-else
        @click="handleClickRemoveStudent"
        :class="index === 0 ? 'translate-y-2/4' : ''"
        class="pi pi-times-circle text-lg rounded-full cursor-pointer"
      ></i>
    </div>
  </div>
</template>
