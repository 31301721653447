<template>
  <div class="mb-5">
    <AppTypographyText
      label="Download and print class coversheets for automatic splitting"
      variant="bd"
      type="title"
      class="mb-5"
    />
    <AppTypographyText
      v-if="!hasStudents"
      label="How many students are in your class?"
      variant="bd"
      type="body"
      class="mb-1"
    />
    <div class="flex gap-5">
      <FormField
        v-if="!hasStudents"
        class="w-[60%]"
        v-model="studentCount"
        :options="options"
        optionLabel="label"
        option-value="value"
        type="select"
        :required="true"
        :background="false"
      />
      <AppButton
        :class="hasStudents ? 'w-full' : 'w-[40%] min-w-[250px]'"
        type="primary"
        :loading="downloadingCoversheets"
        :disabled="downloadingCoversheets"
        @click="handleDownloadCoversheets"
      >
        <template #icon_left>
          <span v-html="icon.iconDownload"></span>
        </template>
        Download Coversheets
      </AppButton>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import FormField from "@/components/Layout/Forms/FormField.vue";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { icons } from "@/utils/icons";
import emitter from "@/config/emitter";

interface StudentCount {
  label: string;
  value: number;
}

@Component({
  components: {
    AppTypographyText,
    FormField,
    AppButton,
  },
})
export default class DownloadCoversheetsComponent extends Vue {
  icon = icons;
  eventBus = emitter;

  @Prop({
    type: Boolean,
    default: false,
    required: false,
  })
  hasStudents!: boolean;

  @Prop({
    type: Boolean,
    default: false,
    required: false,
  })
  downloadingCoversheets!: boolean;

  studentCount = 1;
  options: StudentCount[] = [];

  mounted() {
    this.populateOptions();
  }

  populateOptions() {
    const options: StudentCount[] = [];
    for (var i = 1; i <= 40; i++) {
      options.push({ label: i.toString(), value: i });
    }
    this.options = options;
  }

  async handleDownloadCoversheets() {
    const studentCount = this.hasStudents ? -1 : this.studentCount;
    this.$emit("onDownloadCoversheets", studentCount);
  }
}
</script>

<style scoped lang="scss"></style>
