import { RootState } from "..";
import { Module } from "vuex";
import * as mutation from "./mutations";
import * as mutationType from "./mutation-types";
import { AssignmentState } from "./assignmentTypes";
import * as action from "./actions";
import * as actionName from "./action-names";
import state from "./assignmentState";

const bulkUpload: Module<AssignmentState, RootState> = {
  namespaced: true,
  state,
  actions: {
    [actionName.SET_ASSIGNMENT_BY_CLASS]: action.SetAssignmentsByClass,
    [actionName.SET_ASSIGNMENT]: action.SetAssignmentAction,
    [actionName.SET_ASSIGNMENT_SUBMISSION_SUMMARY]:
      action.SetAssignmentSubmissionSummaryAction,
    [actionName.SET_SELECTED_STUDENT_SUMMARY]:
      action.SetSelectedStudentSummaryAction,
    [actionName.DELETE_ASSIGNMENT]: action.DeleteAssignmentAction,
    [actionName.SET_SELECTED_ASSIGNMENT]: action.SetSelectedAssignmentAction,
    [actionName.SET_WARNING_MODAL]: action.SetWarningModalAction,
  },
  mutations: {
    [mutationType.SET_ASSIGNMENTS_BY_CLASS]: mutation.SetAssignmentByClass,
    [mutationType.SET_ASSIGNMENT_SUBMISSION_SUMMARY]:
      mutation.SetAssignmentSubmissionSummary,
    [mutationType.SET_ASSIGNMENT]: mutation.SetAssignment,
    [mutationType.SET_SELECTED_STUDENT_SUMMARY]:
      mutation.SetSelectedStudentSummary,
    [mutationType.SET_SELECTED_ASSIGNMENT]: mutation.SetSelectedAssignment,
    [mutationType.SET_WARNING_MODAL]: mutation.SetWarningModal,
  },
};

export default bulkUpload;
