import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "px-3" }
const _hoisted_2 = { class: "flex justify-start items-center p-3" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AddStudent = _resolveComponent("AddStudent")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.students, (student, index) => {
        return (_openBlock(), _createBlock(_component_AddStudent, {
          key: index,
          index: index,
          student: student,
          onOnRemoveStudent: _ctx.handleClickRemoveStudent,
          error: _ctx.errors.find((error) => error.index === index),
          studentCount: _ctx.students.length
        }, null, 8, ["index", "student", "onOnRemoveStudent", "error", "studentCount"]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AppButton, {
        type: "primary",
        onClick: _ctx.handleSetInitialStudent
      }, {
        icon_left: _withCtx(() => [
          _createElementVNode("span", {
            innerHTML: _ctx.icon.plusBlack
          }, null, 8, _hoisted_3)
        ]),
        default: _withCtx(() => [
          _createTextVNode(" Add more rows ")
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ], 64))
}