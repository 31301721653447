<script lang="ts">
import { Vue, Component, Prop, Emit } from "vue-facing-decorator";
import ModalUtility from "../utilities/ModalUtility.vue";
import FormField from "../Layout/Forms/FormField.vue";
import { Icon, icons } from "@/utils/icons";
import AppButton from "../Layout/Buttons/AppButton.vue";
import AddStudentManually from "./Manually/AddStudentManually.vue";
import { ClassInfo, Student } from "@/store/createClass/types";
import { toTitleCase, isEmailValid } from "@/utils/helper";
import ClassService from "@/services/ClassService";
import emitter from "@/config/emitter";
import { AxiosResponse } from "axios";
import { useToast } from "primevue/usetoast";

type InvalidObject = {
  index: number;
  [key: string]: string | number;
};

type Error = {
  index?: number;
  firstName?: string;
  lastName?: string;
  studentId?: string;
  email?: string;
};

@Component({
  components: {
    ModalUtility,
    FormField,
    AppButton,
    AddStudentManually,
  },
})
export default class InviteManually extends Vue {
  toast = useToast();
  @Prop({
    type: Object,
    required: true,
  })
  selectedClass!: ClassInfo;

  private classService = new ClassService();

  icon: Icon = icons;
  isValid = true;
  invalidObjects: InvalidObject[] = [];
  errors: Error[] = [];
  loadingStudents = false;
  errorStudents = false;
  eventBus = emitter;
  classUuid = "";

  validateData() {
    this.isValid = true;
    let students: Student[] = structuredClone(
      this.$store.state.createClassPopup.addManually
    );

    this.invalidObjects = [];
    students.forEach((object: Student, index: number) => {
      for (const key in object) {
        let message = "";
        if (
          object[key as keyof Student] === null ||
          object[key as keyof Student] === undefined ||
          object[key as keyof Student] === ""
        ) {
          message = `${toTitleCase(key)} is required.`;
        }
        if (
          key === "email" &&
          object[key as keyof Student] &&
          !isEmailValid(object[key as keyof Student])
        ) {
          message = `${toTitleCase(key)} is invalid.`;
        }

        if (
          key === "email" &&
          students.filter((student) => student.email === object.email).length >=
            2
        ) {
          message = `The ${toTitleCase(key)} is duplicate`;
        }

        if (message) {
          const invalidObj = {
            index,
            [key]: message,
          } as InvalidObject;
          this.invalidObjects.push(invalidObj);
        }
      }
    });

    if (this.invalidObjects.length > 0) {
      this.isValid = false;
      const result: InvalidObject[] = [];

      this.invalidObjects.forEach((item) => {
        const existingItem = result.find((entry) => entry.index === item.index);

        if (existingItem) {
          for (const key in item) {
            if (key !== "index") {
              existingItem[key] = item[key];
            }
          }
        } else {
          result.push({ ...item });
        }
      });
      this.errors = result;
    } else {
      this.isValid = true;
    }
  }

  handleCloseCreateClass() {
    this.$store.dispatch("createClassPopup/resetClass");
  }

  handleClickSave() {
    this.eventBus.emit("EVENT_TRIGGER", "CM006");
    if (this.selectedClass && this.selectedClass.uuid) {
      this.classUuid = this.selectedClass.uuid;
      this.validateData();
      if (this.isValid) {
        this.onPostStudent();
      }
    } else {
      this.$emit("onClassError", true);
    }
  }

  async onPostStudent() {
    try {
      this.loadingStudents = true;
      const students = structuredClone(
        this.$store.state.createClassPopup.addManually
      );
      const payload = {
        students,
      };
      const response: AxiosResponse =
        await this.classService.postManualAddStudent(
          this.selectedClass.uuid as string,
          payload
        );
      if (response.data.ok) {
        this.toast.add({
          severity: "success",
          detail: "An invitation has been sent to the students",
          life: 3000,
        });

        this.$emit("onComplete", this.selectedClass.uuid);
        this.eventBus.emit("RESET_INVITE_MANUALLY_FIELDS");
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingStudents = false;
    }
  }

  unmounted() {
    this.errors = [];
  }
}
</script>
<template>
  <FormField label="Add your student details below">
    <AddStudentManually :errors="errors" />
  </FormField>
  <hr class="my-5" />
  <div class="w-full flex justify-end items-end pb-5">
    <AppButton
      type="submit"
      @click="handleClickSave"
      :loading="loadingStudents"
      :disabled="loadingStudents"
    >
      <template #icon_left>
        <span v-html="icon.mailBlack"></span>
      </template>
      Send Invite
    </AppButton>
  </div>
</template>
