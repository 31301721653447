<script lang="ts">
import { Vue, Component, Prop } from "vue-facing-decorator";
import { Student } from "../../../store/createClass/types";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import AddStudent from "./AddStudent.vue";
import { icons } from "@/utils/icons";
import emitter from "@/config/emitter";

type Error = {
  index?: number;
  firstName?: string;
  lastName?: string;
  studentId?: string;
  email?: string;
};

@Component({ components: { AppButton, AddStudent } })
export default class AddStudentManually extends Vue {
  eventBus = emitter;

  @Prop({
    type: Array,
  })
  errors!: Error[];

  @Prop({
    type: Boolean,
    default: false,
  })
  resetState!: boolean;

  icon = icons;
  students: Student[] = [];
  isAddStudent = true;

  handleSetInitialStudent() {
    if (
      this.$store.state?.createClassPopup.addManually.length &&
      this.isAddStudent
    ) {
      this.students = structuredClone(
        this.$store.state?.createClassPopup.addManually
      );
      this.isAddStudent = false;
      return;
    }
    this.isAddStudent = false;
    const student: Student = {
      email: "",
      firstName: "",
      lastName: "",
      studentId: "N/A",
    };
    this.students.push(structuredClone(student));
    this.$store.dispatch(
      "createClassPopup/addStudent",
      structuredClone(student)
    );
  }

  handleClickRemoveStudent(index: number) {
    this.students.splice(index, 1);
    this.$store.dispatch("createClassPopup/removeStudent", index);
  }

  resetFields() {
    this.students = [
      {
        email: "",
        firstName: "",
        lastName: "",
        studentId: "N/A",
      },
    ];
  }

  mounted() {
    // this.handleSetInitialStudent();
    this.resetFields();

    this.eventBus.on("RESET_INVITE_MANUALLY_FIELDS", () => {
      this.resetFields();
    });
  }
}
</script>
<template>
  <div class="px-3">
    <AddStudent
      v-for="(student, index) in students"
      :key="index"
      :index="index"
      :student="student"
      @onRemoveStudent="handleClickRemoveStudent"
      :error="errors.find((error) => error.index === index)"
      :studentCount="students.length"
    />
  </div>
  <div class="flex justify-start items-center p-3">
    <AppButton type="primary" @click="handleSetInitialStudent">
      <template #icon_left>
        <span v-html="icon.plusBlack"></span>
      </template>
      Add more rows
    </AppButton>
  </div>
</template>
