<template>
  <PageTitle title="Dashboard" :routes="routes" />
  <div class="dashboard">
    <StudentDashboardComponent :loadingDashboard="loadingAssignments" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import axios, { AxiosResponse } from "axios";
import StudentDashboardComponent from "@/components/Dashboard/StudentDashboardComponent.vue";
import { setStudentDashboard } from "@/store/dashboard/dashboard-dispatch";
import emitter from "@/config/emitter";
import PageTitle from "@/components/Layout/Partials/PageTitle.vue";
import { TRoute } from "@/components/Layout/Partials/PageTitle.vue";

@Component({
  components: {
    StudentDashboardComponent,
    PageTitle,
  },
})
export default class StudentDashboardView extends Vue {
  public user!: object | null;
  loadingAssignments = false;
  eventBus = emitter;
  routes: TRoute[] = [
    {
      title: "Dashboard",
      routeName: "StudentDashboard",
      current: true,
    },
  ];

  /**
   * created
   */
  public async created() {
    //
  }

  async handleFetchStudentDashboard() {
    try {
      this.loadingAssignments = true;
      if (setStudentDashboard) {
        await this.$store.dispatch(setStudentDashboard);
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingAssignments = false;
    }
  }

  /**
   * mounted
   */
  public mounted() {
    this.handleFetchStudentDashboard();

    this.eventBus.on("REFRESH_DASHBOARD", () => {
      this.handleFetchStudentDashboard();
    });
  }
}
</script>

<style scoped lang="scss">
body {
  margin: 0;
  overflow: hidden;
}
</style>
