<template>
  <ModalUtility
    v-model="visible"
    width="20vw"
    title="Unsaved Changes"
    @onClose="handleCloseUnsaveModal"
  >
    <template #content>
      <div class="px-[28px] py-[30px]">
        <AppTypographyText
          variant="rg"
          type="body"
          label="You have unsaved changes on this page. Do you want to discard or save your changes?"
        />
      </div>
    </template>
    <template #footer>
      <div class="flex pt-5 border-t border-solid border-flohh-neutral-85">
        <div class="flex-1 flex justify-start items-center">
          <AppButton @click="handleLeave" text blackLabel> Discard </AppButton>
        </div>
        <div class="flex-1 flex justify-end items-center gap-x-4">
          <AppButton type="submit" @click="handlePrimaryButtonAction">
            <template #icon_left>
              <span v-html="icons.checkBlack"></span>
            </template>
            Save Changes
          </AppButton>
        </div>
      </div>
    </template>
  </ModalUtility>
</template>

<script lang="ts">
import { Component, Prop, Vue, Ref, Model } from "vue-facing-decorator";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import ModalUtility from "@/components/utilities/ModalUtility.vue";
import { icons } from "@/utils/icons";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import emitter from "@/config/emitter";

@Component({
  components: {
    AppTypographyText,
    ModalUtility,
    AppButton,
  },
})
export default class UnsavedWarningModal extends Vue {
  icons = icons;
  eventBus = emitter;

  @Model({
    type: Boolean,
    required: true,
    default: true,
  })
  visible!: boolean;

  @Prop({
    type: String,
    required: true,
  })
  redirectTo!: string;

  @Prop({
    type: String,
    required: false,
    default: "stay",
    validator: (value) => {
      return ["save and close", "stay"].includes(value);
    },
  })
  modalType!: string;

  handleCloseUnsaveModal() {
    this.$emit("onCloseSaveModal", false);
  }

  handleLeave() {
    this.$emit("onLeave", this.redirectTo);
  }

  handleStay() {
    this.eventBus.emit("CLOSE_CRITERIA_SHEET_LIST_MODAL");
    this.eventBus.emit("CLOSE_COMMENT_BANK_LIST_MODAL");
    this.visible = false;
    this.$emit("onStay");
  }

  handleClickSaveAndClose() {
    this.eventBus.emit("CLOSE_CRITERIA_SHEET_LIST_MODAL");
    this.eventBus.emit("CLOSE_COMMENT_BANK_LIST_MODAL");
    this.$emit("onSaveAndClose", this.redirectTo);
  }

  handlePrimaryButtonAction() {
    if (this.modalType === "save and close") {
      this.handleClickSaveAndClose();
    } else {
      this.handleStay();
    }
  }
}
</script>

<style scoped lang="scss"></style>
