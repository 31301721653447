<script lang="ts">
import { Vue, Component, Model, Emit, Watch, Prop } from "vue-facing-decorator";
import AppButton from "../Layout/Buttons/AppButton.vue";
import AppTypographyText from "../Layout/Typhography/AppTypographyText.vue";
import FormField from "../Layout/Forms/FormField.vue";
import { Icon, icons } from "@/utils/icons";
import { useToast } from "primevue/usetoast";
import { ClassInfo } from "@/store/createClass/types";

@Component({
  components: {
    AppButton,
    AppTypographyText,
    FormField,
  },
})
export default class InviteViaLink extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  selectedClass!: ClassInfo;

  icon: Icon = icons;
  link = "";
  toast = useToast();

  handleClickCopy() {
    if (!this.link) {
      this.$emit("onClassError", true);
      return;
    }
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(this.link)
        .then(() => {
          this.toast.add({
            severity: "success",
            detail: "Invite link copied to clipboard",
            life: 3000,
          });
        })
        .catch((err) => {
          this.toast.add({
            severity: "error",
            detail: `Unable to copy invite link: ${err}`,
            life: 3000,
          });
        });
    }
  }

  handleRedirectToClassPage() {
    if (!this.link) {
      this.$emit("onClassError", true);
      return;
    }
    if (this.$route.name === "Classes") {
      this.$emit("onClose");
    } else {
      this.$router.push({
        name: "Classes",
        params: { id: this.selectedClass.uuid },
      });
    }
  }

  generateInviteLink() {
    if (this.selectedClass) {
      const code = this.selectedClass.inviteCode;
      if (code) {
        this.link = `${window.location.protocol}//${window.location.host}/student/invite?code=${code}`;
      } else {
        this.link = "";
      }
    }
  }

  mounted() {
    this.generateInviteLink();
  }

  @Watch("selectedClass.inviteCode")
  selectedClassCodeWatcher() {
    this.generateInviteLink();
  }
}
</script>
<template>
  <FormField
    v-model="link"
    label="Invite students to join your class with a link"
    placeholder="Select a class to generate the invite link"
    readonly
  />
  <div class="w-full flex justify-end items-end py-2">
    <AppButton
      type="primary"
      @click="handleClickCopy"
      :disabled="!link"
      :class="link ? 'opacity-100' : 'opacity-40'"
    >
      <template #icon_left>
        <span v-html="icon.copyContent"></span>
      </template>
      Copy Link
    </AppButton>
  </div>
  <hr class="my-5" />
  <div class="w-full flex justify-end items-end pb-5">
    <AppButton
      type="submit"
      @click="handleRedirectToClassPage"
      :disabled="!link"
      :class="link ? 'opacity-100' : 'opacity-40'"
    >
      <template #icon_left>
        <span v-html="icon.checkBlack"></span>
      </template>
      Take me to my class page
    </AppButton>
  </div>
</template>
