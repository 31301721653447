<template>
  <h6 class="text-flohh-h6 font-flohh-font-bold">Digital Files Bulk Upload</h6>
  <div class="py-6">
    <hr />
  </div>
  <AppTypographyText
    label="Choose one of the upload options below to upload your student submissions. You can select multiple submission files and include them in a single upload."
    variant="md"
    type="body"
    class="mb-10"
  />
  <div class="px-2 flex gap-10 mb-5">
    <div class="flex gap-2 items-center">
      <FormRadioButton
        v-model="selectedUploadMethod"
        name="fileUpload"
        inputId="fileUpload"
        :value="0"
      />
      <label
        for="fileUpload"
        class="font-flohh-font-medium text-flohh-text-body cursor-pointer"
        :class="selectedUploadMethod === 0 ? 'text-flohh-primary-pink' : ''"
        >File Upload</label
      >
    </div>
    <div class="flex gap-2 items-center">
      <FormRadioButton
        v-model="selectedUploadMethod"
        name="googleDriveUpload"
        inputId="googleDriveUpload"
        :value="1"
      />
      <label
        for="googleDriveUpload"
        class="font-flohh-font-medium text-flohh-text-body cursor-pointer"
        :class="selectedUploadMethod === 1 ? 'text-flohh-primary-pink' : ''"
        >Google Drive</label
      >
    </div>
  </div>
  <div :class="[{ 'invalid-file': fileError }, 'relative']">
    <DefaultFileUploader
      v-if="selectedUploadMethod === 0"
      v-model:selectedFiles="selectedFiles"
      :multiple="true"
      :fileSizeLimit="32"
      fileSizeLimitPlaceholder="File size limit: 125 pages/32MB"
      acceptedFiles=".pdf,.docx,.doc"
      supportedFileTypes="Supported file types: PDF, Microsoft Word & Google Docs"
      :uploadProgress="uploadProgress"
      @onRemoveFile="handleRemoveFile"
    /><GoogleDriveFileUploader
      v-else-if="selectedUploadMethod === 1"
      v-model:selectedFiles="selectedFiles"
      :multiple="true"
      :fileSizeLimit="32"
      fileSizeLimitPlaceholder="File size limit: 125 pages/32MB"
      gDriveAcceptedFiles="application/pdf,application/vnd.google-apps.document"
      supportedFileTypes="Supported file types: PDF & Google Docs"
      :uploadProgress="uploadProgress"
      :fetchOnSubmit="true"
      @onDocumentsUpload="handleGoogleDocsFileData"
      @onRemoveFile="handleRemoveFile"
    />
  </div>
  <ErrorSpan v-if="fileError">{{ fileError }}</ErrorSpan>
  <hr class="my-5" />
  <AppButton
    type="submit"
    @click="handleClickUpload"
    :loading="uploadingFiles || uploading"
    :disabled="uploadingFiles || uploading"
    class="ml-auto"
  >
    <template #icon_left>
      <span v-html="icon.uploadBlack"></span>
    </template>
    Upload Submissions
  </AppButton>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from "vue-facing-decorator";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { icons } from "@/utils/icons";
import ModalUtility from "@/components/utilities/ModalUtility.vue";
import { useToast } from "primevue/usetoast";
import FormRadioButton from "@/components/Layout/Forms/FormRadioButton.vue";
import DefaultFileUploader from "@/components/utilities/DefaultFileUploader.vue";
import GoogleDriveFileUploader from "@/components/utilities/GoogleDriveFileUploader.vue";
import ErrorSpan from "@/components/utilities/ErrorSpan.vue";
import { UploadProgress } from "@/components/utilities/utilitiesTypes";
import ProgressLoader from "@/components/utilities/ProgressLoader.vue";

@Component({
  components: {
    AppButton,
    AppTypographyText,
    ModalUtility,
    FormRadioButton,
    DefaultFileUploader,
    GoogleDriveFileUploader,
    ErrorSpan,
    ProgressLoader,
  },
})
export default class UploadSubmissionsComponent extends Vue {
  toast = useToast();

  @Prop({
    type: Boolean,
    default: false,
    required: false,
  })
  uploadingFiles!: boolean;

  @Prop({
    type: Object,
    required: false,
  })
  uploadProgress!: UploadProgress;

  selectedUploadMethod = 0;
  selectedFiles: File[] = [];
  icon = icons;
  uploading = false;
  fileError = "";

  filesSucceeded: File[] = [];
  filesFailed: File[] = [];

  documentsFromDrive = "";

  @Watch("selectedFiles")
  selectedFilesWatcher(value: File[]) {
    if (value.length > 0) {
      this.fileError = "";
    } else {
      this.fileError = "At least 1 submission is required";
    }
  }

  @Watch("documentsFromDrive")
  documentsFromDriveWatcher(value: string) {
    if (value) {
      this.fileError = "";
    } else {
      this.fileError = "At least 1 submission is required";
    }
  }

  @Watch("uploadingFiles")
  uploadingFilesWatcher(newValue: boolean, oldValue: boolean) {
    if (!newValue && oldValue) {
      this.$emit("onUploadComplete", {
        filesSucceeded: this.filesSucceeded,
        filesFailed: this.filesFailed,
      });
    }
  }

  handleRemoveFile() {
    //
  }

  handleGoogleDocsFileData(document: string) {
    this.documentsFromDrive = document;
  }

  async handleClickUpload() {
    if (this.selectedFiles.length < 1 && !this.documentsFromDrive) {
      this.fileError = "At least 1 submission is required";
    }

    if (this.documentsFromDrive) {
      this.$emit("onSubmit", this.documentsFromDrive);
    } else {
      this.$emit("onSubmit", this.selectedFiles);
    }
  }

  showToastMessage(severity: "error" | "success", message: string) {
    this.toast.add({
      severity: severity,
      detail: message,
      life: 3000,
    });
  }
}
</script>

<style scoped lang="scss">
.invalid-file {
  border: 1px solid #f59289;
  border-radius: 8px;
}
</style>
