import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppDropdownButton = _resolveComponent("AppDropdownButton")!
  const _component_CreateClassPopup = _resolveComponent("CreateClassPopup")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_AppDropdownButton, {
      label: "Create",
      type: "primary",
      width: "w-13",
      onClickMenu: _ctx.handleClickMenu,
      menus: _ctx.menu,
      dropdownIcon: "plusBlack",
      iconRight: false,
      toRight: false
    }, null, 8, ["onClickMenu", "menus"]),
    _createVNode(_component_CreateClassPopup, {
      modelValue: _ctx.isAddClassOpen,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isAddClassOpen) = $event)),
      isEdit: false
    }, null, 8, ["modelValue"])
  ]))
}