import { v4 as uuidv4 } from "uuid";
import CryptoJS from "crypto-js";

//helper.ts
export const getImage = (img: string) => {
  return img ? require(`@/assets/${img}`) : "";
};

export const getTableIcons = (img: string) => {
  return img ? require(`@/assets/table-icons/${img}`) : "";
};

export const generateGuid = () => {
  return uuidv4();
};

export const escapeRegExp = (text: string) => {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
};

export const strToNum = (stringNumber: string) => {
  if (!stringNumber) return null;
  return +stringNumber;
};

export const validateEmail = (email: string) => {
  const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  if (!emailRegex.test(email)) {
    return "Invalid email address";
  } else {
    return "";
  }
};

export const validatePassword = (password: string) => {
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
  if (!regex.test(password)) {
    return "Password must contain at least one uppercase letter, one lowercase letter, one symbol, and be at least 8 characters long.";
  } else {
    return "";
  }
};

export const toTitleCase = (str: string) => {
  // Split the string into words based on uppercase letters
  const words = str.split(/(?=[A-Z])/);

  // Capitalize the first letter of each word
  const titleCaseWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  // Join the words back together with a space separator
  return titleCaseWords.join(" ");
};

export const capitalizeSentences = (inputString: string) => {
  return inputString.replace(/(^\w|\.\s\w)/g, (match) => match.toUpperCase());
};

export const isEmailValid = (email: string) => {
  // Regular expression for basic email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const converToTwentyFourHoursFormat = (
  twelveHourTime: string
): string => {
  const date = new Date();
  date.setHours(Number(twelveHourTime.split(":")[0])); // Extract hours and convert to number
  date.setMinutes(Number(twelveHourTime.split(":")[1].split(" ")[0])); // Extract minutes and convert to number

  // Check if it's PM and add 12 hours (since 12 PM remains 12)
  if (twelveHourTime.includes("PM") || twelveHourTime.includes("pm")) {
    if (!twelveHourTime.includes("12")) {
      date.setHours(date.getHours() + 12);
    }
  }

  if (twelveHourTime.includes("am") && twelveHourTime.includes("12")) {
    date.setHours(date.getHours() - 12);
  }

  // Format the result as a 24-hour time string
  const twentyFourHourTime = date.toLocaleTimeString("en-US", {
    hour12: false,
  });

  const [hours, minutes] = twentyFourHourTime.split(":");
  const formattedTime = `${hours}:${minutes}:00`;

  return formattedTime;
};

export const converToTwelveHoursFormat = (
  twentyFourHourTime: string
): string => {
  // Parameter should be in this format 07:58:00+10:00 (timezone optional)
  let withoutTimezone = twentyFourHourTime;
  if (twentyFourHourTime.includes("-")) {
    withoutTimezone = twentyFourHourTime.split("-")[0];
  } else {
    withoutTimezone = twentyFourHourTime.split("+")[0];
  }

  const hour = Number(withoutTimezone.split(":")[0]);
  const minute = Number(withoutTimezone.split(":")[1].split(":")[0]);

  const convertedMinute = minute < 10 ? `0${minute}` : minute;

  let twelveHourTime = "";

  if (hour === 12) {
    twelveHourTime = `12:${convertedMinute} PM`;
  } else if (hour === 0) {
    twelveHourTime = `12:${convertedMinute} AM`;
  } else if (hour > 12) {
    twelveHourTime = `${hour - 12}:${convertedMinute} PM`;
  } else if (hour < 12) {
    twelveHourTime = `${hour}:${convertedMinute} AM`;
  }

  return twelveHourTime;
};

export const dateConverter = (date: string): string => {
  // Create a Date object from the string
  const dateTime = new Date(date);

  // Extract the date in 'YYYY-MM-DD' format
  const datePart = dateTime.toISOString().split("T")[0];

  return datePart;
};

export const arrayBufferToFile = (
  arrayBuffer: ArrayBuffer | string,
  fileName: string
): File => {
  const blob = new Blob([arrayBuffer], { type: "application/pdf" });
  const file = new File([blob], fileName, { type: "application/pdf" });
  return file;
};

export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result as string;
      resolve(base64String.split(",")[1]);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};

export const dataURLtoFile = (dataurl: any, filename: string) => {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[arr.length - 1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const encryptString = (value: string, secret: string) => {
  return CryptoJS.AES.encrypt(value, secret).toString();
};

export const getRole = () => {
  const authAs = localStorage.getItem("authenticatedAs");
  return authAs;
};

export const debounce = <T extends (...args: any[]) => any>(
  func: T,
  wait: number
): ((...args: Parameters<T>) => void) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;

  return function (...args: Parameters<T>) {
    if (timeout !== null) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      func(...args);
    }, wait);
  };
};
