<script lang="ts">
import {
  Vue,
  Component,
  Model,
  Watch,
  Prop,
  Emit,
  Ref,
} from "vue-facing-decorator";
import ModalUtility from "../utilities/ModalUtility.vue";
import SelectOrCreateCommentABank from "./modal/SelectOrCreateCommentABank.vue";
import CommentsForm from "./modal/CommentsForm.vue";
import { Comments, CommentBankList } from "@/store/comments/types";
import Toast from "primevue/toast";
import { Icon, icons } from "@/utils/icons";
import AppButton from "../Layout/Buttons/AppButton.vue";

@Component({
  components: {
    ModalUtility,
    SelectOrCreateCommentABank,
    CommentsForm,
    Toast,
    AppButton,
  },
})
export default class CommentABankPopUp extends Vue {
  @Ref("CommentsForm") CommentsFormRef!: any;
  @Model({
    type: Boolean,
    default: false,
  })
  openDailog!: boolean;

  @Prop({
    type: Object,
    default: {},
  })
  commentDetails!: object;

  @Prop({
    type: Array,
    default: [],
  })
  commentBankList!: CommentBankList[];

  @Prop({
    type: Boolean,
    default: false,
  })
  isEdit!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  isView!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  isCopy!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  isAddToBank!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  isPreview!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  isAttach!: boolean;

  @Prop({
    type: Function,
  })
  handleEditView!: (action: string) => void;

  @Prop({
    type: Function,
  })
  handleSaveCommentBank!: (uuid: string, name: string) => void;

  showSelectOrCreate = true;
  showComments = false;
  icon: Icon = icons;

  async handleAddtoBank() {
    if (this.CommentsFormRef) {
      await this.CommentsFormRef.handleAddBank();
    }
    this.handleEditView("Add Bank");
  }

  handleEdit() {
    this.handleEditView("Edit");
  }

  handleCopy() {
    this.handleEditView("Copy");
  }

  handleShare() {
    this.handleEditView("Share");
  }

  handleClickContinue(selectedComment: Comments | null) {
    this.$store.dispatch("comments/setCopiedComment", selectedComment);
    this.showSelectOrCreate = false;
    this.showComments = true;
  }

  initalizeVariables() {
    this.showSelectOrCreate = true;
    this.showComments = false;
    if (this.isEdit || this.isView) {
      this.showSelectOrCreate = false;
      this.showComments = true;
    }
  }

  @Emit("onClickSave")
  handleClickUpdateAndAttach(data: { uuid: string; name: string }) {
    this.showComments = false;
    this.openDailog = false;
    if (this.handleSaveCommentBank) {
      this.handleSaveCommentBank(data.uuid, data.name);
    }
    return data.name;
  }

  handleClickGoBack() {
    this.showSelectOrCreate = true;
    this.showComments = false;
  }

  mounted() {
    this.initalizeVariables();
    if (this.isEdit || this.isView) {
      this.showSelectOrCreate = false;
      this.showComments = true;
    }
  }

  unmounted() {
    //
    sessionStorage.removeItem("shareCode");
    sessionStorage.removeItem("sharePage");
  }

  @Watch("openDailog")
  openDailogWatcher(value: boolean) {
    if (value) {
      this.initalizeVariables();
    }
  }

  @Watch("isEdit")
  isEditWatcher(value: boolean) {
    this.initalizeVariables();
    if (value) {
      this.showSelectOrCreate = false;
      this.showComments = true;
    }
  }
  handleShareCodeUrl() {
    sessionStorage.clear();
    window.history.replaceState(null, "", window.location.pathname);
  }
}
</script>
<template>
  <Toast />
  <ModalUtility v-model="openDailog" :draggable="false">
    <template #header>
      <div
        class="w-full h-[76px] bg-flohh-primary-pink rounded-t-[4px] sm:py-0 flex flex-col justify-center items-start bg-[url('@/assets/modal-header-bg.png')] bg-repeat !bg-contain z-[1]"
      >
        <div
          class="absolute top-50 right-50 px-4 flex flex-col items-start justify-center w-full"
        >
          <h1 class="text-flohh-h6 font-flohh-font-bold text-flohh-neutral-20">
            {{
              isAddToBank
                ? "Comment Bank"
                : isCopy
                ? "Copy Comment Bank"
                : isView
                ? "View Comment Bank"
                : isEdit
                ? "Update Comment Bank"
                : "Add New Comment Bank"
            }}
          </h1>

          <!-- Use for teleportation don't remove this tag -->
          <p id="modal-subtitle-teleport" class="font-semibold"></p>
          <!-- end of teleportation -->
        </div>

        <div
          class="absolute right-[60px] top-[18px] flex gap-[8px]"
          v-if="isView && !isAddToBank"
        >
          <AppButton
            type="default"
            v-if="!isPreview"
            @click="handleShare"
            v-tooltip.bottom="{
              value: 'Share link',
              showDelay: 500,
            }"
          >
            <span v-html="icon.shareSmallBlackIcon"></span>
          </AppButton>
          <AppButton
            type="default"
            v-if="!isPreview"
            @click="handleEdit"
            v-tooltip.bottom="{
              value: 'Edit',
              showDelay: 500,
            }"
          >
            <span v-html="icon.pencilBlack"></span>
          </AppButton>
          <AppButton
            type="default"
            @click="handleCopy"
            v-tooltip.bottom="{
              value: 'Make a copy',
              showDelay: 500,
            }"
          >
            <span v-html="icon.copyContent"></span>
          </AppButton>
        </div>

        <div
          class="absolute right-[60px] top-[18px] flex gap-[8px]"
          v-if="isView && isAddToBank"
        >
          <AppButton type="default" @click="handleAddtoBank">
            <span v-html="icon.plusBlack"></span>
            Add to bank
          </AppButton>
        </div>
        <div class="btn-close-wrap absolute top-[10px] right-[10px]">
          <button
            class="p-2 text-white rounded-full"
            @click="
              () => {
                handleShareCodeUrl();
                openDailog = !openDailog;
              }
            "
          >
            <img src="@/assets/close.svg" class="w-4" />
          </button>
        </div>
      </div>
    </template>
    <template #content>
      <!-- <SelectOrCreateCommentABank
        v-if="showSelectOrCreate"
        :commentBankList="commentBankList"
        @onClickContinue="handleClickContinue"
      /> -->
      <CommentsForm
        ref="CommentsFormRef"
        @onClickUpdateAndAttach="handleClickUpdateAndAttach"
        @onClickGoBack="handleClickGoBack"
        :commentDetails="commentDetails"
        :showFooter="true"
        :isEdit="isEdit"
        :isView="isView"
        :isAddToBank="isAddToBank"
        :isAttach="isAttach"
        :isCopy="isCopy"
      />
    </template>
    <template #footer>
      <div id="modal_footer_comment"></div>
    </template>
  </ModalUtility>
</template>
<style scoped></style>
