import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bg-flohh-primary-pink rounded-md"
}
const _hoisted_2 = { class: "flex py-[12px] px-[20px] justify-between items-center gap-2" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "px-[28px] py-[30px] h-[364px] overflow-auto" }
const _hoisted_5 = {
  key: 0,
  class: "flex flex-col"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["for"]
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "flex pt-5 border-t border-solid border-flohh-neutral-85" }
const _hoisted_10 = { class: "flex-1 flex justify-start items-center" }
const _hoisted_11 = { class: "flex-1 flex justify-end items-center gap-x-4" }
const _hoisted_12 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Ellipsis = _resolveComponent("Ellipsis")!
  const _component_AppDropdownButton = _resolveComponent("AppDropdownButton")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FormRadioButton = _resolveComponent("FormRadioButton")!
  const _component_ProgressLoader = _resolveComponent("ProgressLoader")!
  const _component_ModalUtility = _resolveComponent("ModalUtility")!
  const _component_DetachDialogComponent = _resolveComponent("DetachDialogComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isMarkingScreen)
      ? (_openBlock(), _createBlock(_component_FormField, {
          key: 0,
          label: "Grading"
        }, {
          default: _withCtx(() => [
            (_ctx.selectedCriteriaSheetName)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_Ellipsis, { content: _ctx.selectedCriteriaSheetName }, null, 8, ["content"]),
                    _createVNode(_component_AppDropdownButton, {
                      size: "xs",
                      onClickMenu: _ctx.handleSelectMenu,
                      menus: _ctx.statuses,
                      width: "w-25",
                      text: "",
                      dropdownIcon: "moreOptionBlack",
                      loading: _ctx.detaching,
                      disabled: _ctx.detaching,
                      class: "!px-0 !w-[15px]"
                    }, null, 8, ["onClickMenu", "menus", "loading", "disabled"])
                  ])
                ]))
              : (_openBlock(), _createBlock(_component_AppButton, {
                  key: 1,
                  type: "transparent",
                  label: "Criteria Sheet",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.visible = true)),
                  iconLeft: "plusPink",
                  textColor: "text-flohh-primary-pink",
                  borderColor: "border-flohh-primary-pink",
                  size: "md"
                }))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.isMarkingScreen)
      ? (_openBlock(), _createBlock(_component_AppButton, {
          key: 1,
          type: "submit",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.visible = true))
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", {
              innerHTML: _ctx.icons.plusBlack
            }, null, 8, _hoisted_3),
            _createTextVNode(" Add criteria sheet ")
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_ModalUtility, {
      modelValue: _ctx.visible,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.visible) = $event)),
      width: "614px",
      title: "Select a Criteria Sheet Bank",
      onOnClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.visible = false)),
      draggable: false
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          (!_ctx.listLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(["flex gap-2 items-center w-full px-4 py-4 hover:bg-flohh-neutral-95 cursor-pointer", [
              {
                'bg-flohh-neutral-95': item.uuid === _ctx.selectedCriteriaSheet,
              },
            ]]),
                    key: index,
                    onClick: ($event: any) => (_ctx.handleCriteriaSheetClick(item.uuid))
                  }, [
                    _createVNode(_component_FormRadioButton, {
                      modelValue: _ctx.selectedCriteriaSheet,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedCriteriaSheet) = $event)),
                      name: index.toString(),
                      inputId: index.toString(),
                      value: item.uuid
                    }, null, 8, ["modelValue", "name", "inputId", "value"]),
                    _createElementVNode("label", {
                      for: item.name,
                      class: "font-flohh-font-medium text-flohh-text-body cursor-pointer"
                    }, _toDisplayString(item.name), 9, _hoisted_7)
                  ], 10, _hoisted_6))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(_component_ProgressLoader)
              ]))
        ])
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_AppButton, {
              onClick: _ctx.handleManageCriteriaSheets,
              text: "",
              blackLabel: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Manage Criteria Sheets ")
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_AppButton, {
              type: "submit",
              onClick: _ctx.handleClickSelect,
              disabled: !_ctx.selectedCriteriaSheet,
              class: _normalizeClass(!_ctx.selectedCriteriaSheet ? 'opacity-[0.5]' : '')
            }, {
              icon_left: _withCtx(() => [
                _createElementVNode("span", {
                  innerHTML: _ctx.icons.checkBlack
                }, null, 8, _hoisted_12)
              ]),
              default: _withCtx(() => [
                _createTextVNode(" Select ")
              ]),
              _: 1
            }, 8, ["onClick", "disabled", "class"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_DetachDialogComponent, {
      modelValue: _ctx.detachModalOpen,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.detachModalOpen) = $event)),
      title: "Detach Criteria Sheet",
      body: `Are you sure you want to detach '${_ctx.selectedCriteriaSheetName}' to this assignment?`,
      onOnConfirmDetach: _ctx.handleDetachCriteriaSheet
    }, null, 8, ["modelValue", "body", "onOnConfirmDetach"])
  ], 64))
}