export interface ResponsiveBreakpoints {
  [key: string]: string;
}

export const defaultModalBreakPoints: ResponsiveBreakpoints = {
  "960px": "75vw",
  "641px": "94vw",
};

export interface UploadProgress {
  fileName: string;
  progress: number;
}
