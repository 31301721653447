import { dataURLtoFile } from "@/utils/helper";
import CloudStorageService from "@/services/CloudStorageService";
import { AxiosResponse } from "axios";

export const getGoogleDriveFile = async (currentFile: Record<string, any>) => {
  const cloudStorageService = new CloudStorageService();
  let file!: File;
  try {
    const fileExtension =
      currentFile.mimeType === "application/pdf" ? ".pdf" : ".docx";

    const fileName = `${currentFile.name.split(".")[0]}${fileExtension}`;

    const { id: fileId, mimeType } = currentFile;
    const provider = "google";

    const params = { fileId, mimeType, provider };
    const res: AxiosResponse = await cloudStorageService.getGoogleDriveFile(
      params
    );

    if (res.data.ok) {
      file = dataURLtoFile(
        `data:${res.data.data.generatedMimeType};base64,${res.data.data.file}`,
        fileName
      );

      return file;
    }
    return file;
  } catch (err) {
    console.error(err);
    return file;
  }
};
