<script lang="ts">
import TextInputComponent from "@/components/Input/TextInput/TextInputComponent.vue";
import SelectComment from "@/components/utilities/SelectComment.vue";
import SelectGrading from "../../../utilities/SelectGrading.vue";

import { Vue, Model, Component, Emit, Prop, Ref } from "vue-facing-decorator";
import Button from "primevue/button";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { icons } from "@/utils/icons";
import FormField from "@/components/Layout/Forms/FormField.vue";
import CriteriaSheetService from "@/services/CriteriaSheetService";
import { useToast } from "primevue/usetoast";
import { IAttachedResource } from "@/models/CriteriaSheet";
@Component({
  components: {
    TextInputComponent,
    SelectComment,
    SelectGrading,
    Button,
    AppButton,
    FormField,
  },
})
export default class AssignmentFeedbackForm extends Vue {
  private criteriaSheetService = new CriteriaSheetService();
  toast = useToast();

  @Ref
  public SelectGradingRef!: SelectGrading;

  @Model({
    type: String,
    name: "bank",
  })
  bankModel!: string;

  @Model({
    type: String,
    name: "grading",
  })
  gradingModel!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  isEdit!: boolean;

  @Prop({
    type: String,
    default: "",
  })
  assignmentUuid!: string;

  icon = icons;
  loadingFeedback = false;

  @Emit("onClickSkipOrGoBack")
  handleClickSkipOrGoBack(index = 1) {
    return index;
  }

  @Emit("onClickSaveFeedback")
  async handleClickSaveFeedback() {
    return;
  }

  async saveCriteriaSheet() {
    if (this.gradingModel) {
      await this.saveCriteriaSheetTemplate();
      if (this.SelectGradingRef) {
        this.SelectGradingRef.handleRefreshGrading();
      }
    }
  }

  async saveCriteriaSheetTemplate() {
    try {
      const criteriaSheetAttachePayload: IAttachedResource = {
        targetType: "assignment",
        targetId: this.assignmentUuid,
        resourceType: "criteria sheet",
        resourceId: this.gradingModel,
      };
      const res =
        await this.criteriaSheetService.attachedCriteriaSheetAssignment(
          criteriaSheetAttachePayload
        );
      if (res.data.ok) {
        this.showToast("Criteria Sheet has been created.", "success");
      }
    } catch (e) {
      console.error(e);
    }
  }

  showToast(
    message: string,
    severity: "success" | "info" | "warn" | "error" | undefined
  ) {
    this.toast.add({
      severity: severity,
      detail: message,
      life: 3000,
    });
  }

  handleCommentBankData() {
    //
  }

  async onPostFeedback() {
    try {
      this.loadingFeedback = true;
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingFeedback = false;
    }
  }
}
</script>
<template>
  <div class="w-full">
    <div class="w-full flex justify-start items-start flex-col">
      <FormField label="Comment Bank">
        <SelectComment
          v-model="bankModel"
          :isEdit="isEdit"
          :assignmentUuid="assignmentUuid"
          :readonly="true"
          @onSelectedChanged="handleCommentBankData"
        />
      </FormField>
    </div>
    <div class="w-full flex justify-start items-start flex-col mt-5">
      <FormField label="Grading">
        <SelectGrading
          v-if="assignmentUuid"
          ref="SelectGradingRef"
          v-model="gradingModel"
          readonly
          :assignmentUuid="assignmentUuid"
        />
      </FormField>
    </div>
    <div
      class="w-full flex flex-col sm:flex-row mt-5 border-t border-flohh-neutral-85 border-solid mb-4 pt-2"
    >
      <div class="flex-1 flex justify-start items-center">
        <AppButton text @click="handleClickSkipOrGoBack()"> Go Back </AppButton>
      </div>
      <div class="flex-1 justify-start items-start flex py-2">
        <div class="w-full flex justify-end items-center gap-x-4">
          <AppButton text @click="handleClickSkipOrGoBack(3)">
            Skip this step
          </AppButton>
          <AppButton
            type="submit"
            :loading="loadingFeedback"
            :disabled="loadingFeedback"
            @click="
              () => {
                handleClickSaveFeedback();
              }
            "
          >
            <template #icon_left>
              <span v-html="icon.checkBlack"></span>
            </template>
            Save
          </AppButton>
        </div>
      </div>
    </div>
  </div>
</template>
