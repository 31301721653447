export const FAQS_COL_1 = [
  {
    title: "How to add a class",
    link: "https://www.flohh.com.au/teacher-articles/how-to-add-a-class/",
  },
  {
    title: "How to create an assignment",
    link: "https://www.flohh.com.au/teacher-articles/how-to-create-an-assignment/",
  },
  {
    title: "How to share an assignment",
    link: "https://www.flohh.com.au/teacher-articles/how-to-share-an-assignment/",
  },
  {
    title: "How to add a comment bank",
    link: "https://www.flohh.com.au/teacher-articles/how-to-add-a-comment-bank/",
  },
  {
    title: "How to make assignments visible to students",
    link: "https://www.flohh.com.au/teacher-articles/how-to-make-assignments-visible-to-students/",
  },
  {
    title: "How to mark student work",
    link: "https://www.flohh.com.au/teacher-articles/how-to-mark-student-work/",
  },
  {
    title: "How to use drag and drop annotations",
    link: "https://www.flohh.com.au/teacher-articles/how-to-use-drag-and-drop-annotations/",
  },
  {
    title: "How to reuse criteria sheets",
    link: "https://www.flohh.com.au/teacher-articles/how-to-reuse-criteria-sheets/",
  },
];

export const FAQS_COL_2 = [
  {
    title: "How to use different features in the annotation box",
    link: "https://www.flohh.com.au/teacher-articles/how-to-use-different-features-in-the-annotation-bank/",
  },
  {
    title: "How to bulk add annotations to bank",
    link: "https://www.flohh.com.au/teacher-articles/how-to-bulk-add-annotations-to-bank/",
  },
  {
    title: "How to delete annotations",
    link: "https://www.flohh.com.au/teacher-articles/how-to-delete-annotations/",
  },
  {
    title: "How to build a criteria sheet",
    link: "https://www.flohh.com.au/teacher-articles/how-to-build-a-criteria-sheet/",
  },
  {
    title: "How to download markbooks",
    link: "https://www.flohh.com.au/teacher-articles/how-to-download-markbooks/",
  },
  {
    title: "How to upload digital submissions",
    link: "https://www.flohh.com.au/teacher-articles/how-to-upload-digital-submissions/",
  },
  {
    title: "How to bulk upload student submissions",
    link: "https://www.flohh.com.au/teacher-articles/how-to-bulk-upload-student-submissions/",
  },
  {
    title: "How to use test schemas",
    link: "https://www.flohh.com.au/teacher-articles/how-to-use-test-schemas/",
  },
];
