import events from "./event/index";

export const EVENT = events;

export const TELEMETRY_HEAD = {
  download: {
    "x-flohh-telemetry": JSON.stringify({
      type: "file-download",
      action: "download-media",
    }),
  },
  upload: {
    "Content-Type": "multipart/form-data",
    "x-flohh-telemetry": JSON.stringify({
      type: "file-upload",
      action: "upload-media",
    }),
  },
  postLearningGoal: {
    tAction: "submit-learning-goal",
  },
  putLearningGoalApprove: {
    tAction: "approve-learning-goal",
  },
  putLearningGoalReject: {
    tAction: "reject-learning-goal",
  },
  postClass: {
    tAction: "create-class",
  },
  putClass: {
    tAction: "update-class",
  },
  postManualAddStudent: {
    tAction: "manual-add-student",
  },
  postFileImportStudents: {
    "Content-Type": "multipart/form-data",
    "x-flohh-telemetry": JSON.stringify({
      type: "file-upload",
      action: "csv-add-student",
    }),
  },
  updateAssignment: {
    tAction: "update-assignment",
  },
  postAssignment: {
    tAction: "create-assignment",
  },
  updateSubmission: {
    tAction: "update-submission",
  },
  postReturnAllFeedback: {
    tAction: "return-all-feedback",
  },
  postReturnFeedback: {
    tAction: "return-feedback",
  },
  postFlattenAssignmentBySubmissionId: {
    tAction: "flatten-submission",
  },
  postAnnotation: {
    tAction: "create-annotation",
  },
  putAnnotation: {
    tAction: "update-annotation",
  },
  putAnnotationUpdates: {
    tAction: "update-annotation-bank",
  },
  postAnnotationBank: {
    tAction: "create-annotation-bank",
  },
  postBulkUploadDocument: {
    "Content-Type": "multipart/form-data",
    "x-flohh-telemetry": JSON.stringify({
      type: "ws-post",
      action: "upload-bulk-assignments",
    }),
  },
  postBulkUploadMultipleDocuments: {
    tAction: "upload-bulk-multiple-assignments",
  },
  postBulkUpload: {
    tAction: "submit-bulk-assignments",
  },
  postCommentBank: {
    tAction: "create-comment-bank",
  },
  updateCommentBank: {
    tAction: "update-comment-bank",
  },
  addNewCommentOnBank: {
    tAction: "create-comment",
  },
  postCriteriaSheet: {
    tAction: "create-criteria-sheet",
  },
  postGrade: {
    tAction: "create-grade",
  },
  postGradeSchema: {
    tAction: "create-grade-schema",
  },
  updateCriteriaSheet: {
    tAction: "update-criteria-sheet",
  },
  updateGrade: {
    tAction: "update-grade",
  },
  updateGradeSchema: {
    tAction: "update-grade-schema",
  },
  attachedCriteriaSheetAssignment: {
    tAction: "attach-criteria-sheet",
  },
  detachedCriteriaSheetAssignment: {
    tAction: "detach-criteria-sheet",
  },
  attachCommentBankAssignment: {
    tAction: "attach-comment-bank",
  },
  detachCommentBankAssignment: {
    tAction: "detach-comment-bank",
  },
  putStudent: {
    tAction: "update-student",
  },
  approveStudent: {
    tAction: "activate-student-account",
  },
  createSubmission: {
    tAction: "teacher-submit-assignment",
  },
  studentCreateSubmission: {
    tAction: "student-submit-assignment",
  },
  putTeacher: {
    tAction: "update-teacher",
  },
  approveTeacher: {
    tAction: "activate-teacher-account",
  },
  resendAllTeacherActivation: {
    tAction: "resend-all-activation-teacher-account",
  },
  resendTeacherActivation: {
    tAction: "resend-activation-teacher-account",
  },
  updateAccount: {
    tAction: "update-account-info",
  },
  deleteStudent: {
    "x-flohh-telemetry": JSON.stringify({
      type: "ws-delete",
      action: "delete-student",
    }),
  },
  deleteAnnotation: {
    "x-flohh-telemetry": JSON.stringify({
      type: "ws-delete",
      action: "delete-annotation",
    }),
  },
  deleteAnnotationBank: {
    "x-flohh-telemetry": JSON.stringify({
      type: "ws-delete",
      action: "delete-annotation-bank",
    }),
  },
  deleteAssignment: {
    "x-flohh-telemetry": JSON.stringify({
      type: "ws-delete",
      action: "delete-assignment",
    }),
  },
  deleteSubmission: {
    "x-flohh-telemetry": JSON.stringify({
      type: "ws-delete",
      action: "delete-submission",
    }),
  },
  deleteComment: {
    "x-flohh-telemetry": JSON.stringify({
      type: "ws-delete",
      action: "delete-comment",
    }),
  },
  deleteCommentBank: {
    "x-flohh-telemetry": JSON.stringify({
      type: "ws-delete",
      action: "delete-comment-bank",
    }),
  },
  deleteCriteriaSheet: {
    "x-flohh-telemetry": JSON.stringify({
      type: "ws-delete",
      action: "delete-criteria-sheet",
    }),
  },
  deleteGradeSchema: {
    "x-flohh-telemetry": JSON.stringify({
      type: "ws-delete",
      action: "delete-grade-schema",
    }),
  },
  postWatchedOnboardingVideo: {
    tAction: "onboarding-watched",
  },

  attachNewStudentSubmission: {
    tAction: "attach-new-student-submission",
  },
  attachStudentSubmission: {
    tAction: "attach-student-submission",
  },
  detachStudentSubmission: {
    tAction: "detach-student-submission",
  },
};
