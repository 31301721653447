import axios from "axios";

export default class SuperAdminService {
  downloadTeachersList() {
    return axios.get(`/reports/teachers`);
  }

  uploadTeachers(payload: any) {
    const formData = new FormData();
    formData.append("file", payload.file);
    formData.append("schoolName", payload.schoolName);
    formData.append("country", payload.country);
    formData.append("state", payload.state);
    return axios.post("/super-admin/teachers/bulk", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  putStudent(payload: any, studentUuid: string) {
    return axios.put(`/super-admin/students/${studentUuid}`, payload);
  }

  getStudents() {
    return axios.get("/super-admin/students");
  }
}
