<template>
  <div
    class="max-w-[290px] w-full flex flex-col gap-[16px] p-[16px] text-left"
    :style="{
      backgroundColor: 'rgba(252, 234, 232, 1)',
      color: 'rgba(0, 0, 0, 1)',
    }"
  >
    <template v-if="!isTeacherApproved">
      <p class="text-[16px] font-bold">Teacher Feedback</p>
      <textarea
        class="input-textarea h-[200px]"
        :value="feedback"
        placeholder="The teacher’s summary comment is displayed here"
        disabled
      ></textarea>

      <p class="text-[16px] font-bold">Learning goal</p>
      <p>
        Read the feedback from your teacher and type your learning goal into the
        box below.
      </p>
      <textarea
        class="input-textarea h-[120px]"
        :name="'learningGoal'"
        placeholder="Enter your learning goal here."
        :value="learningGoal"
        @input="handleInputChange($event)"
        :disabled="isLearningGoalSubmitted"
      ></textarea>

      <div class="flex justify-center">
        <Button
          @click="handleSubmit"
          class="modal-default-btn flex justify-center font-bold"
          v-if="!isLearningGoalSubmitted"
          >Submit
        </Button>
        <Button
          @click="handleSubmit"
          class="modal-default-btn flex justify-center font-bold"
          v-else
          disabled
        >
          <img
            src="@/assets/icon-check-circle.svg"
            class="mr-[10px] h-[25px] w-[25px]"
          />
          Submitted
        </Button>
      </div>
    </template>

    <template v-else-if="isTeacherApproved">
      <p class="text-[16px] font-bold">Teacher Feedback</p>
      <textarea
        class="input-textarea h-[200px]"
        :name="'feedback'"
        placeholder="This is the teacher’s final comment."
        :value="feedback"
        disabled
      ></textarea>

      <p class="text-[16px] font-bold">Learning goal</p>

      <textarea
        class="input-textarea h-[120px]"
        :name="'learningGoal'"
        placeholder="Student learning goal is displayed here"
        :value="learningGoal"
        disabled
      ></textarea>

      <div class="flex justify-center">
        <Button class="modal-default-btn w-full flex justify-center font-bold"
          >View criteria and grade</Button
        >
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import Button from "primevue/button";
import { useToast } from "primevue/usetoast";
import Toast from "primevue/toast";

import TextInputComponent from "../Input/TextInput/TextInputComponent.vue";

@Component({ components: { TextInputComponent, Button, Toast } })
export default class SidePanelComponent extends Vue {
  @Prop({
    type: Boolean,
  })
  isTeacherApproved!: boolean;

  @Prop({
    type: String,
  })
  viewType!: string;

  toast = useToast();

  feedback = "This is a test feedback. Good job! Keep up the good work";
  learningGoal = "";
  isLearningGoalSubmitted = false;

  handleInputChange(event: Event) {
    const evt = event.target as HTMLInputElement;
    this.learningGoal = evt.value;
  }

  handleSubmit() {
    // TODO: Call Api to submit learning goals
    this.toast.add({
      severity: "success",
      summary: "Success",
      detail: "You’ve submitted your learning goals.",
      life: 3000,
    });

    this.isLearningGoalSubmitted = true;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.modal-default-btn {
  background-color: rgba(37, 192, 113, 1);
  font-size: 15px;
  transition: 0.3s ease;

  &:hover {
    background-color: rgba(37, 192, 113, 1) !important;
    opacity: 0.6;
  }
}

.input-textarea {
  width: 100%;
  background: #f9f9f9;
  border: 1px solid #dedede;
  padding: 10px 15px;
  margin-top: 5px;
  border-radius: 8px;

  resize: none;
}
</style>
