<template>
  <div class="flex flex-col">
    <TabUtilityComponent
      :sortDefault="sortDefault"
      @onSort="handleSort"
      @onSearch="handleSearch"
      class="mb-[29px]"
    />
    <div
      v-if="assignmentsList && assignmentsList.length > 0"
      class="assignments-wrapper"
    >
      <AssignmentCardComponent
        v-for="(item, i) in assignmentsList"
        :key="i"
        :data="item"
        :tab="tab"
        :activeIndex="activeIndex"
        :maxWidth="maxWidth"
        @onDelete="handleDeleteAssignment"
      />
    </div>
    <div v-else class="assignments-wrapper-empty">
      <AppTypographyText
        variant="rg"
        type="body"
        label="No Active Assignments"
      />
    </div>
    <PaginationUtility
      v-if="assignmentsListClone && assignmentsListClone.length > 0"
      :data="assignmentsListClone"
      :searchText="searchText"
      :config="paginatorConfig"
      @onPageChange="handlePageChange"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import AssignmentCardComponent from "./utilities/AssignmentCardComponent.vue";
import TabUtilityComponent from "./utilities/TabUtilityComponent.vue";
import { AssignmentDashboard } from "@/store/dashboard/dashboardTypes";
import { AssignmentsTabsData } from "./type";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import PaginationUtility from "@/components/utilities/PaginationUtility.vue";
import { sortAssignmentsPageData } from "@/utils/sort";
import { formatDate, formatStringToNumberMinutes } from "@/utils/formatter";
import { useToast } from "primevue/usetoast";

@Component({
  components: {
    AssignmentCardComponent,
    TabUtilityComponent,
    AppTypographyText,
    PaginationUtility,
  },
})
export default class AssignmentsActiveComponent extends Vue {
  toast = useToast();

  @Prop({
    type: String,
    required: true,
  })
  tab!: string;

  @Prop({
    type: [],
    required: true,
  })
  assignments!: AssignmentDashboard[];

  @Prop({
    type: Number,
    required: true,
  })
  activeIndex!: number;

  @Prop({
    type: Number,
    required: false,
  })
  maxWidth!: number;

  assignmentsList!: AssignmentsTabsData[];
  assignmentsListClone!: AssignmentsTabsData[];

  paginatorConfig = {};

  sortDefault = {
    sortBy: "title_alphabetical",
    sortData: [
      { label: "Title: Alphabetical", value: "title_alphabetical" },
      { label: "Marking Time: Fastest", value: "time_fastest" },
      { label: "Due Date: Earliest", value: "due_earliest" },
      { label: "Marked Percentage: Lowest", value: "marked_lowest" },
    ],
  };
  searchText = "";

  handlePageChange(data: AssignmentsTabsData[]) {
    this.assignmentsList = data;
  }

  fixDecimal(value: number, fixTo = 1) {
    return value.toFixed(fixTo);
  }

  handleDeleteAssignment(uuid: string) {
    const assignments = structuredClone(this.assignmentsListClone);
    const filteredAssignments = assignments.filter(
      (assignment: AssignmentsTabsData) => assignment.uuid !== uuid
    );
    this.assignmentsList = filteredAssignments;
    this.assignmentsListClone = filteredAssignments;
  }

  mounted() {
    const assignments = this.assignments;
    const assignmentsList: AssignmentsTabsData[] = [];
    assignments.forEach((assignment: AssignmentDashboard) => {
      const lgSubmitted = assignment.submissionCounts.learningGoalsToReview;
      const lgRequested = assignment.submissionCounts.learningGoalsRequested;
      const done = assignment.submissionCounts.completedSubmissions;
      const marked = assignment.submissionCounts.markedSubmissions;
      const total = assignment.submissionCounts.totalSubmissions;

      const markedNumerator = done + marked + lgRequested + lgSubmitted;
      const markedPerc =
        total !== 0 && markedNumerator !== 0
          ? (markedNumerator / total) * 100
          : 0;

      const goalDenominator = lgSubmitted + done;
      const goalPerc =
        done !== 0 && goalDenominator !== 0
          ? (done / goalDenominator) * 100
          : 0;

      if (!assignment.visible || (done === total && done !== 0 && total !== 0))
        return;

      const elapsedTime = assignment.elapsedTime
        ? assignment.elapsedTime
        : "0 second";

      const formData = {
        uuid: assignment.uuid,
        title: assignment.title,
        class: assignment.class.code,
        classUuid: assignment.class.uuid,
        subject: assignment.class.subject,
        dueDate: `${formatDate(
          assignment.dueDate,
          "DD MMMM YYYY"
        )} at ${formatDate(assignment.dueDate, "h:mm A")}`,
        dueDateValue: assignment.dueDate,
        elapsedTime: `Elapsed time: ${elapsedTime}`,
        elapsedTimeValue: formatStringToNumberMinutes(elapsedTime),
        marked: `${markedNumerator} of ${total} Marked`,
        reviewed: `${done} of ${goalDenominator} Learning Goals Reviewed`,
        submissionProgressBar: Number(this.fixDecimal(markedPerc)),
        learningGoalsProgressBar: Number(this.fixDecimal(goalPerc)),
      };
      assignmentsList.push(formData);
    });
    const sortedData = sortAssignmentsPageData(
      "title_alphabetical",
      assignmentsList
    );
    this.assignmentsList = sortedData;
    this.assignmentsListClone = sortedData;
  }

  handleSort(sort: string) {
    const assignmentsList = structuredClone(this.assignmentsList);
    const sortedData = sortAssignmentsPageData(sort, assignmentsList);
    this.assignmentsList = sortedData;
  }

  handleSearch(searchText: string) {
    this.searchText = searchText;
  }
}
</script>

<style scoped lang="scss">
.assignments-wrapper {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1em;

  &-empty {
    grid-template-columns: 1fr !important;
    place-items: center;
    padding: 3em 0em;
    text-align: center;
  }
}
</style>
