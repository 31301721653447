import { ActionTree } from "vuex";
import { RootState } from "@/store";
import { CommentState, Comments } from "../types";
import CommentService from "@/services/CommentService";

const actions: ActionTree<CommentState, RootState> = {
  getComments({ commit }) {
    const comments = localStorage.getItem("CommentsList");
    if (comments) {
      const list: Comments[] = JSON.parse(comments);
      commit("SET_COMMENTS", list);
      return;
    }
  },
  addComment({ commit }, comment: Comments) {
    commit("ADD_COMMENTS", comment);
  },
  setSelectedComment({ commit }, comment: Comments) {
    localStorage.setItem("SelectedComment", JSON.stringify(comment));
    commit("SET_SELECTED_COMMENT", comment);
  },
  setCopiedComment({ commit }, comment: Comments) {
    commit("SET_COPIED_COMMENT", comment);
  },
  setAssignmentUuid({ commit }, assignmentUuid: string) {
    commit("SET_ASSIGNMENT_UUID", assignmentUuid);
  },
  async getComment({ commit }, assignmentUuid: string) {
    //
    try {
      const service = new CommentService();
      const params = {
        scope: "assignment",
        spec: assignmentUuid,
      };
      const response = await service.getCommentBankByAssignmentUuid(params);
      if (response.data.ok) {
        //
        const comments = response.data.data;
        const selectedComment: Comments = {
          assignment: "",
          name: "",
          comments: [],
        };
        if (comments.length) {
          selectedComment.comments = comments[comments.length - 1].comments;
          selectedComment.name = comments[comments.length - 1].name;
          selectedComment.assignment = comments[comments.length - 1].scopeSpec;
          selectedComment.uuid = comments[comments.length - 1].uuid;
          commit("SET_SELECTED_COMMENT", selectedComment);
        }
      }
    } catch (error) {
      console.error(error);
    }
  },
};

export default actions;
