<template>
  <PageTitle title="Assignments" :routes="routes" />
  <div class="assignments">
    <AssignmentsListComponent />
    <div
      class="parent-div mt-[30rem] fixed h-[370px] bottom-[-165px] w-full bg-[url('@/assets/bg-dashboard-pattern.png')] bg-repeat-x bg-left-top bg-[auto_60%]"
    ></div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import AssignmentsListComponent from "@/components/Assignments/AssignmentsListComponent.vue";
import PageTitle, { TRoute } from "@/components/Layout/Partials/PageTitle.vue";

@Component({
  components: {
    AssignmentsListComponent,
    PageTitle,
  },
})
export default class AssignmentsListView extends Vue {
  routes: TRoute[] = [
    {
      title: "Assignments",
      routeTo: "/assignments/all",
      current: true,
    },
  ];
}
</script>

<style scoped lang="scss">
body {
  margin: 0;
  overflow: hidden !important;
}
.assignments {
  margin-left: 0;
  height: 100vh;
  position: relative;
  background-color: #f1f3f3;
}
</style>
