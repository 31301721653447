<template>
  <PageTitle title="Create Assignment" :routes="routes" />
  <div class="assignments">
    <AssignmentFormComponent :isEdit="false" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import AssignmentFormComponent from "@/components/Assignments/AssignmentFormComponent.vue";
import PageTitle, { TRoute } from "@/components/Layout/Partials/PageTitle.vue";

@Component({
  components: {
    AssignmentFormComponent,
    PageTitle,
  },
})
export default class CreateAssignmentView extends Vue {
  routes: TRoute[] = [
    {
      title: "Assignments",
      routeTo: "/assignments/all",
      current: false,
    },
    {
      title: "Create",
      routeTo: "/assignments/create",
      current: true,
    },
  ];
}
</script>

<style scoped lang="scss">
body {
  margin: 0;
  overflow: hidden !important;
}
.assignments {
  margin-left: 0;
  min-height: calc(100vh - 80px);
  position: relative;
  background-color: #f1f3f3;
  // position: sticky;
  // top: 80px;
}
</style>
