<template>
  <PageTitle title="Class" :routes="routes" />
  <div class="classes">
    <ClassesComponent
      v-if="id"
      :classUuid="id"
      @onClassInfoLoaded="handleClassInfoLoaded"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import ClassesComponent from "@/components/Classes/ClassesComponent.vue";
import PageTitle, { TRoute } from "@/components/Layout/Partials/PageTitle.vue";

@Component({
  components: {
    ClassesComponent,
    PageTitle,
  },
})
export default class ClassesView extends Vue {
  routes: TRoute[] = [];
  @Prop({
    type: String,
    required: true,
  })
  id!: string;

  handleClassInfoLoaded(classTitle: string) {
    this.routes = [
      {
        title: "Classes",
        routeTo: "/classes/all",
        current: false,
      },
      {
        title: classTitle,
        routeTo: `/classes/${this.id}`,
        current: true,
      },
    ];
  }
}
</script>

<style scoped lang="scss">
body {
  margin: 0;
  overflow: hidden !important;
}
.classes {
  margin-left: 0;
  height: 100vh;
  position: relative;
  background-color: #f1f3f3;
}
</style>
