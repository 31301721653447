import { EventDetail, EventName } from "../type";

const bulkUploadEvents = (eventName: EventName): EventDetail[] => [
  {
    id: "BU001",
    name: eventName.button,
    description: "Submit bulk upload for splitting",
  },
  {
    id: "BU002",
    name: eventName.button,
    description: "Bulk upload check status",
  },
  {
    id: "BU003",
    name: eventName.button,
    description: "Submit bulk upload",
  },
  {
    id: "BU004",
    name: eventName.button,
    description: "Remove submission page",
  },
  {
    id: "BU005",
    name: eventName.button,
    description: "Split submission",
  },
  {
    id: "BU006",
    name: eventName.button,
    description: "Merge submission",
  },
  {
    id: "BU007",
    name: eventName.dropdown,
    description: "Assign submission",
  },
  {
    id: "BU008",
    name: eventName.button,
    description: "Unassign submission",
  },
];

export default bulkUploadEvents;
