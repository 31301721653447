<template>
  <Paginator
    :first="first"
    :rows="rows"
    :totalRecords="pageCount"
    @page="handlePageChange"
  />
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-facing-decorator";
import Paginator from "primevue/paginator";
import { PageState } from "primevue/paginator";

interface PaginatorConfig {
  first?: number;
  rows?: number;
  page?: number;
  pageCount?: number;
}

@Component({
  components: { Paginator },
})
export default class PaginationUtility extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  data!: [];

  @Prop({
    type: Object,
    required: false,
    default: false,
  })
  config!: PaginatorConfig;

  @Prop({
    type: String,
    required: true,
  })
  searchText!: string;

  first!: number;
  rows!: number;
  page!: number;
  pageCount!: number;

  mounted() {
    this.setData();
  }

  @Watch("data")
  dataWatcher() {
    this.setData();
  }

  @Watch("searchText")
  searcTextWatcher(value: string) {
    this.setData(value);
  }

  setData(searchText = "") {
    const dataClone: Record<string, any>[] = structuredClone(this.data);
    let data: Record<string, any>[] = dataClone;
    if (searchText) {
      data = this.handleSearch(searchText, dataClone);
    }
    this.first = this.config.first ? this.config.first : 1;
    this.rows = this.config.rows ? this.config.rows : 21;
    this.page = this.config.page ? this.config.page : 0;
    this.pageCount = data.length;

    const currentPageData = this.getChunkData(data, this.first, this.rows);
    this.$emit("onPageChange", currentPageData);
  }

  getChunkData(
    array: Record<string, any>[],
    currentPage: number,
    pageSize: number
  ) {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return array.slice(startIndex, endIndex);
  }

  handlePageChange(event: PageState) {
    const data = this.data;
    const currentPage = event.page + 1;
    const currentPageData = this.getChunkData(data, currentPage, this.rows);
    this.$emit("onPageChange", currentPageData);
  }

  handleSearch(searchText: string, data: Record<string, any>[]) {
    const dataList = data.filter(
      (item: Record<string, any>) =>
        item.title.toLowerCase().includes(searchText.toLowerCase()) ||
        item.class.toLowerCase().includes(searchText.toLowerCase()) ||
        item.subject.toLowerCase().includes(searchText.toLowerCase())
    );
    return dataList;
  }
}
</script>

<style scoped lang="scss"></style>
