import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "classes-tabs min-h-[calc(100vh-240px)]",
  ref: "tabContainer"
}
const _hoisted_2 = {
  key: 0,
  class: "flex items-center flex-col gap-2 pt-5"
}
const _hoisted_3 = {
  key: 1,
  class: "h-full w-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressLoader = _resolveComponent("ProgressLoader")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_TabView = _resolveComponent("TabView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TabView, {
      activeIndex: _ctx.active,
      "onUpdate:activeIndex": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.active) = $event))
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (item, key) => {
          return (_openBlock(), _createBlock(_component_TabPanel, {
            header: item.heading,
            key: key
          }, {
            default: _withCtx(() => [
              (_ctx.assignmentsLoading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_ProgressLoader, {
                      label: "Loading Assignments",
                      labelVariant: "md",
                      labelType: "subtitle"
                    })
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(item.component), {
                      tab: item.name,
                      assignments: _ctx.assignments,
                      activeIndex: _ctx.active,
                      maxWidth: _ctx.maxWidth
                    }, null, 8, ["tab", "assignments", "activeIndex", "maxWidth"]))
                  ]))
            ]),
            _: 2
          }, 1032, ["header"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["activeIndex"])
  ], 512))
}