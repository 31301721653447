import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UploadSubmissionsComponent = _resolveComponent("UploadSubmissionsComponent")!
  const _component_SubmissionResultComponent = _resolveComponent("SubmissionResultComponent")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.filesSucceeded.length === 0 && _ctx.filesFailed.length === 0)
      ? (_openBlock(), _createBlock(_component_UploadSubmissionsComponent, {
          key: 0,
          uploadingFiles: _ctx.uploadingFiles,
          uploadProgress: _ctx.uploadProgress,
          onOnSubmit: _ctx.handleOnSubmit
        }, null, 8, ["uploadingFiles", "uploadProgress", "onOnSubmit"]))
      : (_openBlock(), _createBlock(_component_SubmissionResultComponent, {
          key: 1,
          filesSucceeded: _ctx.filesSucceeded,
          filesFailed: _ctx.filesFailed,
          digitalFirstSubmissionUuid: _ctx.digitalFirstSubmissionUuid,
          onOnFinish: _ctx.handleFinishUpload,
          onOnSubmit: _ctx.handleOnSubmit
        }, null, 8, ["filesSucceeded", "filesFailed", "digitalFirstSubmissionUuid", "onOnFinish", "onOnSubmit"]))
  ]))
}