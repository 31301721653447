// Annotations.ts
//

import PSPDFKit, {
  Instance,
  Annotation,
  Color,
  AnnotationsUnion,
  List,
} from "pspdfkit";
import {
  TColorPreset,
  IAnnotationOptions,
  IColorPreset,
  ICustomData,
} from "./types";
import { StorageDataKey } from "@/enums/enums";
import { generateGuid } from "@/utils/helper";
import AnnotationService from "@/services/AnnotationService";
import eventBus from "@/config/emitter";

import {
  newArrowAnnotation,
  newEllipseAnnotation,
  newHighlightAnnotation,
  newInkAnnotation,
  newLineAnnotation,
  newNoteAnnotation,
  newPolygonAnnotation,
  newPolylineAnnotation,
  newRectangleAnnotation,
  newStrikeOutAnnotation,
  newSquiggleAnnotation,
  newUnderlineAnnotation,
  newTextAnnotation,
} from "./Annotations";

const highlightAnnotationTypes = [
  PSPDFKit.Annotations.HighlightAnnotation,
  PSPDFKit.Annotations.StrikeOutAnnotation,
  PSPDFKit.Annotations.SquiggleAnnotation,
  PSPDFKit.Annotations.UnderlineAnnotation,
  PSPDFKit.Annotations.InkAnnotation,
  PSPDFKit.Annotations.MarkupAnnotation,
];

export const AnnotationComponent = async (
  instance: Instance,
  presetColors: TColorPreset
) => {
  await handleAnnotationData(instance, presetColors);
};

let annotationUpdating = "";
let annotationDeleting = "";

const handleAnnotationData = async (
  instance: Instance,
  presetColors: TColorPreset
) => {
  const annotationdata = localStorage.getItem(StorageDataKey.AnnotationData);
  if (annotationdata) {
    const annotations: Annotation[] = JSON.parse(annotationdata);
    await loadAnnotations(instance, annotations);

    handleAnnotationCreate(instance, presetColors);
    handleAnnotationUpdate(instance, presetColors);
    handleAnnotationDelete(instance);
    handleAnnotationSelectionChange(instance);
  }
};

const handleAnnotationCreate = (
  instance: Instance,
  presetColors: TColorPreset
) => {
  instance.addEventListener(
    "annotations.create",
    (createdAnnotations: List<AnnotationsUnion>) => {
      const annotation = createdAnnotations.toArray()[0];
      handleUpdateAnnotationData(annotation, "create", instance);
      if (highlightAnnotationTypes.some((type) => annotation instanceof type)) {
        if (!annotation.isSignature) {
          addHighlightAnnotationToPDF(instance, annotation, presetColors);
        }
      } else {
        // note annotations
      }
    }
  );
};

const handleAnnotationUpdate = (
  instance: Instance,
  presetColors: TColorPreset
) => {
  instance.addEventListener(
    "annotations.update",
    (updatedAnnotations: List<AnnotationsUnion>) => {
      const annotation = updatedAnnotations.toArray()[0];
      const selectedAnnotation = handleUpdateAnnotationData(
        annotation,
        "update",
        instance
      );
      if (highlightAnnotationTypes.some((type) => annotation instanceof type)) {
        updateHighlightAnnotation(instance, annotation, presetColors);
      } else if (annotation instanceof PSPDFKit.Annotations.NoteAnnotation) {
        // note annotations
        updateNoteAnnotation(instance, annotation);
        updateColor(instance, annotation, selectedAnnotation);
      }
    }
  );
};

const updateColor = (
  instance: Instance,
  annotation: Annotation,
  selectedAnnotation: any
) => {
  if (
    annotation &&
    selectedAnnotation &&
    annotation.color &&
    selectedAnnotation.color &&
    annotation.color.r === selectedAnnotation.color.r &&
    annotation.color.g === selectedAnnotation.color.g &&
    annotation.color.b === selectedAnnotation.color.b
  ) {
    return;
  }
  if (!selectedAnnotation) {
    return;
  }
  const annotationPageIndex = annotation.pageIndex || 0;
  instance
    .getAnnotations(annotationPageIndex)
    .then((res: List<AnnotationsUnion>) => {
      const annotationsArray = res.toArray();
      const partnerAnnotation = annotationsArray.find(
        (o: Annotation) => o.id === selectedAnnotation.customData.annotationId
      );
      if (partnerAnnotation) {
        const desiredColor = new PSPDFKit.Color({
          r: annotation.color.r,
          g: annotation.color.g,
          b: annotation.color.b,
        });
        const color = new PSPDFKit.Color(desiredColor);
        let editedAnnotation = null;
        if (partnerAnnotation.color) {
          editedAnnotation = partnerAnnotation.set("color", color);
        } else {
          editedAnnotation = partnerAnnotation.set("strokeColor", color);
        }
        instance.update(editedAnnotation).then(() => {
          return;
        });
      }
    });
};

const handleAnnotationDelete = (instance: Instance) => {
  instance.addEventListener(
    "annotations.delete",
    (deletedAnnotations: List<AnnotationsUnion>) => {
      const annotation = deletedAnnotations.toArray()[0];

      if (annotation) {
        instance
          .getAnnotations(annotation.pageIndex)
          .then((res: List<AnnotationsUnion>) => {
            const annotationsArray = res.toArray();
            if (annotationsArray.length) {
              const foundAnnotation = findAnnotationById(
                annotationsArray,
                annotation.id,
                "customData"
              );

              if (foundAnnotation) {
                instance.delete(foundAnnotation.id).then((res) => {
                  annotationDeleting = "";
                  handleDeleteAnnotation(foundAnnotation);
                });
              } else {
                if (annotation.customData) {
                  if (annotation.customData.annotationId) {
                    const id = annotation.customData.annotationId;
                    const item = annotationsArray.find((o) => o.id === id);
                    if (item) {
                      instance.delete(item.id).then((res) => {
                        annotationDeleting = "";
                        handleDeleteAnnotation(item);
                      });
                    }
                  }
                }
              }
            }
          });
        handleDeleteAnnotation(annotation);
      }
    }
  );
};

const handleAnnotationSelectionChange = (instance: Instance) => {
  instance.addEventListener("annotations.willChange", (event: any) => {
    if (event.annotations._tail) {
      const annotation = event.annotations._tail.array[0];
      if (event.reason === "MOVE_START") {
        //
      }
      if (event.reason === "MOVE_END") {
        //
      }
    }
  });
};

const handleUpdateAnnotationData = (
  annotation: Annotation,
  type: string,
  instance: Instance
) => {
  try {
    const getAnnotationData = localStorage.getItem(
      StorageDataKey.AnnotationData
    );
    const annotationData = getAnnotationData
      ? JSON.parse(getAnnotationData)
      : [];
    let updatedAnnotations: Annotation[] = annotationData;
    const annotationType = getAnnotationType(annotation);
    let item!: Annotation;
    if (annotationType) {
      item = annotation.set("customData", {
        ...annotation.customData,
        annotationType: annotationType,
      });

      instance.update(item);
    }
    if (type === "update") {
      const selectedAnnotation = annotationData.find(
        (o: Annotation) => o.id === annotation.id
      );
      updatedAnnotations = annotationData.map((o: Annotation) => {
        if (o.id === annotation.id) {
          const item = annotation.set("customData", {
            ...selectedAnnotation.customData,
            annotationType: annotationType,
          });
          return item;
        }
        return o;
      });
      localStorage.setItem(
        StorageDataKey.AnnotationData,
        JSON.stringify(updatedAnnotations)
      );

      return selectedAnnotation;
    } else {
      const checkIfExists = annotationData.find(
        (o: Annotation) => o.id === annotation.id
      );
      if (!checkIfExists) {
        updatedAnnotations.push(item);
      }

      localStorage.setItem(
        StorageDataKey.AnnotationData,
        JSON.stringify(updatedAnnotations)
      );
    }
  } catch (e) {
    //
    console.error(e);
  }
};

export const addHighlightAnnotationToPDF = (
  instance: Instance,
  annotation: Annotation,
  presetColors: TColorPreset
) => {
  eventBus.emit("EVENT_TRIGGER", "MS007");
  const noteAnnotation = noteAnnotationNew(annotation, instance, presetColors);
  instance.create(noteAnnotation).then((annotations: any) => {
    const createdAnnotation = annotations[0];
    handleUpdateAnnotationData(createdAnnotation, "create", instance);
    showNotePopUp(instance, createdAnnotation);
  });
};

const updateHighlightAnnotation = (
  instance: Instance,
  annotation: Annotation,
  presetColors: TColorPreset
) => {
  annotationUpdating = "textAnnotation";
  const annotationId = annotation.id;
  const annotationPageIndex = annotation.pageIndex || 0;
  instance
    .getAnnotations(annotationPageIndex)
    .then((res: List<AnnotationsUnion>) => {
      const annotationsArray = res.toArray();

      if (annotationsArray) {
        const foundAnnotation = findAnnotationById(
          annotationsArray,
          annotationId,
          "customData"
        );

        if (annotation && foundAnnotation) {
          const colorComponent: Color | undefined = getColorComponent(
            annotation,
            ["color", "backgroundColor", "strokeColor", "fontColor"]
          );

          if (colorComponent) {
            const color = new PSPDFKit.Color(colorComponent);

            const pos = calculateAnnotationPosition(annotation);
            const boundingBox = new PSPDFKit.Geometry.Rect({
              left: pos.left,
              top: pos.top,
              width: 32,
              height: 32,
            });

            let customData: ICustomData = {
              filterTag: findColorTag(color, presetColors) || "",
            };

            if (foundAnnotation.customData) {
              customData = {
                ...foundAnnotation.customData,
                ...customData,
                annotationId: annotationId,
              };
            }
            const customDataAsRecord: Record<string, unknown> = customData;
            let editedAnnotation = null;
            if (
              foundAnnotation instanceof PSPDFKit.Annotations.NoteAnnotation
            ) {
              editedAnnotation = foundAnnotation
                .set("color", color)
                .set("boundingBox", boundingBox)
                .set("text", foundAnnotation.text)
                .set("customData", customDataAsRecord);
            } else {
              editedAnnotation = foundAnnotation
                .set("color", color)
                .set("text", foundAnnotation.text)
                .set("customData", customDataAsRecord);
            }

            if (annotationUpdating === "textAnnotation") {
              instance.update(editedAnnotation).then(() => {
                annotationUpdating = "";
              });
            }
          }
        }
      }
    });
};

const updateNoteAnnotation = (instance: Instance, annotation: Annotation) => {
  annotationUpdating = "noteAnnotation";
  if (annotation.customData) {
    const annotationId: string =
      String(annotation.customData.annotationId) || "";
    const annotationPageIndex = annotation.pageIndex || 0;
    instance
      .getAnnotations(annotationPageIndex)
      .then((res: List<AnnotationsUnion>) => {
        const annotationsArray = res.toArray();
        if (annotationsArray) {
          const foundAnnotation = findAnnotationById(
            annotationsArray,
            annotationId
          );
          if (annotation && foundAnnotation) {
            const colorComponent: Color | undefined = getColorComponent(
              annotation,
              ["color", "strokeColor"]
            );

            if (colorComponent) {
              const color = new PSPDFKit.Color(colorComponent);
              if (
                foundAnnotation instanceof PSPDFKit.Annotations.NoteAnnotation
              ) {
                const editedAnnotation = foundAnnotation
                  .set("color", color)
                  .set("text", annotation.text)
                  .set("strokeColor", color)
                  .set("customData", {
                    ...annotation.customData,
                  });
                if (annotationUpdating === "noteAnnotation") {
                  instance.update(editedAnnotation).then(() => {
                    annotationUpdating = "";
                  });
                }
              }
            }
          }
        }
      });
  } else {
    //
  }
};

const deleteHighlightAnnotation = (
  instance: Instance,
  annotation: Annotation
) => {
  annotationDeleting = "textDeleting";
  const annotationId = annotation.id;
  const annotationPageIndex = annotation.pageIndex || 0;
  instance
    .getAnnotations(annotationPageIndex)
    .then((res: List<AnnotationsUnion>) => {
      const annotationsArray = res.toArray();
      if (annotationsArray) {
        const foundAnnotation = findAnnotationById(
          annotationsArray,
          annotationId,
          "customData"
        );
        if (annotation && foundAnnotation) {
          instance.delete(foundAnnotation.id).then(() => {
            annotationDeleting = "";
            handleDeleteAnnotation(annotation);
          });
        } else {
          if (annotationsArray.length) {
            const item = annotationsArray.find(
              (o: Annotation) => o.id === annotation.id
            );
            if (item) {
              instance.delete(item.id).then(() => {
                annotationDeleting = "";
                handleDeleteAnnotation(annotation);
              });
            }
          }
        }
      }
    });
};

const deleteNoteAnnotation = (instance: Instance, annotation: Annotation) => {
  if (annotation.customData) {
    const annotationId: string = (annotation.customData.annotationId ||
      "") as string;
    if (annotation && annotationId && !annotationDeleting) {
      instance.delete(annotationId).then(() => {
        annotationDeleting = "";
        handleDeleteAnnotation(annotation);
      });
    }
  }
};

const handleDeleteAnnotation = (annotation: Annotation) => {
  const storageData = localStorage.getItem(StorageDataKey.AnnotationData);
  const annotationData = storageData ? JSON.parse(storageData) : [];

  const filteredData = annotationData.filter(
    (o: Annotation) => o.id !== annotation.id
  );
  localStorage.setItem(
    StorageDataKey.AnnotationData,
    JSON.stringify(filteredData)
  );
  // const dataIndex = annotationData.findIndex((o: Annotation) => {
  //   return o.id == annotation.id;
  // });

  // if (dataIndex) {
  //   const slicedAnnotationLibrary = annotationData.slice();
  //   slicedAnnotationLibrary.splice(dataIndex, 1);
  //   localStorage.setItem(
  //     StorageDataKey.AnnotationData,
  //     JSON.stringify(slicedAnnotationLibrary)
  //   );
  // }
};

const noteAnnotationNew = (
  annotation: Annotation,
  instance: Instance,
  presetColors: TColorPreset
) => {
  eventBus.emit("EVENT_TRIGGER", "MS007");
  const annotationPageIndex =
    annotation.pageIndex || instance.viewState.currentPageIndex;
  const pos = calculateAnnotationPosition(annotation);

  const color = new PSPDFKit.Color(annotation.color || annotation.strokeColor);

  const filterTag = findColorTag(color, presetColors);
  return new PSPDFKit.Annotations.NoteAnnotation({
    pageIndex: annotationPageIndex,
    text: {
      format: "plain",
      value: "",
    },
    color,
    boundingBox: new PSPDFKit.Geometry.Rect({
      left: pos.left,
      top: pos.top,
      width: 32,
      height: 32,
    }),
    customData: {
      annotationId: annotation.id,
      filterTag,
      libraryId:
        annotation.customData && annotation.customData.libraryId
          ? annotation.customData.libraryId
          : generateGuid(),
      annotationType: "NoteAnnotation",
    },
  });
};

const calculateAnnotationPosition = (annotation: Annotation) => {
  eventBus.emit("EVENT_TRIGGER", "MS013");
  const pos = {
    left: annotation.boundingBox.left,
    top: annotation.boundingBox.top - 35,
  };

  if ("rects" in annotation) {
    const rects = annotation.rects;
    const rectSize = rects.size;

    if (rects._tail) {
      const rectArr = rects._tail.array;
      const lastRect = rectArr[rectSize - 1];

      pos.left = lastRect.left + lastRect.width;
      pos.top = lastRect.top + lastRect.height - 38;
    }
  }

  if ("lines" in annotation) {
    const lines = annotation.lines;

    if (lines._tail) {
      const linesTails = lines._tail.array[0];
      const linesTailFirst = linesTails._tail.array[0];
      const linesTailChild =
        linesTails._tail.array[linesTails._tail.array.length - 1];

      if (linesTailFirst.x > linesTailChild.x) {
        pos.left = linesTailChild.x - 12 - 26;
      } else {
        pos.left = linesTailChild.x - 12 + 26;
      }

      pos.top = linesTailChild.y - 16;
    }
  }

  return pos;
};

export const findAnnotationById = (
  annotations: Annotation[],
  annotationId: string,
  type?: string
) => {
  return annotations.find((annotation) => {
    if (type === "customData") {
      return annotation.customData
        ? annotation.customData.annotationId === annotationId
        : null;
    } else {
      return annotation.id ? annotation.id === annotationId : null;
    }
  });
};

const getColorComponent = (
  obj: Annotation,
  properties: (keyof IAnnotationOptions)[]
): Color | undefined => {
  for (const prop of properties) {
    if (obj[prop]) {
      return obj[prop];
    }
  }
  return undefined;
};

const findColorTag = (color: Color, presetColor: TColorPreset) => {
  const filter = presetColor.find((obj: IColorPreset) => {
    return color &&
      obj.color &&
      color.r === obj.color.r &&
      color.g === obj.color.g &&
      color.b === obj.color.b
      ? obj
      : null;
  });
  return filter ? filter.tag : null;
};

export const showNotePopUp = (instance: Instance, annotation: Annotation) => {
  instance.setViewState((viewState) => viewState.set("interactionMode", null));
  instance.setSelectedAnnotation(annotation.id);
  const noteElements = instance.contentDocument.querySelectorAll(
    ".PSPDFKit-Note-Annotation-Icon-Comment"
  );
  const currentAnnotation = instance.getSelectedAnnotation() as Annotation;
  instance.update(currentAnnotation).then(() => {
    if (noteElements && annotation && annotation.boundingBox.top > 0) {
      noteElements.forEach((item: Element) => {
        const itemElement = item as HTMLElement;
        if (itemElement.getAttribute("data-annotation-id") === annotation.id) {
          // instance.setSelectedAnnotation(annotation.id);
          itemElement.addEventListener(
            "click",
            () => {
              //
            },
            false
          );
          itemElement.click();
          const el: HTMLElement | null = instance.contentDocument.querySelector(
            ".PSPDFKit-c8up8r9n155axqjtb8dat8e5t"
          );
          if (el) {
            el.focus();
          }
        }
      });
    }
  });
};

const getAnnotationType = (annotation: Annotation): string => {
  let type = "";
  if (annotation instanceof PSPDFKit.Annotations.NoteAnnotation) {
    type = "NoteAnnotation";
  } else if (annotation instanceof PSPDFKit.Annotations.HighlightAnnotation) {
    type = "HighlightAnnotation";
  } else if (annotation instanceof PSPDFKit.Annotations.UnderlineAnnotation) {
    type = "UnderlineAnnotation";
  } else if (annotation instanceof PSPDFKit.Annotations.StrikeOutAnnotation) {
    type = "StrikeOutAnnotation";
  } else if (annotation instanceof PSPDFKit.Annotations.SquiggleAnnotation) {
    type = "SquiggleAnnotation";
  } else if (annotation instanceof PSPDFKit.Annotations.InkAnnotation) {
    type = "InkAnnotation";
  } else if (annotation instanceof PSPDFKit.Annotations.MarkupAnnotation) {
    type = "MarkupAnnotation";
  } else if (annotation instanceof PSPDFKit.Annotations.StampAnnotation) {
    type = "StampAnnotation";
  } else if (annotation instanceof PSPDFKit.Annotations.LineAnnotation) {
    type = "LineAnnotation";
  } else if (annotation instanceof PSPDFKit.Annotations.EllipseAnnotation) {
    type = "EllipseAnnotation";
  } else if (annotation instanceof PSPDFKit.Annotations.PolylineAnnotation) {
    type = "PolylineAnnotation";
  } else if (annotation instanceof PSPDFKit.Annotations.PolygonAnnotation) {
    type = "PolygonAnnotation";
  } else if (annotation instanceof PSPDFKit.Annotations.RectangleAnnotation) {
    type = "RectangleAnnotation";
  } else if (annotation instanceof PSPDFKit.Annotations.TextAnnotation) {
    type = "TextAnnotation";
  } else {
    type = "default";
  }
  return type;
};

const loadAnnotations = async (
  instance: Instance,
  annotationData: Annotation[]
) => {
  // if (localStorage.getItem('annotationData')) {
  // let annotationData = JSON.parse(localStorage.getItem('annotationData'));
  if (annotationData && instance) {
    if (annotationData) {
      await Promise.all(
        annotationData.map(async (item) => {
          const annotationType = item.customData?.annotationType;
          if (annotationType === "NoteAnnotation") {
            await instance.create(newNoteAnnotation(item));
          }
          if (annotationType === "HighlightAnnotation") {
            await instance.create(newHighlightAnnotation(item));
          }
          if (annotationType === "StrikeOutAnnotation") {
            await instance.create(newStrikeOutAnnotation(item));
          }
          if (annotationType === "UnderlineAnnotation") {
            await instance.create(newUnderlineAnnotation(item));
          }
          if (annotationType === "SquiggleAnnotation") {
            await instance.create(newSquiggleAnnotation(item));
          }
          if (annotationType === "InkAnnotation" && !item.isSignature) {
            const annotation = newInkAnnotation(item);
            if (annotation) {
              await instance.create(annotation);
            }
          }
          if (annotationType === "PolylineAnnotation") {
            await instance.create(newPolylineAnnotation(item));
          }
          if (annotationType === "PolygonAnnotation") {
            await instance.create(newPolygonAnnotation(item));
          }
          if (annotationType === "RectangleAnnotation") {
            await instance.create(newRectangleAnnotation(item));
          }
          if (annotationType === "ArrowAnnotation") {
            await instance.create(newArrowAnnotation(item));
          }
          if (annotationType === "EllipseAnnotation") {
            await instance.create(newEllipseAnnotation(item));
          }
          if (annotationType === "LineAnnotation") {
            addAnnotationToPDF(instance, newLineAnnotation(item));
          }
          if (annotationType === "TextAnnotation") {
            addAnnotationToPDF(instance, newTextAnnotation(item));
          }
        })
      );
    }
  }
};

const addAnnotationToPDF = (instance: Instance, annotation: Annotation) => {
  eventBus.emit("EVENT_TRIGGER", "MS007");
  instance.create(annotation);
};
