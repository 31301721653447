import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "bg-white w-full h-[600px] px-4 py-2" }
const _hoisted_2 = { class: "w-full flex justify-center items-center pt-4" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditMyAccountForm = _resolveComponent("EditMyAccountForm")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_ModalUtility = _resolveComponent("ModalUtility")!

  return (_openBlock(), _createBlock(_component_ModalUtility, {
    title: "Edit My Account",
    modelValue: _ctx.isVisible,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.isVisible) = $event))
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.userProfile)
          ? (_openBlock(), _createBlock(_component_EditMyAccountForm, {
              key: 0,
              firstName: _ctx.userProfile.profile.firstName,
              "onUpdate:firstName": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.userProfile.profile.firstName) = $event)),
              lastName: _ctx.userProfile.profile.lastName,
              "onUpdate:lastName": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.userProfile.profile.lastName) = $event)),
              email: _ctx.userProfile.email,
              "onUpdate:email": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.userProfile.email) = $event)),
              country: _ctx.userProfile.schoolAddress.country,
              "onUpdate:country": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.userProfile.schoolAddress.country) = $event)),
              name: _ctx.userProfile.schoolName,
              "onUpdate:name": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.userProfile.schoolName) = $event)),
              state: _ctx.userProfile.schoolAddress.state,
              "onUpdate:state": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.userProfile.schoolAddress.state) = $event))
            }, null, 8, ["firstName", "lastName", "email", "country", "name", "state"]))
          : _createCommentVNode("", true)
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_AppButton, _mergeProps({ type: "submit" }, _ctx.$attrs, {
          loading: _ctx.isSaving,
          onClick: _ctx.handleClickSaveMyAccount
        }), {
          icon_left: _withCtx(() => [
            _createElementVNode("span", {
              innerHTML: _ctx.icons.checkBlack,
              class: "pr-2"
            }, null, 8, _hoisted_3)
          ]),
          default: _withCtx(() => [
            _createTextVNode(" " + _toDisplayString(_ctx.isSaving ? "Loading" : "Save"), 1)
          ]),
          _: 1
        }, 16, ["loading", "onClick"])
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}